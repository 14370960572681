import React from 'react';
import { CheckboxProps, InputLabel, styled } from '@mui/material';

import { Flex, FlexProps } from '../../Base';

interface CheckboxSelectionProps {
  checked: boolean;
  name?: string;
  label?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: string | number;
  checkboxSx?: CheckboxProps['sx'];
}

const HiddenCheckboxSelectionInput = styled('input')({
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const CheckboxSelectionWrapper = (props: FlexProps) => {
  const { width, height, sx, ...restProps } = props;

  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      width={{ xs: '40px', sm: '32px', md: '24px' }}
      height={{ xs: '40px', sm: '32px', md: '24px' }}
      border={'1px solid'}
      borderColor={'grey.400'}
      borderRadius={'32px'}
      position={'relative'}
      sx={sx}
      {...restProps}
    />
  );
};

type CheckboxSelectionLabelType = {
  checked: boolean;
  label?: React.ReactNode;
};

const CheckboxSelectionLabel = (props: CheckboxSelectionLabelType) => {
  const { checked = false, label = '' } = props;
  return (
    <InputLabel
      sx={{
        color: `${checked ? 'common.white' : 'grey.700'}`,
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      {label}
    </InputLabel>
  );
};

const CheckboxSelection = (props: CheckboxSelectionProps) => {
  const { checked = false, label = '', name, onBlur, onChange, value, checkboxSx } = props;

  return (
    <CheckboxSelectionWrapper bgcolor={checked ? 'grey.700' : 'common.white'} sx={checkboxSx}>
      <CheckboxSelectionLabel checked={checked} label={label} />
      <HiddenCheckboxSelectionInput
        type={'checkbox'}
        value={value}
        name={name}
        checked={checked}
        onBlur={onBlur}
        onChange={(e) => onChange?.(e)}
      />
    </CheckboxSelectionWrapper>
  );
};

export default CheckboxSelection;
