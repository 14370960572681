import { Fragment } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { Box, Flex, Text } from '../../../../Components/Base';
import FormBookmarkInput from '../../../../Components/FormBookmarkInput';
import FormAnswerYesNoChoices from '../AnswerSheet/FormAnswerYesNoChoices/FormAnswerYesNoChoices';

const choices = [
  { label: 'ใช่', value: true },
  { label: 'ไม่ใช่', value: false },
];

interface SubQuestionGroupProps {
  questionNo: number;
  formContext: UseFormReturn<FieldValues, any>;
  subQuestions: {
    label?: string;
    value?: string;
  }[];
}

const SubQuestionGroup = ({ questionNo, formContext, subQuestions }: SubQuestionGroupProps) => {
  if (subQuestions.length === 0) {
    return <Fragment />;
  } else {
    return (
      <Box>
        {subQuestions.map(({ value }, index) => {
          return (
            <Flex key={`${questionNo}.${value}`} alignItems={'center'} justifyContent={'flex-end'}>
              {index === 0 && (
                <Flex alignItems={'center'} mr={1}>
                  <FormBookmarkInput
                    name={`answers.${questionNo - 1}.isBookmark`}
                    control={formContext.control}
                  />
                  <Text
                    width={32}
                    sx={{ typography: { xs: 'subtitle1', md: 'subtitle2' } }}
                    textAlign={'center'}
                  >
                    {questionNo}
                  </Text>
                </Flex>
              )}
              <Text
                width={32}
                mr={1}
                sx={{ typography: { xs: 'subtitle1', md: 'subtitle2' } }}
                textAlign={'center'}
              >
                {`${questionNo}.${value}`}
              </Text>
              <FormAnswerYesNoChoices
                choices={choices}
                control={formContext.control}
                name={`answers.${questionNo - 1}.answer.value.${index}`}
              />
            </Flex>
          );
        })}
      </Box>
    );
  }
};

export default SubQuestionGroup;
