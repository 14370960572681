import { styled, Divider } from '@mui/material';

import { Box, Text, Flex, Label, Paper } from '../Base';
import ExaminationQuestionTitle from '../ExaminationQuestionTitle';
import ResultByQuestion from '../ResultByQuestion';

import { Difficulty, QuestionDetailType, QuestionType, ResultByQuestionItemType } from './types';

const getDifficultyLabelColor = (difficulty?: string) => {
  switch (difficulty) {
    case Difficulty.Easy:
      return 'success';
    case Difficulty.Medium:
      return 'warning';
    case Difficulty.Hard:
      return 'error';
    default:
      return undefined;
  }
};

const TitleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ResultByQuestionItem = (props: ResultByQuestionItemType) => {
  const { answer, isCorrect, questionNo, score, type } = props;

  const displayQuestionNo = `ข้อที่ ${questionNo}`;
  const displayAnswer = answer;

  switch (type) {
    case QuestionType.MultuipleSubQuestionsConnectionAnswers: {
      const examScore = score?.toFixed(4) ?? 0;
      return (
        <ResultByQuestion prefixText={displayQuestionNo} answer={displayAnswer} score={examScore} />
      );
    }

    case QuestionType.MultipleSubQuestionsYesNoAnswer: {
      const examScore = score?.toFixed(2) ?? 0;
      return (
        <ResultByQuestion prefixText={displayQuestionNo} answer={displayAnswer} score={examScore} />
      );
    }
    case QuestionType.MultipleChoiceDifferentScore: {
      const examScore = score?.toFixed(2) ?? 0;
      return (
        <ResultByQuestion prefixText={displayQuestionNo} answer={displayAnswer} score={examScore} />
      );
    }
    case QuestionType.MultipleSelectWithCorrectAnswers:
    case QuestionType.MultipleChoice:
    case QuestionType.FillNumber:
    default: {
      return (
        <ResultByQuestion
          prefixText={displayQuestionNo}
          answer={displayAnswer}
          isCorrect={isCorrect}
        />
      );
    }
  }
};

const QuestionDetail = (props: QuestionDetailType) => {
  const {
    examTitle,
    questionNo,
    partTitle,
    section,
    correctAnswer,
    answer,
    isCorrect,
    difficulty,
    variant = 'EXAMINATION_SOLUTION',
    score,
    type,
  } = props;
  const difficultyLabelColor = getDifficultyLabelColor(difficulty);

  return (
    <Paper
      sx={{
        mb: { xs: 1, sm: 2 },
        p: { xs: 2, md: 3 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      <TitleText variant="h4" mb={2}>
        {examTitle}
      </TitleText>
      <Divider />
      {variant === 'EXAMINATION_SOLUTION' && (
        <ExaminationQuestionTitle questionNo={questionNo} title={partTitle ?? ''} />
      )}
      {variant === 'EXAMINATION_RESULT' && (
        <ResultByQuestionItem
          questionNo={questionNo}
          answer={answer}
          isCorrect={isCorrect}
          score={score}
          type={type as QuestionType}
        />
      )}
      <Divider />
      <Box>
        <Flex mt={1.5} gap={1} justifyContent={'space-between'}>
          <Text variant="subtitle1" color={'text.secondary'}>
            หัวข้อ:
          </Text>
          <Text variant="subtitle1" textAlign={'right'}>
            {section}
          </Text>
        </Flex>
        {type !== QuestionType.MultipleChoiceDifferentScore && (
          <Flex mt={1.5} gap={1} justifyContent={'space-between'}>
            <Text variant="subtitle1" color={'text.secondary'}>
              เฉลย:
            </Text>
            <Text variant="subtitle1">
              {Array.isArray(correctAnswer) ? correctAnswer.join(', ') : correctAnswer}
            </Text>
          </Flex>
        )}

        <Flex mt={1.5} gap={1} justifyContent={'space-between'} alignItems={'center'}>
          <Text variant="subtitle1" color={'text.secondary'}>
            ระดับความยาก:
          </Text>
          {difficulty ? (
            <Label color={difficultyLabelColor}>{difficulty}</Label>
          ) : (
            <Text>{'-'}</Text>
          )}
        </Flex>
      </Box>
    </Paper>
  );
};

export default QuestionDetail;
