import { Box, Dialog as Modal, styled } from '@mui/material';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex } from '../../Components/Base';
import LoLButton from '../../Components/Base/LoLButton';
import { FOOTER_HEIGHT } from '../../Components/Layout';
import LoLResultItem from '../../Components/LoLResultItem';
import { useConfig } from '../../Contexts/ConfigProvider';
import { useLoLExamination } from '../../Contexts/LoLExaminationProvider';
import {
  Lol_Exam_Result_Type,
  QmsExamRoomLolExamResult,
  QmslolExamResult,
} from '../../generated/graphql';
import { getShortCurrentLanguage } from '../../i18n/utils';
import FixedMobileLayout from '../FixedMobileLayout';
import LoLExamFooterLayout from '../LoLExaminationRoom/LoLExamFooterLayout';
import SubmitLoLExamButton from '../SubmitLoLExamButton';

import { breakDescription, getLoLResultCategory, getRoundedImageBoolean } from './utils';

const StyledButton = styled(LoLButton)`
  box-shadow: none;
`;

StyledButton.defaultProps = {
  size: 'large',
  fullWidth: true,
};

type LoLResultModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  displayData?: QmslolExamResult[] | QmsExamRoomLolExamResult[];
  isExamCompleted?: boolean;
};

const LoLResultModal = (props: LoLResultModalProps) => {
  const { isOpen, handleClose, displayData, isExamCompleted = false } = props;
  const { lol = {} } = useConfig();
  const { t } = useTranslation('lolResults');
  const currentLanguage = getShortCurrentLanguage();

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const { isUnlockedExam, openRedeemModal } = useLoLExamination();

  const hasData = useMemo(() => {
    return displayData !== undefined && displayData?.length > 0;
  }, [displayData]);

  const isLastResult = useMemo(() => {
    return displayData !== undefined && currentIndex === displayData.length - 1;
  }, [currentIndex, displayData]);

  const currentResult = useMemo(() => {
    if (displayData === undefined) {
      return null;
    }
    return displayData[currentIndex];
  }, [displayData, currentIndex]);

  const goToInstruction = () => {
    setCurrentIndex(0);
    handleClose();
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace('milestones', 'instruction');
    navigate(newPath);
  };

  const goToMilestone = (isUnlockedExam: boolean | null) => {
    setCurrentIndex(0);
    handleClose();
    if (!isUnlockedExam) {
      openRedeemModal();
    }
  };

  const handleShowNextResult = debounce(() => {
    if (isLastResult) {
      if (isUnlockedExam) {
        goToInstruction();
      } else {
        goToMilestone(isUnlockedExam);
      }
      return;
    }
    setCurrentIndex((prev) => prev + 1);
  }, 500);

  const getResultTitle = (type?: Lol_Exam_Result_Type) => {
    switch (type) {
      case Lol_Exam_Result_Type.FirstRiasec:
        return `${t('CORE_TRAIT.LIST')} 1`;
      case Lol_Exam_Result_Type.SecondRiasec:
        return `${t('CORE_TRAIT.LIST')} 2`;
      case Lol_Exam_Result_Type.FirstValue:
        return `${t('CORE_TRAIT.LIST')} 3`;
      case Lol_Exam_Result_Type.SecondValue:
        return `${t('CORE_TRAIT.LIST')} 4`;
      case Lol_Exam_Result_Type.ThirdValue:
        return `${t('CORE_TRAIT.LIST')} 5`;
      case Lol_Exam_Result_Type.FirstStrength:
        return `${t('CORE_TRAIT.LIST')} 6`;
      case Lol_Exam_Result_Type.SecondStrength:
        return `${t('CORE_TRAIT.LIST')} 7`;
      case Lol_Exam_Result_Type.ThirdStrength:
        return `${t('CORE_TRAIT.LIST')} 8`;
      case Lol_Exam_Result_Type.Character:
        return t('CHARACTER');
      case Lol_Exam_Result_Type.IdentityCareer:
        return t('CAREER.IDENTITY', { ns: 'lolCommon' });
      case Lol_Exam_Result_Type.PotentialIdentityCareer:
        return t('CAREER.POTENTIAL_IDENTITY', { ns: 'lolCommon' });
      case Lol_Exam_Result_Type.PersonalityCareer:
        return t('CAREER.PERSONALITY', { ns: 'lolCommon' });
      case Lol_Exam_Result_Type.PreferencesCareer:
        return t('CAREER.PREFERENCE', { ns: 'lolCommon' });
      case Lol_Exam_Result_Type.CapableCareer:
        return t('CAREER.CAPABLE', { ns: 'lolCommon' });
      case Lol_Exam_Result_Type.CareerGroup:
      default:
        return t('CAREER.GROUP', { ns: 'lolCommon' });
    }
  };

  const renderActionButton = () => {
    if (isExamCompleted && isLastResult) {
      return <SubmitLoLExamButton />;
    } else {
      return (
        <>
          {isLastResult && (
            <StyledButton
              buttonVariant="secondary"
              fullWidth
              onClick={() => goToMilestone(isUnlockedExam)}
            >
              {t('BUTTON.BACK_TO_MILESTONES')}
            </StyledButton>
          )}
          <StyledButton onClick={handleShowNextResult}>{t('BUTTON.NEXT')}</StyledButton>
        </>
      );
    }
  };

  const renderResults = () => {
    if (!hasData) {
      return null;
    }

    const {
      type,
      description: resultDescription,
      imageUrl,
      title,
      refCode,
    } = currentResult as QmslolExamResult;

    const resultTitle = getResultTitle(type);
    const isRoundedImage = getRoundedImageBoolean(type);
    const descriptions = breakDescription(resultDescription, type);

    const isCareerGroup = type === Lol_Exam_Result_Type.CareerGroup;
    const { sharingWebUrl } = lol;
    const permalink =
      type && refCode
        ? `${sharingWebUrl}/${currentLanguage}/${getLoLResultCategory(type)}/${refCode}`
        : undefined;

    return (
      <>
        <Box
          p={3}
          pb={10}
          minHeight={`calc(100% - ${FOOTER_HEIGHT}px)`}
          height={'auto'}
          id="scroll-top"
        >
          <LoLResultItem
            permalink={permalink}
            title={title}
            descriptions={descriptions}
            resultTitle={resultTitle}
            imageUrl={imageUrl}
            index={currentIndex}
            isShowSharedButton={!isCareerGroup}
            isRoundedImage={isRoundedImage}
          />
        </Box>
        <LoLExamFooterLayout>
          <Flex columnGap={1} flex={1}>
            {renderActionButton()}
          </Flex>
        </LoLExamFooterLayout>
      </>
    );
  };

  return (
    <Modal open={isOpen} fullScreen PaperProps={{ sx: { bgcolor: 'transparent' } }}>
      <FixedMobileLayout>
        <Box
          minHeight={1}
          height={'auto'}
          overflow={'auto'}
          bgcolor={'lolBackground.primary'}
          color={'lolText.primary'}
        >
          {renderResults()}
        </Box>
      </FixedMobileLayout>
    </Modal>
  );
};

export default LoLResultModal;
