import { SvgIcon, SvgIconProps } from '@mui/material';

const CrownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 38 38" {...props}>
      <path
        fill="inherit"
        d="M35.236 12.477a2.145 2.145 0 0 0-2.432-.318l-6.43 3.288-5.483-9.35a2.236 2.236 0 0 0-.805-.804 2.165 2.165 0 0 0-2.173 0 2.235 2.235 0 0 0-.805.804l-5.484 9.35-6.43-3.287a2.16 2.16 0 0 0-2.43.313c-.33.292-.567.677-.684 1.108a2.32 2.32 0 0 0 .03 1.312l5.073 15.94c.076.24.204.46.374.642s.378.324.608.413a1.607 1.607 0 0 0 1.418-.126c.033-.02 3.435-2.012 9.414-2.012 5.978 0 9.38 1.995 9.407 2.01a1.618 1.618 0 0 0 1.421.133c.232-.09.44-.23.611-.413.171-.183.3-.404.376-.645l5.073-15.931c.14-.425.153-.883.037-1.315a2.258 2.258 0 0 0-.686-1.112Zm-6.982 15.518c-1.83-.72-4.98-1.62-9.257-1.62-4.277 0-7.428.902-9.258 1.622l-3.701-11.63 5.008 2.555c.497.252 1.07.304 1.602.145.533-.16.989-.518 1.277-1.005l5.072-8.647 5.072 8.649c.288.486.744.844 1.277 1.003a2.167 2.167 0 0 0 1.602-.145l5.006-2.56-3.7 11.633Zm-2.696-4.36a1.7 1.7 0 0 1-.555 1.024 1.62 1.62 0 0 1-1.325.387 30.655 30.655 0 0 0-9.368 0 1.613 1.613 0 0 1-1.223-.307 1.694 1.694 0 0 1-.654-1.104c-.066-.442.04-.893.299-1.254a1.64 1.64 0 0 1 1.076-.67 33.87 33.87 0 0 1 10.372 0c.214.033.419.109.604.224s.346.266.474.445a1.708 1.708 0 0 1 .3 1.255Z"
      />
    </SvgIcon>
  );
};

export default CrownIcon;
