import { useNavigate } from 'react-router-dom';

import { Flex } from '../../Components/Base';
import AccountCircleIcon from '../../Components/Icons/AccountCircleIcon';

const UserButton = () => {
  const navigate = useNavigate();

  const handleGoToProfilePage = () => {
    navigate('/profile');
  };

  return (
    <Flex alignItems={'center'} onClick={handleGoToProfilePage} sx={{ cursor: 'pointer' }}>
      <AccountCircleIcon sx={{ fontSize: 36, color: 'grey.700' }} />
    </Flex>
  );
};

export default UserButton;
