import { Fragment } from 'react';

import { Button, IconButton, Skeleton } from '../../../Components/Base';
import AddRoundedIcon from '../../../Components/Icons/AddRoundedIcon';
import useGetMyBooks from '../../../Domains/useGetMyBooks';
import useDisclosure from '../../../Utils/useDisclosure';
import useGoogleTagManager from '../../../Utils/useGoogleTagManager';

import ImportBookModal from './ImportBookModal';

const RedeemBookButton = () => {
  const { isOpen, open, close } = useDisclosure();
  const sendDataToGTM = useGoogleTagManager();

  const { data, loading } = useGetMyBooks();
  const myBooks = data?.qmsMyBooks;

  if (loading) {
    return (
      <>
        <Skeleton
          width={112}
          height={35}
          sx={{ display: { xs: 'none', sm: 'inherit' }, borderRadius: 48 }}
        />
        <Skeleton width={36} height={36} sx={{ display: { sm: 'none' }, borderRadius: 36 }} />
      </>
    );
  }

  if (myBooks && myBooks.length === 0) return <Fragment />;

  const handleGoToAddNewBookTracking = () => {
    sendDataToGTM({
      event: 'add_new_book_button_click',
    });
  };

  const handleAddNewBook = () => {
    handleGoToAddNewBookTracking();
    open();
  };

  return (
    <>
      <Button
        onClick={handleAddNewBook}
        size={'small'}
        sx={{ display: { xs: 'none', sm: 'inherit' } }}
      >
        <AddRoundedIcon sx={{ fontSize: 16, mr: 0.5 }} />
        เพิ่มหนังสือ
      </Button>
      <IconButton
        onClick={handleAddNewBook}
        sx={{
          bgcolor: 'primary.main',
          display: { sm: 'none' },
          '&:hover': { bgcolor: 'primary.dark' },
        }}
      >
        <AddRoundedIcon sx={{ color: 'common.white' }} />
      </IconButton>
      <ImportBookModal isOpen={isOpen} onClose={close} />
    </>
  );
};

export default RedeemBookButton;
