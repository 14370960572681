import { SvgIcon, SvgIconProps } from '@mui/material';
const InProgressIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 100 100" {...props}>
    <path
      fill={'currentColor'}
      fillRule="evenodd"
      d="M75.302 64.51a29.166 29.166 0 1 1-8.382-38.267 4.167 4.167 0 0 0 4.834-6.788 37.5 37.5 0 1 0 15.554 34.333 4.167 4.167 0 1 0-8.29-.842 29.164 29.164 0 0 1-3.716 11.564Z"
      clipRule="evenodd"
    />
    <path
      fill={'currentColor'}
      fillRule="evenodd"
      d="M78.036 29.878c.692-.94.958-2.128.733-3.274l-3.148-16.018a4.167 4.167 0 1 0-8.176 1.607l2.271 11.56L56.66 25.16a4.167 4.167 0 0 0 .893 8.285l17.575-1.895a4.167 4.167 0 0 0 2.91-1.672Z"
      clipRule="evenodd"
    />
    <path
      fill={'currentColor'}
      d="M54.167 50a4.167 4.167 0 1 1-8.334 0 4.167 4.167 0 0 1 8.334 0ZM70.834 50a4.167 4.167 0 1 1-8.334 0 4.167 4.167 0 0 1 8.334 0ZM37.5 50a4.167 4.167 0 1 1-8.333 0 4.167 4.167 0 0 1 8.333 0Z"
    />
  </SvgIcon>
);
export default InProgressIcon;
