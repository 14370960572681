import { SvgIcon, SvgIconProps } from '@mui/material';

const RecognitionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        d="M8.33331 8.33328V31.6666C8.33331 32.1086 8.50891 32.5326 8.82147 32.8451C9.13403 33.1577 9.55795 33.3333 9.99998 33.3333H15V29.9999H11.6666V9.99995H15V6.66661H9.99998C9.55795 6.66661 9.13403 6.84221 8.82147 7.15477C8.50891 7.46733 8.33331 7.89125 8.33331 8.33328ZM32.07 6.71661L18.7366 3.38328C18.491 3.32201 18.2346 3.31751 17.987 3.37012C17.7394 3.42272 17.507 3.53105 17.3074 3.68687C17.1079 3.8427 16.9465 4.04193 16.8355 4.26945C16.7245 4.49696 16.6667 4.74678 16.6666 4.99995V34.9999C16.6661 35.2533 16.7234 35.5035 16.8342 35.7313C16.945 35.9592 17.1065 36.1587 17.3062 36.3147C17.5059 36.4706 17.7385 36.5789 17.9864 36.6312C18.2344 36.6836 18.491 36.6786 18.7366 36.6166L32.07 33.2833C32.4307 33.1933 32.751 32.9853 32.9799 32.6924C33.2088 32.3994 33.3332 32.0384 33.3333 31.6666V8.33328C33.3332 7.96151 33.2088 7.60045 32.9799 7.30751C32.751 7.01457 32.4307 6.80658 32.07 6.71661ZM25 20.3133C24.9808 20.7424 24.7968 21.1476 24.4863 21.4445C24.1759 21.7414 23.7629 21.9071 23.3333 21.9071C22.9038 21.9071 22.4907 21.7414 22.1803 21.4445C21.8698 21.1476 21.6858 20.7424 21.6666 20.3133V19.6849C21.6669 19.2429 21.8427 18.8191 22.1554 18.5067C22.4681 18.1943 22.8921 18.0189 23.3341 18.0191C23.7762 18.0193 24.2 18.1951 24.5124 18.5079C24.8248 18.8206 25.0002 19.2446 25 19.6866V20.3133Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default RecognitionIcon;
