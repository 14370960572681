import { SvgIcon, SvgIconProps } from '@mui/material';

const WorkingConditionsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66669 8.33329C1.66669 7.41282 2.41288 6.66663 3.33335 6.66663H21.6667C22.5872 6.66663 23.3334 7.41282 23.3334 8.33329V15C23.3334 15.9204 22.5872 16.6666 21.6667 16.6666H3.33335C2.41288 16.6666 1.66669 15.9204 1.66669 15V8.33329ZM5.00002 9.99996V13.3333H20V9.99996H5.00002ZM27.1548 7.15478C27.8057 6.50391 28.861 6.50391 29.5119 7.15478L31.6667 9.3096L33.8215 7.15478C34.4724 6.50391 35.5277 6.50391 36.1785 7.15478C36.8294 7.80566 36.8294 8.86093 36.1785 9.5118L34.0237 11.6666L36.1785 13.8214C36.8294 14.4723 36.8294 15.5276 36.1785 16.1785C35.5277 16.8293 34.4724 16.8293 33.8215 16.1785L31.6667 14.0236L29.5119 16.1785C28.861 16.8293 27.8057 16.8293 27.1548 16.1785C26.504 15.5276 26.504 14.4723 27.1548 13.8214L29.3097 11.6666L27.1548 9.5118C26.504 8.86093 26.504 7.80566 27.1548 7.15478ZM37.7642 23.7457C38.4569 24.3518 38.5271 25.4047 37.921 26.0975L32.0876 32.7641C31.7839 33.1113 31.3498 33.317 30.8888 33.3324C30.4278 33.3477 29.981 33.1713 29.6548 32.8451L26.3215 29.5118C25.6706 28.8609 25.6706 27.8057 26.3215 27.1548C26.9724 26.5039 28.0277 26.5039 28.6785 27.1548L30.7522 29.2284L35.4124 23.9025C36.0185 23.2097 37.0715 23.1395 37.7642 23.7457ZM1.66669 25C1.66669 24.0795 2.41288 23.3333 3.33335 23.3333H21.6667C22.5872 23.3333 23.3334 24.0795 23.3334 25V31.6666C23.3334 32.5871 22.5872 33.3333 21.6667 33.3333H3.33335C2.41288 33.3333 1.66669 32.5871 1.66669 31.6666V25ZM5.00002 26.6666V30H20V26.6666H5.00002Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default WorkingConditionsIcon;
