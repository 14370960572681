import { Navigate, useParams } from 'react-router-dom';

import { MYBOOKS_RESULTS } from '../constant';

const ResultDetails = () => {
  const { bookId, examPaperId } = useParams();
  const resultPath = MYBOOKS_RESULTS.replace(':bookId', bookId ?? '');
  const overviewPath = `${resultPath}/${examPaperId}/overview`;

  return <Navigate to={overviewPath} replace />;
};

export default ResultDetails;
