import { LoLExaminationSection } from '../../Contexts/LoLExaminationProvider/types';
import { Lol_Exam_Result_Type } from '../../generated/graphql';

interface ResultCard {
  title?: string;
  type: Lol_Exam_Result_Type;
  tags?: string[];
  conditionNumber: number;
}

export const careerList: ResultCard[] = [
  {
    title: 'GROUP',
    type: Lol_Exam_Result_Type.CareerGroup,
    tags: [LoLExaminationSection.RIASEC_PART_1],
    conditionNumber: 1,
  },
  {
    title: 'CAPABLE',
    type: Lol_Exam_Result_Type.CapableCareer,
    tags: [LoLExaminationSection.RIASEC_PART_1, LoLExaminationSection.RIASEC_PART_2],
    conditionNumber: 2,
  },
  {
    title: 'PERSONALITY',
    type: Lol_Exam_Result_Type.PersonalityCareer,
    tags: [
      LoLExaminationSection.RIASEC_PART_1,
      LoLExaminationSection.RIASEC_PART_2,
      LoLExaminationSection.VALUE,
    ],
    conditionNumber: 3,
  },
  {
    title: 'IDENTITY',
    type: Lol_Exam_Result_Type.IdentityCareer,
    tags: [
      LoLExaminationSection.RIASEC_PART_1,
      LoLExaminationSection.RIASEC_PART_2,
      LoLExaminationSection.VALUE,
      LoLExaminationSection.ACTIVITY_PART_1,
      LoLExaminationSection.ACTIVITY_PART_2,
    ],
    conditionNumber: 4,
  },
  {
    title: 'POTENTIAL_IDENTITY',
    type: Lol_Exam_Result_Type.PotentialIdentityCareer,
    tags: [
      LoLExaminationSection.RIASEC_PART_1,
      LoLExaminationSection.RIASEC_PART_2,
      LoLExaminationSection.VALUE,
      LoLExaminationSection.ACTIVITY_PART_1,
      LoLExaminationSection.ACTIVITY_PART_2,
    ],
    conditionNumber: 4,
  },
  {
    title: 'PREFERENCE',
    type: Lol_Exam_Result_Type.PreferencesCareer,
    tags: [
      LoLExaminationSection.RIASEC_PART_1,
      LoLExaminationSection.RIASEC_PART_2,
      LoLExaminationSection.ACTIVITY_PART_1,
      LoLExaminationSection.ACTIVITY_PART_2,
    ],
    conditionNumber: 4,
  },
];

export const characterList: ResultCard[] = [
  {
    type: Lol_Exam_Result_Type.Character,
    tags: [LoLExaminationSection.RIASEC_PART_1],
    conditionNumber: 1,
  },
];

export const coreTraitList: ResultCard[] = [
  {
    type: Lol_Exam_Result_Type.FirstRiasec,
    tags: [LoLExaminationSection.RIASEC_PART_1, LoLExaminationSection.RIASEC_PART_2],
    conditionNumber: 2,
  },
  {
    type: Lol_Exam_Result_Type.SecondRiasec,
    tags: [LoLExaminationSection.RIASEC_PART_1, LoLExaminationSection.RIASEC_PART_2],
    conditionNumber: 2,
  },
  {
    type: Lol_Exam_Result_Type.FirstValue,
    tags: [LoLExaminationSection.VALUE],
    conditionNumber: 3,
  },
  {
    type: Lol_Exam_Result_Type.SecondValue,
    tags: [LoLExaminationSection.VALUE],
    conditionNumber: 3,
  },
  {
    type: Lol_Exam_Result_Type.ThirdValue,
    tags: [LoLExaminationSection.VALUE],
    conditionNumber: 3,
  },
  {
    type: Lol_Exam_Result_Type.FirstStrength,
    tags: [LoLExaminationSection.STRENGTH],
    conditionNumber: 4,
  },
  {
    type: Lol_Exam_Result_Type.SecondStrength,
    tags: [LoLExaminationSection.STRENGTH],
    conditionNumber: 4,
  },
  {
    type: Lol_Exam_Result_Type.ThirdStrength,
    tags: [LoLExaminationSection.STRENGTH],
    conditionNumber: 4,
  },
];
