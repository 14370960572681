import { SvgIcon, SvgIconProps } from '@mui/material';

const TimeUpIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 100 100" {...props}>
    <path
      d="M50 91.667c-5.208 0-10.086-.99-14.633-2.967-4.55-1.98-8.508-4.656-11.875-8.025-3.37-3.367-6.045-7.325-8.025-11.875-1.978-4.547-2.967-9.425-2.967-14.633 0-5.209.99-10.088 2.967-14.638 1.98-4.547 4.655-8.505 8.025-11.875 3.366-3.366 7.325-6.039 11.875-8.017 4.547-1.98 9.425-2.97 14.633-2.97 5.209 0 10.088.99 14.638 2.97 4.547 1.978 8.505 4.65 11.875 8.017 3.366 3.37 6.04 7.328 8.02 11.875 1.978 4.55 2.967 9.43 2.967 14.638s-.989 10.086-2.966 14.633c-1.981 4.55-4.655 8.508-8.021 11.875-3.37 3.37-7.328 6.044-11.875 8.025-4.55 1.978-9.43 2.967-14.638 2.967ZM45.834 37.5v16.563c0 .555.104 1.093.312 1.612.208.522.52.992.938 1.408l11.77 11.771c.764.764 1.702 1.146 2.813 1.146 1.11 0 2.083-.417 2.916-1.25.764-.764 1.146-1.736 1.146-2.917 0-1.18-.382-2.152-1.145-2.916L54.166 52.5V37.396c0-1.18-.399-2.153-1.196-2.917-.8-.764-1.79-1.146-2.97-1.146-1.181 0-2.17.399-2.968 1.196-.8.8-1.2 1.79-1.2 2.971ZM14.27 30.52c-.764.765-1.701 1.147-2.813 1.147-1.11 0-2.083-.417-2.916-1.25-.764-.764-1.146-1.736-1.146-2.917 0-1.18.382-2.153 1.146-2.917L20.52 12.604c.764-.764 1.701-1.146 2.813-1.146 1.11 0 2.083.417 2.916 1.25.764.764 1.146 1.736 1.146 2.917 0 1.18-.382 2.153-1.146 2.917L14.271 30.52Zm71.354-.103-11.979-11.98c-.764-.763-1.146-1.7-1.146-2.812 0-1.111.417-2.083 1.25-2.917.764-.764 1.736-1.146 2.917-1.146 1.18 0 2.153.383 2.916 1.146l11.98 11.98c.764.763 1.145 1.7 1.145 2.812 0 1.111-.416 2.083-1.25 2.917-.763.764-1.736 1.145-2.916 1.145s-2.153-.381-2.917-1.145ZM50 83.333c8.125 0 15.017-2.83 20.675-8.491 5.661-5.659 8.492-12.55 8.492-20.675s-2.83-15.018-8.492-20.68C65.017 27.83 58.125 25 50 25s-15.017 2.83-20.675 8.487c-5.66 5.662-8.491 12.555-8.491 20.68s2.83 15.016 8.491 20.675c5.659 5.66 12.55 8.491 20.675 8.491Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TimeUpIcon;
