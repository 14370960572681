import { useMemo } from 'react';

import { Button, Flex, Text } from '../../../Components/Base';
import AddIcon from '../../../Components/Icons/AddIcon';
import LoadingIcon from '../../../Components/Icons/LoadingIcon';
import MyBookIcon from '../../../Components/Icons/MyBookIcon';
import RedeemBookForm, {
  RedeemBookFormContext,
  RedeemBookFormSubmission,
} from '../Components/RedeemBookForm';

const initialFormValues = {
  myBookCode: null,
};

const AddBookButton = (formContext: RedeemBookFormContext) => {
  const { watch, formState } = formContext;
  const { isValid, isSubmitting } = formState;
  const { myBookCode } = watch();

  const isEmptyMyBookCode = useMemo(
    () => myBookCode === null || myBookCode?.trim() === '',
    [myBookCode]
  );

  return (
    <Button
      type="submit"
      size={'large'}
      fullWidth
      disabled={isEmptyMyBookCode || !isValid || isSubmitting}
    >
      {formContext.formState.isSubmitting ? (
        <>
          <LoadingIcon sx={{ mr: 0.5 }} />
          กำลังโหลดข้อมูลหนังสือ...
        </>
      ) : (
        <>
          <AddIcon sx={{ mr: 0.5 }} />
          เพิ่มหนังสือ
        </>
      )}
    </Button>
  );
};

const RedeemBook = ({ onRedeemCode }: { onRedeemCode: RedeemBookFormSubmission }) => {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      maxWidth={350}
      height={{ xs: '100%', sm: 'auto' }}
      mt={{ sm: 6 }}
      mx={'auto'}
    >
      <MyBookIcon color={'primary'} sx={{ width: '115px', height: '100px' }} />
      <Text sx={{ typography: { xs: 'h3', md: 'h2' } }} my={4}>
        กรอกโค้ดหนังสือ
      </Text>
      <RedeemBookForm
        onSubmit={onRedeemCode}
        initialFormValues={initialFormValues}
        submitComponent={AddBookButton}
      />
    </Flex>
  );
};

export default RedeemBook;
