import { Text } from '../../Components/Base';

import SolutionList from './Containers/SolutionList';

const BookSolutions = () => {
  return (
    <>
      <Text variant={'h3'} sx={{ pl: 2, mb: 1 }}>
        เฉลยข้อสอบทั้งหมด
      </Text>
      <SolutionList />
    </>
  );
};

export default BookSolutions;
