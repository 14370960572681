import { Label, LegacyLabelProps, Text } from '../Base';
import TimerOutlinedIcon from '../Icons/TimerOutlinedIcon';

export type ExaminationTimerProps = {
  timer: string;
  severity: 'noWarning' | 'warningThirty' | 'warningFifteen' | 'warningFive';
} & LegacyLabelProps;

const getColorVariant = (severity: ExaminationTimerProps['severity']) => {
  switch (severity) {
    case 'warningThirty':
      return 'info';
    case 'warningFifteen':
      return 'warning';
    case 'warningFive':
      return 'error';
    case 'noWarning':
    default:
      return 'success';
  }
};

const TimerLabel = ({ timer, severity, ...restProps }: ExaminationTimerProps) => {
  const color = getColorVariant(severity);

  return (
    <Label color={color} display={'flex'} alignItems={'center'} {...restProps}>
      <TimerOutlinedIcon fontSize={'small'} sx={{ color: `${color}.dark` }} />
      <Text
        variant={'body2'}
        color={`${color}.dark`}
        fontWeight={700}
        fontFamily={'Azeret Mono'}
        ml={0.5}
      >
        {timer}
      </Text>
    </Label>
  );
};

export default TimerLabel;
