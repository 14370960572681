import { Button, Flex, FlexProps, Text } from '../Base';
import PageErrorIcon from '../Icons/PageErrorIcon';

export type DataLoadingErrorProps = {
  onRetry: () => void;
} & FlexProps;

export const DataLoadingError = ({ onRetry, ...restProps }: DataLoadingErrorProps) => {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      maxWidth={350}
      height={{ xs: '100%', sm: 'auto' }}
      mx={'auto'}
      {...restProps}
    >
      <PageErrorIcon
        color={'primary'}
        sx={{
          width: 100,
          height: 100,
        }}
      />
      <Text sx={{ typography: { xs: 'h3', md: 'h2' } }} mt={{ xs: 3, sm: 4 }} mb={1}>
        เกิดข้อผิดพลาดในการโหลดข้อมูล
      </Text>
      <Text mb={3} textAlign={'center'}>
        กรุณาลองใหม่อีกครั้ง
      </Text>
      <Button fullWidth size={'large'} onClick={onRetry}>
        <Text>ลองใหม่อีกครั้ง</Text>
      </Button>
    </Flex>
  );
};
