import { Flex, FlexProps, Text } from '../Base';

export type ExaminationResultScoreCardProps = {
  iteration: string | number;
  score: string | number;
  fullScore: string | number;
  startedExamAt: string;
} & FlexProps;

const ExaminationResultScoreCard = ({
  iteration,
  score,
  fullScore,
  startedExamAt,
  ...restProps
}: ExaminationResultScoreCardProps) => {
  return (
    <Flex flexDirection="column" alignItems="center" {...restProps}>
      <Text variant="body1">คะแนนที่ได้/คะแนนเต็ม</Text>
      <Text variant="h1" color="primary.main">
        {score}/{fullScore}
      </Text>
      <Text variant="body2" color="text.secondary">
        ครั้งที่ {iteration} {startedExamAt}
      </Text>
    </Flex>
  );
};

export default ExaminationResultScoreCard;
