import { useTranslation } from 'react-i18next';

import LoLButton from '../../../../Components/Base/LoLButton';
import { ButtonProps } from '../../../../Components/Base';

type LoLContinueButtonProps = ButtonProps;

const LoLContinueButton = (props: LoLContinueButtonProps) => {
  const { t } = useTranslation('lolCommon');

  return (
    <LoLButton fullWidth size="large" {...props}>
      {t('BUTTON.CONTINUE')}
    </LoLButton>
  );
};

export default LoLContinueButton;
