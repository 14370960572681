import { SvgIcon, SvgIconProps } from '@mui/material';

const MyExamResultIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 100 100" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M95.51 89.52h-10.2c-1.1 0-2-.9-2-2V23.86c0-.28.06-.56.17-.82l5.1-11.37a2.01 2.01 0 0 1 3.66 0l5.1 11.37c.12.26.17.54.17.82v63.66c0 1.1-.9 2-2 2Zm-8.2-4h6.2V24.29l-3.1-6.91-3.1 6.91v61.23Z"
        fill="#5F6060"
      />
      <path
        d="M95.51 89.52h-10.2c-1.1 0-2-.9-2-2V77.27c0-1.1.9-2 2-2h10.2c1.1 0 2 .9 2 2v10.25c0 1.1-.9 2-2 2Zm-8.2-4h6.2v-6.25h-6.2v6.25Z"
        fill="#5F6060"
      />
      <path
        d="M73.73 100H5.67C3.64 100 2 98.35 2 96.33V3.67C2 1.65 3.65 0 5.67 0h50.08c.53 0 1.04.21 1.41.58L76.81 20.2c.38.38.59.88.59 1.42v74.71c0 2.03-1.65 3.67-3.67 3.67ZM6 96h67.4V22.45L54.93 4H6v92Z"
        fill={'currentColor'}
      />
      <path
        d="M75.4 23.62H57.43c-2.03 0-3.67-1.65-3.67-3.67V2c0-.81.49-1.54 1.23-1.85a2 2 0 0 1 2.18.43L76.82 20.2a2 2 0 0 1 .43 2.18c-.31.75-1.04 1.24-1.85 1.24Zm-17.65-4h12.82L57.75 6.82v12.8Z"
        fill={'currentColor'}
      />
      <path
        d="M18.83 63.48h-.13c-.56-.04-1.07-.3-1.43-.74l-3.42-4.23a2 2 0 0 1 .3-2.81 2 2 0 0 1 2.81.3l2.06 2.54 8.29-7.89c.8-.76 2.07-.73 2.83.07.76.8.73 2.07-.07 2.83l-9.86 9.39c-.37.35-.87.55-1.38.55v-.01ZM63.99 54.09H40.17c-1.1 0-2-.9-2-2s.9-2 2-2h23.82c1.1 0 2 .9 2 2s-.9 2-2 2ZM55.34 63.48H40.17c-1.1 0-2-.9-2-2s.9-2 2-2h15.17c1.1 0 2 .9 2 2s-.9 2-2 2ZM63.99 76.84H40.17c-1.1 0-2-.9-2-2s.9-2 2-2h23.82c1.1 0 2 .9 2 2s-.9 2-2 2ZM55.34 86.23H40.17c-1.1 0-2-.9-2-2s.9-2 2-2h15.17c1.1 0 2 .9 2 2s-.9 2-2 2ZM26.72 86.23c-.51 0-1.03-.2-1.42-.59l-9.34-9.39c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0l9.34 9.39c.78.78.78 2.05 0 2.83-.39.39-.9.58-1.41.58v.01Z"
        fill="#5F6060"
      />
      <path
        d="M17.39 86.23a1.995 1.995 0 0 1-1.41-3.41l9.34-9.39c.78-.78 2.05-.79 2.83 0 .78.78.79 2.05 0 2.83l-9.34 9.39c-.39.39-.9.59-1.42.59v-.01ZM59.31 39.04H21.02c-1.1 0-2-.9-2-2s.9-2 2-2h38.29c1.1 0 2 .9 2 2s-.9 2-2 2ZM51.16 29.25H29.17c-1.1 0-2-.9-2-2s.9-2 2-2h22c1.1 0 2 .9 2 2s-.9 2-2 2h-.01Z"
        fill="#5F6060"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MyExamResultIcon;
