import { createTheme, experimental_sx as sx } from '@mui/material/styles';

declare module '@mui/material/Fab' {
  interface FabPropsVariantOverrides {
    circularLight: true;
  }
}

const fabTheme = createTheme({
  components: {
    MuiFab: {
      styleOverrides: {
        root: sx({
          boxShadow: '0px 8px 16px hsla(210, 10%, 23%, 0.15)',
          '&:active': {
            boxShadow: '0px 8px 16px hsla(210, 10%, 23%, 0.15)',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'circularLight' },
          style: sx({
            bgcolor: 'common.white',
            color: 'greyButton.main',
            border: 1,
            borderColor: 'greyButton.main',
            '&:hover': {
              bgcolor: 'primary.dark',
              color: 'greyButton.contrastText',
              border: 0,
            },
          }),
        },
      ],
    },
  },
});

export default fabTheme;
