import { useNavigate } from 'react-router-dom';

import { Button, ButtonProps } from '../../../../Components/Base';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

type ReviewAnswerButtonProps = ButtonProps;

const ReviewAnswerButton = (props: ReviewAnswerButtonProps) => {
  const navigate = useNavigate();

  const sendDataToGTM = useGoogleTagManager();

  const handleButtonClick = () => {
    sendDataToGTM({ event: 'examination_review_button_click' });
    const path = location.pathname.replace('examroom', 'review');
    navigate(path, { replace: true });
  };

  return (
    <Button
      sx={{ maxWidth: { xs: 1, sm: '200px' }, width: 1 }}
      size={'large'}
      onClick={handleButtonClick}
      {...props}
    >
      ตรวจทานข้อสอบ
    </Button>
  );
};

export default ReviewAnswerButton;
