import { Box, Divider, Paper, PaperProps } from '@mui/material';

import { Text } from '../../../../../Components/Base';
import {
  getExamTypeDetail,
  getRangeOfQuestion,
} from '../../../../../Domains/useGetExaminationDetails/utils';
import { AnswerSheetByGroupProps } from '../../../Components/AnswerSheet/AnswerSheetByGroup/types';
import FormAnswerItem from '../../FormAnswerItem/FormAnswerItem';

const AnswerSheetTypeConnectionAnswer = ({
  group,
  questions,
  ...restProps
}: AnswerSheetByGroupProps & PaperProps) => {
  const questionGroup = group;
  const questionRangeText = getRangeOfQuestion(questions);

  return (
    <Paper
      elevation={4}
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 1, md: 2 },
        borderRadius: { xs: 0, sm: 1 },
        bgcolor: 'common.white',
      }}
      {...restProps}
    >
      <Text mb={2} variant={'subtitle2'}>
        {`${questionRangeText} ${getExamTypeDetail(questionGroup)}`}
      </Text>
      <Divider />
      {questions?.map((question, index) => {
        const questionNo = question?.order ?? 0;
        const questionType = question?.type;

        return (
          <Box
            key={`${questionType}_${index}`}
            id={`${questionNo}`}
            sx={{ mt: index === 0 ? 2 : 0 }}
          >
            {index > 0 && <Divider sx={{ my: 4 }} />}
            <FormAnswerItem question={question} />
          </Box>
        );
      })}
    </Paper>
  );
};

export default AnswerSheetTypeConnectionAnswer;
