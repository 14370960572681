import { useParams, useNavigate } from 'react-router-dom';
import Portal from '@mui/material/Portal';
import { useState } from 'react';

import { Button, ButtonProps } from '../../../../Components/Base';
import { FormAnswerQuestionType } from '../../../../Contexts/ExaminationProvider';
import useUpdateExaminationPaper from '../../../../Domains/useUpdateExaminationPaper';
import useExamRoomSubmitExamPaper from '../../../../Domains/useExamRoomSubmitExamPaper';
import useDisclosure from '../../../../Utils/useDisclosure';
import ExaminationSubmitModal from '../../../ExaminationReview/Components/ExaminationSubmitModal';
import SubmitExaminationSuccessModal from '../../../../Components/SubmitExaminationSuccessModal';
import useGetExaminationDetails from '../../../../Domains/useGetExaminationDetails';
import useGetExaminationSession, {
  QmsExamSession,
} from '../../../../Domains/useGetExaminationSession';
import {
  getInitialAnswers,
  getSubmitAnswers,
} from '../../../../Contexts/ExaminationProvider/ExaminationProvider';
import { UpdateExamPaperType } from '../../../../Domains/useUpdateExaminationPaper/useUpdateExaminationPaper';
import SubmitExamErrorSnackbar from '../../../ExaminationReview/Components/SubmitExamErrorSnackbar';
import { ExamAnswerType } from '../../../../Contexts/ExaminationProvider/types';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

type ExaminationSubmitButtonProps = ButtonProps;

const ExaminationSubmitButton = (props: ExaminationSubmitButtonProps) => {
  const {
    isOpen: isSuccessModalOpen,
    open: openSuccessModal,
    close: closeSuccessModal,
  } = useDisclosure();
  const {
    isOpen: isSubmitModalOpen,
    open: openSubmitModal,
    close: closeSubmitModal,
  } = useDisclosure();
  const { isOpen: isSnackbarOpen, open: openSnackbar, close: closeSnackbar } = useDisclosure();
  const navigate = useNavigate();
  const { bookId = '' } = useParams();

  const sendDataToGTM = useGoogleTagManager();

  const { data: examinationData, loading: examinationDataLoading } = useGetExaminationDetails();
  const { data: examSession, loading: examSessionDataLoading } = useGetExaminationSession();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const examData = examinationData?.qmsExamRoomGetExam;
  const examItems = examData?.examItems ?? [];
  const sortedExamItems = [...examItems].sort((current, next) => current.order - next.order);

  const examSessionAnswer = (examSession?.qmsExamRoomGetExamSessionAnswer ?? {}) as QmsExamSession;
  const examAnswer = examSessionAnswer?.examRoomAnswer?.examRoomExamPaperItems ?? [];
  const initialAnswers = getInitialAnswers((examAnswer ?? []) as ExamAnswerType) ?? [];
  const answersMapping = sortedExamItems.map(({ question }) => {
    const answer = initialAnswers.find((answer) => answer.questionId === question?.id);
    return {
      examPaperItemId: answer?.examPaperItemId,
      questionId: answer?.questionId,
      answer: answer?.answer,
      isBookmark: answer?.isBookmark,
    };
  });

  const [updateAnswer, { loading: updateLoading }] = useUpdateExaminationPaper({
    onError: (error) => {
      openSnackbar();
    },
  });
  const [submitExam, { loading: submitLoading }] = useExamRoomSubmitExamPaper({
    onError: (error) => {
      openSnackbar();
    },
  });

  const handleUpdateAnswer = async () => {
    setIsSubmitting(true);
    sendDataToGTM({
      event: 'examination_instruction_cta_click',
      buttonCategory: 'submit examination',
    });
    const { data: updatedData } = await updateAnswer({
      variables: { examRoomExamPaperItems: answersMapping as FormAnswerQuestionType[] },
    });
    if (!updateLoading && updatedData) {
      const answers = updatedData.qmsExamRoomUpdateExamPaper.examRoomExamPaperItems;
      await handleSubmitExamPaper(answers);
    }
    setIsSubmitting(false);
  };

  const handleSubmitExamPaper = async (
    answers: UpdateExamPaperType['qmsExamRoomUpdateExamPaper']['examRoomExamPaperItems']
  ) => {
    const submitAnswers = getSubmitAnswers(answers as FormAnswerQuestionType[]);
    const { data: submitExamData } = await submitExam({
      variables: { submitExamInput: { rawAnswers: submitAnswers } },
    });

    if (!submitLoading && submitExamData) {
      closeSubmitModal();
      openSuccessModal();
    }
  };

  const goToExaminationResult = () => {
    closeSuccessModal();
    navigate(`/mybooks/${bookId}/results`, { replace: true });
  };

  const goToExamination = () => {
    closeSuccessModal();
    navigate(`/mybooks/${bookId}/examinations`, { replace: true });
  };
  if (examinationDataLoading || examSessionDataLoading) return null;

  return (
    <>
      <Button
        sx={{ maxWidth: { xs: 1, sm: '343px' }, width: 1 }}
        size={'large'}
        onClick={openSubmitModal}
        {...props}
      >
        ส่งข้อสอบ
      </Button>
      <ExaminationSubmitModal
        open={isSubmitModalOpen}
        onSubmit={handleUpdateAnswer}
        onCancel={closeSubmitModal}
        isSubmitting={isSubmitting}
      />
      <SubmitExaminationSuccessModal
        open={isSuccessModalOpen}
        onSeeExaminationSolution={goToExaminationResult}
        onClose={goToExamination}
      />
      <Portal>
        <SubmitExamErrorSnackbar
          open={isSnackbarOpen}
          onClose={closeSnackbar}
          autoHideDuration={5000}
        />
      </Portal>
    </>
  );
};

export default ExaminationSubmitButton;
