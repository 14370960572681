import { Button, ButtonProps, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Text } from '../../../../Components/Base';
import { useAnonymous } from '../../../../Contexts/AnonymousProvider';
import { LoginState, useAuthentication } from '../../../../Contexts/AuthenticationProvider';
import {
  useQmsGetExamCardByBookIdAndExamIdQuery as useGetExamCardByBookIdAndExamId,
  useGetExamPaperIdByExamCardIdQuery as useGetExamPaperIdByExamCardId,
} from '../../../../generated/graphql';

type SeePublicExaminationResultButtonProps = ButtonProps;

const SeePublicExaminationResultButton = (props: SeePublicExaminationResultButtonProps) => {
  const { bookId = '', examId = '' } = useParams();
  const { anonymousUser } = useAnonymous();
  const { loginState, loginWithLearnId } = useAuthentication();

  const navigate = useNavigate();

  const { data: examCardData, loading: examCardLoading } = useGetExamCardByBookIdAndExamId({
    variables: { bookId, examId },
    onError: (error) => {
      console.error(error);
    },
  });

  const examCardId = examCardData?.qmsGetExamCardByBookIdAndExamId?.id ?? '';

  const { data: examPaperData, loading: examPaperLoading } = useGetExamPaperIdByExamCardId({
    variables: { examCardId },
    fetchPolicy: 'network-only',
  });

  const examPaperId = examPaperData?.qmsFoGetExamPapersByCardId?.[0]?.id ?? '';

  const redirectPath = `/mybooks/${bookId}/results/${examPaperId}/overview`;

  const handleLoginWithLearnId = () => {
    if (anonymousUser?.id) {
      loginWithLearnId({ anonymousUserId: anonymousUser?.id, redirectPath });
    }
  };

  const goToExaminationResult = () => {
    navigate(redirectPath, {
      state: { prevPath: `../../examinations/${examId}` },
    });
  };

  if (examCardLoading || examPaperLoading) {
    <Skeleton
      width={'100%'}
      height={48}
      sx={{
        borderRadius: 1,
        mx: 'auto',
      }}
    />;
  }

  return (
    <Button
      sx={{ maxWidth: { xs: 1, sm: '343px' }, width: 1 }}
      size={'large'}
      onClick={
        loginState === LoginState.NOT_LOGGED_IN
          ? () => handleLoginWithLearnId()
          : () => goToExaminationResult()
      }
      {...props}
    >
      <Text>ดูผลสอบ</Text>
    </Button>
  );
};

export default SeePublicExaminationResultButton;
