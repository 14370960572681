import {
  ContainerProps,
  AppBar as MUIAppBar,
  AppBarProps as MUIAppBarProps,
  Toolbar as MUIToolBar,
  styled,
} from '@mui/material';
import React from 'react';

import { Container } from '../../../Components/Base';

export type AppBarProps = MUIAppBarProps & {
  children?: React.ReactNode;
  bottomAdornment?: React.ReactNode;
  containerSx?: ContainerProps['sx'];
  containerMaxWidth?: ContainerProps['maxWidth'];
};

const StyledAppBar = styled(MUIAppBar)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  boxShadow: 'none',
}));

const StyledToolbar = styled(MUIToolBar)(() => ({
  padding: 0,
}));

const AppBar = (props: AppBarProps) => {
  const { bottomAdornment, children, containerSx, containerMaxWidth, ...restProps } = props;
  return (
    <StyledAppBar {...restProps}>
      <StyledToolbar>
        <Container sx={containerSx} maxWidth={containerMaxWidth}>
          {children}
        </Container>
      </StyledToolbar>
      {bottomAdornment}
    </StyledAppBar>
  );
};

export default AppBar;
