import { useNavigate, useParams } from 'react-router-dom';

import useGetExaminationResultSolutionSummary from '../../Domains/useGetExaminationResultSolutionSummary';
import { Box, Text, Paper } from '../../Components/Base';
import { RESPONSE_TYPE } from '../../Domains/constants';
import DataLoadingError from '../../Components/DataLoadingError';
import NotFoundError from '../../Components/NotFoundError';

import SolutionDetails from './Containers/SolutionDetails';
import ResultSolutionSummary from './Containers/ResultSolutionSummary';

const ResultSolutionDetails = () => {
  const navigate = useNavigate();
  const { examPaperItemId = '' } = useParams();
  const { error: errorResultSolutionSummary, refetch: refetchResultSolutionSummary } =
    useGetExaminationResultSolutionSummary({
      variables: { examPaperItemId },
      fetchPolicy: 'network-only',
    });

  if (errorResultSolutionSummary) {
    const resultSummaryNetworkError =
      errorResultSolutionSummary?.message === RESPONSE_TYPE.NETWORK_ERROR;

    if (resultSummaryNetworkError) {
      return <DataLoadingError mt={16} onRetry={() => refetchResultSolutionSummary()} />;
    }

    const resultSummaryNotFoundError =
      errorResultSolutionSummary?.graphQLErrors[0]?.extensions?.exception?.httpStatus ===
      RESPONSE_TYPE.NOT_FOUND;
    if (resultSummaryNotFoundError) {
      return <NotFoundError open={true} onBackToHome={() => navigate('/')} />;
    }
  }
  return (
    <Box>
      <ResultSolutionSummary />
      <Paper
        sx={{
          mb: { xs: 1, sm: 2 },
          p: { xs: 2, md: 3 },
          borderRadius: { xs: 0, sm: 1 },
        }}
      >
        <Text mb={2} variant="subtitle1">
          เฉลยละเอียด
        </Text>
        <SolutionDetails />
      </Paper>
    </Box>
  );
};

export default ResultSolutionDetails;
