import { alpha, styled } from '@mui/material';

import { QbQuestionType } from '../../../generated/graphql';
import { Flex, FlexProps } from '../../../Components/Base';
import { QmsExamPaperItemType } from '../../../Domains/useGetResultSolution';
import { renderAnswer } from '../../../Domains/useGetExaminationDetails/utils';
import ResultByQuestion from '../../../Components/ResultByQuestion';
import ChevronRightRoundedIcon from '../../../Components/Icons/ChevronRightRoundedIcon';

import ResultByQuestionItem from './ResultByQuestionItem';

const ResultByQuestionWrapper = styled(Flex)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    background: alpha(theme.palette.primary.main, 0.04),
  },
}));

interface ResultByQuestionProps {
  questionType?: QbQuestionType;
  examPaperItemId: string;
  examPaperItem: QmsExamPaperItemType;
  handleGoToResultSolutionDetails: (examPaperItemId: string) => void;
}

const renderResultListByQuestionType = (
  examPaperItem: QmsExamPaperItemType,
  questionType?: QbQuestionType
) => {
  switch (questionType) {
    case QbQuestionType.MultipleSubQuestionsConnectionAnswers: {
      const haveAnswers = examPaperItem?.answer?.value?.some(
        (subAnswer: string | null | undefined) => subAnswer !== null && subAnswer !== undefined
      );

      return (
        <>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <ResultByQuestionItem type={questionType} examPaperItem={examPaperItem} />
            <ChevronRightRoundedIcon color="primary" sx={{ ml: 1 }} />
          </Flex>
          {haveAnswers && (
            <ResultByQuestion
              prefixText={'ตอบ'}
              prefixTextProps={{
                variant: 'body1',
                textAlign: 'right',
              }}
              answer={renderAnswer(questionType, examPaperItem?.answer?.value)}
            />
          )}
        </>
      );
    }

    case QbQuestionType.MultipleSubQuestionsYesNoAnswer:
      return (
        <>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <ResultByQuestionItem type={questionType} examPaperItem={examPaperItem} />
            <ChevronRightRoundedIcon color="primary" sx={{ ml: 1 }} />
          </Flex>
          {examPaperItem?.answer?.value?.map(
            (subAnswer: boolean | null | undefined, index: number) => (
              <ResultByQuestion
                key={index}
                prefixText={`${examPaperItem?.order}.${index + 1}`}
                answer={`ตอบ ${renderAnswer(questionType, subAnswer)}`}
                prefixTextProps={{
                  textAlign: 'right',
                  variant: 'body1',
                }}
              />
            )
          )}
        </>
      );
    case QbQuestionType.FillInTheBlank:
    case QbQuestionType.FillNumber:
    case QbQuestionType.MultipleChoice:
    case QbQuestionType.MultipleChoiceDifferentScore:
    case QbQuestionType.MultipleSelect:
    case QbQuestionType.MultipleSelectWithCorrectAnswers:
    default:
      return (
        <>
          <ResultByQuestionItem type={questionType} examPaperItem={examPaperItem} />
          <ChevronRightRoundedIcon color="primary" sx={{ ml: 1 }} />
        </>
      );
  }
};

const ResultListByQuestion = (props: ResultByQuestionProps) => {
  const { questionType, examPaperItemId, examPaperItem, handleGoToResultSolutionDetails } = props;

  let flexDirection: FlexProps['flexDirection'] = 'row';

  if (
    questionType === QbQuestionType.MultipleSubQuestionsYesNoAnswer ||
    questionType === QbQuestionType.MultipleSubQuestionsConnectionAnswers
  ) {
    flexDirection = 'column';
  }

  return (
    <ResultByQuestionWrapper
      px={{ xs: 2, md: 3 }}
      flexDirection={flexDirection}
      alignItems="center"
      onClick={() => handleGoToResultSolutionDetails(examPaperItemId)}
    >
      {renderResultListByQuestionType(examPaperItem, questionType)}
    </ResultByQuestionWrapper>
  );
};

export default ResultListByQuestion;
