import { createTheme, experimental_sx as sx } from '@mui/material/styles';

import ExpandMoreRoundedIcon from '../../Icons/ExpandMoreRoundedIcon';

const accordionTheme = createTheme({
  components: {
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ExpandMoreRoundedIcon sx={{ color: 'text.secondary' }} />,
      },
      styleOverrides: {
        root: sx({
          px: { xs: 2, md: 3 },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: sx({
          '&:before': {
            display: 'none',
          },
          '&.MuiPaper-root': {
            boxShadow: 'none',
          },
          '& .MuiAccordionSummary-content': {
            margin: '16px 0px 16px 0px',
          },
          '& .MuiAccordionDetails-root': {
            padding: 0,
          },
        }),
      },
    },
  },
});

export default accordionTheme;
