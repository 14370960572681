import { useFormContext } from 'react-hook-form';

import { Flex, Text } from '../../../../Components/Base';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import FormBookmarkInput from '../../../../Components/FormBookmarkInput/FormBookmarkInput';
import { QuestionType } from '../../../../Domains/useGetExaminationDetails';
import FormAnswerMultipleChoicesItem from '../AnswerSheet/AnswerSheetTypeMultipleChoice/FormAnswerMultipleChoicesItem';

interface FormAnswerMultipleChoiceProps {
  question: QuestionType;
  questionNo: number;
}

const FormAnswerMultipleChoice = ({ question, questionNo }: FormAnswerMultipleChoiceProps) => {
  const { control } = useFormContext();
  return (
    <Flex
      key={`${questionNo}`}
      id={`${questionNo}`}
      py={{ xs: 0.5, md: 1 }}
      pl={{ xs: 0.5, md: 1 }}
      pr={1.5}
      alignItems={'center'}
      justifyContent={'flex-start'}
    >
      <FormTextInput name={`answers.${questionNo - 1}.questionId`} sx={{ display: 'none' }} />
      <FormTextInput name={`answers.${questionNo - 1}.examPaperItemId`} sx={{ display: 'none' }} />
      <FormBookmarkInput name={`answers.${questionNo - 1}.isBookmark`} control={control} />
      <Text
        width={32}
        mr={1}
        sx={{ typography: { xs: 'subtitle1', md: 'subtitle2' } }}
        textAlign={'center'}
      >
        {questionNo}
      </Text>
      <FormAnswerMultipleChoicesItem
        subType={question?.subType}
        control={control}
        name={`answers.${questionNo - 1}.answer.value`}
      />
    </Flex>
  );
};

export default FormAnswerMultipleChoice;
