import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import LoLButton from '../../Components/Base/LoLButton';
import { useLoLExamination } from '../../Contexts/LoLExaminationProvider';
import useCheckExamStatusByBookIdAndExamId from '../../Domains/useCheckStatusExamByBookIdAndExamId';
import GradingLoLExaminationButton from '../../Routes/LoLInstruction/Containers/LoLExaminationActionButton/GradingLoLExaminationButton';
import { CHECK_EXAM_STATUS_INTERVAL, PublicExamStatusErrorCode } from '../../Routes/constant';

const SubmitLoLExamButton = () => {
  const { bookId = '', examId = '' } = useParams();
  const [isGrading, setIsGrading] = useState(false);
  const { t } = useTranslation('lolCommon');

  const {
    error,
    loading: examStatusLoading,
    refetch: checkExamStatusByBookIdAndExamId,
  } = useCheckExamStatusByBookIdAndExamId({
    variables: { bookId, examId },
    fetchPolicy: 'network-only',
  });

  const { handleSubmitExamination, examPaperId } = useLoLExamination();
  const navigate = useNavigate();

  const examStatus = error?.graphQLErrors?.[0].extensions?.exception?.errors?.[0]?.code;

  const goToExaminationResult = () => {
    const currentPath = window.location.pathname;
    const newPath = currentPath
      .replace('milestones', '')
      .replace('examinations', 'results')
      .replace(examId, examPaperId)
      .split('/')
      .filter(Boolean)
      .join('/');
    navigate(`/${newPath}`, { replace: true });
  };

  const handleSubmit = async () => {
    if (examStatus === PublicExamStatusErrorCode.FINISHED) {
      goToExaminationResult();
    } else {
      setIsGrading(true);
      await handleSubmitExamination();
      setIsGrading(false);
    }
  };

  useEffect(() => {
    if (examStatus == PublicExamStatusErrorCode.GRADING) {
      const intervalId = setInterval(() => {
        checkExamStatusByBookIdAndExamId();
      }, CHECK_EXAM_STATUS_INTERVAL);
      return () => clearInterval(intervalId);
    }
  }, [checkExamStatusByBookIdAndExamId, examStatus]);

  if (examStatusLoading) {
    return (
      <Skeleton
        width={'100%'}
        height={48}
        sx={{
          borderRadius: 1,
          mx: 'auto',
        }}
      />
    );
  }

  if (examStatus === PublicExamStatusErrorCode.GRADING || isGrading) {
    return <GradingLoLExaminationButton />;
  }

  return (
    <LoLButton fullWidth size="large" onClick={handleSubmit} disabled={isGrading}>
      {t('BUTTON.SUBMIT_EXAM')}
    </LoLButton>
  );
};

export default SubmitLoLExamButton;
