import { Flex, Paper, Text } from '../../../Components/Base';
import AccountCircleIcon from '../../../Components/Icons/AccountCircleIcon';
import { useAuthentication } from '../../../Contexts/AuthenticationProvider';
import { getLearnIdFormat } from '../../../Domains/User/utils';

const UserInformation = () => {
  const { userProfile } = useAuthentication();
  const learnId = getLearnIdFormat(userProfile?.learnId ?? '');

  return (
    <Paper sx={{ borderRadius: { xs: 0, sm: 1 } }}>
      <Flex flexDirection={'column'} alignItems={'center'} py={2}>
        <AccountCircleIcon color={'primary'} sx={{ fontSize: 80 }} />
        <Text variant={'h3'}>
          {`${userProfile?.firstname ?? ''} ${userProfile?.lastname ?? ''}`}
        </Text>
        <Text color={'text.secondary'}>{`Learn ID: ${learnId}`}</Text>
      </Flex>
    </Paper>
  );
};

export default UserInformation;
