import React from 'react';
import { useController } from 'react-hook-form';

import { Box, Flex, Text } from '../../../../../Components/Base';
import CheckboxIconSelection from '../../../../../Components/Form/CheckboxIconSelection';
import CheckboxSelection from '../../../../../Components/Form/CheckboxSelection/CheckboxSelection';
import CheckboxTextSelection from '../../../../../Components/Form/CheckboxTextSelection';
import DisappointedFaceIcon from '../../../../../Components/Icons/Emoji/DisappointedFaceIcon';
import FrowningFaceIcon from '../../../../../Components/Icons/Emoji/FrowningFaceIcon';
import HappyFaceIcon from '../../../../../Components/Icons/Emoji/HappyFaceIcon';
import NeutralFaceIcon from '../../../../../Components/Icons/Emoji/NeutralFaceIcon';
import SmilingFaceIcon from '../../../../../Components/Icons/Emoji/SmilingFaceIcon';

import { FieldValues, FormAnswerMultipleChoiceProps, MultipleChoiceVariant } from './type';

const iconChoices = [
  <DisappointedFaceIcon />,
  <FrowningFaceIcon />,
  <NeutralFaceIcon />,
  <SmilingFaceIcon />,
  <HappyFaceIcon />,
];

const FormAnswerMultipleChoices = <TFieldValues extends FieldValues = FieldValues>(
  props: FormAnswerMultipleChoiceProps<TFieldValues>
): JSX.Element => {
  const {
    answerableCount = 1,
    choicesCount = 4,
    choices,
    variant = MultipleChoiceVariant.NUMBER,
    ...restProps
  } = props;
  const { field } = useController({
    ...restProps,
    name: restProps.name,
  });

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (e.target as HTMLInputElement).value as string;

    let newInitialValue: string | Array<string> = '';
    if (typeof field.value === 'string') {
      newInitialValue = [...field.value.split('')];
    } else if (Array.isArray(field.value)) {
      newInitialValue = [...field.value];
    }

    if (answerableCount === 1) {
      field.onChange(newValue);
    } else {
      if (newInitialValue.includes(newValue) && Array.isArray(newInitialValue)) {
        // this is more like toggle - remove the new value
        const newAnswerList = [...newInitialValue.filter((value) => value !== newValue)];
        field.onChange(newAnswerList.join(''));
      } else {
        let newAnswerList: Array<string> = [];

        if (Array.isArray(newInitialValue)) {
          newAnswerList = [...newInitialValue.filter((value) => value !== ''), newValue];
        } else {
          newAnswerList = [newInitialValue, newValue];
        }
        if (newAnswerList.length >= answerableCount) {
          newAnswerList.splice(0, newAnswerList.length - answerableCount);
        }
        field.onChange(newAnswerList.join(''));
      }
    }
  };

  const renderMultipleChoicesByVariant = (variant: MultipleChoiceVariant) => {
    switch (variant) {
      case MultipleChoiceVariant.ICON:
        return (
          <Flex>
            {Array.from({ length: choicesCount }).map((item, index) => {
              const label = index + 1;
              const icon = iconChoices[index % iconChoices.length];

              return (
                <Box key={`${label}-${index}`} ml={index === 0 ? 0 : 1}>
                  <CheckboxIconSelection
                    icon={icon}
                    checked={field.value ? field.value.includes(label) : false}
                    {...field}
                    value={label}
                    onChange={handleCheckboxClick}
                  />
                </Box>
              );
            })}
          </Flex>
        );
      case MultipleChoiceVariant.TEXT:
        return (
          <Box width={'100%'}>
            {choices?.map((title, index) => {
              const label = choices[index]?.label;
              const value = choices[index]?.value;
              //Remark: Handle specify styling for LOL Only
              return (
                <Box
                  key={value}
                  width={'100%'}
                  mt={index > 0 ? 2 : 0}
                  sx={{
                    '@media(max-width: 389px)': {
                      mt: index > 0 ? 1 : 0,
                    },
                  }}
                >
                  <CheckboxTextSelection
                    label={
                      <Text width={'100%'} variant="body1">
                        {label}
                      </Text>
                    }
                    checked={field.value ? field.value.includes(value) : false}
                    {...field}
                    value={value}
                    onChange={handleCheckboxClick}
                  />
                </Box>
              );
            })}
          </Box>
        );
      default:
      case MultipleChoiceVariant.NUMBER:
        return (
          <Flex>
            {Array.from({ length: choicesCount }).map((item, index) => {
              const label = index + 1;

              return (
                <Box key={`${label}-${index}`} ml={index === 0 ? 0 : 1}>
                  <CheckboxSelection
                    label={
                      <Text sx={{ typography: { xs: 'subtitle1', md: 'subtitle2' } }}>{label}</Text>
                    }
                    checked={field.value ? field.value.includes(label) : false}
                    {...field}
                    value={label}
                    onChange={handleCheckboxClick}
                  />
                </Box>
              );
            })}
          </Flex>
        );
    }
  };

  return renderMultipleChoicesByVariant(variant);
};

export default FormAnswerMultipleChoices;
