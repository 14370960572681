import { useMemo } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import useGetBookDetails from '../../Domains/useGetBookDetails';
import { QmsskuType } from '../../generated/graphql';

type ExaminationPortalProps = {
  children?: JSX.Element;
};

const pathGroup = {
  mybooks: [QmsskuType.Book, QmsskuType.CbtBook, QmsskuType.EBook],
  publicbooks: [QmsskuType.PublicBook],
};

const ExaminationPortal = (props: ExaminationPortalProps) => {
  const { children } = props;
  const { bookId = '' } = useParams();
  const location = useLocation();

  const { data, loading: isGetBookDetailsLoading } = useGetBookDetails({ variables: { bookId } });

  const bookType = data?.qmsGetBookDetailsByBookId?.type as QmsskuType;

  const isLoLExam = useMemo(() => {
    return data?.qmsGetBookDetailsByBookId.exams?.some(({ type }) => type === 'LOL') ?? false;
  }, [data?.qmsGetBookDetailsByBookId.exams]);

  const pathHasLoL = useMemo(() => {
    return location.pathname.includes('learn-o-life');
  }, [location.pathname]);

  const pathHasPublicBooks = useMemo(() => {
    return location.pathname.includes('publicbooks');
  }, [location.pathname]);

  const pathSegments = location.pathname.split('/');

  if (pathSegments.length < 2) {
    return children ?? <Outlet />;
  }

  //REMARK: This component is used for handling sub route of '/mybooks/:examId' and '/publicbooks/:examId' only.
  const path = pathSegments
    .filter(Boolean)
    .slice(2, pathSegments.length)
    .filter((params) => params !== 'learn-o-life')
    .join('/');

  const isPublicbook = pathGroup.publicbooks.includes(bookType);

  if (isGetBookDetailsLoading) {
    return children ?? <Outlet />;
  }

  /*
  Every public books can be rendered by both '/mybooks' and '/publicbooks'.
  On the other hand, every private books can be rendered by '/mybooks' only.
  */
  if (isLoLExam && isPublicbook) {
    if (pathHasLoL) return children ?? <Outlet />;
    else if (pathHasPublicBooks) {
      return <Navigate to={`/publicbooks/${bookId}/learn-o-life/${path}`} replace />;
    } else {
      return <Navigate to={`/mybooks/${bookId}/learn-o-life/${path}`} replace />;
    }
  } else if (isLoLExam && !isPublicbook) {
    if (pathHasLoL && !pathHasPublicBooks) return children ?? <Outlet />;
    return <Navigate to={`/mybooks/${bookId}/learn-o-life/${path}`} replace />;
  } else if (!isLoLExam && isPublicbook) {
    if (!pathHasLoL || pathHasPublicBooks) return children ?? <Outlet />;
    return <Navigate to={`/publicbooks/${bookId}/${path}`} replace />;
  } else if (!isLoLExam && !isPublicbook) {
    if (!pathHasLoL && !pathHasPublicBooks) return children ?? <Outlet />;
    return <Navigate to={`/mybooks/${bookId}/${path}`} replace />;
  }

  return children ?? <Outlet />;
};

export default ExaminationPortal;
