//TODO: remove this function after gg drive link is fixed
const replaceImgTagWithIframe = (content: string) => {
  // Parse the content
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  // Select all img elements
  const imgElements = doc.querySelectorAll('img');

  // Loop over the img elements and replace the src attribute
  imgElements.forEach((img) => {
    const imgSrc = img.getAttribute('src');
    const id = imgSrc?.match(/drive\.google\.com\/uc\?id=([^&]+)/)?.[1];
    if (id) {
      const newSrc = `https://drive.google.com/file/d/${id}/preview`;
      img.setAttribute('src', newSrc);
      //change img to iframe
      const iframe = doc.createElement('iframe');
      iframe.setAttribute('src', newSrc);
      iframe.setAttribute('width', '100%');
      // Set the height of the iframe based on the viewport width
      const isTabletOrAbove = window.matchMedia('(min-width: 768px)').matches;
      iframe.setAttribute('height', isTabletOrAbove ? '950' : '480');

      iframe.allow = 'autoplay';
      iframe.style.borderWidth = '0px';
      img.parentNode?.replaceChild(iframe, img);
    }
  });

  // Serialize the modified HTML back to a string
  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
};

export default replaceImgTagWithIframe;
