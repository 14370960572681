import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloProvider } from '@apollo/client';

import theme from './Components/Base/Theme';
import AppRoute from './Routes/routes';
import ClarityScript from './Containers/ClarityScript';
import { ConfigProvider, useConfig } from './Contexts/ConfigProvider';
import createApolloClient from './Libs/GraphQL/ApolloClient';
import { AuthenticationProvider } from './Contexts/AuthenticationProvider';
import useGoogleTagManager from './Utils/useGoogleTagManager';

import './i18n/config';

function App() {
  const { graphqlURL, googletagmanager: gtmConfigs } = useConfig();
  const client = createApolloClient(graphqlURL);

  useGoogleTagManager(gtmConfigs);

  return (
    <>
      <ApolloProvider client={client}>
        <AuthenticationProvider>
          <CssBaseline />
          <ClarityScript id={process.env.REACT_APP_CLARITY_ID} />
          <Router>
            <AppRoute />
          </Router>
        </AuthenticationProvider>
      </ApolloProvider>
    </>
  );
}

function AppWithConfig() {
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default AppWithConfig;
