import { styled } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '../../Components/Base';
import LoLButton from '../../Components/Base/LoLButton';
import OTPInput from '../../Components/Form/OTPInput/OTPInput';
import useTimer from '../../Utils/useTimer';

const RESEND_DURATION_TIME = 30000; //Milliseconds

type OTPFormProps = {
  refCode: string;
  handleResendOTP: () => void;
  isSubmitting: boolean;
};

const ResendOTPButton = styled(LoLButton)(({ theme }) => ({
  color: theme.palette.lolText?.secondary,
  marginTop: theme.spacing(4),
  fontWeight: 600,
  padding: 0,
  '&.Mui-disabled': {
    fontWeight: 400,
    color: theme.palette.lolText?.secondary,
  },
}));

const OTPForm = ({ refCode, handleResendOTP, isSubmitting }: OTPFormProps) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation('lolSignIn');

  const resendTime = useMemo(() => {
    const currentTimeInMilliseconds = new Date().getTime();
    return new Date(currentTimeInMilliseconds + RESEND_DURATION_TIME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refCode]);

  const phoneNumber = watch('phoneNumber')?.match(/^(\d{3})(\d{3})(\d{4})$/);
  phoneNumber?.shift();

  const { remainingTime = 0 } = useTimer(resendTime);

  const countdownTime = remainingTime >= 10 ? `${remainingTime}` : `0${remainingTime}`;
  const displayRemainingTime = remainingTime > 0 ? `(00:${countdownTime})` : '';
  return (
    <>
      <Text variant="h2">{t('OTP.CONFIRM')}</Text>
      <Text variant="body2" mb={4} mt={1}>
        {t('OTP.INFO', {
          phoneNumber: phoneNumber?.join('-'),
        })}
      </Text>
      <Controller
        control={control}
        name="otp"
        render={({ field }) => {
          return (
            <OTPInput
              error={!!errors['otp']}
              {...field}
              disabled={isSubmitting}
              helperText={errors['otp']?.message}
            />
          );
        }}
      />
      <ResendOTPButton onClick={handleResendOTP} variant="text" disabled={remainingTime > 0}>
        {remainingTime > 0
          ? t('OTP.RESEND_DISABLED', {
              remainingTime: displayRemainingTime,
            })
          : t('OTP.RESEND')}
      </ResendOTPButton>
      <Box mt={1} color={'lolText.secondary'}>
        <Text variant="caption">
          {t('OTP.REF', {
            refCode: refCode,
          })}
        </Text>
      </Box>
    </>
  );
};

export default OTPForm;
