import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { handleChangeLanguage } from '../../i18n/utils';
import useGetLoLLanguageByExamId from '../../Domains/useGetLoLLanguageByExamId';
import { LANGUAGES, LANGUAGES_LABEL } from '../../i18n/constants';
import { useGetLoLExamByExamPaperIdQuery as useGetLoLExamByExamPaperId } from '../../generated/graphql';

import { ILoLLanguageContext, LoLLanguageProviderType } from './types';

const LoLLanguageContext = createContext<ILoLLanguageContext | undefined>(undefined);

const LoLLanguageProvider = (props: LoLLanguageProviderType) => {
  const { children } = props;
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language as LANGUAGES);

  const { examId = '', examPaperId = '' } = useParams();
  const { data: examData, loading: examLoading } = useGetLoLLanguageByExamId({
    variables: {
      examId,
    },
    skip: !examId,
  });

  const { data: examPaperData, loading: examPaperLoading } = useGetLoLExamByExamPaperId({
    variables: {
      examPaperId,
    },
    skip: !examPaperId,
  });

  const changeLanguage = (newLanguage: LANGUAGES) => {
    if (newLanguage === language) return;
    setLanguage(newLanguage);
    handleChangeLanguage(newLanguage);
  };

  useEffect(() => {
    if (!examLoading && !examPaperLoading) {
      let examLanguage: LANGUAGES_LABEL | undefined;
      if (examData) {
        examLanguage = examData?.qmsFoGetExamById.metadata?.language;
      } else if (examPaperData) {
        examLanguage = examPaperData?.qmsFoGetExamPaperById.exam?.metadata?.language;
      }

      if (examLanguage) {
        handleChangeLanguage(examLanguage);
        setLanguage(LANGUAGES[examLanguage]);
      }
    }
  }, [examLoading, examData, examPaperLoading, examPaperData]);

  return (
    <LoLLanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LoLLanguageContext.Provider>
  );
};

export const useLoLLanguage = () => {
  const context = useContext(LoLLanguageContext);
  if (!context) {
    throw new Error('useLoLLanguage must be used within a LoLLanguageProvider');
  }
  return context;
};

export default LoLLanguageProvider;
