import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckCircleOutlineRoundedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <path
        d="m44.167 57.5-9.063-9.063c-.764-.764-1.718-1.127-2.862-1.091-1.148.033-2.103.432-2.867 1.196-.764.764-1.146 1.736-1.146 2.916s.382 2.153 1.146 2.917L41.25 66.25c.764.764 1.736 1.146 2.917 1.146 1.18 0 2.152-.382 2.916-1.146L70.73 42.604c.764-.764 1.13-1.72 1.096-2.867-.036-1.144-.436-2.098-1.2-2.862-.764-.764-1.736-1.146-2.917-1.146-1.18 0-2.153.382-2.916 1.146L44.167 57.5ZM50 91.667c-5.764 0-11.18-1.095-16.25-3.284-5.07-2.186-9.48-5.154-13.23-8.904s-6.717-8.16-8.903-13.23C9.427 61.182 8.333 55.765 8.333 50c0-5.763 1.095-11.18 3.284-16.25 2.186-5.069 5.154-9.478 8.904-13.228s8.16-6.72 13.229-8.909C38.82 9.426 44.236 8.333 50 8.333c5.764 0 11.18 1.093 16.25 3.28 5.07 2.188 9.48 5.158 13.23 8.908 3.75 3.75 6.717 8.16 8.903 13.229 2.19 5.07 3.284 10.486 3.284 16.25 0 5.764-1.095 11.18-3.284 16.25-2.186 5.07-5.154 9.48-8.904 13.23s-8.16 6.717-13.23 8.903c-5.068 2.19-10.485 3.284-16.25 3.284Zm0-8.334c9.236 0 17.101-3.246 23.596-9.737C80.087 67.1 83.333 59.236 83.333 50s-3.246-17.102-9.737-23.596C67.1 19.912 59.236 16.667 50 16.667s-17.1 3.245-23.592 9.737c-6.494 6.494-9.741 14.36-9.741 23.596s3.247 17.101 9.741 23.596C32.9 80.087 40.764 83.333 50 83.333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CheckCircleOutlineRoundedIcon;
