import { Box, Divider } from '@mui/material';
import { isNaN } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { Flex, Text, Paper, PaperProps } from '../../../../Components/Base';
import { useExamination } from '../../../../Contexts/ExaminationProvider';
import { QuestionType } from '../../../../Domains/useGetExaminationDetails';
import { QbQuestionType } from '../../../../generated/graphql';
import ExamNavigation from '../ExamNavigation';
import FormAnswerItem from '../FormAnswerItem/FormAnswerItem';
import QuestionContent from '../QuestionContent';
import ExamRoomHeader from '../../Components/ExamRoomHeader';
import theme from '../../../../Components/Base/Theme';
import useScreenSize from '../../../../Utils/useScreenSize';

import { splitterPropsByDeviceType, splitterPropsByDevice } from './constant';
import ExaminationCBTwithArticle from './ExaminationCBTwithArticle';

type ExaminationCBTWrapperProps = {
  children: React.ReactNode;
} & PaperProps;

export const ExaminationCBTWrapper = (props: ExaminationCBTWrapperProps) => {
  const { children, sx, ...restProps } = props;
  return (
    <Paper
      elevation={4}
      sx={{
        paddingX: { xs: 2, sm: 3, lg: 8 },
        paddingY: { xs: 2, lg: 3 },
        mt: { xs: 1, sm: 2, lg: 3 },
        mb: { xs: 1, md: 2 },
        borderRadius: 0,
        bgcolor: 'common.white',
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Paper>
  );
};

export enum SplitterWay {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

const ExaminationCBT = () => {
  const { examQuestions, examTitle } = useExamination();
  const { hash } = useLocation();
  const { screenSize } = useScreenSize();
  const [splitterWay, setSplitterWay] = useState<SplitterWay>(SplitterWay.Horizontal);
  const [splitterProps, setSplitterProps] = useState<splitterPropsByDeviceType>(
    splitterPropsByDevice['Desktop']
  );

  const anchorId = hash.replace('#', '');

  useEffect(() => {
    const changeSplitterWayByBreakpoint = () => {
      const width = screenSize.width;
      if (width < theme.breakpoints.values.md) {
        setSplitterWay(SplitterWay.Horizontal);
      } else {
        setSplitterWay(SplitterWay.Vertical);
      }
    };

    changeSplitterWayByBreakpoint();
    window.addEventListener('resize', changeSplitterWayByBreakpoint);
    return () => {
      window.removeEventListener('resize', changeSplitterWayByBreakpoint);
    };
  }, [screenSize]);

  useEffect(() => {
    const handleGetDesignByBreakpoint = () => {
      const width = screenSize.width;
      if (width <= theme.breakpoints.values.sm) {
        return splitterPropsByDevice.Mobile_Portrait;
      } else if (width <= theme.breakpoints.values.md) {
        return splitterPropsByDevice.Tablet_Portrait;
      } else if (width <= theme.breakpoints.values.lg) {
        return splitterPropsByDevice.Tablet_Landscape;
      } else {
        return splitterPropsByDevice.Desktop;
      }
    };

    const handleSetSplitterProps = () => {
      const newSplitterProps = handleGetDesignByBreakpoint();
      if (newSplitterProps !== splitterProps) {
        setSplitterProps(newSplitterProps);
      }
    };

    const changeSplitterWayByBreakpoint = () => {
      if (screenSize.width < theme.breakpoints.values.md) {
        setSplitterWay(SplitterWay.Horizontal);
      } else {
        setSplitterWay(SplitterWay.Vertical);
      }
      handleSetSplitterProps();
    };

    changeSplitterWayByBreakpoint();
  }, [splitterProps, screenSize]);

  useEffect(() => {
    const questionNo = parseInt(anchorId);
    if (questionNo <= 1 || anchorId === '') window.location.hash = '#1';
    if (questionNo > examQuestions.length) window.location.hash = `#${examQuestions.length}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorId]);

  const currentQuestion = useMemo(() => {
    const questionNo = parseInt(anchorId.trim() === '' ? '1' : anchorId);

    if (isNaN(questionNo)) {
      if (examQuestions.length === 0) {
        return null;
      } else {
        return examQuestions?.[0] ?? null;
      }
    } else {
      if (examQuestions.length === 0) {
        return null;
      } else {
        const filterQuestion =
          examQuestions?.find((question) => question.order === questionNo) ?? null;
        return filterQuestion;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorId]);

  // For displaying a decimal point answer instruction in case FillNumber type only
  const decimal = useMemo(() => {
    return currentQuestion?.metadata?.presition ?? 2;
  }, [currentQuestion]);

  const questionType = useMemo(() => {
    return currentQuestion?.type;
  }, [currentQuestion]);

  const examMaterialId = useMemo(() => {
    return currentQuestion?.examMaterialId;
  }, [currentQuestion]);

  const haveExamMaterial = useMemo(() => examMaterialId != null, [examMaterialId]);

  if (currentQuestion === null) {
    return <></>;
  }

  return (
    <>
      {haveExamMaterial ? (
        <ExaminationCBTwithArticle
          split={splitterWay}
          splitterProps={splitterProps}
          currentQuestion={currentQuestion}
          examMaterialId={examMaterialId as string}
        />
      ) : (
        <>
          <ExamRoomHeader
            examTitle={examTitle}
            maxWidth={false}
            containerSx={{ px: { xs: 2, sm: 3, lg: 8 }, py: 2 }}
          />
          <ExaminationCBTWrapper>
            <ExamNavigation />
            <Divider sx={{ my: 2 }} />
            <Flex id="Normal_CBT" flexDirection={'column'} alignItems={'center'}>
              <Box width={'100%'} maxWidth={{ xs: '100%', sm: 720 }}>
                <QuestionContent />
                <Divider sx={{ my: 2 }} />
                {questionType === QbQuestionType.FillNumber && (
                  <Text my={2} variant={'body2'}>
                    ตอบเป็นทศนิยม {decimal} ตำแหน่ง
                  </Text>
                )}
                <FormAnswerItem question={currentQuestion as QuestionType} />
              </Box>
            </Flex>
          </ExaminationCBTWrapper>
        </>
      )}
    </>
  );
};

export default ExaminationCBT;
