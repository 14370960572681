import { useEffect, useRef, useState } from 'react';

export enum AnimationFrameStatus {
  RUNNING = 'RUNNING',
  STOP = 'STOP',
}

const useAnimationFrame = (callback: (deltaTime?: number) => void) => {
  const [animationFrameStatus, setAnimationFrameStatus] = useState<AnimationFrameStatus>(
    AnimationFrameStatus.RUNNING
  );
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  const animate = (time: number) => {
    if (previousTimeRef.current) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }

    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (animationFrameStatus === AnimationFrameStatus.STOP) return;

    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationFrameStatus]); // Make sure the effect runs only once

  return { setAnimationFrameStatus };
};

export default useAnimationFrame;
