import { styled } from '@mui/material';

import { Flex, FlexProps, Button, ButtonProps, Text } from '../../../Components/Base';
import AddIcon from '../../../Components/Icons/AddIcon';
import MyBookIcon from '../../../Components/Icons/MyBookIcon';

const EmptyBookWrapper = styled(Flex)`
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type EmptyMyBookResultProps = {
  onRedeemBook?: ButtonProps['onClick'];
} & FlexProps;

const EmptyMyBookResult = (props: EmptyMyBookResultProps) => {
  const { onRedeemBook, ...restProps } = props;

  return (
    <EmptyBookWrapper mt={{ xs: 13 }} {...restProps}>
      <MyBookIcon color={'primary'} sx={{ width: '115px', height: '100px' }} />
      <Text whiteSpace={'pre-line'} sx={{ typography: { xs: 'h3', md: 'h2' } }} mt={4} mb={1}>
        {`เริ่มต้นทำข้อสอบได้ง่าย ๆ
  เพียงคุณเพิ่มหนังสือเข้าสู่ระบบ`}
      </Text>
      <Text marginBottom={3}>คลิกที่ปุ่มด้านล่าง เพื่อเพิ่มหนังสือเข้าสู่ระบบ</Text>
      <Button size={'large'} onClick={onRedeemBook}>
        <AddIcon />
        เพิ่มหนังสือ
      </Button>
    </EmptyBookWrapper>
  );
};

export default EmptyMyBookResult;
