import { SvgIcon, SvgIconProps } from '@mui/material';

const HeartCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 38 38" {...props}>
      <path
        fill="inherit"
        fillRule="evenodd"
        d="M13.318 7.934a6.333 6.333 0 0 0-5.089 10.837l.009.008c3.432 3.392 6.863 6.784 10.293 10.18a1.583 1.583 0 0 1-2.228 2.25A13993.03 13993.03 0 0 0 6.016 21.036a9.5 9.5 0 0 1 12.99-13.86 9.5 9.5 0 0 1 12.979 13.869 1.6 1.6 0 0 1-.115.102l-4.64 4.528a1.583 1.583 0 0 1-2.21-2.266l4.75-4.636c.03-.03.06-.058.092-.084a6.333 6.333 0 1 0-9.596-8.228 1.583 1.583 0 0 1-2.537-.007 6.333 6.333 0 0 0-4.411-2.52ZM34.37 25.797c.618.618.618 1.62 0 2.24l-6.334 6.333a1.583 1.583 0 0 1-2.239 0l-3.167-3.167a1.583 1.583 0 0 1 2.24-2.24l2.047 2.048 5.213-5.214a1.583 1.583 0 0 1 2.24 0Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default HeartCheckIcon;
