import { Fragment } from 'react';
import { Divider, styled } from '@mui/material';
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';

import { Flex, Button } from '../../../../Components/Base';
import ResultRoundItem from '../../../../Components/ResultRoundItem';
import { formatScore } from '../../../../Domains/useGetExaminationResultByLesson';
import {
  QmsFoMyExamPapersQuery,
  QmsExamPaperStatus,
} from '../../../../Domains/useGetExaminationResults';
import { formatFullScore } from '../../../../Domains/useGetExaminationResultByLesson/utils';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';
import { QbQuestionType } from '../../../../generated/graphql';

const Link = styled(ReactLink)(() => ({
  color: 'inherit',
  textDecoration: 'none',
}));

type ExaminationResultByRoundProps = {
  currentExamId?: string;
  examCardId: string;
  examResults: QmsFoMyExamPapersQuery['qmsFoMyExamPapers'];
  title: string;
};

const ExaminationResultByRound = (props: ExaminationResultByRoundProps) => {
  const { currentExamId, examCardId, examResults, title } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const sendDataToGTM = useGoogleTagManager();

  const handleGoToResultOverview = (answerSheetId: string, examIteration: number) => {
    sendDataToGTM({
      event: 'examination_item_click',
      examTitle: title,
      examIteration: examIteration,
    });

    navigate(`${location.pathname}/${answerSheetId}/overview`, {
      state: {
        prevPath: `${location.pathname}${location.hash !== null ? `${location.hash}` : ''}`,
      },
    });
  };

  const examPapers = examResults.examPapers ?? [];
  const allExamIdList = examPapers.map((paper) => paper.examId);
  const uniqueExamIdList = [...new Set(allExamIdList)];
  const isShowCurrentExamLabel = uniqueExamIdList.length > 1;
  const sortedExamPapers = examPapers.sort((a, b) => {
    return b.iteration - a.iteration;
  });

  return (
    <>
      {sortedExamPapers.map((paper, index) => {
        const {
          id: answerSheetId,
          examId: examIdByExamPaper,
          score = 0,
          fullScore = 0,
          createdAt,
          status,
          iteration,
          examPaperItems,
        } = paper;

        //Remark: Workaroud for display score by 4 decimal points
        const connectionAnswerTypqQuestionIndex =
          examPaperItems?.findIndex(
            (item) => item?.question?.type === QbQuestionType.MultipleSubQuestionsConnectionAnswers
          ) ?? -1;

        const hasConnectionAnswer = connectionAnswerTypqQuestionIndex > -1;

        return (
          <Fragment key={`${answerSheetId}`}>
            {index < 10 && (
              <>
                <Divider sx={{ mx: { xs: 2, md: 3 } }} />
                <ResultRoundItem
                  iteration={iteration}
                  score={formatScore(score, fullScore, hasConnectionAnswer ? 4 : 2)}
                  fullScore={formatFullScore(fullScore)}
                  startedExamAt={createdAt}
                  variant={status === QmsExamPaperStatus.Finished ? 'FINISHED' : 'GRADING'}
                  onClick={() => {
                    if (status === QmsExamPaperStatus.Finished) {
                      handleGoToResultOverview(answerSheetId, iteration);
                    }
                  }}
                  pl={{ xs: 2, md: 3 }}
                  pr={{ xs: 2, md: 3 }}
                  py={2}
                  isCurrentExam={isShowCurrentExamLabel && currentExamId === examIdByExamPaper}
                />
              </>
            )}
          </Fragment>
        );
      })}
      {examResults.total > 10 && (
        <Link to={`${location.pathname}/examination/${examCardId}`}>
          <Flex justifyContent={'center'} py={1}>
            <Button variant={'text'} color={'primary'}>
              ดูเพิ่มเติม
            </Button>
          </Flex>
        </Link>
      )}
    </>
  );
};

export default ExaminationResultByRound;
