export const formatScore = (score: number, fullScore?: number, decimalPoints?: number) => {
  if (fullScore && score >= Math.floor(fullScore)) return Math.floor(score);
  return parseFloat(score.toFixed(decimalPoints ?? 2));
};

export const formatLessonScore = (score: number, decimalPoints?: number, fullScore?: number) => {
  if (fullScore && score > fullScore) return parseFloat(fullScore.toFixed(decimalPoints ?? 2));
  return parseFloat(score.toFixed(decimalPoints ?? 2));
};

export const formatFullScore = (score: number) => {
  const roundScore = Math.round(score);
  const floorScore = Math.floor(score);

  if (roundScore % 5 == 0) return roundScore;

  return floorScore;
};
