import BookIcon from '../../Components/Icons/BookIcon';
import HelpIcon from '../../Components/Icons/HelpIcon';
import BrushIcon from '../../Components/Icons/BrushIcon';

enum MenuIcon {
  BOOK_ICON = 'BOOK_ICON',
  HELP_ICON = 'HELP_ICON',
  UI_COMPONENTS = 'UI_COMPONENTS',
}

export const getMenuIcon = (icon: MenuIcon | string) => {
  switch (icon) {
    case MenuIcon.BOOK_ICON: {
      return <BookIcon />;
    }
    case MenuIcon.HELP_ICON: {
      return <HelpIcon />;
    }
    case MenuIcon.UI_COMPONENTS: {
      return <BrushIcon />;
    }
    default: {
      return null;
    }
  }
};
