import { useRef, useState } from 'react';

import { getAuthorString } from '../../../Domains/Book/utils';
import { Modal, ModalContent, ModalTitle } from '../../../Components/Base';
import { RedeemBookFormContext, RedeemBookFormInput } from '../Components/RedeemBookForm';
import { RESPONSE_TYPE } from '../../../Domains/constants';
import ImportErrorSnackbar from '../Components/ImportErrorSnackbar';
import MyBookPreview from '../Components/MyBookPreview';
import MyBookSuccess from '../Components/MyBookSuccess';
import useDisclosure from '../../../Utils/useDisclosure';
import useGetBookByRedeemCode from '../../../Domains/useGetBookByRedeemCode';
import useGetMyBooks from '../../../Domains/useGetMyBooks';
import useRedeemBook from '../../../Domains/useRedeemBook';
import useGoogleTagManager from '../../../Utils/useGoogleTagManager';

import RedeemBook from './RedeemBook';

type ImportBookModalType = {
  isOpen: boolean;
  onClose: () => void;
};

export enum RedeemBookError {
  'NotFoundRedeemCode' = 'NOT_FOUND_REDEEM_CODE',
  'RedeemCodeHasUsed' = 'REDEEM_CODE_HAS_USED',
  'AlreadyHaveBook' = 'ALREADY_HAVE_BOOK',
}

const getRedeemCodeErrorMessageByErrorCode = (code?: RedeemBookError) => {
  switch (code) {
    case RedeemBookError.RedeemCodeHasUsed:
      return 'โค้ดนี้ถูกใช้งานแล้ว';
    case RedeemBookError.AlreadyHaveBook:
      return 'คุณมีหนังสือเล่มนี้แล้ว';
    default:
      return '';
  }
};

const ImportBookModal = ({ isOpen, onClose }: ImportBookModalType) => {
  const [currentIndexStep, setCurrentIndexStep] = useState(0);
  const redeemCodeRef = useRef<string>();
  const { isOpen: isSnackbarOpen, open: openSnackbar, close: closeSnackbar } = useDisclosure();
  const sendDataToGTM = useGoogleTagManager();

  const handleRedeemBookTracking = ({ redeemBookCode }: { redeemBookCode?: string }) => {
    sendDataToGTM({
      event: 'redeem_book_code_cta_click',
      redeemBookCode: redeemBookCode,
    });
  };

  const handleConfirmRedeemBookTracking = ({ redeemBookCode }: { redeemBookCode?: string }) => {
    sendDataToGTM({
      event: 'confirm_redeem_book_code_cta_click',
      redeemBookCode: redeemBookCode,
    });
  };

  const [redeemBook, { loading: redeemBookLoading }] = useRedeemBook({
    onError: (error) => {
      if (error.networkError) {
        openSnackbar();
      }
    },
  });

  const [getBookByRedeemCode, { data }] = useGetBookByRedeemCode({
    fetchPolicy: 'network-only',
    onError: (error) => {
      if (error.message === RESPONSE_TYPE.NETWORK_ERROR) {
        openSnackbar();
      }
    },
  });

  const { refetch: refetchMyBooks } = useGetMyBooks();

  const bookData = data?.qmsGetBookByRedeemCode;

  const handleSubmitImportBookForm =
    (formContext: RedeemBookFormContext) => async (formValues: RedeemBookFormInput) => {
      const redeemCode = formValues.myBookCode;
      if (redeemCode) {
        const { data: bookResultData, error } = await getBookByRedeemCode({
          variables: { redeemCode: redeemCode },
        });

        const prefixIndex = redeemCode.indexOf('_') < 0 ? undefined : redeemCode.indexOf('_');
        const redeemBookCode = redeemCode.substring(0, prefixIndex);

        handleRedeemBookTracking({ redeemBookCode: redeemBookCode });

        if (error) {
          if (error.message === RESPONSE_TYPE.NETWORK_ERROR) {
            openSnackbar();
          }

          const exception = error.graphQLErrors[0]?.extensions?.exception;
          if (exception.httpStatus === RESPONSE_TYPE.UNPROCESSABLE_ENTITY) {
            formContext.setError('myBookCode', {
              message: getRedeemCodeErrorMessageByErrorCode(exception.code),
            });
          }
          if (exception.httpStatus === RESPONSE_TYPE.NOT_FOUND) {
            formContext.setError('myBookCode', {
              message: 'โค้ดไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
            });
          }

          return;
        }

        if (bookResultData) {
          redeemCodeRef.current = redeemCode;
          handleNextStep();
        }
      }
    };

  const handleRedeemBook = async () => {
    const { data: redeemBookData } = await redeemBook({
      variables: { redeemCode: redeemCodeRef.current ?? '' },
    });

    if (!redeemBookLoading && redeemBookData) {
      const redeemCode = redeemCodeRef.current ?? '';
      const prefixIndex = redeemCode.indexOf('_') < 0 ? undefined : redeemCode.indexOf('_');
      const redeemBookCode = redeemCode.substring(0, prefixIndex);

      handleConfirmRedeemBookTracking({ redeemBookCode: redeemBookCode });
      handleNextStep();
    }
  };

  const handleNextStep = () => setCurrentIndexStep(currentIndexStep + 1);

  const handleClose = () => {
    refetchMyBooks();
    setCurrentIndexStep(0);
    onClose();
  };

  return (
    <>
      <Modal open={isOpen} fullScreen onClose={handleClose}>
        <ModalTitle onClose={handleClose} />
        <ModalContent>
          {currentIndexStep === 0 && <RedeemBook onRedeemCode={handleSubmitImportBookForm} />}
          {currentIndexStep === 1 && (
            <>
              <MyBookPreview
                isSubmitting={redeemBookLoading}
                title={bookData?.title ?? ''}
                author={getAuthorString(bookData?.authors ?? [])}
                imageUrl={bookData?.coverImageUrl ?? ''}
                refNo={bookData?.refCode ?? ''}
                onSubmit={handleRedeemBook}
              />
            </>
          )}
          {currentIndexStep === 2 && <MyBookSuccess onClick={handleClose} />}
        </ModalContent>
      </Modal>
      <ImportErrorSnackbar open={isSnackbarOpen} onClose={closeSnackbar} autoHideDuration={5000} />
    </>
  );
};

export default ImportBookModal;
