import { SvgIcon, SvgIconProps } from '@mui/material';

const PageErrorIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 20" {...props}>
    <path
      d="M2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196 19.021 0 18.55 0 18V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H9.175C9.44167 0 9.696 0.0500001 9.938 0.15C10.1793 0.25 10.3917 0.391667 10.575 0.575L15.425 5.425C15.6083 5.60833 15.75 5.82067 15.85 6.062C15.95 6.304 16 6.55833 16 6.825V18C16 18.55 15.8043 19.021 15.413 19.413C15.021 19.8043 14.55 20 14 20H2ZM9 6V2H2V18H14V7H10C9.71667 7 9.47933 6.904 9.288 6.712C9.096 6.52067 9 6.28333 9 6Z"
      fill="currentColor"
    />
    <path
      d="M8.713 12.712C8.521 12.904 8.28333 13 8 13C7.71667 13 7.47933 12.9083 7.288 12.725C7.096 12.5417 7 12.3083 7 12.025V9C7 8.71667 7.096 8.479 7.288 8.287C7.47933 8.09567 7.71667 8 8 8C8.28333 8 8.521 8.09167 8.713 8.275C8.90433 8.45833 9 8.69167 9 8.975V12C9 12.2833 8.90433 12.5207 8.713 12.712Z"
      fill="currentColor"
    />
    <path
      d="M8.713 15.712C8.521 15.904 8.28333 16 8 16C7.71667 16 7.47933 15.904 7.288 15.712C7.096 15.5207 7 15.2833 7 15C7 14.7167 7.096 14.479 7.288 14.287C7.47933 14.0957 7.71667 14 8 14C8.28333 14 8.521 14.0957 8.713 14.287C8.90433 14.479 9 14.7167 9 15C9 15.2833 8.90433 15.5207 8.713 15.712Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PageErrorIcon;
