import { experimental_sx as sx } from '@mui/material';
import { styled, createTheme } from '@mui/system';

import Flex, { FlexProps } from '../Flex';

interface LabelProps extends FlexProps {
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
  variant?: 'contained' | 'outlined';
  size?: 'small' | 'medium';
}

const labelTheme = createTheme({
  components: {
    Label: {
      defaultProps: {
        size: 'medium',
        color: 'primary',
        variant: 'outlined',
      },
      styleOverrides: {
        root: sx({
          borderRadius: 3.5,
          fontWeight: 600,
        }),
        sizeSmall: sx({
          py: 0.5,
          px: 1.25,
          fontSize: 12,
          lineHeight: 1.37,
        }),
        sizeMedium: sx({
          px: 1.5,
          py: 0.75,
          fontSize: 14,
          lineHeight: 1.46,
        }),
        contained: sx({
          color: 'common.white',
        }),
        containedPrimary: sx({
          bgcolor: 'primary.dark',
        }),
        containedSecondary: sx({
          bgcolor: 'secondary.dark',
        }),
        containedInfo: sx({
          bgcolor: 'info.dark',
        }),
        containedSuccess: sx({
          bgcolor: 'success.dark',
        }),
        containedWarning: sx({
          bgcolor: 'warning.dark',
        }),
        containedError: sx({
          bgcolor: 'error.dark',
        }),
        outlined: sx({
          border: '1px solid',
        }),
        outlinedPrimary: sx({
          bgcolor: 'primary.lighter',
          borderColor: 'primary.light',
          color: 'primary.dark',
        }),
        outlinedSecondary: sx({
          bgcolor: 'secondary.lighter',
          borderColor: 'secondary.light',
          color: 'secondary.dark',
        }),
        outlinedInfo: sx({
          bgcolor: 'info.lighter',
          borderColor: 'info.light',
          color: 'info.dark',
        }),
        outlinedSuccess: sx({
          bgcolor: 'success.lighter',
          borderColor: 'success.light',
          color: 'success.dark',
        }),
        outlinedWarning: sx({
          bgcolor: 'warning.lighter',
          borderColor: 'warning.light',
          color: 'warning.dark',
        }),
        outlinedError: sx({
          bgcolor: 'error.lighter',
          borderColor: 'error.light',
          color: 'error.dark',
        }),
      },
    },
  },
});

const Label = styled(Flex, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'variant' && prop !== 'size' && prop !== 'sx',
  name: 'Label',
  slot: 'Root',
  // We are specifying here how the styleOverrides are being applied based on props
  overridesResolver: (props, styles) => [
    styles.root,
    props.variant === 'contained' && styles.contained,
    props.variant === 'contained' && props.color === 'primary' && styles.containedPrimary,
    props.variant === 'contained' && props.color === 'secondary' && styles.containedSecondary,
    props.variant === 'contained' && props.color === 'info' && styles.containedInfo,
    props.variant === 'contained' && props.color === 'success' && styles.containedSuccess,
    props.variant === 'contained' && props.color === 'warning' && styles.containedWarning,
    props.variant === 'contained' && props.color === 'error' && styles.containedError,
    props.variant === 'outlined' && styles.outlined,
    props.variant === 'outlined' && props.color === 'primary' && styles.outlinedPrimary,
    props.variant === 'outlined' && props.color === 'secondary' && styles.outlinedSecondary,
    props.variant === 'outlined' && props.color === 'info' && styles.outlinedInfo,
    props.variant === 'outlined' && props.color === 'success' && styles.outlinedSuccess,
    props.variant === 'outlined' && props.color === 'warning' && styles.outlinedWarning,
    props.variant === 'outlined' && props.color === 'error' && styles.outlinedError,
    props.size === 'medium' && styles.sizeMedium,
    props.size === 'small' && styles.sizeSmall,
  ],
})<LabelProps>({
  width: 'fit-content',
});

export default Label;

export { labelTheme };
