import { Divider } from '@mui/material';

import { Box, Paper, PaperProps, Text } from '../../../../../Components/Base';
import {
  getExamTypeDetail,
  getQuestionRow,
  getRangeOfQuestion,
} from '../../../../../Domains/useGetExaminationDetails/utils';
import { AnswerSheetByGroupProps } from '../../../Components/AnswerSheet/AnswerSheetByGroup/types';
import FormAnswerItem from '../../FormAnswerItem/FormAnswerItem';

const AnswerSheetTypeMultipleChoice = ({
  group,
  questions,
  ...restProps
}: AnswerSheetByGroupProps & PaperProps) => {
  const questionGroup = group;
  const questionRangeText = getRangeOfQuestion(questions);

  return (
    <Paper
      elevation={4}
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 1, md: 2 },
        borderRadius: { xs: 0, sm: 1 },
        bgcolor: 'common.white',
      }}
      {...restProps}
    >
      <Text mb={2} variant={'subtitle2'}>
        {`${questionRangeText} ${getExamTypeDetail(questionGroup)}`}
      </Text>
      <Divider />
      <Box
        mt={2}
        display="grid"
        gridAutoFlow="column"
        gridTemplateRows={{
          xs: `repeat(${questions.length}, 1fr)`,
          sm: `repeat(${getQuestionRow(questions.length / 2)}, 1fr)`,
          md: `repeat(${getQuestionRow(questions.length / 3)}, 1fr)`,
          lg: `repeat(${getQuestionRow(questions.length / 4)}, 1fr)`,
        }}
        justifyContent={'space-evenly'}
        rowGap={1}
        columnGap={1}
      >
        {questions.map((question, index) => {
          const questionType = question?.type;

          return <FormAnswerItem question={question} key={`${questionType}_${index}`} />;
        })}
      </Box>
    </Paper>
  );
};

export default AnswerSheetTypeMultipleChoice;
