import { createElement } from 'react';
import { FormProvider, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';

import FormTextInput from '../../../Components/Form/FormTextInput';

export interface RedeemBookFormInput {
  myBookCode: string | null;
}

export type RedeemBookFormContext = UseFormReturn<RedeemBookFormInput>;

export type RedeemBookFormSubmission = (
  formUtils: RedeemBookFormContext
) => SubmitHandler<RedeemBookFormInput>;

export type RedeemBookFormProps = {
  onSubmit: RedeemBookFormSubmission;
  initialFormValues: RedeemBookFormInput;
  submitComponent?: (formContext: RedeemBookFormContext) => JSX.Element;
};

const RedeemBookForm = (props: RedeemBookFormProps) => {
  const { onSubmit, initialFormValues, submitComponent } = props;
  const formContext = useForm<RedeemBookFormInput>({
    mode: 'onTouched',
    defaultValues: initialFormValues,
  });

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit(formContext))}>
        <FormTextInput name={'myBookCode'} sx={{ marginBottom: '32px' }} />
        {submitComponent && createElement(submitComponent, formContext)}
      </form>
    </FormProvider>
  );
};

export default RedeemBookForm;
