import { Alert, AlertTitle, Snackbar, SnackbarProps, Text } from '../Base';
import TimerOutlinedIcon from '../Icons/TimerOutlinedIcon';

export type TimerSnackbarProps = {
  variant: 'warningThirty' | 'warningFifteen' | 'warningFive';
} & SnackbarProps;

const getColorVariant = (variant: TimerSnackbarProps['variant']) => {
  switch (variant) {
    case 'warningThirty':
      return 'info';
    case 'warningFifteen':
      return 'warning';
    case 'warningFive':
      return 'error';
    default:
      return 'success';
  }
};

const getTimeVariant = (variant: TimerSnackbarProps['variant']) => {
  switch (variant) {
    case 'warningThirty':
      return 30;
    case 'warningFifteen':
      return 15;
    case 'warningFive':
      return 5;
    default:
      return;
  }
};

const TimerSnackbar = ({ variant, ...restProps }: TimerSnackbarProps) => {
  const color = getColorVariant(variant);
  const time = getTimeVariant(variant);

  return (
    <Snackbar {...restProps}>
      <Alert color={color} icon={<TimerOutlinedIcon />}>
        <AlertTitle>เหลือเวลาอีก {time} นาที</AlertTitle>
        <Text variant={'body2'} color={'text.secondary'}>
          ในการทำข้อสอบ เร่งมือหน่อยนะ
        </Text>
      </Alert>
    </Snackbar>
  );
};

export default TimerSnackbar;
