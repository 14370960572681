import { SvgIcon, SvgIconProps } from '@mui/material';

const RealisticIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4645 16.4645C17.4021 15.5268 18.6739 15 20 15C21.3261 15 22.5979 15.5268 23.5355 16.4645C24.4732 17.4021 25 18.6739 25 20C25 21.3261 24.4732 22.5979 23.5355 23.5355C22.5979 24.4732 21.3261 25 20 25C18.6739 25 17.4021 24.4732 16.4645 23.5355C15.5268 22.5979 15 21.3261 15 20C15 18.6739 15.5268 17.4021 16.4645 16.4645ZM20 18.3333C19.558 18.3333 19.1341 18.5089 18.8215 18.8215C18.5089 19.1341 18.3333 19.558 18.3333 20C18.3333 20.442 18.5089 20.8659 18.8215 21.1785C19.1341 21.4911 19.558 21.6667 20 21.6667C20.442 21.6667 20.8659 21.4911 21.1785 21.1785C21.4911 20.8659 21.6667 20.442 21.6667 20C21.6667 19.558 21.4911 19.1341 21.1785 18.8215C20.8659 18.5089 20.442 18.3333 20 18.3333Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.96173 20C10.3323 25.2395 14.2472 27.8463 18.6623 28.2693C19.5786 28.3571 20.2502 29.1711 20.1624 30.0873C20.0746 31.0036 19.2606 31.6752 18.3443 31.5874C12.314 31.0096 7.40888 27.2537 3.57086 20.8576C3.25414 20.3298 3.25413 19.6704 3.57082 19.1425C7.7703 12.1434 13.2407 8.33337 20 8.33337C26.7592 8.33337 32.2297 12.1434 36.4291 19.1425C36.7456 19.6699 36.7458 20.3287 36.4299 20.8563C36.0617 21.4711 35.6743 22.0741 35.2682 22.6645C34.7465 23.4229 33.7089 23.6149 32.9505 23.0932C32.1921 22.5716 32.0001 21.5339 32.5218 20.7755C32.6978 20.5196 32.87 20.261 33.0382 19.9999C29.3493 14.2649 24.998 11.6667 20 11.6667C15.0019 11.6667 10.6506 14.2649 6.96173 20ZM36.1785 27.1549C36.8294 27.8057 36.8294 28.861 36.1785 29.5119L29.5118 36.1786C28.861 36.8294 27.8057 36.8294 27.1548 36.1786L23.8215 32.8452C23.1706 32.1943 23.1706 31.1391 23.8215 30.4882C24.4723 29.8373 25.5276 29.8373 26.1785 30.4882L28.3333 32.643L33.8215 27.1549C34.4723 26.504 35.5276 26.504 36.1785 27.1549Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default RealisticIcon;
