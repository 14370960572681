// import { Flex, FlexProps } from '@skooldio/qms-ui';

// export default Flex;
// export type { FlexProps };

import { styled } from '@mui/material';

import Box, { BoxProps } from '../Box';

const Flex = styled(Box)``;
Flex.defaultProps = { display: 'flex' };

export default Flex;
export type { BoxProps as FlexProps };
