import { useFormContext } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';

import { FormAnswerQuestionType, useExamination } from '../../Contexts/ExaminationProvider';
import { setPageTitle } from '../../Utils/page';
import ExamRoomHeader from '../ExaminationRoom/Components/ExamRoomHeader';
import ExamRoomAppBar from '../../Containers/ExaminationRoom/ExamRoomAppBar';
import { APP_BAR_HEIGHT, FOOTER_HEIGHT } from '../../Components/Layout';

import RecheckAnswer from './Containers/RecheckAnswer';

const ExaminationReview = () => {
  const formContext = useFormContext();
  const answers: FormAnswerQuestionType[] = formContext.getValues().answers;
  const { examTitle } = useExamination();

  return (
    <>
      <Helmet>
        <title>{setPageTitle('Examination Review')}</title>
      </Helmet>
      <ExamRoomAppBar />
      <Box
        bgcolor={'grey.50'}
        pt={0.75}
        pb={`${FOOTER_HEIGHT}px`}
        minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px - ${FOOTER_HEIGHT}px)`}
      >
        <ExamRoomHeader examTitle={examTitle} />
        <Container
          component="main"
          sx={{
            px: { xs: 0, md: 3, lg: 0 },
            pt: 3,
            pb: 2,
          }}
        >
          <RecheckAnswer answers={answers} />
        </Container>
      </Box>
    </>
  );
};

export default ExaminationReview;
