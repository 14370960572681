import { QuestionType } from '../../../../Domains/useGetExaminationDetails';
import { QbQuestionType } from '../../../../generated/graphql';

import FormAnswerConnectionType from './FormAnswerConnectionType';
import FormAnswerFillNumber from './FormAnswerFillNumberType';
import FormAnswerMultipleChoice from './FormAnswerMultipleChoiceType';
import FormAnswerYesNoType from './FormAnswerYesNoType';

interface FormAnswerItemProps {
  question: QuestionType;
}

const renderFormAnswerByQuestionType = ({
  question,
  questionType,
  questionNo,
}: {
  question: QuestionType;
  questionType: QbQuestionType;
  questionNo: number;
}) => {
  switch (questionType) {
    case QbQuestionType.MultipleSubQuestionsConnectionAnswers: {
      return <FormAnswerConnectionType questionNo={questionNo} />;
    }

    case QbQuestionType.MultipleSubQuestionsYesNoAnswer: {
      return (
        <FormAnswerYesNoType
          questionNo={questionNo}
          subQuestions={question?.metadata?.subQuestions}
        />
      );
    }

    case QbQuestionType.FillNumber: {
      return (
        <FormAnswerFillNumber decimal={question?.metadata?.precision} questionNo={questionNo} />
      );
    }

    default:
    case QbQuestionType.MultipleSelectWithCorrectAnswers:
    case QbQuestionType.MultipleSelect:
    case QbQuestionType.MultipleChoiceDifferentScore:
    case QbQuestionType.MultipleChoice: {
      return <FormAnswerMultipleChoice question={question} questionNo={questionNo} />;
    }
  }
};

const FormAnswerItem = ({ question }: FormAnswerItemProps) => {
  return renderFormAnswerByQuestionType({
    question,
    questionType: question?.type,
    questionNo: question?.order ?? 1,
  });
};

export default FormAnswerItem;
