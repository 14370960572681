import { Helmet } from 'react-helmet';

import { setPageTitle } from '../../Utils/page';
import { useExamination } from '../../Contexts/ExaminationProvider';
import { useConfig } from '../../Contexts/ConfigProvider';
import { Box, Container } from '../../Components/Base';
import ExamRoomAppBar from '../../Containers/ExaminationRoom/ExamRoomAppBar';
import { APP_BAR_HEIGHT, FOOTER_HEIGHT } from '../../Components/Layout';

import Examination from './Containers/Examination/Examination';
import ExaminationCBT from './Containers/Examination/ExaminationCBT';
import ExamRoomHeader from './Components/ExamRoomHeader';

const ExaminationRoom = () => {
  const { bookType, examTitle } = useExamination();
  const {
    examroom: { enabledCBT },
  } = useConfig();

  if ((bookType === 'PUBLIC_BOOK' || bookType === 'CBT_BOOK') && enabledCBT) {
    return (
      <>
        <Helmet>
          <title>{setPageTitle('Examination Room | CBT')}</title>
        </Helmet>
        <ExamRoomAppBar
          containerSx={{
            paddingX: { xs: 2, sm: 3, lg: 8 },
          }}
          containerMaxWidth={false}
        />
        <Box
          bgcolor={'grey.50'}
          pt={0.75}
          pb={`${FOOTER_HEIGHT}px`}
          minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px - ${FOOTER_HEIGHT}px)`}
        >
          <ExaminationCBT />
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{setPageTitle('Examination Room | PBT')}</title>
      </Helmet>
      <ExamRoomAppBar />
      <Box
        bgcolor={'grey.50'}
        pt={0.75}
        pb={`${FOOTER_HEIGHT}px`}
        minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px - ${FOOTER_HEIGHT}px)`}
      >
        <ExamRoomHeader examTitle={examTitle} />
        <Container
          component="main"
          sx={{
            px: { xs: 0, md: 3, lg: 0 },
            pt: 3,
            pb: 2,
          }}
        >
          <Examination />
        </Container>
      </Box>
    </>
  );
};

export default ExaminationRoom;
