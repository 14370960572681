import BaseBookDetails from '../../../../Components/BookDetails';

const bookData = {
  imageUrl: process.env.PUBLIC_URL + '/assets/my-book-preview.svg',
  title: 'รวมโจทย์ 9 วิชาสามัญ',
  author: 'ทีม TCASter และ Ondemand Education',
  refNo: 'TC001',
  bookUrl: process.env.PUBLIC_URL + '/assets/my-book-preview.svg',
};

const BookDetails = () => {
  return <BaseBookDetails {...bookData} />;
};

export default BookDetails;
