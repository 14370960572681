import { createTheme } from '@mui/material/styles';

const typographyTheme = createTheme({
  typography: {
    fontFamily: [
      'Prompt',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
    h1: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.53,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.46,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.46,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.46,
    },
    // typography of caption is a span element
    caption: {
      fontSize: 12,
      lineHeight: 1.5,
    },
  },
});

export default typographyTheme;
