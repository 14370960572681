import { groupBy, summarize, tidy } from '@tidyjs/tidy';
import { Outlet, useParams } from 'react-router-dom';

import Loading from '../../../Components/Loading';
import ExaminationProvider from '../../../Contexts/ExaminationProvider';
import { getInitialAnswers } from '../../../Contexts/ExaminationProvider/ExaminationProvider';
import {
  ExamAnswerType,
  FormAnswerQuestionType,
} from '../../../Contexts/ExaminationProvider/types';
import ExaminationTimerProvider from '../../../Contexts/ExaminationTimerProvider';
import { ExamQuestion } from '../../../Contexts/types';
import useGetBookDetails from '../../../Domains/useGetBookDetails';
import useGetExaminationDetails, {
  QmsskuType,
  QuestionFieldType,
} from '../../../Domains/useGetExaminationDetails';
import useGetExaminationSession, {
  QmsExamSession,
} from '../../../Domains/useGetExaminationSession';
import ExamRoomFooter from '../ExamRoomFooter';

type ExamRoomLayoutProps = {
  children?: React.ReactNode;
};

const ExamRoomLayout = ({ children }: ExamRoomLayoutProps) => {
  const { bookId = '' } = useParams();

  const { data: bookDetails, loading: bookDetailLoading } = useGetBookDetails({
    variables: { bookId },
    skip: !bookId,
  });
  const { data: examinationData, loading: examinationLoading } = useGetExaminationDetails({
    fetchPolicy: 'network-only',
  });
  const { data: examSession, loading: examSessionLoading } = useGetExaminationSession({
    fetchPolicy: 'network-only',
  });

  if (examSessionLoading || examinationLoading || bookDetailLoading) return <Loading open />;

  const examData = examinationData?.qmsExamRoomGetExam;
  const examItems = examData?.examItems ?? [];
  const sortedExamItems = [...examItems].sort((current, next) => current.order - next.order);

  // Get examination details
  const examQuestions = sortedExamItems.map((examItem) => ({
    id: examItem.question?.id,
    order: examItem.order,
    question: examItem.question?.question,
    metadata: examItem.question?.metadata,
    type: examItem.question?.type ?? QuestionFieldType.MultipleChoice,
    subType: examItem.question?.subType,
    examMaterialId: examItem.question?.examMaterialId,
  }));

  // Get examination answer
  const examSessionAnswer = (examSession?.qmsExamRoomGetExamSessionAnswer ?? {}) as QmsExamSession;
  const examAnswer = examSessionAnswer?.examRoomAnswer?.examRoomExamPaperItems ?? [];
  const examAnswerWithOrder = examAnswer?.map((answer) => {
    const order = examQuestions.find(({ id }) => id === answer?.questionId)?.order ?? 0;
    return { ...answer, order };
  });

  const initialAnswers = getInitialAnswers((examAnswerWithOrder ?? []) as ExamAnswerType) ?? [];
  const answersMapping = sortedExamItems.map(({ question }) => {
    const answer = initialAnswers.find((answer) => answer.questionId === question?.id);
    return { ...answer };
  });

  // Get examination materials
  const examMaterialGroups = tidy(
    sortedExamItems.map((item) => {
      return {
        order: item.order,
        examMaterial: item.question?.examMaterial,
      };
    }) ?? [],
    groupBy(
      ['examMaterial'],
      summarize({
        orderGroup: (items) => items.map((item) => item.order),
      })
    )
  );

  return (
    <ExaminationTimerProvider>
      <ExaminationProvider
        answers={answersMapping as FormAnswerQuestionType[]}
        examQuestions={examQuestions as ExamQuestion[]}
        bookType={bookDetails?.qmsGetBookDetailsByBookId.type ?? QmsskuType.Book}
        examMaterialGroups={examMaterialGroups}
        examTitle={examData?.title}
      >
        {children ?? <Outlet />}
        <ExamRoomFooter />
      </ExaminationProvider>
    </ExaminationTimerProvider>
  );
};

export default ExamRoomLayout;
