import { Divider, Toolbar } from '@mui/material';

import { AppBarWithAction } from '../../../Components/Layout';
import useGoogleTagManager from '../../../Utils/useGoogleTagManager';

type LoLExamRoomAppBarProps = {
  title?: string;
  description?: string;
  onBack?: () => void;
  rightAdornment?: React.ReactNode;
};

const LoLExamRoomAppBar = (props: LoLExamRoomAppBarProps) => {
  const { title = '', description = '', onBack, rightAdornment } = props;
  //TODO: Handle GA
  const sendDataToGTM = useGoogleTagManager();

  return (
    <>
      <AppBarWithAction
        onBack={onBack}
        title={title}
        description={description}
        iconSize="small"
        theme="lol"
        containerSx={{ px: 2, py: 1, borderBottom: '1px solid', borderColor: 'lolLine.line1' }}
        titlePosition={typeof onBack === 'function' ? 'center' : 'left'}
        rightAdornment={rightAdornment}
      />
      <Toolbar />
    </>
  );
};

export default LoLExamRoomAppBar;
