import { CheckboxProps, styled } from '@mui/material';
import React from 'react';

import { Flex, FlexProps } from '../../Base';

interface CheckboxIconSelectionProps {
  checked: boolean;
  name?: string;
  icon?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: string | number;
  checkboxSx?: CheckboxProps['sx'];
}

const HiddenCheckboxIconSelectionInput = styled('input')({
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const CheckboxIconSelectionWrapper = (props: FlexProps) => {
  const { sx, ...restProps } = props;

  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      border={'1px solid'}
      borderRadius={1}
      position={'relative'}
      p={1.5}
      sx={sx}
      {...restProps}
    />
  );
};

const CheckboxIconSelection = (checkboxProps: CheckboxIconSelectionProps) => {
  const {
    checked = false,
    icon = '',
    name,
    onBlur,
    onChange,
    value,
    checkboxSx,
    ...props
  } = checkboxProps;

  return (
    //REMARK: Handle specify styling for LOL Only
    <CheckboxIconSelectionWrapper
      bgcolor={checked ? 'lolElement.gray36' : 'lolElement.gray'}
      color={'lolTextButton.contrast'}
      border={'1px solid'}
      borderColor={checked ? 'lolElement.gray' : 'transparent'}
      sx={checkboxSx}
      {...props}
    >
      {icon}
      <HiddenCheckboxIconSelectionInput
        type={'checkbox'}
        value={value}
        name={name}
        checked={checked}
        onBlur={onBlur}
        onChange={(e) => onChange?.(e)}
      />
    </CheckboxIconSelectionWrapper>
  );
};

export default CheckboxIconSelection;
