import { Button, Modal, ModalProps, ModalContent, ModalFooter, Text, Flex } from '../Base';
import TimeUpIcon from '../Icons/TimeUpIcon';

export type TimeUpModalProps = {
  onConfirm: () => void;
} & ModalProps;

const TimeUpModal = ({ onConfirm, ...restProps }: TimeUpModalProps) => {
  return (
    <Modal {...restProps}>
      <ModalContent sx={{ py: 3, px: 6 }}>
        <Flex flexDirection={'column'} alignItems={'center'} mb={1}>
          <TimeUpIcon color={'primary'} sx={{ fontSize: 100 }} />
        </Flex>
        <Text variant={'h2'} color="grey.700" align="center">
          หมดเวลาทำข้อสอบ
        </Text>
        <Text align="center" color="grey.700" whiteSpace={'pre-line'}>
          กรุณากดปุ่มด้านล่าง เพื่อส่งข้อสอบ
        </Text>
      </ModalContent>
      <ModalFooter flexDirection={'column'} px={3} pb={3}>
        <Button size={'large'} fullWidth onClick={onConfirm}>
          ส่งข้อสอบ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TimeUpModal;
