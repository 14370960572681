import { LoLExaminationSection } from '../../Contexts/LoLExaminationProvider/types';

export const DEFAULT_MILESTONES = [
  {
    tags: [LoLExaminationSection.RIASEC_PART_1],
    careers: ['GROUP'],
    duration: 10,
  },
  {
    tags: [LoLExaminationSection.RIASEC_PART_2],
    careers: ['CAPABLE'],
    duration: 10,
  },

  {
    tags: [LoLExaminationSection.ACTIVITY_PART_1, LoLExaminationSection.VALUE],
    careers: ['PERSONALITY'],
    duration: 10,
  },
  {
    tags: [LoLExaminationSection.ACTIVITY_PART_2, LoLExaminationSection.STRENGTH],
    careers: ['IDENTITY', 'POTENTIAL_IDENTITY', 'PREFERENCE'],
    duration: 10,
  },
];
