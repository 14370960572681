import { MyProfileType } from '../../Domains/User/useGetMyProfile';

export const formatUserProfile = (data: MyProfileType['getMyProfile']) => {
  const userProfile = {
    id: data.id,
    firstname: data.firstname,
    lastname: data.lastname,
    learnId: data?.additional1 ?? '',
  };

  return userProfile;
};
