import { Divider } from '@mui/material';
import { Fragment } from 'react';

import { Flex, Box, Skeleton, Paper } from '../../../../Components/Base';
import ChevronRightRoundedIcon from '../../../../Components/Icons/ChevronRightRoundedIcon';

const ExamListLoading = () => {
  return (
    <Paper
      sx={{
        mb: { xs: 1, md: 2 },
        borderRadius: { xs: 0, md: 1 },
      }}
    >
      {Array.from({ length: 5 }).map((_, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider sx={{ mx: { xs: 2, md: 3 } }} />}
          <Flex px={{ xs: 2, md: 3 }} py={2} alignItems={'center'}>
            <Skeleton height={40} width={40} sx={{ p: 1, mr: 2 }} />
            <Box flex={1}>
              <Skeleton width={200} />
            </Box>
            <ChevronRightRoundedIcon sx={{ ml: 1.5, color: 'text.secondary' }} />
          </Flex>
        </Fragment>
      ))}
    </Paper>
  );
};

export default ExamListLoading;
