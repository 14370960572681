import { Text } from '../../../../Components/Base';

const TextView = () => {
  return (
    <>
      <Text variant="h1" component="div" gutterBottom>
        h1. Heading
      </Text>
      <Text variant="h2" gutterBottom component="div">
        h2. Heading
      </Text>
      <Text variant="h3" gutterBottom component="div">
        h3. Heading
      </Text>
      <Text variant="subtitle1" gutterBottom component="div">
        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
      </Text>
      <Text variant="subtitle2" gutterBottom component="div">
        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
      </Text>
      <Text variant="body1" gutterBottom>
        body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
        dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </Text>
      <Text variant="body2" gutterBottom>
        body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
        dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </Text>
      <Text variant="button" display="block" gutterBottom>
        button text
      </Text>
      <Text variant="caption" display="block" gutterBottom>
        caption text
      </Text>
    </>
  );
};

export default TextView;
