import { styled, alpha } from '@mui/material';

import { Flex, FlexProps, Text } from '../Base';
import ArrowForwardIcon from '../Icons/ArrowForwardIcon';
import { parseDate } from '../../Utils/DateUtils';

const ResultRoundItemWrapper = styled(Flex)<FlexProps & { $disabled?: boolean }>(
  ({ theme, $disabled }) => ({
    '&:hover': {
      cursor: $disabled ? 'not-allowed' : 'pointer',
      background: $disabled ? 'unset' : alpha(theme.palette.primary.main, 0.04),
    },
  })
);

export type ResultRoundItemProps = {
  fullScore: string | number;
  isCurrentExam?: boolean;
  iteration: string | number;
  score: string | number;
  startedExamAt: string;
  variant: 'GRADING' | 'FINISHED';
} & FlexProps;

const getStyleByVariant = (variant: 'GRADING' | 'FINISHED') => {
  switch (variant) {
    case 'GRADING':
      return {
        color: {
          accent: 'grey.500',
          mainText: 'grey.500',
          subText: 'grey.500',
        },
      };
    case 'FINISHED':
    default:
      return {
        color: {
          accent: 'primary.main',
          mainText: 'text.primary',
          subText: 'text.secondary',
        },
      };
  }
};

const getMessageByVariant = (variant: 'GRADING' | 'FINISHED') => {
  switch (variant) {
    case 'GRADING':
      return 'กำลังตรวจข้อสอบ...';
    case 'FINISHED':
    default:
      return 'คะแนนที่ได้';
  }
};

const ResultRoundItem = (props: ResultRoundItemProps) => {
  const {
    fullScore,
    isCurrentExam = true,
    iteration,
    score,
    startedExamAt,
    variant = 'GRADING',
    ...restProps
  } = props;

  const styling = getStyleByVariant(variant);
  const statusText = getMessageByVariant(variant);
  const currentExamMessage = variant === 'FINISHED' && isCurrentExam ? '(อัปเดตล่าสุด)' : '';

  return (
    <ResultRoundItemWrapper $disabled={variant === 'GRADING'} {...restProps}>
      <Flex minWidth={40} justifyContent={'center'} mr={2}>
        <Flex
          justifyContent={'center'}
          border={'1px solid'}
          borderColor={styling?.color.accent}
          borderRadius={30}
          width={24}
          height={24}
          flexShrink={0}
        >
          <Text variant="subtitle1" color={styling?.color.accent}>
            {iteration}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} width={'100%'}>
        <Flex alignItems={'center'} justifyContent={'space-between'} pb={'4px'}>
          <Text variant="body1" color={styling?.color.mainText}>
            {`${statusText} ${currentExamMessage}`.trim()}
          </Text>
          <Text variant="body1" fontWeight={600} color={styling?.color.mainText}>
            {variant === 'GRADING' ? 0 : score}/{fullScore}
          </Text>
        </Flex>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Text variant="caption" color={styling?.color.subText}>
            สอบเมื่อ {parseDate(startedExamAt)}
          </Text>
          <Flex color={styling?.color.accent}>
            <Text fontSize={12} fontWeight={600}>
              ดูผลวิเคราะห์
            </Text>
            <ArrowForwardIcon sx={{ fontSize: 18 }} />
          </Flex>
        </Flex>
      </Flex>
    </ResultRoundItemWrapper>
  );
};

export default ResultRoundItem;
