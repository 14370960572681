import defaultsDeep from 'lodash/defaultsDeep';

const defaultConfig = {};

const mergeConfigOverrides = (config, ...args) => {
  // use defaultsDeep as same as odah-studyroom
  // might consider other merge mechanics as needed
  const resultConfig = defaultsDeep({}, config, ...args, defaultConfig);

  return resultConfig;
};

export { defaultConfig, mergeConfigOverrides };

export default defaultConfig;
