const padding = (value: number) => String(value).padStart(2, '0');

export const parseDate = (isoDate: string) => {
  const date = new Date(isoDate);
  const dd = padding(date.getDate());
  const mm = padding(date.getMonth() + 1);
  const yy = date.getFullYear().toString().substring(2);
  const time = date.toLocaleTimeString('en', {
    timeStyle: 'short',
    hour12: false,
  });
  return `${dd}/${mm}/${yy}, ${time}`;
};
