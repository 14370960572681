import { ArrowBackIosNewRounded } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text } from '../../../../Components/Base';
import HTMLView from '../../../../Components/HTMLView';
import { useLoLExamination } from '../../../../Contexts/LoLExaminationProvider';
import FormAnswerLabel from '../LoLFormAnswer/FormAnswerLabel';
import FormAnswerRating from '../LoLFormAnswer/FormAnswerRating';
import { LoLFormAnswerType } from '../LoLFormAnswer/utils';

const removeHTMLTag = (content: string) => {
  return content.replace(/<[^>]*>/g, '');
};

const renderChoices = ({
  subType,
  type,
  questionNo,
  choices,
}: {
  subType?: string | null;
  type: LoLFormAnswerType;
  questionNo: number;
  choices?: Array<{ label: string; value: string }>;
}) => {
  switch (type) {
    case LoLFormAnswerType.LABEL:
      return <FormAnswerLabel subType={subType} questionNo={questionNo} choices={choices} />;
    default:
    case LoLFormAnswerType.RATING:
      return <FormAnswerRating subType={subType} questionNo={questionNo} choices={choices} />;
  }
};

const LoLQuestionContent = () => {
  const {
    examQuestions,
    currentQuestionNo = 1,
    setCurrentQuestionNo,
    milestones,
  } = useLoLExamination();
  const { t } = useTranslation('lolExamroom');
  const answer = useWatch({ name: `answers.${currentQuestionNo - 1}` });

  const disabledBackButon = useMemo(() => {
    return milestones?.some(({ startQuestionNo }) => currentQuestionNo === startQuestionNo);
  }, [currentQuestionNo, milestones]);

  const currentQuestion = useMemo(() => {
    const currentExam = examQuestions.find((question) => question.order === currentQuestionNo);
    return currentExam;
  }, [examQuestions, currentQuestionNo]);

  const { subType, question, metadata = { choices: [] }, tags } = currentQuestion ?? {};

  const { choices = [] } = metadata;

  const formAnswerType = useMemo(() => {
    if (tags && tags.findIndex(({ slug }) => slug === 'ACTIVITY') > -1) {
      return LoLFormAnswerType.RATING;
    }
    return LoLFormAnswerType.LABEL;
  }, [tags]);

  const formatChoices = choices.map(({ label, ...choiceProps }: { label: string }) => {
    return { label: removeHTMLTag(label), ...choiceProps };
  });

  const [shouldReverseQuestion, setShouldReverseQuestion] = useState(false);

  const handleBack = () => {
    (async () => {
      setShouldReverseQuestion(true);
    })().then(() => {
      setCurrentQuestionNo(currentQuestionNo - 1);
    });
  };

  useEffect(() => {
    (async () => {
      setShouldReverseQuestion(false);
    })().then(() => {
      if (answer?.answer?.value !== undefined) {
        setCurrentQuestionNo((prev) => (prev !== undefined ? prev + 1 : undefined));
      }
    });
  }, [answer, setCurrentQuestionNo]);

  return (
    <>
      <Box
        overflow={'hidden'}
        // HACK: Below styles for hiding first entry of another items from framer motion
        sx={{
          '& > :nth-child(n+2)': {
            display: 'none!important',
            width: '100%!important',
          },
        }}
      >
        <AnimatePresence>
          <motion.div
            key={currentQuestionNo}
            initial={{
              width: '100%',
              x: shouldReverseQuestion ? '-1000px' : '0px',
              opacity: 0,
              zIndex: 0,
            }}
            animate={{ width: '100%', x: 0, opacity: 1, zIndex: 1 }}
            transition={{
              opacity: { delay: 0.4, duration: 0.2, ease: 'easeIn' },
              x: { duration: 0.5, ease: 'easeIn' },
            }}
            exit={{
              width: '100%',
              x: shouldReverseQuestion ? '1000px' : '-1000px',
            }}
          >
            <Box
              color={'lolText.primary'}
              sx={{
                '& > div > p': {
                  fontSize: 20,
                },
                '@media(max-width: 389px)': {
                  '& > div > p': {
                    fontSize: 16,
                  },
                },
              }}
            >
              <HTMLView rawContent={question} />
              <Text variant="caption" color={'lolText.secondary'} component={'div'} mt={1}>
                {t('DIRECTION')}
              </Text>
              <Box my={2}>
                {renderChoices({
                  subType,
                  type: formAnswerType,
                  questionNo: currentQuestionNo,
                  choices: formatChoices,
                })}
              </Box>
            </Box>
          </motion.div>
        </AnimatePresence>
      </Box>
      <Button
        variant="outlined"
        size="small"
        sx={{
          borderColor: 'lolLine.line1',
          color: 'lolTextButton.contrast36',
          '&:hover': {
            borderColor: 'lolLine.line1',
          },
        }}
        disabled={disabledBackButon}
        onClick={handleBack}
        startIcon={<ArrowBackIosNewRounded sx={{ color: 'lolIcon.contrast36' }} />}
      >
        {t('BACK')}
      </Button>
    </>
  );
};

export default LoLQuestionContent;
