import { styled } from '@mui/material';

import { Text, Flex, FlexProps } from '../Base';
import BaseBookmarkSharpIcon from '../Icons/BookmarkSharpIcon';

const BookmarkSharpIcon = styled(BaseBookmarkSharpIcon)({
  position: 'absolute',
  fontSize: 16,
  top: '-3.5px',
  right: '5px',
});

const ShadowBookmarkSharpIcon = styled(BaseBookmarkSharpIcon)({
  position: 'absolute',
  fontSize: 16,
  top: '-2.5px',
  right: '5px',
  filter: 'blur(0.5px)',
});

export type QuestionNoItemProps = {
  questionNo: string | number;
  isMark: boolean;
  isAnswered: boolean;
  isActive?: boolean;
} & FlexProps;

const renderStyles = (isActive: boolean, isAnswered: boolean) => {
  if (isActive) {
    return { bgColor: 'common.white', borderColor: 'primary.main', textColor: 'primary.main' };
  }

  if (isAnswered) {
    return { bgColor: 'success.main', borderColor: 'transparent', textColor: 'common.white' };
  }

  return { bgColor: 'common.white', borderColor: 'grey.400', textColor: 'grey.700' };
};

const QuestionNoItem = (props: QuestionNoItemProps) => {
  const { questionNo, isMark, isAnswered, isActive = false, ...restProps } = props;

  const { bgColor, textColor, borderColor } = renderStyles(isActive, isAnswered);

  return (
    <Flex
      position={'relative'}
      justifyContent={'center'}
      alignItems={'center'}
      py={1.25}
      px={1}
      width={'100%'}
      borderRadius={1}
      border="1px solid"
      borderColor={borderColor}
      bgcolor={bgColor}
      color={textColor}
      sx={{ cursor: 'pointer', ...restProps.sx }}
      {...restProps}
    >
      <Text variant={'subtitle1'}>{questionNo}</Text>
      {isMark ? (
        <>
          {/* FIXME: CSS filter spread drop-shadow value 1px on safari not working */}
          <ShadowBookmarkSharpIcon htmlColor={'rgba(0, 0, 0, 0.25)'} />
          <BookmarkSharpIcon color={'warning'} />
        </>
      ) : null}
    </Flex>
  );
};

export default QuestionNoItem;
