import { SvgIcon, SvgIconProps } from '@mui/material';

const AchievementIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        d="M11.6667 35V31.6667H18.3333V26.5C16.9722 26.1944 15.7567 25.6178 14.6867 24.77C13.6167 23.9222 12.8322 22.86 12.3333 21.5833C10.25 21.3333 8.50667 20.4239 7.10333 18.855C5.7 17.2861 4.99889 15.4456 5 13.3333V11.6667C5 10.75 5.32667 9.965 5.98 9.31167C6.63333 8.65833 7.41778 8.33222 8.33333 8.33333H11.6667V5H28.3333V8.33333H31.6667C32.5833 8.33333 33.3683 8.66 34.0217 9.31333C34.675 9.96667 35.0011 10.7511 35 11.6667V13.3333C35 15.4444 34.2983 17.285 32.895 18.855C31.4917 20.425 29.7489 21.3344 27.6667 21.5833C27.1667 22.8611 26.3817 23.9239 25.3117 24.7717C24.2417 25.6194 23.0267 26.1956 21.6667 26.5V31.6667H28.3333V35H11.6667ZM11.6667 18V11.6667H8.33333V13.3333C8.33333 14.3889 8.63889 15.3406 9.25 16.1883C9.86111 17.0361 10.6667 17.64 11.6667 18ZM28.3333 18C29.3333 17.6389 30.1389 17.0344 30.75 16.1867C31.3611 15.3389 31.6667 14.3878 31.6667 13.3333V11.6667H28.3333V18Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AchievementIcon;
