import { forwardRef, HTMLAttributes, useEffect, useImperativeHandle, useRef } from 'react';
import { styled } from '@mui/material';
import DOMPurify from 'dompurify';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import katex from 'katex/dist/contrib/auto-render.min.js';

import { Box, BoxProps } from '../Base';

const isAllowedIframeSource = (srcAttribute: string | null) => !!srcAttribute;

DOMPurify.addHook('beforeSanitizeElements', (currentNode: Element) => {
  if (currentNode.tagName === 'IFRAME') {
    const srcAttribute = currentNode.getAttribute('src');
    if (isAllowedIframeSource(srcAttribute)) {
      return currentNode;
    }
    currentNode.remove();
  }
});

type HTMLViewProps = {
  rawContent?: string;
  children?: string;
} & HTMLAttributes<HTMLDivElement> &
  Omit<BoxProps, 'children'>;

const HTMLView = styled(
  forwardRef((props: HTMLViewProps, ref: React.ForwardedRef<HTMLElement>) => {
    const { rawContent = '', children = '', ...restProps } = props;
    const sanitized = DOMPurify.sanitize(rawContent || children, {
      ADD_TAGS: ['iframe'],
    });

    const htmlRef = useRef<HTMLElement | null>(null);

    useImperativeHandle(ref, () => htmlRef.current ?? document.createElement('div'), []);

    useEffect(() => {
      // This is an extension to automatically render all of the math inside of text.
      // Ref: https://katex.org/docs/autorender.html
      katex(htmlRef.current, {
        delimiters: [
          { left: '$$', right: '$$', display: true },
          { left: '$', right: '$', display: false },
          { left: '\\(', right: '\\)', display: false },
          { left: '\\[', right: '\\]', display: true },
        ],
        throwOnError: true,
      });
    }, [htmlRef, rawContent]);

    return <Box dangerouslySetInnerHTML={{ __html: sanitized }} {...restProps} ref={htmlRef} />;
  })
)(({ theme }) => ({
  p: {
    margin: theme.spacing(0),
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
  '.katex': {
    lineHeight: 2,
  },
}));

export default HTMLView;
