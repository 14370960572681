import { useEffect, useState } from 'react';

import {
  useQmsFoMyExamPapersLazyQuery,
  QmsExamPapersResult as QMSExamPapersResultType,
  QmsFoMyExamPapersQuery,
  QmsOrderType,
  QmsExamPaperStatus,
  GetMyExamCardsQuery,
} from '../../generated/graphql';

export type ExamResultsType = {
  examCard: GetMyExamCardsQuery['qmsMyExamCards'][0];
  examResults: QmsFoMyExamPapersQuery['qmsFoMyExamPapers'];
};

const useGetExaminationResults = ({
  bookId,
  examCards,
  statuses,
}: {
  bookId?: string;
  examCards?: GetMyExamCardsQuery['qmsMyExamCards'];
  statuses?: Array<QmsExamPaperStatus>;
}) => {
  const [examResults, setExamResults] = useState<ExamResultsType[]>();
  const [loading, setLoading] = useState(false);
  const [getExaminationResults, { error, fetchMore }] = useQmsFoMyExamPapersLazyQuery();

  const fetchExaminationResults = async (examCards: GetMyExamCardsQuery['qmsMyExamCards']) => {
    setLoading(true);
    try {
      const queryResults = await Promise.allSettled(
        examCards.map(async (exam, index: number) => {
          const examCardId = exam.id;
          const option = {
            variables: {
              myExamPapersInput: {
                bookId: bookId ?? '',
                examCardId: examCardId ?? '',
                statuses: statuses ?? [],
              },
              paging: {
                pageSize: 10,
                currentPage: 0,
              },
              order: {
                field: 'createdAt',
                type: QmsOrderType.Desc,
              },
            },
          };
          const { data } =
            index === 0
              ? await getExaminationResults({
                  ...option,
                  fetchPolicy: 'network-only',
                  nextFetchPolicy: 'network-only',
                })
              : await fetchMore(option);

          if (data?.qmsFoMyExamPapers && data?.qmsFoMyExamPapers.total > 0) {
            return {
              examCard: exam,
              examResults: data?.qmsFoMyExamPapers,
            };
          }
        })
      );

      const examResultList = queryResults
        .map((result) => (result.status === 'fulfilled' ? result.value : undefined))
        .filter((result) => result) as ExamResultsType[];

      setLoading(false);
      setExamResults(examResultList);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const refetch = () => {
    if (examCards) {
      fetchExaminationResults(examCards);
    }
  };

  useEffect(() => {
    if (examCards) {
      fetchExaminationResults(examCards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, examCards, fetchMore, getExaminationResults]);

  return { data: examResults, loading, error, refetch };
};

export default useGetExaminationResults;
export { QmsOrderType, QmsExamPaperStatus };
export type { QMSExamPapersResultType, QmsFoMyExamPapersQuery };
