import { Navigate, useParams } from 'react-router-dom';

import { MYBOOKS_EXAMINATIONS } from '../constant';

const MyBookDetails = () => {
  const { bookId } = useParams();
  return <Navigate to={MYBOOKS_EXAMINATIONS.replace(':bookId', bookId ?? '')} replace />;
};

export default MyBookDetails;
