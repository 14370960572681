import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import urlJoin from 'url-join';

import fetch, { METHOD } from '../../Utils/fetch';
import { LoginState, useAuthentication } from '../AuthenticationProvider';
import { useConfig } from '../ConfigProvider';

import { Anonymous, AnonymousState } from './type';
import { isAuthenticationError } from './utils';

const AnonymousContext = createContext<Anonymous | undefined>(undefined);

const AnonymousProvider = ({ children }: { children?: React.ReactNode }) => {
  const { loginState } = useAuthentication();
  const [anonymousUserState, setAnonymousUserState] = useState<AnonymousState>(
    AnonymousState.NOT_LOGGED_IN
  );
  const [anonymousUser, setAnonymousUser] = useState();

  const { userApi } = useConfig();

  const ANONYMOUS_USER_PATH = urlJoin(userApi.path, userApi.anonymousUser);
  const ANONYMOUS_USER_LOGIN_PATH = urlJoin(userApi.path, userApi.anonymousUserLogin);

  const setAnonymousUserId = useCallback(async () => {
    try {
      const result = await fetch(ANONYMOUS_USER_LOGIN_PATH, null, METHOD.POST);
      const anonymousUser = result.anonymousUser;

      if (anonymousUser?.id) {
        console.log('ANONYMOUS LOGGED IN');
        setAnonymousUser(anonymousUser);
        setAnonymousUserState(AnonymousState.LOGGED_IN);
      } else {
        throw Error('Unable to retrieve anonymous user id');
      }
    } catch (error) {
      console.error('Error logging in as anonymous user', error);
      setAnonymousUserState(AnonymousState.NOT_LOGGED_IN);
    }
  }, [ANONYMOUS_USER_LOGIN_PATH]);

  const anonymousUserLogin = useCallback(async () => {
    setAnonymousUserState(AnonymousState.LOADING);
    try {
      const anonymousData = await fetch(ANONYMOUS_USER_PATH);
      const isNotCurrentlyAnonymous = isAuthenticationError(anonymousData?.error);

      if (isNotCurrentlyAnonymous) {
        await setAnonymousUserId();
      } else {
        setAnonymousUser(anonymousData?.anonymousUser);
        setAnonymousUserState(AnonymousState.LOGGED_IN);
      }
    } catch (error) {
      console.error('Error checking anonymous user path >>', error);
      setAnonymousUserState(AnonymousState.NOT_LOGGED_IN);
    }
  }, [ANONYMOUS_USER_PATH, setAnonymousUserId]);

  useEffect(() => {
    // If the users are not logged in, they should be logged in as anonymous user.
    if (loginState === LoginState.NOT_LOGGED_IN) {
      anonymousUserLogin();
    }
  }, [anonymousUserLogin, loginState]);

  return (
    <AnonymousContext.Provider value={{ anonymousUserState, anonymousUser }}>
      {children ?? <Outlet />}
    </AnonymousContext.Provider>
  );
};

const useAnonymous = (): Anonymous => {
  const context = useContext(AnonymousContext);
  if (context === undefined) {
    throw new Error('useAnonymous must by used within AnonymousContext');
  }
  return context;
};

export default AnonymousProvider;
export { AnonymousContext, useAnonymous };
