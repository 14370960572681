import { Box, Divider } from '@mui/material';

import { Paper, PaperProps, Text } from '../../../../../Components/Base';
import {
  getExamTypeDetail,
  getRangeOfQuestion,
} from '../../../../../Domains/useGetExaminationDetails/utils';
import { AnswerSheetByGroupProps } from '../../../Components/AnswerSheet/AnswerSheetByGroup/types';
import FormAnswerItem from '../../FormAnswerItem/FormAnswerItem';

const AnswerSheetTypeMultipleSubQuestionsYesNoAnswerChoice = ({
  group,
  questions,
  ...restProps
}: AnswerSheetByGroupProps & PaperProps) => {
  const questionGroup = group;
  const questionRangeText = getRangeOfQuestion(questions);

  return (
    <Paper
      elevation={4}
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 1, md: 2 },
        borderRadius: { xs: 0, sm: 1 },
        bgcolor: 'common.white',
      }}
      {...restProps}
    >
      <Text mb={2} variant={'subtitle2'}>
        {`${questionRangeText} ${getExamTypeDetail(questionGroup)}`}
      </Text>
      <Divider />
      <Box
        mt={2}
        display="grid"
        gridAutoFlow="row"
        gridTemplateColumns={{
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        justifyContent={'space-evenly'}
        rowGap={{ xs: 'auto', sm: 2, md: 3 }}
        columnGap={2}
      >
        {questions.slice(0, 5).map((question, index) => {
          const questionType = question?.type;
          return <FormAnswerItem question={question} key={`${questionType}_${index}`} />;
        })}
      </Box>
    </Paper>
  );
};

export default AnswerSheetTypeMultipleSubQuestionsYesNoAnswerChoice;
