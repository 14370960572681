import { QbQuestionType } from '../../generated/graphql';

export const renderAnswerTypeMessage = (type?: QbQuestionType, subType?: string | null) => {
  const numberOfChoices =
    typeof subType === 'string' ? parseInt(subType?.charAt(subType.length - 1)) : undefined;

  switch (type) {
    case 'FILL_NUMBER':
      return 'อัตนัยเติมตัวเลข';
    case 'MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER':
      return 'รูปแบบเลือกคำตอบเชิงซ้อนใช่ หรือไม่ใช่ 3 ข้อย่อย';
    case 'MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS':
      return 'รูปแบบกรอกตัวเลข 2 หลักของข้อความที่กำหนด และตามด้วยตัวอักษร';
    case 'MULTIPLE_CHOICE_DIFFERENT_SCORE':
      switch (subType) {
        case 'MDS4':
        case 'MDS5':
        default:
          return `ปรนัย ${numberOfChoices} ตัวเลือก 1 คำตอบ คะแนนต่างกัน`;
      }
    case 'MULTIPLE_SELECT_WITH_CORRECT_ANSWERS':
      switch (subType) {
        case 'MMA4':
        case 'MMA5':
        default:
          return `ปรนัย ${numberOfChoices} ตัวเลือกหลายคำตอบ`;
      }
    case 'MULTIPLE_CHOICE':
      if (!subType) break;
      switch (subType) {
        case 'MUL1/4':
        case 'MUL1/5':
        default:
          return `ปรนัย ${numberOfChoices} ตัวเลือก 1 คำตอบ`;
      }
    default:
      break;
  }

  return 'รูปแบบปรนัย 4 ตัวเลือก';
};
