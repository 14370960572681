import { Fragment } from 'react';
import { ContainerProps, styled, Toolbar } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AnswerQuestionType } from '../../../Contexts/ExaminationProvider';
import { AppBarWithAction } from '../../../Components/Layout';
import { checkAnswerdQuestions } from '../../../Routes/ExaminationReview/utils';
import { useExaminationTimer } from '../../../Contexts/ExaminationTimerProvider/ExaminationTimerProvider';
import BaseLinearProgress from '../../../Components/Base/LinearProgress';
import LeaveExamRoomModal from '../../../Components/LeaveExamRoomModal';
import Timer from '../../Timer';
import useDisclosure from '../../../Utils/useDisclosure';
import useGetExaminationDetails from '../../../Domains/useGetExaminationDetails';
import useGoogleTagManager from '../../../Utils/useGoogleTagManager';
import useUpdateExaminationPaper from '../../../Domains/useUpdateExaminationPaper';
import { QmsExamRoomExamPaperItemInput } from '../../../generated/graphql';

const LinearProgress = styled(BaseLinearProgress)(({ value }) => ({
  '&.MuiLinearProgress-root': {
    borderRadius: '0px',
  },
  '& .MuiLinearProgress-bar1Determinate': {
    borderRadius: value === 100 ? '0px' : '0px 40px 40px 0px',
  },
}));

const ExamRoomTimer = () => {
  const { hasTimer } = useExaminationTimer();
  if (hasTimer) return <Timer />;
  return <Fragment />;
};

type ExamRoomAppBarProps = {
  containerSx?: ContainerProps['sx'];
  containerMaxWidth?: ContainerProps['maxWidth'];
};

const ExamRoomAppBar = (props: ExamRoomAppBarProps) => {
  const { containerSx, containerMaxWidth } = props;
  const navigate = useNavigate();
  const { control } = useFormContext();
  const {
    isOpen: isLeaveExamModalOpen,
    open: openLeaveExamModal,
    close: closeLeaveExamModal,
  } = useDisclosure();

  const sendDataToGTM = useGoogleTagManager();

  const { data } = useGetExaminationDetails({
    fetchPolicy: 'cache-and-network',
  });
  const answers: AnswerQuestionType[] = useWatch({ control, name: 'answers' });
  const answeredCount = answers.filter(checkAnswerdQuestions).length;
  const examData = data?.qmsExamRoomGetExam;
  const totalQuestion = examData?.examItems?.length ?? 0;
  const examProgressPercentage = totalQuestion > 0 ? (answeredCount / totalQuestion) * 100 : 0;

  const [updateExaminationPaper, { loading: updateLoading }] = useUpdateExaminationPaper();
  const { getValues } = useFormContext();

  // Not require from requirement
  // The system should be updated latest answer to backup answer data before exit examination room
  const handleUpdateAnswer = async () => {
    const formatAnswer: QmsExamRoomExamPaperItemInput[] = getValues('answers')?.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ order, ...answer }: { order: number }) => answer
    );

    await updateExaminationPaper({
      variables: { examRoomExamPaperItems: formatAnswer },
    });
  };

  const handleCancelLeaveExam = () => {
    sendDataToGTM({ event: 'exit_examroom_cta_click', buttonCategory: 'cancel exit' });
    closeLeaveExamModal();
  };

  const handleLeaveExamRoom = () => {
    sendDataToGTM({ event: 'exit_examroom_cta_click', buttonCategory: 'confirm exit' });
    handleUpdateAnswer();
    if (!updateLoading) {
      navigate('/mybooks');
    }
  };

  return (
    <>
      <AppBarWithAction
        title={`จำนวนข้อ ${answeredCount}/${totalQuestion}`}
        titlePosition={'left'}
        onClose={openLeaveExamModal}
        rightAdornment={<ExamRoomTimer />}
        bottomAdornment={<LinearProgress value={examProgressPercentage} />}
        containerSx={containerSx}
        containerMaxWidth={containerMaxWidth}
      />
      <Toolbar />
      <LeaveExamRoomModal
        onLeave={handleLeaveExamRoom}
        onCancel={handleCancelLeaveExam}
        open={isLeaveExamModalOpen}
      />
    </>
  );
};

export default ExamRoomAppBar;
