import { useNavigate, useParams } from 'react-router-dom';
import { groupBy, summarize, tidy } from '@tidyjs/tidy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../Components/Base';
import Loading from '../../Components/Loading';
import LoLExamRoomAppBar from '../../Containers/LoLExaminationRoom/LoLExamRoomAppBar';
import { useGetLoLExamByExamPaperIdQuery as useGetLoLExamByExamPaperId } from '../../generated/graphql';
import {
  LoLExaminationSection,
  LoLExaminationTag,
} from '../../Contexts/LoLExaminationProvider/types';
import { LAST_QUESTION_NO_OF_FIRST_ACTIVITY } from '../../Containers/LoLExaminationRoom/constants';
import { DEFAULT_MILESTONES } from '../LoLMilestones/constants';

import CoreTraitList from './Containers/CoreTraitList';
import CharacterList from './Containers/CharacterList';
import CareerList from './Containers/CareerList';
import { MilestoneTags } from './types';

// TODO: refactor LoLFullReport to be as normal Route element. (add the container to fetch/render lists insted of LoLFullReport)
const LoLFullReport = () => {
  const { examPaperId = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('lolResults');

  const { data: examinationDataByExamPaper, loading: examinationDataByExamPaperLoading } =
    useGetLoLExamByExamPaperId({
      variables: { examPaperId },
      fetchPolicy: 'network-only',
      skip: examPaperId === '' || !examPaperId,
    });

  const examData = examinationDataByExamPaper?.qmsFoGetExamPaperById.exam;

  const examItems = examData?.examItems ?? [];
  const sortedExamItems = [...examItems].sort((current, next) => current.order - next.order);

  const lastTrialQuestionNo = examData?.metadata?.endOfTrialAtQuestionNo;

  const examQuestions = sortedExamItems.map((examItem) => ({
    id: examItem.question?.id,
    order: examItem.order,
    tags: examItem.question?.tags,
  }));

  const examQuestionByTags = tidy(
    examQuestions.map((examItem) => {
      return {
        order: examItem?.order,
        tag: examItem.tags?.find(({ category }) => category === 'SUBJECT')?.name,
      };
    }),
    groupBy('tag', summarize({ questions: (items) => items.map((item) => item) }))
  );

  const examQuestionByMilestones = examQuestionByTags.flatMap(({ tag, questions }) => {
    const tempExamByTag = [];
    //RIASEC type is separated to 2 part
    if (tag === LoLExaminationTag.RIASEC) {
      tempExamByTag.push({
        tag: LoLExaminationSection.RIASEC_PART_1,
        questions: questions.slice(0, lastTrialQuestionNo),
      });
      if (questions.length > lastTrialQuestionNo) {
        tempExamByTag.push({
          tag: LoLExaminationSection.RIASEC_PART_2,
          questions: questions.slice(lastTrialQuestionNo),
        });
      }
      return [...tempExamByTag];
    } else if (tag === LoLExaminationTag.ACTIVITY) {
      tempExamByTag.push({
        tag: LoLExaminationSection.ACTIVITY_PART_1,
        questions: questions.slice(0, LAST_QUESTION_NO_OF_FIRST_ACTIVITY),
      });
      if (questions.length > LAST_QUESTION_NO_OF_FIRST_ACTIVITY) {
        tempExamByTag.push({
          tag: LoLExaminationSection.ACTIVITY_PART_2,
          questions: questions.slice(LAST_QUESTION_NO_OF_FIRST_ACTIVITY),
        });
      }
      return [...tempExamByTag];
    }

    return { tag, questions };
  });

  const allTags = examQuestionByMilestones.map(({ tag }) => tag);

  const milestones: MilestoneTags[] = useMemo(() => {
    return DEFAULT_MILESTONES.filter((milestone) => {
      return milestone.tags.some((tag) => allTags.includes(tag));
    }).map((milestone) => {
      const defaultMilestoneTags = milestone.tags;
      const filteredTags = defaultMilestoneTags.filter((tag) => allTags.includes(tag));

      return {
        tags: filteredTags,
      };
    });
  }, [allTags]);

  const handleBack = () => {
    navigate(-1);
  };

  if (examinationDataByExamPaperLoading) return <Loading open />;

  return (
    <>
      <LoLExamRoomAppBar title={t('TITLE')} onBack={handleBack} />
      <Flex flexDirection={'column'} gap={5} p={3} overflow={'auto'}>
        {/* TODO: refactor this to `LoLReportList` and using different defaultList */}
        <CareerList examPaperId={examPaperId} milestones={milestones} />
        <CharacterList examPaperId={examPaperId} milestones={milestones} />
        <CoreTraitList examPaperId={examPaperId} milestones={milestones} />
      </Flex>
    </>
  );
};

export default LoLFullReport;
