import { FormProvider, useForm } from 'react-hook-form';

import { Text, Flex, Box } from '../../../../Components/Base';
import FormAnswerMultipleChoices from '../../../ExaminationRoom/Components/AnswerSheet/FormAnswerMultipleChoices';

const AnswerMultipleChoices = () => {
  const formContext = useForm({ mode: 'onTouched' });

  return (
    <FormProvider {...formContext}>
      <form>
        <Box>
          <Text variant="body2" fontWeight={600}>
            Multiple Choice: Single Choose
          </Text>
          <Flex alignItems={'center'}>
            <FormAnswerMultipleChoices
              name={'answerSingleChoice'}
              answerableCount={1}
              choicesCount={5}
            />
            <Text ml={2}>Select: {formContext.watch()?.answerSingleChoice ?? ''}</Text>
          </Flex>
        </Box>

        <Box mt={2}>
          <Text variant="body2" fontWeight={600}>
            Multiple Choice: Multiple Chooses
          </Text>
          <Flex alignItems={'center'}>
            <FormAnswerMultipleChoices
              name={'answerMultipleChoices'}
              answerableCount={2}
              choicesCount={5}
            />
            <Text ml={2}>Select: {formContext.watch()?.answerMultipleChoices ?? ''}</Text>
          </Flex>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AnswerMultipleChoices;
