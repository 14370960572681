import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  Text,
} from '../Base';

type ChangeDeviceModalProps = {
  learnId: string;
  onBack?: ButtonProps['onClick'];
} & ModalProps;

const ChangeDeviceModal = (props: ChangeDeviceModalProps) => {
  const { learnId = '', onBack, ...restProps } = props;
  const { t } = useTranslation();
  const displayLearnId = learnId ? `Learn ID: ${learnId}` : '';
  return (
    <Modal {...restProps}>
      <ModalContent>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <Text variant={'h2'} whiteSpace={'pre-line'} textAlign={'center'}>
            {t('STATE.CHANGE_DEVICE.TITLE')}
          </Text>
          <Text mt={3} whiteSpace={'pre-line'} textAlign={'center'}>
            {t('STATE.CHANGE_DEVICE.DESCRIPTION', { learnId: displayLearnId })}
          </Text>
        </Flex>
      </ModalContent>
      <ModalFooter px={3} pb={3}>
        <Button size={'large'} fullWidth onClick={onBack}>
          {t('BUTTON.BACK_TO_HOME')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangeDeviceModal;
