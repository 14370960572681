import { useMemo } from 'react';

const WaterWaveAnimation = (props: { percentage: number; fillColor: string }) => {
  const { percentage, fillColor } = props;
  const y = useMemo(() => {
    return -((percentage / 100) * 1000 - 500);
  }, [percentage]);

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      overflow="auto"
      shapeRendering="auto"
      fill="transparent"
    >
      <defs>
        <path
          id="wavepath"
          d="M 0 2000 0 500 Q 140 450 280 500 t 280 0 280 0 280 0 280 0 280 0  v1000 z"
        />
        <path id="motionpath" d="M -560 0 0 0" />
      </defs>
      <g>
        <use
          xlinkHref="#wavepath"
          y={y}
          fill={fillColor}
          style={{
            transition: 'y 0.5s cubic-bezier(0.32, 0, 0.67, 0)',
          }}
        >
          <animateMotion dur="5s" repeatCount="indefinite">
            <mpath xlinkHref="#motionpath" />
          </animateMotion>
        </use>
      </g>
    </svg>
  );
};

export default WaterWaveAnimation;
