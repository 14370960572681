import { useState } from 'react';

import { Tabs, TabsProps, Tab } from '../../../../Components/Base';

const itemLists = [
  {
    label: 'Item one',
    value: 'one',
  },
  {
    label: 'Item two',
    value: 'two',
  },
  {
    label: 'Item three',
    value: 'three',
  },
];

const TabsView = () => {
  const [activeTab, setActiveTab] = useState<string>('one');

  const handleChange: TabsProps['onChange'] = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Tabs onChange={handleChange} value={activeTab}>
      {itemLists?.map((item, index): JSX.Element => {
        return <Tab key={index} value={item.value} label={item.label} iconPosition="start" />;
      })}
    </Tabs>
  );
};

export default TabsView;
