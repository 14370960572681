import { default as ReactSplitter, SplitPaneProps } from 'react-split-pane';
import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';

import { RESIZER_GAP, RESIZER_SIZE } from './constant';

type SplitterProps = {
  children: React.ReactNode;
} & SplitPaneProps;

const Splitter = (props: SplitterProps) => {
  const {
    children,
    split = 'horizontal',
    defaultSize = '50%',
    style,
    resizerStyle,
    paneStyle,
    pane1Style,
    pane2Style,
    ...restProps
  } = props;
  const theme = useTheme();
  const [size, setSize] = useState(defaultSize);

  const isSplitVertical = useMemo(() => split === 'vertical', [split]);

  const defaultSplitPaneStyle: React.CSSProperties = {
    position: 'relative',
  };

  const defaultPaneStyle: React.CSSProperties = {
    position: 'relative',
    overflow: 'auto',
  };

  const defaultPane1Style: React.CSSProperties = useMemo(() => {
    return {
      padding: isSplitVertical ? `0 ${RESIZER_GAP}px 0 0` : '0',
    };
  }, [isSplitVertical]);

  const defaultPane2Style: React.CSSProperties = useMemo(() => {
    return {
      padding: isSplitVertical ? `0 0 0 ${RESIZER_GAP}px` : '0',
    };
  }, [isSplitVertical]);

  const defaultResizerStyle: React.CSSProperties = useMemo(() => {
    return {
      backgroundColor: theme.palette.grey[200],
      cursor: isSplitVertical ? 'col-resize' : 'row-resize',
      width: isSplitVertical ? `${RESIZER_SIZE}px` : 'auto',
      height: isSplitVertical ? 'auto' : `${RESIZER_SIZE}px`,
      margin: isSplitVertical ? '0' : `${RESIZER_GAP}px 0`,
    };
  }, [isSplitVertical, theme]);

  const handleResizeStart = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleResizeEnd = () => {
    document.body.style.removeProperty('overflow');
  };

  const handleTouchMove = (ev: TouchEvent) => {
    ev.preventDefault();
  };

  useEffect(() => {
    const resizer = document.querySelector('.Resizer') as HTMLElement;
    if (resizer) {
      resizer.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    return () => {
      if (resizer) {
        resizer.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [size]);

  useEffect(() => {
    setSize(defaultSize);
  }, [defaultSize, split]);

  useEffect(() => {
    const resizer = document.querySelector('.Resizer') as HTMLElement;
    const verticalSplitLinePath = process.env.PUBLIC_URL + '/assets/split-line-vertical.svg';
    const horizontalSplitLinePath = process.env.PUBLIC_URL + '/assets/split-line-horizontal.svg';
    const splitLinePath = isSplitVertical ? verticalSplitLinePath : horizontalSplitLinePath;

    if (resizer) {
      const img = new Image();
      img.alt = 'split-line';
      img.src = splitLinePath;
      img.style.pointerEvents = 'none';
      img.style.userSelect = 'none';

      resizer.appendChild(img);
      resizer.style.display = 'flex';
      resizer.style.justifyContent = 'center';
      resizer.style.alignItems = 'center';

      return () => {
        resizer.removeChild(img);
      };
    }
  }, [isSplitVertical]);

  return (
    <ReactSplitter
      style={{
        ...defaultSplitPaneStyle,
        ...style,
      }}
      resizerStyle={{
        ...defaultResizerStyle,
        ...resizerStyle,
      }}
      defaultSize={defaultSize}
      split={split}
      paneStyle={{
        ...defaultPaneStyle,
        ...paneStyle,
      }}
      pane1Style={{
        ...defaultPane1Style,
        ...pane1Style,
      }}
      pane2Style={{
        ...defaultPane2Style,
        ...pane2Style,
      }}
      size={size}
      onChange={(size) => {
        setSize(size);
      }}
      onDragStarted={handleResizeStart}
      onDragFinished={handleResizeEnd}
      {...restProps}
    >
      {children}
    </ReactSplitter>
  );
};

export default Splitter;
