import { Divider, Skeleton } from '@mui/material';
import { Fragment } from 'react';

import { Flex, Box, Paper } from '../../../../Components/Base';

const ScoreByLessonLoading = () => {
  return (
    <Paper
      sx={{
        mb: { xs: 1, sm: 2 },
        px: { xs: 2, md: 3 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      <Box py={2}>
        <Skeleton height={28} width={120} />
      </Box>
      <Divider />
      {Array.from({ length: 5 }).map((_, index) => (
        <Fragment key={index}>
          <Divider />
          <Flex flexDirection={'column'} py={2}>
            <Skeleton height={16} width={195} sx={{ my: 0.5 }} />
            <Flex justifyContent={'space-between'} alignItems={'center'} mt={1.5}>
              <Skeleton height={14} width={66} sx={{ my: '3px' }} />
              <Skeleton height={14} width={40} sx={{ my: '3px' }} />
            </Flex>
            <Skeleton height={8} width={'100%'} sx={{ mt: 1 }} />
            <Flex justifyContent={'space-between'} alignItems={'center'} mt={1}>
              <Skeleton height={12} width={10} sx={{ my: '3px' }} />
              <Skeleton height={12} width={70} sx={{ my: '3px' }} />
            </Flex>
          </Flex>
        </Fragment>
      ))}
    </Paper>
  );
};

export default ScoreByLessonLoading;
