import { RadioGroup } from '@mui/material';
import { useController } from 'react-hook-form';

import { Box } from '../../../../../Components/Base';
import RadioSelection from '../../../../../Components/Form/RadioSelection';

import { FieldValues, FormAnswerYesNoChoicesProps } from './type';

const FormAnswerYesNoChoices = <TFieldValues extends FieldValues = FieldValues>(
  props: FormAnswerYesNoChoicesProps<TFieldValues>
): JSX.Element => {
  const { choices = [], ...restProps } = props;
  const { field } = useController({
    ...restProps,
    name: restProps.name,
  });

  const handleRadioChange = (event: React.ChangeEvent<EventTarget>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === 'true') {
      field.onChange(true);
    } else if (value === 'false') {
      field.onChange(false);
    } else {
      field.onChange(null);
    }
  };

  return (
    <RadioGroup row {...field} onChange={handleRadioChange}>
      {choices.map((choice, index) => {
        const { label, value } = choice;
        const groupValue = field.value;
        return (
          <Box key={label} ml={index === 0 ? 0 : 3}>
            <RadioSelection
              checked={groupValue === value}
              label={label}
              value={value}
              groupValue={groupValue}
            />
          </Box>
        );
      })}
    </RadioGroup>
  );
};

export default FormAnswerYesNoChoices;
