import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, ButtonProps } from '../../../../Components/Base';
import ArrowForwardIcon from '../../../../Components/Icons/ArrowForwardIcon';
import Loading from '../../../../Components/Loading';
import useStartExamination from '../../../../Domains/useStartExamination';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

type StartExaminationButtonProps = ButtonProps;

const StartExaminationButton = (props: StartExaminationButtonProps) => {
  const { examCardId = '' } = useParams();
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  const sendDataToGTM = useGoogleTagManager();

  const [startExamination, { loading: startExaminationLoading }] = useStartExamination();

  const handleEnterExamRoom = async () => {
    sendDataToGTM({
      event: 'examination_instruction_cta_click',
      buttonCategory: 'start examination',
    });

    try {
      await startExamination({
        variables: { examCardId, isSetTimer: getValues('isShowTimer') },
      });
      navigate(`${location.pathname}/examroom`, { replace: true });
    } catch (error) {
      console.error('Start examination error: ', error);
    }
  };

  return (
    <>
      <Button
        sx={{ maxWidth: { xs: 1, sm: '343px' }, width: 1 }}
        size={'large'}
        onClick={handleEnterExamRoom}
        disabled={startExaminationLoading}
        {...props}
      >
        เริ่มทำข้อสอบ
        <ArrowForwardIcon sx={{ ml: 0.5 }} />
      </Button>
      <Loading open={startExaminationLoading} />
    </>
  );
};

export default StartExaminationButton;
