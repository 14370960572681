import { Divider } from '@mui/material';
import { useLocation, useNavigate, useParams, Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import { MYBOOKS_RESULTS } from '../../Routes/constant';
import { Tabs, Tab, Paper, TabsProps } from '../../Components/Base';
import PageTitleLayout from '../PageTitleLayout';
import useGetExaminationResultById from '../../Domains/useGetExaminationResultById';
import DataLoadingError from '../../Components/DataLoadingError';
import { RESPONSE_TYPE } from '../../Domains/constants';
import useGoogleTagManager from '../../Utils/useGoogleTagManager';

import ExaminationResultDetail from './ExaminationResultDetail/ExaminationResultDetail';

const itemLists = [
  {
    label: 'คะแนนรายบท',
    value: 'overview',
  },
  {
    label: 'เฉลยละเอียด',
    value: 'solutions',
  },
];

type LocationState = { prevPath: string };

const ExaminationResultLayout = ({ children }: { children?: React.ReactChild }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { bookId, examPaperId = '' } = useParams();
  const myBookResultPath = MYBOOKS_RESULTS.replace(':bookId', bookId ?? '');
  const previousPathname = (location.state as LocationState)?.prevPath ?? myBookResultPath;
  const sendDataToGTM = useGoogleTagManager();

  const pathname = location.pathname.slice(
    location.pathname.lastIndexOf('/') + 1,
    location.pathname.length
  );
  const activeTab = itemLists.map(({ value }) => value).includes(pathname) ? pathname : 'overview';

  const handleChange: TabsProps['onChange'] = (
    event: React.SyntheticEvent<Element, Event>,
    tabValue: string
  ) => {
    navigate(`./${tabValue}`, { replace: true, state: { prevPath: previousPathname } });
  };

  const { error, refetch } = useGetExaminationResultById({
    variables: { examPaperId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (activeTab) {
      sendDataToGTM({ event: `exam_result_${activeTab}_tab` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  if (error?.message === RESPONSE_TYPE.NETWORK_ERROR) {
    return <DataLoadingError mt={16} onRetry={() => refetch()} />;
  }

  return (
    <PageTitleLayout title={'ผลสอบ'} onBack={() => navigate(previousPathname, { replace: true })}>
      <Paper
        sx={{
          px: { xs: 2, sm: 3 },
          borderRadius: { xs: 0, sm: 1 },
          mb: 3,
        }}
      >
        <ExaminationResultDetail />
        <Divider />
        <Tabs onChange={handleChange} value={activeTab} variant="fullWidth">
          {itemLists?.map((item, index): JSX.Element => {
            return (
              <Tab
                key={index}
                value={item.value}
                label={item.label}
                sx={{ width: `${100 / itemLists.length}%` }}
              />
            );
          })}
        </Tabs>
      </Paper>
      {children ?? <Outlet />}
    </PageTitleLayout>
  );
};

export default ExaminationResultLayout;
