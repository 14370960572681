import { Flex, Text } from '../../Components/Base';

const NotFound = () => {
  return (
    <Flex justifyContent={'center'} alignItems={'center'} flex={1} height={'100%'}>
      <Text>
        <Text component={'span'} fontWeight={600}>
          {`404 |`}
        </Text>
        {` Page not found`}
      </Text>
    </Flex>
  );
};

export default NotFound;
