import { Divider, styled } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import { Text } from '../../../Components/Base';
import NotFoundError from '../../../Components/NotFoundError';
import ExaminationResultScoreCard from '../../../Components/ExaminationResultScoreCard';
import { RESPONSE_TYPE } from '../../../Domains/constants';
import useGetExaminationResultById from '../../../Domains/useGetExaminationResultById';
import { parseDate } from '../../../Utils/DateUtils';
import { formatScore } from '../../../Domains/useGetExaminationResultByLesson';
import { formatFullScore } from '../../../Domains/useGetExaminationResultByLesson/utils';
import { setPageTitle } from '../../../Utils/page';
import { QbQuestionType } from '../../../generated/graphql';

import ExaminationResultDetailLoading from './ExaminationResultDetailLoading';

const TitleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExaminationResultDetail = () => {
  const navigate = useNavigate();
  const { examPaperId = '' } = useParams();
  const { data, loading, error } = useGetExaminationResultById({
    variables: { examPaperId },
  });
  const examPaperData = data?.qmsFoGetExamPaperById;
  const {
    iteration,
    score = 0,
    fullScore = 0,
    createdAt,
    examSubjects,
    exam,
  } = examPaperData || {};
  const { examTitle } = examSubjects || {};

  if (loading) return <ExaminationResultDetailLoading />;

  const backToHome = () => {
    navigate('/');
  };

  if (error) {
    const errorExamination = error?.graphQLErrors[0].extensions?.exception?.httpStatus;
    if (errorExamination === RESPONSE_TYPE.NOT_FOUND) {
      return <NotFoundError open={true} onBackToHome={backToHome} />;
    }
  }

  //Remark: Workaroud for display score by 4 decimal points
  const connectionAnswerTypqQuestionIndex =
    exam?.examItems?.findIndex(
      (item) => item?.question?.type === QbQuestionType.MultipleSubQuestionsConnectionAnswers
    ) ?? -1;

  const hasConnectionAnswer = connectionAnswerTypqQuestionIndex > -1;

  return (
    <>
      <Helmet>
        <title>{setPageTitle(`ผลสอบ | ${examTitle} `)}</title>
      </Helmet>
      <TitleText py={2} variant="h4">
        {examTitle}
      </TitleText>
      <Divider />
      <ExaminationResultScoreCard
        iteration={iteration?.toString() ?? 0}
        score={formatScore(score, fullScore, hasConnectionAnswer ? 4 : 2)}
        fullScore={formatFullScore(fullScore)}
        startedExamAt={parseDate(createdAt)}
        py={2}
      />
    </>
  );
};

export default ExaminationResultDetail;
