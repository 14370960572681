import i18next, { changeLanguage } from 'i18next';

import { LANGUAGES } from './constants';

export const handleChangeLanguage = (targetLanguage: string) => {
  const uppercaseLanguage = targetLanguage.toUpperCase();
  const targetLanguageFromEnum =
    LANGUAGES[uppercaseLanguage as keyof typeof LANGUAGES] ?? LANGUAGES.TH;

  localStorage.setItem('i18nextLng', targetLanguageFromEnum);
  changeLanguage(targetLanguageFromEnum);
};

export const getShortCurrentLanguage = () => {
  return i18next.language.split('-')[0] || 'th';
};
