import { groupBy, GroupKey, summarize, tidy } from '@tidyjs/tidy';

import { QuestionFieldType } from '../../Domains/useGetExaminationDetails';
import { renderAnswerTypeMessage } from '../../Containers/ExaminationRoom/utils';
import { QmsExamItem } from '../../generated/graphql';

type QuestionWithType = Array<{
  order: number;
  metadata: any;
  type: QuestionFieldType;
  subType: string | null | undefined;
}>;

export const formatQuestionGroup = (questions: QuestionWithType, groupKey: GroupKey) => {
  return tidy(questions, groupBy(groupKey, summarize({ questions: (items) => items })));
};

export const getAllAnswerTypeWord = (examItems: QmsExamItem[]) => {
  const sortedExamItems = [...examItems].sort((current, next) => current.order - next.order);
  const questions = sortedExamItems.map((examItem) => ({
    order: examItem.order,
    metadata: examItem.question?.metadata,
    type: examItem.question?.type ?? QuestionFieldType.MultipleChoice,
    subType: examItem.question?.subType,
  }));

  const answerTypes: Array<string> = [];
  const questionBySubType = formatQuestionGroup(questions, ['type', 'subType']);

  questionBySubType.map((question) => {
    const { type, subType } = question;

    const typeMessage = renderAnswerTypeMessage(type, subType);
    if (typeMessage) {
      answerTypes.push(typeMessage);
    }
  });
  return answerTypes;
};
