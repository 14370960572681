import { Snackbar, Alert, AlertTitle, Text, Button } from '../../../../Components/Base';
import TimerOutlinedIcon from '../../../../Components/Icons/TimerOutlinedIcon';
import useDisclosure from '../../../../Utils/useDisclosure';

const SnackbarView = () => {
  const { isOpen, open, close } = useDisclosure();

  return (
    <>
      <Button onClick={open}>Open Snackbar</Button>
      <Snackbar open={isOpen} onClose={close}>
        <Alert color="info" icon={<TimerOutlinedIcon />}>
          <AlertTitle>เหลือเวลาอีก 30 นาที</AlertTitle>
          <Text variant={'body2'} color={'text.secondary'}>
            ในการทำข้อสอบ เร่งมือหน่อยนะ
          </Text>
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarView;
