export type QuestionDetailType = {
  examTitle: string;
  questionNo: number;
  partTitle?: string;
  section: string;
  correctAnswer: string | Array<string>;
  answer?: string;
  isCorrect?: boolean;
  difficulty?: string;
  variant?: 'EXAMINATION_SOLUTION' | 'EXAMINATION_RESULT';
  score?: number;
  type?: string;
};

export type ResultByQuestionItemType = {
  answer?: string;
  isCorrect?: boolean;
  questionNo: number;
  score?: number;
  type: QuestionType;
};

export enum QuestionType {
  FillInTheBlank = 'FILL_IN_THE_BLANK',
  FillNumber = 'FILL_NUMBER',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleChoiceDifferentScore = 'MULTIPLE_CHOICE_DIFFERENT_SCORE',
  MultipleSelectWithCorrectAnswers = 'MULTIPLE_SELECT_WITH_CORRECT_ANSWERS',
  MultipleSelect = 'MULTIPLE_SELECT',
  MultuipleSubQuestionsConnectionAnswers = 'MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS',
  MultipleSubQuestionsYesNoAnswer = 'MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER',
}

export enum Difficulty {
  Easy = 'ง่าย',
  Medium = 'ปานกลาง',
  Hard = 'ยาก',
}
