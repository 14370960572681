import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '../../../Components/Base';
import AcheivementCard, { AcheivementCardLoading } from '../Components/AcheivementCard';
import { characterList } from '../constants';
import {
  QmslolExamResult,
  useGetCharacterReportByExamPaperIdQuery as useGetCharacterReportByExamPaperId,
} from '../../../generated/graphql';
import useDisclosure from '../../../Utils/useDisclosure';
import Loading from '../../../Components/Loading';
import { MilestoneTags } from '../types';
import { LoLReportModal } from '../../../Containers/LoLResultModal';

type CharacterListProps = {
  examPaperId?: string;
  milestones?: MilestoneTags[];
};

const CharacterList = (props: CharacterListProps) => {
  const { examPaperId = '', milestones = [] } = props;
  const { isOpen, open, close } = useDisclosure();
  const { t } = useTranslation('lolResults');
  const [currentCharacter, setCurrentCharacter] = useState<QmslolExamResult>();
  const [currentResultGeneralData, setCurrentResultGeneralData] = useState<{
    title?: string;
    conditionNumber: number;
  }>();

  const { data: characterData, loading } = useGetCharacterReportByExamPaperId({
    variables: {
      examPaperId,
    },
    fetchPolicy: 'network-only',
  });

  const characters = characterData?.qmsGetLOLExamReport.characters;

  const handleCardClick = (
    conditionNumber: number,
    title?: string,
    character?: QmslolExamResult
  ) => {
    setCurrentCharacter(character ?? undefined);
    setCurrentResultGeneralData({ title, conditionNumber });
    open();
  };

  const handleCloseModal = () => {
    close();
  };

  const milestoneTags = milestones.flatMap((milestone) => {
    return milestone.tags;
  });

  const filterCharacterList = characterList.filter((character) => {
    const tags = character?.tags ?? [];
    const hasTagsInMilestoneTags = tags.every((tag) => milestoneTags.includes(tag));

    return hasTagsInMilestoneTags;
  });

  const hasCharacterList = filterCharacterList.length > 0;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {hasCharacterList && (
        <Flex flexDirection={'column'} gap={2}>
          <Box>
            <Text
              variant="h2"
              fontWeight={500}
              mr={1}
              display={'inline-block'}
              color={'lolText.primary'}
            >
              {t('CHARACTER')}
            </Text>
            <Text
              variant="h3"
              fontWeight={400}
              lineHeight={1.4}
              color={'lolText.secondary'}
              display={'inline-block'}
            >
              {` (${characters?.length ?? 0}/${filterCharacterList.length})`}
            </Text>
          </Box>
          {filterCharacterList?.map((character) => {
            const characterResult = characters?.find(
              (characterResult) => characterResult.type === character.type
            );
            const isLocked = characterResult === undefined;
            const tags = character?.tags ?? [];
            const lastTag = tags[tags.length - 1];

            const indexOfLastTagInMilestones = milestones.findIndex((milestone) =>
              milestone.tags.includes(lastTag)
            );

            const conditionNumber =
              indexOfLastTagInMilestones !== -1
                ? indexOfLastTagInMilestones + 1
                : character?.conditionNumber;

            const characterTitle = t('CHARACTER');

            if (loading) {
              return <AcheivementCardLoading key={character.type} />;
            }

            return (
              <AcheivementCard
                key={character.type}
                title={characterTitle}
                conditionNumber={conditionNumber}
                resultType={character.type}
                locked={isLocked}
                onClick={() => handleCardClick(conditionNumber, characterTitle, characterResult)}
              />
            );
          })}
        </Flex>
      )}
      <LoLReportModal
        isOpen={isOpen}
        handleClose={handleCloseModal}
        displayData={currentCharacter}
        title={currentResultGeneralData?.title}
        conditionNumber={currentResultGeneralData?.conditionNumber}
      />
    </>
  );
};

export default CharacterList;
