import { Fragment } from 'react';
import { Divider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { QbQuestionType } from '../../../Domains/useGetSolutionById';
import SolutionListItem from '../Components/SolutionListItem';

//declare this type because there're order added from examItems
type SolutionQuestionType = Array<{
  __typename?: 'QMSQuestion';
  id: string;
  correctAnswer?: any | null;
  examItemId: string;
  order: number;
  type: QbQuestionType;
  subType?: string | null;
}>;

const SolutionQuestionList = ({
  questions,
  onTracking,
}: {
  questions: SolutionQuestionType;
  onTracking?: () => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleGoToSolutionDetails = (examItemId: string) => {
    if (typeof onTracking === 'function') {
      onTracking();
    }
    navigate(`${location.pathname}/question/${examItemId}`);
  };

  return (
    <Fragment>
      {questions.map((question) => {
        const { id, examItemId, order, correctAnswer, type } = question;
        return (
          <Fragment key={id}>
            <Divider sx={{ mx: { xs: 2, md: 3 } }} />
            <SolutionListItem
              examItemId={examItemId}
              order={order}
              type={type}
              correctAnswer={correctAnswer}
              handleGoToSolutionDetails={handleGoToSolutionDetails}
            />
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default SolutionQuestionList;
