import { styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  IconButton as BaseIconButton,
  Image,
  Modal,
  ModalContent as BaseModalContent,
} from '../../../../Components/Base';
import CloseRoundedIcon from '../../../../Components/Icons/CloseRoundedIcon';
import useDisclosure from '../../../../Utils/useDisclosure';
import VideoPlayer from '../../../../Components/VideoPlayer';
import useGetExaminationResultSolutionDetail from '../../../../Domains/useGetExaminationResultSolutionDetail';
import HTMLView from '../../../../Components/HTMLView';
import replaceImgTagWithIframe from '../../../../Utils/replaceImgTagWithIframe';
import { useConfig } from '../../../../Contexts/ConfigProvider';

import SolutionDetailsLoading from './SolutionDetailsLoading';

const VideoWrapaper = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingBottom: '56.25%',
});

const PreviewModal = styled(Modal)({
  '.MuiPaper-root': {
    position: 'relative',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
});

const ModalContent = styled(BaseModalContent)({
  background: 'white',
  padding: 0,
  borderRadius: 8,
  overflowY: 'auto',
});

const IconButton = styled(BaseIconButton)(({ theme }) => ({
  color: theme.palette.grey[300],

  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

const SolutionDetails = () => {
  const { examPaperItemId } = useParams();
  const htmlRef = useRef<HTMLElement>(null);
  const { isOpen, open, close } = useDisclosure();
  const [currentImageSrc, setCurrentImageSrc] = useState<string>('');
  const {
    solutions: { enableGoogleDriveLinkFix },
  } = useConfig();

  // The following line is a mockup examPaperItemId from the backend. It is used for testing purpose.
  // const examPaperItemId = '4a284de0-40f9-4883-aae5-a726bdaa4533';

  const { data, loading, error } = useGetExaminationResultSolutionDetail({
    variables: { examPaperItemId: examPaperItemId ?? '' },
  });

  useEffect(() => {
    if (data) {
      const imageTags: HTMLCollectionOf<HTMLImageElement> | undefined =
        htmlRef?.current?.getElementsByTagName('img');
      if (imageTags) {
        Array.from(imageTags).forEach((image) => {
          image.style.width = '100%';
          image.style.objectFit = 'contain';
          image.style.cursor = 'pointer';
          image.addEventListener('click', (event) => {
            open();
            setCurrentImageSrc((event.target as HTMLImageElement).currentSrc ?? '');
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const solutionDetail = data?.qmsGetExamPaperItemById.question?.solution ?? [];

  if (loading) return <SolutionDetailsLoading />;
  if (error) return <div>Error</div>;

  return (
    <Box maxWidth={'100%'} width={'100%'} mx={'auto'}>
      {solutionDetail?.map((solution, index) => {
        const { type, content: rawContent } = solution;
        if (type === 'HTML') {
          const content = enableGoogleDriveLinkFix
            ? replaceImgTagWithIframe(rawContent)
            : rawContent;
          return (
            <Box key={index}>
              <HTMLView rawContent={content} ref={htmlRef} />
              <PreviewModal open={isOpen}>
                <Flex width={1} justifyContent={'flex-end'}>
                  <IconButton color={'inherit'} onClick={close} sx={{ background: 'transparent' }}>
                    <CloseRoundedIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Flex>
                <ModalContent>
                  <Image width={'100%'} src={currentImageSrc} />
                </ModalContent>
              </PreviewModal>
            </Box>
          );
        }
        return (
          <Box key={index}>
            {type === 'YOUTUBE' && (
              <VideoWrapaper width={'100%'}>
                <VideoPlayer
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                  width={'100%'}
                  height={'100%'}
                  videoUrl={rawContent}
                  controls
                />
              </VideoWrapaper>
            )}
            {index !== 0 && <Box mt={1.5} />}
          </Box>
        );
      })}
    </Box>
  );
};

export default SolutionDetails;
