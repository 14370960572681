import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Portal from '@mui/material/Portal';

import {
  FormAnswerQuestionType,
  getSubmitAnswers,
  useExamination,
} from '../../../Contexts/ExaminationProvider';
import { ModalProps } from '../../../Components/Base';
import { UpdateExamPaperType } from '../../../Domains/useUpdateExaminationPaper/useUpdateExaminationPaper';
import TimeUpModal from '../../../Components/TimeUpModal';
import useExamRoomSubmitExamPaper from '../../../Domains/useExamRoomSubmitExamPaper';
import useUpdateExaminationPaper from '../../../Domains/useUpdateExaminationPaper';
import { AnimationFrameStatus } from '../../../Utils/useAnimationFrame';
import useDisclosure from '../../../Utils/useDisclosure';
import SubmitExaminationSuccessModal from '../../../Components/SubmitExaminationSuccessModal';
import SubmitExamErrorSnackbar from '../../../Routes/ExaminationReview/Components/SubmitExamErrorSnackbar';
import { QmsExamRoomExamPaperItemInput } from '../../../generated/graphql';

const ExamTimeUp = (props: ModalProps) => {
  const { bookId = '' } = useParams();
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  const { setAnimationFrameStatus } = useExamination();

  const {
    isOpen: isSubmitSuccessModalOpen,
    open: openSubmitSuccessModal,
    close: closeSubmitSuccessModal,
  } = useDisclosure();
  const { isOpen: isSnackbarOpen, open: openSnackbar, close: closeSnackbar } = useDisclosure();
  const [updateExaminationPaper, { loading: updateLoading }] = useUpdateExaminationPaper({
    onError: (error) => {
      openSnackbar();
    },
  });
  const [submitExamination, { loading: submitLoading }] = useExamRoomSubmitExamPaper({
    onError: (error) => {
      openSnackbar();
    },
  });

  const handleUpdateAnswer = async () => {
    const formatAnswer: QmsExamRoomExamPaperItemInput[] = getValues('answers')?.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ order, ...answer }: { order: number }) => answer
    );

    const { data: updatedData } = await updateExaminationPaper({
      variables: { examRoomExamPaperItems: formatAnswer },
    });

    if (!updateLoading && updatedData) {
      const answers = updatedData.qmsExamRoomUpdateExamPaper.examRoomExamPaperItems;
      handleSubmitExamPaper(answers);
    }
  };

  const handleSubmitExamPaper = async (
    answers: UpdateExamPaperType['qmsExamRoomUpdateExamPaper']['examRoomExamPaperItems']
  ) => {
    const submitAnswers = getSubmitAnswers(answers as FormAnswerQuestionType[]);

    const { data: submitExamData } = await submitExamination({
      variables: { submitExamInput: { rawAnswers: submitAnswers } },
    });

    if (!submitLoading && submitExamData) {
      setAnimationFrameStatus(AnimationFrameStatus.STOP);
      openSubmitSuccessModal();
    }
  };

  const goToExaminationResult = () => {
    closeSubmitSuccessModal();
    navigate(`/mybooks/${bookId}/results`, { replace: true });
  };

  const goToExamination = () => {
    closeSubmitSuccessModal();
    navigate(`/mybooks/${bookId}/examinations`, { replace: true });
  };

  return (
    <>
      <TimeUpModal onConfirm={handleUpdateAnswer} {...props} />
      <SubmitExaminationSuccessModal
        open={isSubmitSuccessModalOpen}
        onSeeExaminationSolution={goToExaminationResult}
        onClose={goToExamination}
      />
      <Portal>
        <SubmitExamErrorSnackbar
          open={isSnackbarOpen}
          onClose={closeSnackbar}
          autoHideDuration={5000}
        />
      </Portal>
    </>
  );
};

export default ExamTimeUp;
