import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '../../../Components/Base';
import AcheivementCard, { AcheivementCardLoading } from '../Components/AcheivementCard';
import { coreTraitList } from '../constants';
import {
  QmslolExamResult,
  useGetPreferenceReportByExamPaperIdQuery as useGetPreferenceReportByExamPaperId,
} from '../../../generated/graphql';
import useDisclosure from '../../../Utils/useDisclosure';
import { MilestoneTags } from '../types';
import { LoLReportModal } from '../../../Containers/LoLResultModal';

type CoreTraitListProps = {
  examPaperId?: string;
  milestones?: MilestoneTags[];
};

const CoreTraitList = (props: CoreTraitListProps) => {
  const { examPaperId = '', milestones = [] } = props;
  const { isOpen, open, close } = useDisclosure();
  const { t } = useTranslation('lolResults');
  const [currentCoreTrait, setCurrentCoreTrait] = useState<QmslolExamResult>();
  const [currentResultGeneralData, setCurrentResultGeneralData] = useState<{
    title?: string;
    conditionNumber: number;
  }>();

  const { data: preferenceData, loading } = useGetPreferenceReportByExamPaperId({
    variables: {
      examPaperId,
    },
    fetchPolicy: 'network-only',
  });

  const preferences = preferenceData?.qmsGetLOLExamReport.preferences ?? [];

  const handleCardClick = (
    conditionNumber: number,
    title?: string,
    coretrait?: QmslolExamResult
  ) => {
    setCurrentCoreTrait(coretrait ?? undefined);
    setCurrentResultGeneralData({ title, conditionNumber });
    open();
  };

  const handleCloseModal = () => {
    close();
  };

  const milestoneTags = milestones.flatMap((milestone) => {
    return milestone.tags;
  });

  const filterCoreTraitList = coreTraitList.filter((coreTrait) => {
    const tags = coreTrait?.tags ?? [];
    const hasTagsInMilestoneTags = tags.every((tag) => milestoneTags.includes(tag));

    return hasTagsInMilestoneTags;
  });

  const hasCoreTraitList = filterCoreTraitList.length > 0;

  return (
    <>
      {hasCoreTraitList && (
        <Flex flexDirection={'column'} gap={2}>
          <Box>
            <Text
              variant="h2"
              fontWeight={500}
              mr={1}
              display={'inline-block'}
              color={'lolText.primary'}
            >
              {t('CORE_TRAIT.TITLE')}
            </Text>
            <Text
              variant="h3"
              fontWeight={400}
              lineHeight={1.4}
              color={'lolText.secondary'}
              display={'inline-block'}
            >
              {` (${preferences?.length ?? 0}/${filterCoreTraitList.length})`}
            </Text>
          </Box>
          <Flex flexDirection={'column'} gap={2} mb={3}>
            {filterCoreTraitList.map((coreTrait, index) => {
              const defaultTitle = `${t('CORE_TRAIT.LIST')} ${index + 1}`;
              const coreTraitResult = preferences?.find(
                (preferenceResult) => preferenceResult.type === coreTrait.type
              );
              const title = coreTraitResult?.title ?? defaultTitle;
              const isLocked = coreTraitResult === undefined;

              const tags = coreTrait?.tags ?? [];
              const lastTag = tags[tags.length - 1];

              const indexOfLastTagInMilestones = milestones.findIndex((milestone) =>
                milestone.tags.includes(lastTag)
              );

              const conditionNumber =
                indexOfLastTagInMilestones !== -1
                  ? indexOfLastTagInMilestones + 1
                  : coreTrait?.conditionNumber;

              const refCode = coreTraitResult?.refCode ?? '';

              if (loading) {
                return <AcheivementCardLoading key={`loading-${coreTrait.type}-${index}`} />;
              }

              return (
                <AcheivementCard
                  key={`${coreTrait.type}-${index}`}
                  title={title}
                  conditionNumber={conditionNumber}
                  resultType={coreTrait.type}
                  locked={isLocked}
                  refCode={refCode}
                  onClick={() => handleCardClick(conditionNumber, title, coreTraitResult)}
                />
              );
            })}
          </Flex>
        </Flex>
      )}
      <LoLReportModal
        isOpen={isOpen}
        handleClose={handleCloseModal}
        displayData={currentCoreTrait}
        title={currentResultGeneralData?.title}
        conditionNumber={currentResultGeneralData?.conditionNumber}
      />
    </>
  );
};

export default CoreTraitList;
