import { useEffect } from 'react';

import TimerLabel, { ExaminationTimerProps } from '../../Components/TimerLabel/TimerLabel';
import TimerSnackbar from '../../Components/TimerSnackbar/TimerSnackbar';
import { getDisplayTime, TIMER_STATUS } from '../../Utils/useTimer';
import useDisclosure from '../../Utils/useDisclosure';
import ExamTimeUp from '../ExaminationRoom/ExamTimeUp';
import { useExaminationTimer } from '../../Contexts/ExaminationTimerProvider/ExaminationTimerProvider';

const getVariant = (time: number): ExaminationTimerProps['severity'] => {
  if (time <= 300) return 'warningFive';
  else if (time <= 900) return 'warningFifteen';
  else if (time <= 1800) return 'warningThirty';
  else return 'noWarning';
};

const Timer = () => {
  const { remainingTime = 0, timerStatus } = useExaminationTimer();

  const variant: ExaminationTimerProps['severity'] = getVariant(remainingTime);
  const hasWarning =
    variant === 'warningThirty' || variant === 'warningFifteen' || variant === 'warningFive';

  const { isOpen: isSnackbarOpen, open: openSnackbar, close: closeSnackbar } = useDisclosure();

  useEffect(() => {
    // open snackbar on 30mins, 15mins, 5mins
    if (remainingTime === 1800 || remainingTime === 900 || remainingTime === 300) openSnackbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  return (
    <>
      <TimerLabel timer={getDisplayTime(remainingTime)} severity={variant} />
      <ExamTimeUp open={timerStatus === TIMER_STATUS.TIMEOUT} />
      {hasWarning && (
        <TimerSnackbar
          variant={variant}
          open={isSnackbarOpen}
          onClose={closeSnackbar}
          autoHideDuration={5000}
        />
      )}
    </>
  );
};

export default Timer;
