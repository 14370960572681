import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, FlexProps, Image, Skeleton } from '../../Components/Base';
import { useConfig } from '../../Contexts/ConfigProvider';
import { useGetQmsMyBookMetadataQuery as useGetMybookMetadata } from '../../generated/graphql';

type LoLSponsorsProps = FlexProps;

const LoLSponsors = (props: LoLSponsorsProps) => {
  const { lol } = useConfig();
  const { logosByProvider, imgPrefixPath } = lol;

  const { bookId = '' } = useParams();

  const { data, loading } = useGetMybookMetadata({ variables: { bookId }, skip: !bookId });
  const provider = data?.qmsGetBookDetailsByBookId?.metadata.provider ?? 'DEFAULT';

  const logoKeys = useMemo(() => {
    let logoKeys: string[] = logosByProvider['DEFAULT'];
    if (logosByProvider.hasOwnProperty(provider)) {
      logoKeys = logosByProvider[provider];
    }
    return logoKeys;
  }, [logosByProvider, provider]);

  const logos = useMemo(() => {
    return [
      ...logoKeys.map((key) => {
        if (lol.hasOwnProperty(key)) {
          return lol[key];
        }
      }),
    ];
  }, [logoKeys, lol]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Flex alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'} gap={0.5} {...props}>
      {logos.map((src, index) => (
        <Image
          src={`${imgPrefixPath}/${src}`}
          alt={`sponsors-of-${provider}-logo`}
          key={`sponsors-of-${provider}-logo-${index}`}
          sx={{
            maxHeight: '40px',
            maxWidth: '74px',
            objectFit: 'contain',
            width: 'fit-content',
            height: 'auto',
          }}
        />
      ))}
    </Flex>
  );
};

export default LoLSponsors;
