import { Link } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text } from '../../Components/Base';
import FormTextInput from '../../Components/Form/FormTextInput';

const PhoneNumberForm = () => {
  const { control, formState } = useFormContext();
  const { isSubmitting } = formState;
  const { t } = useTranslation('lolSignIn');
  return (
    <>
      <Text variant="h2">{t('OTP.PHONE_NUMBER.CONFIRM')}</Text>
      <Text variant="body2" mb={4} mt={1}>
        {t('OTP.PHONE_NUMBER.INFO')}
      </Text>
      <Controller
        control={control}
        name={'phoneNumber'}
        render={({ field }) => {
          return (
            <FormTextInput
              {...field}
              disabled={isSubmitting}
              placeholder={t('OTP.PHONE_NUMBER.PLACEHOLDER')}
              type="tel"
              inputProps={{ maxLength: 10, inputMode: 'numeric' }}
            />
          );
        }}
        rules={{
          required: { value: true, message: t('OTP.PHONE_NUMBER.PLEASE_ENTER_CORRECT') },
          minLength: { value: 10, message: t('OTP.PHONE_NUMBER.PLEASE_ENTER_CORRECT') },
          maxLength: { value: 10, message: t('OTP.PHONE_NUMBER.PLEASE_ENTER_CORRECT') },
          pattern: {
            value: /^[0-9]+$/,
            message: t('OTP.PHONE_NUMBER.PLEASE_ENTER_CORRECT'),
          },
        }}
      />
      <Text variant="body2" mt={4} color={'lolText.secondary'}>
        {t('OTP.ALREADY_HAVE_ACCOUNT')}
        <Link href={window.location.pathname} sx={{ ml: '8px', color: 'lolText.primary' }}>
          <strong>{t('OTP.LOGIN')}</strong>
        </Link>
      </Text>
    </>
  );
};

export default PhoneNumberForm;
