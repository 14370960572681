import styled from 'styled-components';
import { List, ListItem as Item, ListItemIcon, ListItemText } from '@mui/material';

import { Paper, Text, Skeleton, Flex } from '../../../../Components/Base';

const ListItem = styled(Item)`
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 8px;
`;

const ListContent = styled(ListItemText)`
  margin: 0;
`;

const ListIcon = () => (
  <ListItemIcon sx={{ minWidth: 0, mt: 0, mr: 1 }}>
    <Skeleton variant="circular" width={20} height={20} />
  </ListItemIcon>
);

const InstructionDetailsLoading = () => {
  return (
    <Paper
      sx={{
        mb: { xs: 1, sm: 2 },
        p: { xs: 2, md: 3 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      <Text variant={'subtitle1'} mb={2}>
        คำชี้แจงการทำข้อสอบ
      </Text>
      <List sx={{ py: 0 }}>
        <ListItem>
          <ListIcon />
          <ListContent>
            <Flex flexDirection={'column'}>
              <Skeleton width={180} height={20} />
              <Skeleton width={300} height={20} sx={{ my: 1 }} />
              <Skeleton width={190} height={20} />
            </Flex>
          </ListContent>
        </ListItem>
        <ListItem>
          <ListIcon />
          <ListContent>
            <Flex>
              <Skeleton width={760} height={20} />
            </Flex>
          </ListContent>
        </ListItem>
        <ListItem>
          <ListIcon />
          <ListContent>
            <Flex>
              <Skeleton width={300} height={20} />
            </Flex>
          </ListContent>
        </ListItem>
        <ListItem>
          <ListIcon />
          <ListContent>
            <Flex>
              <Skeleton width={930} height={20} />
            </Flex>
          </ListContent>
        </ListItem>
      </List>
    </Paper>
  );
};

export default InstructionDetailsLoading;
