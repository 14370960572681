import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';

import { Box, Text } from '../../../../../Components/Base';
import CheckboxSelection from '../../../../../Components/Form/CheckboxSelection/CheckboxSelection';
import { alphabet, replaceChar } from '../utils';

import { FormSubQuestionConnectionAnswerProps, FieldValues, ChoiceType } from './type';

const FormSubQuestionConnectionAnswer = <TFieldValues extends FieldValues = FieldValues>(
  props: FormSubQuestionConnectionAnswerProps<TFieldValues>
): JSX.Element => {
  const {
    choicesCount = 4,
    choiceType = ChoiceType.NUMBER,
    sequenceNo,
    onChange,
    ...restProps
  } = props;
  const { field } = useController({
    ...restProps,
    name: restProps.name,
  });

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (e.target as HTMLInputElement).value as string;

    const currentValue: string = field.value ?? '–––';
    let replacedValue = '';

    if (newValue === currentValue[sequenceNo]) {
      replacedValue = replaceChar(currentValue, sequenceNo, '–');
    } else {
      replacedValue = replaceChar(currentValue, sequenceNo, newValue);
    }

    if (replacedValue === '–––') {
      field.onChange(null);
      onChange?.('–––');
    } else {
      field.onChange(replacedValue);
      onChange?.(replacedValue);
    }
  };

  const getChoiceGroupByType = (type: ChoiceType) => {
    switch (type) {
      case ChoiceType.ENG_ALPHABET:
        return alphabet.slice(0, choicesCount);
      case ChoiceType.DIGIT:
        return Array.from({ length: choicesCount }, (_, choiceNo) => choiceNo);
      default:
      case ChoiceType.NUMBER:
        return Array.from({ length: choicesCount }, (_, choiceNo) => choiceNo + 1);
    }
  };

  const choices = getChoiceGroupByType(choiceType);

  return (
    <Fragment>
      {choices.map((choice, subIndex) => {
        return (
          <Box key={choice} ml={subIndex === 0 ? 0 : '1px'}>
            <CheckboxSelection
              label={
                <Text sx={{ typography: { xs: 'subtitle1', md: 'subtitle2' } }}>{choice}</Text>
              }
              checked={
                field?.value?.[sequenceNo].toString() === choice.toString()
                  ? field.value.includes(choice)
                  : false
              }
              {...field}
              value={choice}
              onChange={handleCheckboxClick}
              checkboxSx={{
                width: 32,
                height: 32,
              }}
            />
          </Box>
        );
      })}
    </Fragment>
  );
};

export default FormSubQuestionConnectionAnswer;
