import { SvgIcon, SvgIconProps } from '@mui/material';

const DotCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 17" {...props}>
      <path
        fill="inherit"
        d="M7.665 2C3.98981 2 1 4.98981 1 8.665C1 12.3402 3.98981 15.33 7.665 15.33C11.3402 15.33 14.33 12.3402 14.33 8.665C14.33 4.98981 11.3402 2 7.665 2ZM7.665 10.8867C6.43808 10.8867 5.44333 9.89192 5.44333 8.665C5.44333 7.43808 6.43808 6.44333 7.665 6.44333C8.89192 6.44333 9.88667 7.43808 9.88667 8.665C9.88667 9.89192 8.89192 10.8867 7.665 10.8867Z"
      />
    </SvgIcon>
  );
};

export default DotCircleIcon;
