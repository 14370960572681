export enum AnonymousState {
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
}

export type AnonymousUser = {
  id?: string;
};

export type Anonymous = {
  anonymousUserState: AnonymousState;
  anonymousUser?: AnonymousUser;
};
