import { Box, BoxProps, Container, styled } from '@mui/material';

import { FOOTER_HEIGHT } from '../constants';

type LoLExamRoomFooterProps = BoxProps;

const LoLExaminationFooterContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${FOOTER_HEIGHT}px;
  width: 100%;
`;

const LoLExamFooterLayout = ({ children, ...restProps }: LoLExamRoomFooterProps) => {
  return (
    <Box
      bottom={0}
      left={0}
      position={'fixed'}
      bgcolor={'lolBackground.primary'}
      width={1}
      {...restProps}
    >
      <LoLExaminationFooterContainer sx={{ px: 3 }}>{children}</LoLExaminationFooterContainer>
    </Box>
  );
};

export default LoLExamFooterLayout;
