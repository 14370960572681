import { Divider, Skeleton } from '@mui/material';

import { Box, Flex } from '../../../Components/Base';

const ExaminationResultDetailLoading = () => (
  <>
    <Box py={2}>
      <Skeleton height={28} width={195} />
    </Box>
    <Divider />
    <Flex flexDirection={'column'} alignItems={'center'} py={2}>
      <Skeleton height={16} width={150} />
      <Skeleton height={32} width={90} sx={{ mt: 1 }} />
      <Skeleton height={14} width={155} sx={{ mt: 1 }} />
    </Flex>
  </>
);

export default ExaminationResultDetailLoading;
