import { useConfig } from '../../Contexts/ConfigProvider';

import { MenuItem } from './type';
import { getMenuIcon } from './utils';

type AppMenuConfig = { label: string; iconName: string; url: string };

const mapAppMenuData = (appMenuConfig: AppMenuConfig[]) => {
  if (Array.isArray(appMenuConfig)) {
    return appMenuConfig.map((menu) => ({
      label: menu?.label,
      icon: getMenuIcon(menu?.iconName),
      link: menu?.url,
    }));
  }
  return [];
};

const useAppMenu = (): { appMenu: MenuItem[] } => {
  const { appShell } = useConfig();
  const sideNavMenuItems = appShell?.sideNavMenuItems;

  const appMenu: MenuItem[] = mapAppMenuData(sideNavMenuItems as AppMenuConfig[]);

  return { appMenu };
};

export default useAppMenu;
