import urlJoin from 'url-join';

import { Lol_Exam_Result_Type } from '../../generated/graphql';

const shareToPlatform = ({
  platformSharer = '',
  shareTargetUrl = '',
  name = '',
  height = 0,
  width = 0,
  otherWindowOptions = '',
}) => {
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 4;
  const windowOptions =
    'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top + otherWindowOptions;

  const sharedUrl = platformSharer + shareTargetUrl;

  window.open(sharedUrl, name, windowOptions);
};

export const shareToFacebook = (shareUrlWithUtm: string) => {
  shareToPlatform({
    platformSharer: 'https://www.facebook.com/sharer/sharer.php?u=',
    shareTargetUrl: shareUrlWithUtm,
    name: 'Share to Facebook',
    height: 550,
    width: 450,
  });
};

export const shareToTwitter = (shareUrlWithUtm: string, initialText: string) => {
  shareToPlatform({
    platformSharer: 'http://twitter.com/intent/tweet?url=',
    shareTargetUrl: `${shareUrlWithUtm}&text=${encodeURIComponent(initialText)}`,
    height: 550,
    width: 450,
    otherWindowOptions: ',personalbar=0,toolbar=0,scrollbars=0,resizable=0',
  });
};

export const getSharingUrl = (
  permalink = '',
  socialChannel = 'social',
  campagin = 'no-associated',
  shouldAddUTM = false
): string => {
  if (shouldAddUTM) {
    return urlJoin(
      permalink,
      `?utm_source=${socialChannel}&utm_medium=social&utm_campaign=${campagin}`
    );
  }

  return urlJoin(permalink);
};

export const getGMTParamsByResultType = (type?: Lol_Exam_Result_Type) => {
  switch (type) {
    case Lol_Exam_Result_Type.FirstRiasec:
      return 'R1';
    case Lol_Exam_Result_Type.SecondRiasec:
      return 'R2';
    case Lol_Exam_Result_Type.FirstValue:
      return 'V1';
    case Lol_Exam_Result_Type.SecondValue:
      return 'V2';
    case Lol_Exam_Result_Type.ThirdValue:
      return 'V3';
    case Lol_Exam_Result_Type.FirstStrength:
      return 'S1';
    case Lol_Exam_Result_Type.SecondStrength:
      return 'S2';
    case Lol_Exam_Result_Type.ThirdStrength:
      return 'S3';
    case Lol_Exam_Result_Type.Character:
      return 'CHARACTER';
    case Lol_Exam_Result_Type.IdentityCareer:
      return 'IDENTITY';
    case Lol_Exam_Result_Type.PotentialIdentityCareer:
      return 'POTENTIAL_IDENTITY';
    case Lol_Exam_Result_Type.PersonalityCareer:
      return 'PERSONALITY';
    case Lol_Exam_Result_Type.PreferencesCareer:
      return 'PREFERENCES';
    case Lol_Exam_Result_Type.CapableCareer:
      return 'CAPABLE';
    case Lol_Exam_Result_Type.CareerGroup:
    default:
      return undefined;
  }
};

export const getEventNameByCategory = (category: string) => {
  switch (category) {
    case 'core-trait':
      return 'share_core_trait';
    case 'character':
      return 'share_character';
    case 'career':
      return 'share_career';
    default:
      return undefined;
  }
};

export const getCategoryAndRefCode = (permalink: string) => {
  const url = new URL(permalink);
  const pathname = url.pathname;
  const [category, refCode] = pathname.split('/').slice(-2);
  return { category, refCode };
};
