import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';

import LoLExamFooterLayout from '../../Containers/LoLExaminationRoom/LoLExamFooterLayout';
import { FOOTER_HEIGHT } from '../../Containers/LoLExaminationRoom/constants';
import { setPageTitle } from '../../Utils/page';

import LoLExaminationActionButton from './Containers/LoLExaminationActionButton';
import LoLInstructionDetails from './Containers/LoLInstructionDetails/LoLInstructionDetails';

const LoLInstruction = () => {
  return (
    <Box overflow={'auto'}>
      <Helmet>
        <title>{setPageTitle('LoLxExamHub Instruction')}</title>
      </Helmet>
      <Box p={3} pb={`${FOOTER_HEIGHT}px`}>
        <LoLInstructionDetails />
      </Box>
      <LoLExamFooterLayout>
        <LoLExaminationActionButton />
      </LoLExamFooterLayout>
    </Box>
  );
};

export default LoLInstruction;
