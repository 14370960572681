import {
  FormControlLabel,
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@mui/material';
import { createTheme, experimental_sx as sx } from '@mui/material/styles';
import { forwardRef } from 'react';

import paletteTheme from '../Theme/paletteTheme';

const switchInputTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: sx({
          '& .MuiFormControlLabel-label, .MuiFormControlLabel-label.Mui-disabled': {
            color: 'text.primary',
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: () => {
          return sx({
            width: 68,
            display: 'flex',
            alignItems: 'center',
            '& .MuiSwitch-switchBase': {
              pl: 1.7,
              '&.Mui-checked': {
                color: paletteTheme.palette.common.white,
                pl: 1.7,
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  bgcolor: paletteTheme.palette.success.main,
                },
                '&.Mui-disabled': {
                  color: paletteTheme.palette.grey['500'],
                  '& + .MuiSwitch-track': {
                    bgcolor: paletteTheme.palette.grey['200'],
                  },
                },
              },
              '&.Mui-disabled': {
                color: paletteTheme.palette.grey['500'],
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  bgcolor: paletteTheme.palette.grey['200'],
                },
              },
            },
            '& .MuiSwitch-track': {
              height: 24,
              borderRadius: 100,
              opacity: 1,
              bgcolor: paletteTheme.palette.grey['300'],
            },
          });
        },
      },
    },
  },
});

type SwitchProps = {
  label?: string;
} & MUISwitchProps;

const Switch = forwardRef((props: SwitchProps, ref) => {
  const { label, value, ...restProps } = props;
  return (
    <FormControlLabel
      ref={ref}
      label={label}
      control={<MUISwitch checked={!!value} value={value} {...restProps} />}
    />
  );
});

export type { SwitchProps };
export { switchInputTheme };
export default Switch;
