import {
  useQmsExamRoomGetExamQuery,
  QbQuestionType as QuestionFieldType,
  QmsExamRoomGetExamQuery as ExamRoomGetExamType,
  QbQuestionType,
  QmsskuType,
} from '../../generated/graphql';

type QuestionType = {
  __typename?: 'QMSQuestion' | undefined;
  id?: string;
  order?: number;
  type: QuestionFieldType;
  subType?: string | null;
  metadata?: any;
};

export default useQmsExamRoomGetExamQuery;
export { QuestionFieldType, QbQuestionType, QmsskuType };
export type { QuestionType, ExamRoomGetExamType };
