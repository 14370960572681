import { createTheme } from '@mui/material/styles';

const containerTheme = createTheme({
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg',
        sx: {
          px: { xs: 2, sm: 3, lg: 0 },
        },
      },
      styleOverrides: {
        maxWidthLg: {
          '&': {
            maxWidth: 1080,
          },
        },
      },
    },
  },
});

export default containerTheme;
