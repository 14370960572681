import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Flex } from '../../../../Components/Base';
import paletteTheme from '../../../../Components/Base/Theme/paletteTheme';
import FormSubQuestionConnectionAnswer from '../../Components/AnswerSheet/FormSubQuestionConnectionAnswer/FormSubQuestionConnectionAnswer';
import { ChoiceType } from '../../Components/AnswerSheet/FormSubQuestionConnectionAnswer/type';

// To display the amount of choices
const CHOICES_NUMBER = 10;

const ConnectionAnswerItem = ({
  answerChoiceTypeList,
  subQuestionNo,
  questionNo,
  isArticleCBT,
}: {
  answerChoiceTypeList: Array<ChoiceType>;
  subQuestionNo: number;
  questionNo: number;
  isArticleCBT: boolean;
}) => {
  const formContext = useFormContext();
  const answerValueFormName = `answers.[${questionNo - 1}].answer.value.[${subQuestionNo - 1}]`;
  const defaultAnswer = formContext.getValues(answerValueFormName);
  const [previewAnswer, setPreviewAnswer] = useState(defaultAnswer ?? '–––');

  const handleUpdatePreviewAnswer = (answer: string) => {
    setPreviewAnswer(answer);
  };

  return (
    <Box
      py={1}
      px={isArticleCBT ? '3px' : { xs: 0.5, md: 1, lg: 0.5 }}
      mx={isArticleCBT ? { sm: -0.5, md: 0 } : undefined}
      bgcolor={'grey.50'}
      borderRadius={1}
      border={`1px solid ${paletteTheme.palette.grey[100]}`}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'} mb={3} px={0.5}>
        <Typography variant="subtitle1">คำตอบข้อที่ {subQuestionNo}</Typography>
        <Box
          minWidth={'100px'}
          textAlign={'center'}
          bgcolor={'grey.200'}
          borderRadius={0.5}
          border={`1px solid ${paletteTheme.palette.grey[200]}`}
        >
          {previewAnswer}
        </Box>
      </Flex>
      {answerChoiceTypeList.map((type, index) => {
        return (
          <Flex
            key={`${answerValueFormName}.${index}`}
            alignItems={'center'}
            justifyContent={'center'}
            mt={index > 0 ? 2 : 0}
          >
            <FormSubQuestionConnectionAnswer
              name={answerValueFormName}
              choicesCount={CHOICES_NUMBER}
              choiceType={type}
              sequenceNo={index}
              onChange={handleUpdatePreviewAnswer}
            />
          </Flex>
        );
      })}
    </Box>
  );
};

export default ConnectionAnswerItem;
