import ExaminationTitle from '../../../../Components/ExaminationTitle';
import { ContainerProps, Flex, PaperProps, Text } from '../../../../Components/Base';
import BookmarkIcon from '../../../../Components/Icons/BookmarkIcon';

type ExamRoomHeaderProps = {
  examTitle: string;
  containerSx?: ContainerProps['sx'];
  maxWidth?: ContainerProps['maxWidth'];
} & PaperProps;

const ExamRoomHeader = (props: ExamRoomHeaderProps) => {
  const { examTitle, containerSx, maxWidth, ...restProps } = props;
  const defaultContainerSx = { py: { xs: 2, md: 1.25 } };
  return (
    <ExaminationTitle
      title={examTitle}
      maxWidth={maxWidth}
      containerSx={{ ...defaultContainerSx, ...containerSx }}
      {...restProps}
    >
      <Flex mt={2} alignItems={'center'}>
        <BookmarkIcon color={'warning'} sx={{ mr: 0.5 }} />
        <Text variant={'body2'}>บุ๊กมาร์กคำถามเพื่อทบทวนภายหลัง</Text>
      </Flex>
    </ExaminationTitle>
  );
};

export default ExamRoomHeader;
