import { Box, Image, Text } from '../../Components/Base';
import LoLBackground from '../../Components/LoLBackground';

import LoLExamList from './Containers/LoLExamList';

const LoLExaminations = () => {
  return (
    <LoLBackground>
      <Image
        src={'/assets/lol/talking-human.svg'}
        width={'100%'}
        height={'100%'}
        maxWidth="360px"
        maxHeight="300px"
      />
      <Box mt={3} mb={8} color={'lolText.primary'}>
        <Text mb={1} variant="h2" whiteSpace={'pre-line'}>
          {`เลือกภาษา`}
        </Text>
        <Text variant="caption" whiteSpace={'pre-line'}>
          {`Select Language`}
        </Text>
      </Box>
      <LoLExamList />
    </LoLBackground>
  );
};

export default LoLExaminations;
