import { SvgIcon, SvgIconProps } from '@mui/material';

const HappyFaceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="#FFDD67"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      />
      <path
        fill="#664E27"
        d="M19.88 6.96c.24.12.12.4-.08.44-1.08.16-2.2.36-3.32.96 1.6.28 2.88 1.08 3.6 1.92.16.2-.04.44-.2.4-1.92-.68-3.88-1.08-6.32-.8-.2 0-.36-.08-.32-.28.64-2.92 4.36-4 6.64-2.64Zm-15.76 0c-.24.12-.12.4.08.44 1.08.16 2.2.36 3.32.96-1.6.28-2.88 1.08-3.6 1.92-.16.2.04.44.2.4C6.04 10 8 9.6 10.44 9.88c.2 0 .36-.08.32-.28-.64-2.92-4.36-4-6.64-2.64Zm14.96 6c-.16-.2-.44-.16-.76-.16H5.68c-.32 0-.6-.04-.76.16-1.56 2 .28 7.84 7.08 7.84s8.64-5.84 7.08-7.84Z"
      />
      <path
        fill="#4C3526"
        d="M12.72 15.88c-.24 0-.6.2-.44.8.08.28.48.64.48 1.12 0 .96-1.52.96-1.52 0 0-.48.4-.8.48-1.12.12-.56-.24-.8-.44-.8-.64 0-1.64.68-1.64 1.84 0 1.28 1.08 2.32 2.4 2.32 1.32 0 2.4-1.04 2.4-2.32-.04-1.12-1.08-1.8-1.72-1.84Z"
      />
      <path
        fill="#FF717F"
        d="M8.92 19.48c.88.4 1.92.6 3.08.6 1.16 0 2.2-.24 3.08-.6-.84-.44-1.88-.68-3.08-.68-1.2 0-2.24.24-3.08.68Z"
      />
      <path fill="#fff" d="M18 13.6H6.04c-.84 0-.84 1.6-.04 1.6h12c.8 0 .8-1.6 0-1.6Z" />
    </SvgIcon>
  );
};

export default HappyFaceIcon;
