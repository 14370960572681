import { Box, BoxProps } from '../../../Components/Base';
import BookDetails, { BookDetailsType } from '../../../Components/BookDetails';

type MyBookItemProps = BoxProps & Omit<BookDetailsType, 'bookUrl'>;

const MyBookItem = ({
  imageUrl,
  title,
  author,
  refNo,
  isComing = false,
  ...restProps
}: MyBookItemProps) => {
  return (
    <Box p={2} bgcolor={'common.white'} borderRadius={1} sx={{ boxShadow: 4 }} {...restProps}>
      <BookDetails
        imageUrl={imageUrl}
        title={title}
        author={author}
        refNo={refNo}
        isComing={isComing}
      />
    </Box>
  );
};

export default MyBookItem;
