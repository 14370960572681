import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import LoLButton, { LoLButtonProps } from '../../../../Components/Base/LoLButton';
import ArrowForwardIcon from '../../../../Components/Icons/ArrowForwardIcon';
import Loading from '../../../../Components/Loading';
import useBackToExam from '../../../../Domains/useBackToExam';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

type ContinueLoLExaminationButtonProps = LoLButtonProps & {
  examCardIdByBookIdAndExamId?: string;
};

const ContinueLoLExaminationButton = (props: ContinueLoLExaminationButtonProps) => {
  const { examCardIdByBookIdAndExamId, ...restProps } = props;
  const { examId = '' } = useParams();
  const { t } = useTranslation('lolCommon');
  const navigate = useNavigate();
  const sendDataToGTM = useGoogleTagManager();

  const [backToExam, { loading: backToExamLoading }] = useBackToExam();

  const handleBackToExamRoom = async () => {
    // It is possible when the users entry from mybooks path, examId will be examCardId instead
    const examCardId = examCardIdByBookIdAndExamId ?? examId;

    if (!examCardId) return; //TODO: Do something if there is no exam card id

    // sendDataToGTM({
    //   event: 'public_examination_instruction_cta_click',
    //   buttonCategory: 'continue examination',
    // });

    try {
      await backToExam({
        variables: { examCardId: examCardId, isReset: false },
      });
      navigate('instruction');
    } catch (error) {
      console.error('Back to examination error: ', error);
    }
  };

  return (
    <>
      <LoLButton
        size={'large'}
        fullWidth
        onClick={handleBackToExamRoom}
        disabled={backToExamLoading}
        {...restProps}
      >
        {t('BUTTON.CONTINUE')}
        <ArrowForwardIcon sx={{ ml: 0.5 }} />
      </LoLButton>
      <Loading open={backToExamLoading} />
    </>
  );
};

export default ContinueLoLExaminationButton;
