import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useCheckExamStatusByBookIdAndExamId from '../../../../Domains/useCheckStatusExamByBookIdAndExamId';
import { CHECK_EXAM_STATUS_INTERVAL, PublicExamStatusErrorCode } from '../../../constant';

import ContinuePublicExaminationButton from './ContinuePublicExaminationButton';
import GradingPublicExaminationResultButton from './GradingPublicExaminationButton';
import SeePublicExaminationResultButton from './SeePublicExaminationResultButton';
import StartPublicExaminationButton from './StartPublicExaminationButton';

const PublicExaminationActionButton = () => {
  const { bookId = '', examId = '' } = useParams();
  const [examStatus, setExamStatus] = useState<PublicExamStatusErrorCode>();

  const { loading, refetch: checkExamStatusByBookIdAndExamId } =
    useCheckExamStatusByBookIdAndExamId({
      variables: { bookId, examId },
      skip: !bookId || !examId,
      fetchPolicy: 'network-only',
      onError(error) {
        setExamStatus(error?.graphQLErrors?.[0].extensions?.exception?.errors?.[0]?.code);
      },
    });

  useEffect(() => {
    if (examStatus == PublicExamStatusErrorCode.GRADING) {
      const intervalId = setInterval(() => {
        checkExamStatusByBookIdAndExamId();
      }, CHECK_EXAM_STATUS_INTERVAL);
      return () => clearInterval(intervalId);
    }
  }, [checkExamStatusByBookIdAndExamId, examStatus]);

  const renderExaminationActionButton = () => {
    if (loading) {
      return (
        <Skeleton
          width={'100%'}
          height={48}
          sx={{ borderRadius: 48, mx: 'auto', maxWidth: { xs: 1, sm: '343px' } }}
        />
      );
    }

    switch (examStatus) {
      case PublicExamStatusErrorCode.NOT_START_NO_EXAM_CARD:
        return <StartPublicExaminationButton />;
      case PublicExamStatusErrorCode.GRADING:
        return <GradingPublicExaminationResultButton />;
      case PublicExamStatusErrorCode.FINISHED:
        return <SeePublicExaminationResultButton />;
      default:
        return <ContinuePublicExaminationButton />;
    }
  };

  return (
    <Box textAlign={'center'} px={{ xs: 2, md: 0 }} pb={2}>
      {renderExaminationActionButton()}
    </Box>
  );
};

export default PublicExaminationActionButton;
