import ResultListBySubject from './Containers/ResultListBySubject';

const ResultSolution = () => {
  return (
    <>
      <ResultListBySubject />
    </>
  );
};

export default ResultSolution;
