import { SvgIcon, SvgIconProps } from '@mui/material';

const LockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 38 38" {...props}>
      <path
        fill="inherit"
        d="M28.288 12.842h-1.54v-1.54c0-4.245-3.453-7.698-7.697-7.698-4.245 0-7.698 3.453-7.698 7.698v1.54h-1.54a3.08 3.08 0 0 0-3.08 3.079v15.396a3.08 3.08 0 0 0 3.08 3.08h18.475a3.08 3.08 0 0 0 3.08-3.08V15.92a3.08 3.08 0 0 0-3.08-3.08Zm-13.856-1.54a4.624 4.624 0 0 1 4.619-4.619 4.624 4.624 0 0 1 4.619 4.619v1.54h-9.238v-1.54Zm6.158 13.43v3.506h-3.079v-3.506a3.066 3.066 0 0 1-.637-4.83 3.079 3.079 0 0 1 5.256 2.177 3.07 3.07 0 0 1-1.54 2.653Z"
      />
    </SvgIcon>
  );
};

export default LockIcon;
