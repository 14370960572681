import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Flex, Text, Box } from '../../../../Components/Base';
import LockIcon from '../../../../Components/Icons/LockIcon';

type LockReportProps = {
  conditionNumber: number;
};

const LockReport = (props: LockReportProps) => {
  const { conditionNumber } = props;
  const { t } = useTranslation('lolResults');
  return (
    <Box
      p={3}
      borderRadius={1}
      bgcolor={'lolCard.primary'}
      border={'1px solid'}
      borderColor={'lolLine.line1'}
      height={'max-content'}
      color={'lolText.primary'}
    >
      <Flex gap={1} flexDirection={'column'} alignItems={'center'}>
        <Text variant="h2" textAlign={'center'}>
          {t('LOCKED.TITLE')}
        </Text>
        <Avatar
          sx={{
            my: 2,
            width: 200,
            height: 200,
            borderStyle: 'dashed',
            borderColor: 'lolElement.gray',
            borderWidth: 4,
            bgcolor: 'lolCard.primary',
          }}
        >
          <LockIcon
            sx={{
              width: 105,
              height: 105,
              color: 'lolElement.gray',
            }}
          />
        </Avatar>
        <Text textAlign={'center'} color={'lolText.secondary'}>
          {t('LOCKED.CONDITION')} {conditionNumber}
        </Text>
      </Flex>
    </Box>
  );
};

export default LockReport;
