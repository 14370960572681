import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Flex, Text } from '../../../../Components/Base';
import FormAnswerYesNoChoices from '../../../ExaminationRoom/Components/AnswerSheet/FormAnswerYesNoChoices';

const choices = [
  { label: 'ใช่', value: true },
  { label: 'ไม่ใช่', value: false },
];

const questions = [
  {
    order: 1,
    subQuestions: [
      { label: '<p>Test</p>', value: '1' },
      { label: '<p>Test</p>', value: '2' },
    ],
  },
];

const AnswerMultipleSubQuestionYesNoChoices = () => {
  const formContext = useForm({ mode: 'onTouched' });

  return (
    <FormProvider {...formContext}>
      <form>
        <Box>
          <Text variant="body2" fontWeight={600}>
            Multiple Sub Question: True or False Choices
          </Text>
          <Box alignItems={'center'}>
            {questions.map(({ order, subQuestions }) => {
              return (
                <Flex key={order}>
                  <Box>
                    {subQuestions.map(({ value }, index) => {
                      return (
                        <Flex alignItems={'center'} key={`${order}.${value}`}>
                          <Typography
                            variant="subtitle1"
                            minWidth={'40px'}
                            textAlign={'center'}
                            mr={1}
                          >{`${order}.${value}`}</Typography>
                          <FormAnswerYesNoChoices
                            name={`YesNoChoices.${index}`}
                            choices={choices}
                          />
                          <Text ml={2}>
                            Select: {formContext.watch()?.YesNoChoices?.[index] ?? ''}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Box>
                </Flex>
              );
            })}
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AnswerMultipleSubQuestionYesNoChoices;
