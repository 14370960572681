import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Text, Flex, Box } from '../../../../Components/Base';
import FormSubQuestionConnectionAnswer from '../../../ExaminationRoom/Components/AnswerSheet/FormSubQuestionConnectionAnswer';
import { ChoiceType } from '../../../ExaminationRoom/Components/AnswerSheet/FormSubQuestionConnectionAnswer/type';

const questions = [
  {
    subQuestions: [
      { label: '<p>ต้องการประชดประชันผู้ที่กำลังกล่าวถึง</p>', value: '1' },
      { label: '<p>แสดงความตัดขาดต่อผู้ที่กำลังอ่าน</p>', value: '2' },
      { label: '<p>ต้องการเตือนสติให้ผู้อ่านฉุกคิดได้</p>', value: '3' },
      { label: '<p>ต้องการกดดันผู้อ่านให้กระทำสิ่งที่ต้องการ</p>', value: '4' },
    ],
  },
];

const AnswerSubQuestionConnectionAnswer = ({
  answerChoiceTypeList = [ChoiceType.DIGIT, ChoiceType.DIGIT, ChoiceType.ENG_ALPHABET],
}: {
  answerChoiceTypeList?: Array<ChoiceType>;
}) => {
  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      answer: {
        value: [{ answerValue: ['–––'] }],
      },
    },
  });

  return (
    <FormProvider {...formContext}>
      <form>
        <Box>
          <Text variant="body2" fontWeight={600}>
            Multiple Answer: Answer combination
          </Text>
          {questions.map(({ subQuestions }, questionNo) => {
            return (
              <Box
                mt={2}
                display="grid"
                gridAutoFlow="row"
                gridTemplateColumns={{
                  xs: 'auto',
                  sm: `repeat(2, 1fr)`,
                  lg: `repeat(3, 1fr)`,
                }}
                rowGap={{ xs: 3, sm: 4, lg: 3 }}
                columnGap={{ xs: 'auto', sm: 2, lg: 1 }}
                justifyContent={'space-evenly'}
              >
                {subQuestions.map((_, index) => {
                  const previewAnswer =
                    formContext.watch().answer?.value?.[questionNo]?.answerValue?.[index] ?? '–––';
                  return (
                    <Box p={'8px'}>
                      <Flex justifyContent={'space-between'} alignItems={'center'} mb={3}>
                        <Typography variant="subtitle1">คำตอบข้อที่ {index + 1}</Typography>
                        <Box
                          minWidth={'100px'}
                          textAlign={'center'}
                          borderRadius={'4px'}
                          bgcolor={'grey.200'}
                        >
                          {previewAnswer}
                        </Box>
                      </Flex>
                      {answerChoiceTypeList.map((type, subIndex) => {
                        return (
                          <Flex
                            key={`answer.value.${questionNo}.answerValue.${index}.%${subIndex}`}
                            alignItems={'center'}
                            justifyContent={'center'}
                            mt={subIndex > 0 ? '16px' : 0}
                          >
                            <FormSubQuestionConnectionAnswer
                              name={`answer.value.[${questionNo}].answerValue.[${index}]`}
                              choicesCount={10}
                              choiceType={type}
                              sequenceNo={subIndex}
                            />
                          </Flex>
                        );
                      })}
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </form>
    </FormProvider>
  );
};

export default AnswerSubQuestionConnectionAnswer;
