import { groupBy, summarize, tidy } from '@tidyjs/tidy';

import { QbQuestionType } from '../useGetSolutionById';

import { QuestionFieldType, QuestionType } from './useGetExaminationDetails';

export enum Device {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
}

export enum GroupType {
  MultipleChoices4 = 'MULTIPLE_CHOICES_4',
  MultipleChoices5 = 'MULTIPLE_CHOICES_5',
  FillNumber = 'FILL_NUMBER',
  MultipleSubQuestionsYesNoAnswer = 'MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER',
  MultipleSubQuestionsConnectionAnswers = 'MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS',
}

type Metadata = {
  [key: string]: any;
};

export const setGroupOfQuestionTypes = (questions: QuestionType[]) => {
  return questions.map((question) => {
    const { type, subType } = question;
    let group = null;
    if (subType === 'MUL1/5' || subType === 'MDS5' || subType === 'MMA5') {
      group = GroupType.MultipleChoices5;
    } else if (subType === 'MUL1/4' || subType === 'MDS4' || subType === 'MMA4') {
      group = GroupType.MultipleChoices4;
    } else if (type === QuestionFieldType.FillNumber) {
      group = GroupType.FillNumber;
    } else if (type === QuestionFieldType.MultipleSubQuestionsConnectionAnswers) {
      group = GroupType.MultipleSubQuestionsConnectionAnswers;
    } else if (type === QuestionFieldType.MultipleSubQuestionsYesNoAnswer) {
      group = GroupType.MultipleSubQuestionsYesNoAnswer;
    }

    return { group, question };
  });
};

export const formatQuestionListByType = (questions: QuestionType[]) => {
  const questionsWithGroup = setGroupOfQuestionTypes(questions);

  const newQuestions = tidy(
    questionsWithGroup,
    groupBy(['group'], summarize({ questions: (items) => items.map((item) => item.question) }))
  );

  return newQuestions;
};

export const getRangeOfQuestion = (questions: QuestionType[]) => {
  if (questions.length === 0) return '';

  const startQuestionNo = questions[0].order ?? 1;
  const endQuestionNo = startQuestionNo + questions.length - 1;

  if (startQuestionNo === endQuestionNo) {
    return `ข้อ ${startQuestionNo}`;
  }

  return `ข้อ ${startQuestionNo} - ${endQuestionNo}`;
};

export const getExamTypeDetail = (group: GroupType | null) => {
  if (group === GroupType.MultipleChoices5) {
    return `รูปแบบปรนัย 5 ตัวเลือก`;
  } else if (group === GroupType.MultipleChoices4) {
    return `รูปแบบปรนัย 4 ตัวเลือก`;
  } else if (group === GroupType.FillNumber) {
    return 'รูปแบบเติมตัวเลข';
  } else if (group === GroupType.MultipleSubQuestionsConnectionAnswers) {
    return 'รูปแบบกรอกเลข 2 หลักของข้อความที่กำหนด และตามด้วยตัวอักษร';
  } else if (group === GroupType.MultipleSubQuestionsYesNoAnswer) {
    return 'รูปแบบเลือกตอบเชิงซ้อนใช่ หรือไม่ใช่ 3 ข้อย่อย';
  }
  return null;
};

export const getQuestionRow = (chunkSize: number) => {
  const isColumnFit = chunkSize % 5 === 0;
  if (isColumnFit) return chunkSize;

  const chunk = Math.floor(chunkSize / 5) + 1;
  return 5 * chunk;
};

export const renderCorrectAnswer = (type?: QbQuestionType, correctAnswer?: any) => {
  switch (type) {
    case QbQuestionType.MultipleSubQuestionsConnectionAnswers: {
      if (Array.isArray(correctAnswer)) {
        return Array.from(correctAnswer).sort().join(', ');
      } else {
        return correctAnswer;
      }
    }
    case QbQuestionType.MultipleSubQuestionsYesNoAnswer: {
      if (Array.isArray(correctAnswer)) {
        return Array.from(correctAnswer)
          .map(({ correctAnswerValue }) => {
            if (correctAnswerValue === null || correctAnswerValue === undefined) {
              return '–';
            } else {
              return correctAnswerValue ? 'ใช่' : 'ไม่ใช่';
            }
          })
          .join(', ');
      } else {
        return correctAnswer;
      }
    }
    case QbQuestionType.MultipleSelectWithCorrectAnswers:
    case QbQuestionType.MultipleChoiceDifferentScore:
    case QbQuestionType.MultipleChoice:
    case QbQuestionType.FillNumber:
    default:
      return correctAnswer;
  }
};

export const renderAnswerResult = (type?: QbQuestionType, answer?: any) => {
  let displayAnswer = '';

  switch (type) {
    case QbQuestionType.MultipleSubQuestionsConnectionAnswers: {
      const haveAnswers = answer?.some((item: null | string) => item !== null);

      if (!haveAnswers) {
        displayAnswer = 'ไม่มีคำตอบ';
      } else {
        displayAnswer = `ตอบ ${answer
          ?.filter(
            (connectionAnswer: string | null) =>
              connectionAnswer !== null && connectionAnswer !== undefined
          )
          ?.join(', ')}`;
      }
      break;
    }
    case QbQuestionType.MultipleSubQuestionsYesNoAnswer: {
      const answerList = answer?.map((item: string | null) => {
        if (item === null || item === undefined) {
          return '–';
        } else {
          return item ? 'ใช่' : 'ไม่ใช่';
        }
      });
      displayAnswer = `ตอบ ${answerList.join(', ')}`;
      break;
    }
    case QbQuestionType.MultipleSelectWithCorrectAnswers: {
      if (!answer) {
        return 'ไม่มีคำตอบ';
      } else {
        displayAnswer = `ตอบ ${(answer as string)?.split('').sort().join(', ')}`;
      }
      break;
    }
    case QbQuestionType.MultipleChoiceDifferentScore:
    case QbQuestionType.MultipleChoice:
    case QbQuestionType.FillNumber:
    default: {
      if (!answer) {
        return 'ไม่มีคำตอบ';
      } else {
        displayAnswer = `ตอบ ${answer}`;
      }
    }
  }
  return displayAnswer;
};

export const renderAnswer = (type?: QbQuestionType, answer?: any) => {
  let displayAnswer = '';

  switch (type) {
    case QbQuestionType.MultipleSubQuestionsConnectionAnswers: {
      const haveAnswers = answer !== null && answer !== undefined && answer !== '';

      if (!haveAnswers) {
        displayAnswer = 'ไม่มีคำตอบ';
      } else {
        displayAnswer = answer
          ?.filter(
            (connectionAnswer: string | null) =>
              connectionAnswer !== null && connectionAnswer !== undefined
          )
          ?.join(', ');
      }
      break;
    }
    case QbQuestionType.MultipleSubQuestionsYesNoAnswer: {
      if (answer === null || answer === undefined) {
        displayAnswer = '-';
      } else {
        displayAnswer = `${answer ? 'ใช่' : 'ไม่ใช่'}`;
      }
      break;
    }
    case QbQuestionType.MultipleSelectWithCorrectAnswers: {
      if (!answer) {
        return 'ไม่มีคำตอบ';
      } else {
        displayAnswer = `ตอบ ${(answer as string)?.split('').sort().join(', ')}`;
      }
      break;
    }
    case QbQuestionType.MultipleChoiceDifferentScore:
    case QbQuestionType.MultipleChoice:
    case QbQuestionType.FillNumber:
    default: {
      if (!answer) {
        return 'ไม่มีคำตอบ';
      } else {
        displayAnswer = `ตอบ ${answer}`;
      }
    }
  }
  return displayAnswer;
};
