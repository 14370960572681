import { SvgIcon, SvgIconProps } from '@mui/material';

const FrowningFaceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="#FFDD67"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      />
      <path
        fill="#664E27"
        d="M7.4 11.84a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM16.6 11.84a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM8.4 18.24c2.32-1.92 4.88-1.92 7.2 0 .28.24.52-.16.32-.52-.72-1.36-2.12-2.6-3.92-2.6-1.8 0-3.24 1.24-3.92 2.6-.2.36.04.76.32.52Z"
      />
    </SvgIcon>
  );
};

export default FrowningFaceIcon;
