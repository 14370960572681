import { Text } from '../../Components/Base';

import ExaminationResults from './Containers/ExaminationResults';

const BookResults = () => {
  return (
    <>
      <Text variant={'h3'} pl={2} mb={1}>
        ผลการสอบทั้งหมด
      </Text>
      <ExaminationResults />
    </>
  );
};

export default BookResults;
