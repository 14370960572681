import { Box, Divider } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex, Text } from '../../Components/Base';
import LoLButton from '../../Components/Base/LoLButton';
import LoLExamFooterLayout from '../../Containers/LoLExaminationRoom/LoLExamFooterLayout';
import { FOOTER_HEIGHT } from '../../Containers/LoLExaminationRoom/constants';
import { useLoLExamination } from '../../Contexts/LoLExaminationProvider';
import { DURATION_PER_QUESTION } from '../../Contexts/LoLExaminationProvider/constants';
import { LoLExaminationSection } from '../../Contexts/LoLExaminationProvider/types';

import { LoLExamInstructionDetails } from './components';

const LoLExaminationInstructions = () => {
  const { currentQuestionNo = 1, milestones = [] } = useLoLExamination();
  const navigate = useNavigate();
  const { t } = useTranslation('lolInstructions');

  const handleGoToExam = () => {
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace('instruction', 'examroom');
    navigate(newPath, { replace: true });
  };

  const currentSection = useMemo(() => {
    const currentMilestone = milestones.find(
      (milestone) =>
        milestone.startQuestionNo <= currentQuestionNo &&
        currentQuestionNo <= milestone.endQuestionNo
    );
    return currentMilestone;
  }, [currentQuestionNo, milestones]);

  const currentSectionTags = useMemo(() => {
    const currentMilestone = milestones.find(
      (milestone) =>
        milestone.startQuestionNo <= currentQuestionNo &&
        currentQuestionNo <= milestone.endQuestionNo
    );
    return currentMilestone?.tags || [];
  }, [currentQuestionNo, milestones]);

  const totalQuestionCount = useMemo(() => {
    return currentSection?.totalQuestionCount ?? 1;
  }, [currentSection]);

  const questionCountByTag = useMemo(() => {
    return currentSection?.questionCountByTag ?? [];
  }, [currentSection]);

  const getInstructionTitle = useCallback(
    (sectionTag: string) => {
      switch (sectionTag) {
        case LoLExaminationSection.RIASEC_PART_1:
          return t('EXAM_TITLE.CAREER_GROUP', { ns: 'lolCommon' });
        case LoLExaminationSection.RIASEC_PART_2:
          return t('EXAM_TITLE.CAPABLE_CAREER', { ns: 'lolCommon' });
        case LoLExaminationSection.ACTIVITY_PART_1:
        case LoLExaminationSection.VALUE:
          return t('EXAM_TITLE.PERSONALITY_CAREER', { ns: 'lolCommon' });
        case LoLExaminationSection.ACTIVITY_PART_2:
        case LoLExaminationSection.STRENGTH:
          return t('EXAM_TITLE.POTENTIAL_IDENTITY_CAREER', { ns: 'lolCommon' });
        default:
          return '';
      }
    },
    [t]
  );

  const instructionTitle = useMemo(() => {
    const titleFromTag = currentSectionTags.map((tag) => getInstructionTitle(tag));
    const uniqueTitle = Array.from(new Set(titleFromTag));
    return uniqueTitle.join(' & ');
  }, [currentSectionTags, getInstructionTitle]);

  const getInstructionDetails = (sectionTag: string) => {
    switch (sectionTag) {
      case LoLExaminationSection.RIASEC_PART_1:
      case LoLExaminationSection.RIASEC_PART_2:
        return {
          description: t('DESCRIPTION.RIASEC'),
          imgUrl: '/assets/lol/instructions/riasec-instruction.svg',
        };
      case LoLExaminationSection.ACTIVITY_PART_1:
      case LoLExaminationSection.ACTIVITY_PART_2:
        return {
          description: t('DESCRIPTION.ACTIVITY'),
          imgUrl: '/assets/lol/instructions/activity-instruction.svg',
        };
      case LoLExaminationSection.VALUE:
        return {
          description: t('DESCRIPTION.VALUE_AND_STRENGTH'),
          imgUrl: '/assets/lol/instructions/value-instruction.svg',
        };
      case LoLExaminationSection.STRENGTH:
        return {
          description: t('DESCRIPTION.VALUE_AND_STRENGTH'),
          imgUrl: '/assets/lol/instructions/strength-instruction.svg',
        };
      default:
        return null;
    }
  };

  return (
    <Box overflow={'auto'}>
      <Flex
        flexDirection={'column'}
        justifyContent={'space-between'}
        pt={2}
        pb={`${FOOTER_HEIGHT + 16}px`}
        px={3}
        gap={2}
      >
        <Flex justifyContent={'space-between'} gap={1} color={'lolText.primary'}>
          <Text variant={'subtitle1'} fontWeight={600}>
            {instructionTitle}
          </Text>
          <Text
            variant={'subtitle1'}
            minWidth={'44.5px'}
            textAlign={'right'}
          >{`${totalQuestionCount} ${t('UNIT.QUESTIONS', { ns: 'lolCommon' })}`}</Text>
        </Flex>
        <Divider
          aria-hidden="true"
          sx={{
            borderColor: 'lolLine.line1',
            borderStyle: 'dashed',
            mx: 3,
          }}
        />
        <Flex flexDirection={'column'} gap={2}>
          {currentSectionTags.map((tag) => {
            const details = getInstructionDetails(tag);
            if (!details) return null;

            const { description, imgUrl } = details;

            const questionCount =
              questionCountByTag.find((item) => item.tag === tag)?.questionCount ?? 0;
            const duration = Math.floor((questionCount * DURATION_PER_QUESTION) / 60);

            if (questionCount === 0) return null;

            return (
              <LoLExamInstructionDetails
                key={tag}
                description={description}
                imgUrl={imgUrl}
                duration={duration}
                questionCount={questionCount}
              />
            );
          })}
        </Flex>
      </Flex>
      <LoLExamFooterLayout>
        <LoLButton fullWidth size={'large'} onClick={handleGoToExam}>
          {t('START_BUTTON')}
        </LoLButton>
      </LoLExamFooterLayout>
    </Box>
  );
};

export default LoLExaminationInstructions;
