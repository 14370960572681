import { styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  IconButton as BaseIconButton,
  Image,
  Modal,
  ModalContent as BaseModalContent,
} from '../../../../Components/Base';
import VideoPlayer from '../../../../Components/VideoPlayer';
import useGetExaminationSolutionDetail from '../../../../Domains/useGetExaminationSolutionDetail';
import HTMLView from '../../../../Components/HTMLView';
import CloseRoundedIcon from '../../../../Components/Icons/CloseRoundedIcon';
import useDisclosure from '../../../../Utils/useDisclosure';
import { useConfig } from '../../../../Contexts/ConfigProvider';
import replaceImgTagWithIframe from '../../../../Utils/replaceImgTagWithIframe';

import SolutionDetailsLoading from './ExaminationSolutionDetailsLoading';

const VideoWrapaper = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingBottom: '56.25%',
});

const PreviewModal = styled(Modal)({
  '.MuiPaper-root': {
    position: 'relative',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
});

const ModalContent = styled(BaseModalContent)({
  background: 'white',
  padding: 0,
  borderRadius: 8,
  overflowY: 'auto',
});

const IconButton = styled(BaseIconButton)(({ theme }) => ({
  color: theme.palette.grey[300],

  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

const ExaminationSolutionDetails = () => {
  const { examItemId = '' } = useParams();
  const { data, loading } = useGetExaminationSolutionDetail({ variables: { id: examItemId } });
  const {
    solutions: { enableGoogleDriveLinkFix },
  } = useConfig();

  const { isOpen, open, close } = useDisclosure();
  const htmlRef = useRef<HTMLElement>(null);
  const [currentImageSrc, setCurrentImageSrc] = useState<string>('');

  useEffect(() => {
    if (data) {
      const imageTags: HTMLCollectionOf<HTMLImageElement> | undefined =
        htmlRef?.current?.getElementsByTagName('img');
      if (imageTags) {
        Array.from(imageTags).forEach((image) => {
          image.style.width = '100%';
          image.style.objectFit = 'contain';
          image.style.cursor = 'pointer';
          image.addEventListener('click', (event) => {
            open();
            setCurrentImageSrc((event.target as HTMLImageElement).currentSrc ?? '');
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const solutionList = data?.qmsExamItemById.question?.solution;

  if (loading) return <SolutionDetailsLoading />;

  return (
    <Box maxWidth={'100%'} width={'100%'} mx={'auto'}>
      {solutionList?.map((solution) => {
        const { type, content: rawContent } = solution;
        if (type === 'HTML') {
          //TODO: remove this condition after gg drive link is fixed
          const content = enableGoogleDriveLinkFix
            ? replaceImgTagWithIframe(rawContent)
            : rawContent;
          return (
            <>
              <HTMLView rawContent={content} ref={htmlRef} />
              <PreviewModal open={isOpen}>
                <Flex width={1} justifyContent={'flex-end'}>
                  <IconButton color={'inherit'} onClick={close} sx={{ background: 'transparent' }}>
                    <CloseRoundedIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Flex>
                <ModalContent>
                  <Image width={'100%'} src={currentImageSrc} />
                </ModalContent>
              </PreviewModal>
            </>
          );
        }
        if (type === 'YOUTUBE') {
          return (
            <VideoWrapaper>
              <VideoPlayer
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                width={'100%'}
                height={'100%'}
                videoUrl={rawContent}
                controls
              />
            </VideoWrapaper>
          );
        }
      })}
    </Box>
  );
};

export default ExaminationSolutionDetails;
