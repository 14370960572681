import { styled } from '@mui/material';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { useTranslation } from 'react-i18next';

import { Flex, Image, Text } from '../../../Components/Base';
import DotCircleIcon from '../../../Components/Icons/DotCircleIcon';

const LoLExamInstructionWrapper = styled(Flex)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  width: '100%',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: theme.palette.lolCard?.primary,
  borderRadius: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.lolLine?.line1,
}));

type LoLExamInstructionDetailsProps = {
  description: string;
  imgUrl: string;
  duration: number;
  questionCount: number;
};

const LoLExamInstructionDetails = (props: LoLExamInstructionDetailsProps) => {
  const { description, imgUrl, duration, questionCount } = props;
  const { t } = useTranslation('lolCommon');

  return (
    <LoLExamInstructionWrapper>
      <Flex width={1} justifyContent={'center'}>
        <Image width={1} maxWidth={200} src={imgUrl} alt={description} />
      </Flex>
      <Flex flexDirection={'column'} color={'lolText.primary'} gap={1}>
        <Text variant={'h3'} fontWeight={400}>
          {description}
        </Text>
      </Flex>
      <Flex gap={2} color={'lolText.secondary'}>
        <Flex gap={1} alignItems={'center'}>
          <AccessTimeRoundedIcon fontSize={'small'} />
          <Text variant={'body2'} lineHeight={0}>{`${duration} ${t('UNIT.MINUTES')}`}</Text>
        </Flex>
        <Flex gap={1} alignItems={'center'}>
          <DotCircleIcon fontSize={'small'} />
          <Text variant={'body2'} lineHeight={0}>{`${questionCount} ${t('UNIT.QUESTIONS')}`}</Text>
        </Flex>
      </Flex>
    </LoLExamInstructionWrapper>
  );
};

export default LoLExamInstructionDetails;
