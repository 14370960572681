import { styled, alpha } from '@mui/material';

import { Flex, FlexProps, Text } from '../../../../Components/Base';
import LaunchRoundedIcon from '../../../../Components/Icons/LaunchRoundedIcon';

const MenuWrapper = styled(Flex)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    background: alpha(theme.palette.primary.main, 0.04),
  },
}));

type MenuItemProps = {
  icon?: React.ReactNode;
  title: string;
  hasBackLink?: boolean;
} & FlexProps;

const MenuItem = (props: MenuItemProps) => {
  const { icon, title, hasBackLink = false, ...restProps } = props;

  return (
    <MenuWrapper
      bgcolor={'common.white'}
      justifyContent={'space-between'}
      alignItems={'center'}
      px={2}
      py={2.5}
      {...restProps}
    >
      <Flex alignItems={'center'}>
        {icon && icon}
        <Text ml={1.5}>{title}</Text>
      </Flex>
      {hasBackLink && <LaunchRoundedIcon sx={{ color: 'text.secondary' }} />}
    </MenuWrapper>
  );
};

export default MenuItem;
