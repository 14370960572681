import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Text } from '../../Components/Base';
import LoLExamFooterLayout from '../../Containers/LoLExaminationRoom/LoLExamFooterLayout';
import LoLExamRoomAppBar from '../../Containers/LoLExaminationRoom/LoLExamRoomAppBar';
import { LoLResultModal } from '../../Containers/LoLResultModal';
import { LoginState, useAuthentication } from '../../Contexts/AuthenticationProvider';
import { useLoLExamination } from '../../Contexts/LoLExaminationProvider';
import { setPageTitle } from '../../Utils/page';
import useDisclosure from '../../Utils/useDisclosure';

import LogoutButton from './Containers/LogoutButton';
import MilestoneActionButton from './Containers/MilestoneActionButton';
import MilestoneGroup from './Containers/MilestoneGroup';

const LoLMilestones = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginState } = useAuthentication();
  const { t } = useTranslation('lolMilestones');
  const {
    milestones,
    isExamCompleted,
    isUnlockedExam,
    isTrialFinished,
    handleValidateRedeemCodeModal,
    latestExamResult = [],
  } = useLoLExamination();

  const {
    isOpen: isOpenResultModal,
    open: openResultModal,
    close: closeResultModal,
  } = useDisclosure();

  const goToInstruction = () => {
    const parts = location.pathname.split('/').filter(Boolean); // Remove empty segments after the last path
    parts[parts.length - 1] = 'instruction';
    const instructionPath = '/' + parts.join('/');
    navigate(instructionPath);
  };

  const handleGoToInstruction = async () => {
    if (!isTrialFinished || isUnlockedExam) {
      goToInstruction();
    } else if (isUnlockedExam === false) {
      await handleValidateRedeemCodeModal();
    }
  };
  useEffect(() => {
    const shouldOpenResultModal = localStorage.getItem('shouldOpenResultModal') ?? 'false';
    if (shouldOpenResultModal === 'true' && latestExamResult.length > 0) {
      localStorage.removeItem('shouldOpenResultModal');
      openResultModal();
    }
  }, [openResultModal, latestExamResult]);

  return (
    <Box overflow={'auto'}>
      <Helmet>
        <title>{setPageTitle('LoLxExamHub Examination Room | Milestones')}</title>
      </Helmet>
      <LoLExamRoomAppBar
        title={t('TITLE')}
        rightAdornment={loginState === LoginState.LOGGED_IN ? <LogoutButton /> : <></>}
      />
      <Box pt={3} pb={isExamCompleted ? 10 : 20} px={3} overflow={'auto'} color={'lolText.primary'}>
        <Text variant="h2">{t('DETAILS.MAIN')}</Text>
        <Text variant="caption" color={'lolText.secondary'} component={'div'} mt={1}>
          {t('DETAILS.SUB')}
        </Text>
        <MilestoneGroup
          milestones={milestones}
          isExamCompleted={isExamCompleted}
          handleGoToInstruction={handleGoToInstruction}
        />
      </Box>
      <LoLExamFooterLayout>
        <MilestoneActionButton goToInstruction={handleGoToInstruction} />
      </LoLExamFooterLayout>
      <LoLResultModal
        isOpen={isOpenResultModal}
        handleClose={closeResultModal}
        displayData={latestExamResult}
        isExamCompleted={isExamCompleted}
      />
    </Box>
  );
};

export default LoLMilestones;
