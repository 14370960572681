import { QuestionFieldType } from '../../../../Domains/useGetExaminationDetails';

type AnswerMultipleChoicesProps = {
  answerableCount: number;
  choicesCount: number;
};

export const getAnswerableMultipleChoice = (
  questionType: string
): AnswerMultipleChoicesProps | undefined => {
  if (questionType === QuestionFieldType.MultipleChoice) {
    const multipleChoice = questionType.slice(3);
    const answers = multipleChoice.split('/');
    return { answerableCount: +answers?.[1], choicesCount: +answers?.[0] };
  }
  return undefined;
};

export const replaceChar = (str: string, index: number, replacement: string) => {
  if (index < 0 || index >= str.length) {
    throw new Error('Index out of range');
  }
  return str.slice(0, index) + replacement + str.slice(index + 1);
};

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
