import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import ExaminationTitle from '../../../Components/ExaminationTitle';
import useGetPublicBookDetails from '../../../Domains/useGetPublicBookDetails';
import { setPageTitle } from '../../../Utils/page';

import PublicExaminationActionButton from './Containers/PublicExaminationActionButton';
import PublicInstructionDetails from './Containers/PublicInstructionDetails';

const PublicExaminationInstruction = () => {
  const { bookId = '', examId = '' } = useParams();
  //Reference from: web/exam-hub/src/Routes/ExaminationInstruction/PrivateExaminationInstruction.tsx

  const { data: bookData, loading: instructionLoading } = useGetPublicBookDetails({
    variables: { bookId },
    fetchPolicy: 'network-only',
  });

  const examDetail = bookData?.qmsGetBookDetailsByBookId.exams?.find((exam) => exam.id === examId);

  return (
    <>
      <Helmet>
        <title>{setPageTitle('Examination Instruction')}</title>
      </Helmet>
      <ExaminationTitle
        title={examDetail?.title ?? ''}
        sx={{ mb: { xs: 1, md: 2 } }}
        containerSx={{
          px: { xs: 2, sm: 3 },
        }}
      >
        {instructionLoading ? <Skeleton width={250} height={27} /> : null}
      </ExaminationTitle>
      <PublicInstructionDetails />
      {instructionLoading ? (
        <Box px={{ xs: 2, md: 0 }}>
          <Skeleton
            width={'100%'}
            height={48}
            sx={{ borderRadius: 48, mx: 'auto', maxWidth: { xs: 1, sm: '343px' } }}
          />
        </Box>
      ) : (
        <PublicExaminationActionButton />
      )}
    </>
  );
};

export default PublicExaminationInstruction;
