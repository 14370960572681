import { styled } from '@mui/material';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  Box,
  Flex,
  Text,
} from '../../../../Components/Base';
import ExamIcon from '../../../../Components/Icons/ExamIcon';

const TitleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type ExaminationResultItemProps = {
  title: string;
} & AccordionProps;

const ExaminationResultItem = ({ title, children, ...restProps }: ExaminationResultItemProps) => {
  return (
    <Accordion disableGutters {...restProps}>
      <AccordionSummary>
        <Flex alignItems={'center'}>
          <Flex p={1} bgcolor={'primary.light'} borderRadius={1} mr={2}>
            <ExamIcon color={'primary'} />
          </Flex>
          <Box width={'100%'}>
            <TitleText variant="subtitle1">{title}</TitleText>
          </Box>
        </Flex>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default ExaminationResultItem;
