import { styled } from '@mui/material';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Flex } from '../../Components/Base';

const StyledMobileLayout = styled(Flex)`
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FixedMobileLayout = ({ children }: { children?: React.ReactNode }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);
  return (
    <StyledMobileLayout
      flexDirection={'column'}
      component="main"
      width={['100%', 'min(360px,100dvw)', 'min(360px,100dvw)']}
      height={['100%', 'min(680px,100dvh)', 'min(680px,100dvh)']}
      overflow={'auto'}
    >
      {children ?? <Outlet />}
    </StyledMobileLayout>
  );
};

export default FixedMobileLayout;
