import { SvgIcon, SvgIconProps } from '@mui/material';

const EnterprisingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M12.6773 5.50458C12.6773 5.02237 12.8688 4.55991 13.2098 4.21893C13.5508 3.87796 14.0132 3.6864 14.4955 3.6864C14.9777 3.6864 15.4401 3.87796 15.7811 4.21893C16.1221 4.55991 16.3136 5.02237 16.3136 5.50458C16.3136 5.9868 16.1221 6.44926 15.7811 6.79023C15.4401 7.13121 14.9777 7.32277 14.4955 7.32277C14.0132 7.32277 13.5508 7.13121 13.2098 6.79023C12.8688 6.44926 12.6773 5.9868 12.6773 5.50458ZM14.4955 12.6291C14.0132 12.6291 13.5508 12.8207 13.2098 13.1617C12.8688 13.5026 12.6773 13.9651 12.6773 14.4473C12.6773 14.9295 12.8688 15.392 13.2098 15.733C13.5508 16.0739 14.0132 16.2655 14.4955 16.2655C14.9777 16.2655 15.4401 16.0739 15.7811 15.733C16.1221 15.392 16.3136 14.9295 16.3136 14.4473C16.3136 13.9651 16.1221 13.5026 15.7811 13.1617C15.4401 12.8207 14.9777 12.6291 14.4955 12.6291ZM3.68637 14.4473C3.68637 13.9651 3.87793 13.5026 4.2189 13.1617C4.55988 12.8207 5.02234 12.6291 5.50455 12.6291C5.98676 12.6291 6.44923 12.8207 6.7902 13.1617C7.13118 13.5026 7.32274 13.9651 7.32274 14.4473C7.32274 14.9295 7.13118 15.392 6.7902 15.733C6.44923 16.0739 5.98676 16.2655 5.50455 16.2655C5.02234 16.2655 4.55988 16.0739 4.2189 15.733C3.87793 15.392 3.68637 14.9295 3.68637 14.4473ZM5.50455 3.6864C5.02234 3.6864 4.55988 3.87796 4.2189 4.21893C3.87793 4.55991 3.68637 5.02237 3.68637 5.50458C3.68637 5.9868 3.87793 6.44926 4.2189 6.79023C4.55988 7.13121 5.02234 7.32277 5.50455 7.32277C5.98676 7.32277 6.44923 7.13121 6.7902 6.79023C7.13118 6.44926 7.32274 5.9868 7.32274 5.50458C7.32274 5.02237 7.13118 4.55991 6.7902 4.21893C6.44923 3.87796 5.98676 3.6864 5.50455 3.6864ZM8.18183 10C8.18183 9.51783 8.37338 9.05537 8.71436 8.71439C9.05533 8.37341 9.5178 8.18186 10 8.18186C10.4822 8.18186 10.9447 8.37341 11.2857 8.71439C11.6266 9.05537 11.8182 9.51783 11.8182 10C11.8182 10.4822 11.6266 10.9447 11.2857 11.2857C10.9447 11.6267 10.4822 11.8182 10 11.8182C9.5178 11.8182 9.05533 11.6267 8.71436 11.2857C8.37338 10.9447 8.18183 10.4822 8.18183 10Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2.72727C0 2.00396 0.287337 1.31026 0.7988 0.7988C1.31026 0.287337 2.00396 0 2.72727 0H17.2727C17.996 0 18.6897 0.287337 19.2012 0.7988C19.7127 1.31026 20 2.00396 20 2.72727V17.2727C20 17.996 19.7127 18.6897 19.2012 19.2012C18.6897 19.7127 17.996 20 17.2727 20H2.72727C2.00396 20 1.31026 19.7127 0.7988 19.2012C0.287337 18.6897 0 17.996 0 17.2727V2.72727ZM2.72727 1.81818H17.2727C17.5138 1.81818 17.7451 1.91396 17.9156 2.08445C18.086 2.25494 18.1818 2.48617 18.1818 2.72727V17.2727C18.1818 17.5138 18.086 17.7451 17.9156 17.9156C17.7451 18.086 17.5138 18.1818 17.2727 18.1818H2.72727C2.48617 18.1818 2.25494 18.086 2.08445 17.9156C1.91396 17.7451 1.81818 17.5138 1.81818 17.2727V2.72727C1.81818 2.48617 1.91396 2.25494 2.08445 2.08445C2.25494 1.91396 2.48617 1.81818 2.72727 1.81818Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default EnterprisingIcon;
