import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import QuestionDetail from '../../../../Components/QuestionDetail';
import useGetExaminationSolutionDetail, {
  QmsExamItemType,
} from '../../../../Domains/useGetExaminationSolutionDetail';
import { setPageTitle } from '../../../../Utils/page';
import { renderCorrectAnswer } from '../../../../Domains/useGetExaminationDetails/utils';

import SolutionSummaryLoading from './SolutionSummaryLoading';

const formatSolutionDetails = (examItem: QmsExamItemType) => {
  const { exam, question, order } = examItem;
  const correctAnswer = question?.correctAnswer?.value;

  return {
    examTitle: exam?.title ?? '',
    questionNo: order,
    partTitle: question?.tags.find((tag) => tag.category === 'SUBJECT')?.name ?? '',
    section: question?.tags.find((tag) => tag.category === 'SUBSECTION')?.name ?? '',
    difficulty: question?.difficulty,
    correctAnswer: renderCorrectAnswer(question?.type, correctAnswer),
    type: question?.type,
  };
};

const SolutionSummary = () => {
  const { examItemId = '' } = useParams();
  const { data, loading } = useGetExaminationSolutionDetail({ variables: { id: examItemId } });
  const examItem = data?.qmsExamItemById;

  if (loading) return <SolutionSummaryLoading />;

  const solutionDetails = formatSolutionDetails(examItem as QmsExamItemType);

  return (
    <>
      <Helmet>
        <title>
          {setPageTitle(
            `เฉลยข้อสอบ | ${solutionDetails.examTitle} | ข้อที่ ${solutionDetails.questionNo} `
          )}
        </title>
      </Helmet>
      <QuestionDetail {...solutionDetails} variant="EXAMINATION_SOLUTION" />
    </>
  );
};

export default SolutionSummary;
