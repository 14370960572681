import { Button, Flex, Modal, ModalTitle, ModalContent, Text, ModalProps } from '../Base';
import CheckCircleOutlineRoundedIcon from '../Icons/CheckCircleOutlineRoundedIcon';

export type SubmitExaminationSuccessModalProps = ModalProps & {
  onSeeExaminationSolution: () => void;
  onClose: () => void;
};

const SubmitExaminationSuccessModal = ({
  onSeeExaminationSolution,
  onClose,
  ...restProps
}: SubmitExaminationSuccessModalProps) => {
  return (
    <Modal fullScreen {...restProps}>
      <ModalTitle onClose={onClose} />
      <ModalContent>
        <Flex
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          maxWidth={350}
          height={{ xs: '100%', sm: 'auto' }}
          mt={{ sm: 13 }}
          mx={'auto'}
        >
          <CheckCircleOutlineRoundedIcon color={'success'} sx={{ fontSize: '100px' }} />
          <Text sx={{ typography: { xs: 'h3', md: 'h2' } }} mt={{ xs: 3, sm: 4 }} mb={1}>
            ส่งข้อสอบเรียบร้อยแล้ว
          </Text>
          <Text mb={3}>กดที่ปุ่มด้านล่าง เพื่อดูผลสอบ</Text>
          <Button fullWidth size={'large'} onClick={onSeeExaminationSolution}>
            <Text>ดูผลสอบ</Text>
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SubmitExaminationSuccessModal;
