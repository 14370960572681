import { Lol_Exam_Result_Type } from '../../generated/graphql';

export const getLoLResultCategory = (type?: Lol_Exam_Result_Type) => {
  switch (type) {
    case Lol_Exam_Result_Type.FirstRiasec:
    case Lol_Exam_Result_Type.SecondRiasec:
    case Lol_Exam_Result_Type.FirstValue:
    case Lol_Exam_Result_Type.SecondValue:
    case Lol_Exam_Result_Type.ThirdValue:
    case Lol_Exam_Result_Type.FirstStrength:
    case Lol_Exam_Result_Type.SecondStrength:
    case Lol_Exam_Result_Type.ThirdStrength:
      return 'core-trait';
    case Lol_Exam_Result_Type.Character:
      return 'character';
    case Lol_Exam_Result_Type.IdentityCareer:
    case Lol_Exam_Result_Type.PotentialIdentityCareer:
    case Lol_Exam_Result_Type.PersonalityCareer:
    case Lol_Exam_Result_Type.PreferencesCareer:
    case Lol_Exam_Result_Type.CapableCareer:
      return 'career';
    case Lol_Exam_Result_Type.CareerGroup:
    default:
      return undefined;
  }
};

export const getRoundedImageBoolean = (type?: Lol_Exam_Result_Type) => {
  switch (type) {
    case Lol_Exam_Result_Type.FirstRiasec:
    case Lol_Exam_Result_Type.SecondRiasec:
    case Lol_Exam_Result_Type.FirstValue:
    case Lol_Exam_Result_Type.SecondValue:
    case Lol_Exam_Result_Type.ThirdValue:
    case Lol_Exam_Result_Type.Character:
    case Lol_Exam_Result_Type.FirstStrength:
    case Lol_Exam_Result_Type.SecondStrength:
    case Lol_Exam_Result_Type.ThirdStrength:
      return true;
    case Lol_Exam_Result_Type.IdentityCareer:
    case Lol_Exam_Result_Type.PotentialIdentityCareer:
    case Lol_Exam_Result_Type.PersonalityCareer:
    case Lol_Exam_Result_Type.PreferencesCareer:
    case Lol_Exam_Result_Type.CapableCareer:
    case Lol_Exam_Result_Type.CareerGroup:
    default:
      return false;
  }
};

export const breakDescription = (description: string, type?: Lol_Exam_Result_Type) => {
  const tagToBreak =
    type === Lol_Exam_Result_Type.FirstRiasec ||
    type === Lol_Exam_Result_Type.SecondRiasec ||
    type === Lol_Exam_Result_Type.FirstValue ||
    type === Lol_Exam_Result_Type.SecondValue ||
    type === Lol_Exam_Result_Type.ThirdValue ||
    type === Lol_Exam_Result_Type.Character
      ? '</p>'
      : '</div>';

  const splitDescription = description.split(tagToBreak);

  if (type === Lol_Exam_Result_Type.CareerGroup) {
    return [
      splitDescription.slice(0, 2).join(tagToBreak),
      splitDescription.slice(2).join(tagToBreak),
    ];
  }

  return [
    splitDescription.slice(0, 1).join(tagToBreak),
    splitDescription.slice(1).join(tagToBreak),
  ];
};
