import {
  Box,
  Checkbox,
  FormControlLabel,
  Divider as MUIDivider,
  Link as MUILink,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Image, Text } from '../../Components/Base';
import LoLButton from '../../Components/Base/LoLButton';
import LoLBackground from '../../Components/LoLBackground';
import LoLSponsors from '../../Containers/LoLSponsors/LoLSponsors';
import { LoginState, useAuthentication } from '../../Contexts/AuthenticationProvider';
import { useConfig } from '../../Contexts/ConfigProvider';
import { useGetQmsMyBookMetadataQuery as useGetQmsMyBookMetadata } from '../../generated/graphql';

const Divider = styled(MUIDivider)(({ theme }) => ({
  color: theme.palette.lolText?.secondary,
  marginBottom: theme.spacing(2),
  width: '100%',
  '&::before, &::after': {
    borderTopStyle: 'dashed',
    borderColor: theme.palette.lolLine?.line1,
  },
}));

const linkStyle = {
  padding: 0,
  fontSize: '12px',
  textDecoration: 'underline',
  marginBottom: '2px',
};

const Link = styled(LoLButton)(({ theme }) => ({
  color: theme.palette.lolText?.primary,
  textDecorationColor: theme.palette.lolText?.primary,
  ...linkStyle,
}));

const TermsLink = styled(MUILink)(({ theme }) => ({
  color: theme.palette.lolText?.primary,
  textDecorationColor: theme.palette.lolText?.primary,
  ...linkStyle,
}));

const LoLWelcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginState } = useAuthentication();
  const { t } = useTranslation('lolCommon');
  const { bookId = '' } = useParams();
  const { lol = {} } = useConfig();
  const { imgPrefixPath = '', providerLogo = {}, termsAndConditionsUrlByProvider = {} } = lol;
  const [agreeTerms, setAgreeTerms] = useState(false);

  const { data } = useGetQmsMyBookMetadata({ variables: { bookId } });

  const handleChangeAgreeTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeTerms(event.target.checked);
  };

  const handleGoToExamList = () => {
    navigate('examinations');
  };

  const handleGoToSignIn = () => {
    const redirectPath = location.pathname.replace(/\/+$/, '').concat('/examinations');
    navigate('sign-in', { state: { redirectPath } });
  };

  const provider = data?.qmsGetBookDetailsByBookId.metadata?.provider;

  const { termUrl, conditionUrl } = termsAndConditionsUrlByProvider.hasOwnProperty(provider)
    ? termsAndConditionsUrlByProvider[provider]
    : termsAndConditionsUrlByProvider['DEFAULT'];

  const disabled = !agreeTerms && termUrl && conditionUrl;

  const hasLogoOfProvider = providerLogo.hasOwnProperty(provider) ?? false;

  const displayLogo = hasLogoOfProvider
    ? lol[providerLogo[provider]]
    : lol[providerLogo['DEFAULT']];

  return (
    <LoLBackground>
      <Box width={1} textAlign={'center'}>
        {displayLogo && (
          <Image
            src={`${imgPrefixPath}/${displayLogo}`}
            alt={`${provider}-logo`}
            sx={{
              aspectRatio: '1/1 auto',
              maxWidth: '115px',
              height: 'auto',
              display: 'block',
              mx: 'auto',
            }}
          />
        )}
        <Image
          src={'/assets/lol/thinking-human.svg'}
          width={'100%'}
          height={'100%'}
          maxWidth="340px"
          maxHeight="250px"
          sx={{ mt: 4, mb: 1 }}
        />
        <Text variant="h1" color={'lolText.primary'}>
          {t('WELCOME.TITLE')}
        </Text>
        <Text variant="h2">
          {t('WELCOME.SUB_TITLE', {
            provider: provider ? `${t('WELCOME.WITH_PROVIDER')} ${provider}` : '',
          })}
        </Text>
        <Text variant="caption" whiteSpace={'pre-line'} component={'div'} mt={1}>
          {t('WELCOME.DESCRIPTION')}
        </Text>
        <Box mt={2} textAlign={'left'}>
          {termUrl && conditionUrl && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeTerms}
                  onChange={handleChangeAgreeTerms}
                  sx={{
                    color: (theme) => theme.palette.lolLine?.line2,
                    '&.Mui-checked': {
                      color: (theme) => theme.palette.lolLine?.line2,
                    },
                  }}
                />
              }
              label={
                <Text variant={'caption'}>
                  {t('WELCOME.TERMS_CONDITIONS.AGREE')}{' '}
                  <TermsLink target="_blank" href={termUrl}>
                    {t('WELCOME.TERMS_CONDITIONS.TERMS')}
                  </TermsLink>{' '}
                  {t('WELCOME.TERMS_CONDITIONS.AND')}{' '}
                  <TermsLink target="_blank" href={conditionUrl}>
                    {t('WELCOME.TERMS_CONDITIONS.CONDITIONS')}
                  </TermsLink>
                </Text>
              }
            />
          )}
          <LoLButton
            fullWidth
            size="large"
            onClick={handleGoToExamList}
            disabled={disabled}
            sx={{
              mt: 1,
            }}
          >
            {t('WELCOME.NEXT')}
          </LoLButton>
        </Box>
        {loginState === LoginState.NOT_LOGGED_IN && (
          <Box width={1} mt={2}>
            <Divider aria-orientation="horizontal" aria-hidden="true" role="presentation">
              <Text variant="subtitle2" fontWeight={600}>
                {t('WELCOME.LOGIN.OR')}
              </Text>
            </Divider>
            <Box component={'span'} fontSize={12} color={'lolText.secondary'}>
              {t('WELCOME.LOGIN.CLICK')}{' '}
              <Link variant="text" onClick={handleGoToSignIn} disabled={disabled}>
                {t('WELCOME.LOGIN.FOR_LOGIN')}
              </Link>
            </Box>
          </Box>
        )}
        <LoLSponsors mt={2} />
      </Box>
    </LoLBackground>
  );
};

export default LoLWelcome;
