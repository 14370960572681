import { useNavigate, useParams } from 'react-router-dom';

import { Button, ButtonProps } from '../../../../Components/Base';
import ArrowForwardIcon from '../../../../Components/Icons/ArrowForwardIcon';
import Loading from '../../../../Components/Loading';
import useRedeemPublicBook from '../../../../Domains/useRedeemPublicBook';
import useStartExamination from '../../../../Domains/useStartExamination';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

type StartPublicExaminationButtonProps = ButtonProps;

const StartPublicExaminationButton = (props: StartPublicExaminationButtonProps) => {
  const { bookId = '', examId = '' } = useParams();
  const navigate = useNavigate();
  const sendDataToGTM = useGoogleTagManager();

  const [redeemPublicBook, { loading: redeemBookLoading }] = useRedeemPublicBook();
  const [startExamination, { loading: startExaminationLoading }] = useStartExamination();

  const handleEnterExamRoom = async () => {
    let examCardId = null;
    try {
      const result = await redeemPublicBook({
        variables: { bookId },
      });
      examCardId =
        result.data?.qmsRedeemPublicBook?.find((exam) => exam.skuExamItem?.examId == examId)?.id ??
        null;
    } catch (error) {
      console.error('Start examination error: ', error);
    }

    if (examCardId === null || examCardId === undefined) return;

    sendDataToGTM({
      event: 'public_examination_instruction_cta_click',
      buttonCategory: 'start examination',
    });

    try {
      await startExamination({
        variables: { examCardId, isSetTimer: false },
      });
      const path = `/publicbooks/${bookId}/examinations/${examId}/examroom`;
      navigate(path, { replace: true });
    } catch (error) {
      console.error('Start examination error: ', error);
    }
  };

  const isLoading = startExaminationLoading || redeemBookLoading;

  return (
    <>
      <Button
        sx={{ maxWidth: { xs: 1, sm: '343px' }, width: 1 }}
        size={'large'}
        onClick={handleEnterExamRoom}
        disabled={isLoading}
        {...props}
      >
        เริ่มทำข้อสอบ
        <ArrowForwardIcon sx={{ ml: 0.5 }} />
      </Button>
      <Loading open={isLoading} />
    </>
  );
};

export default StartPublicExaminationButton;
