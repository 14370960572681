import { createTheme, experimental_sx as sx } from '@mui/material/styles';
import { ComponentsPropsList } from '@mui/material';

import paletteTheme from '../Theme/paletteTheme';

const alertTheme = createTheme({
  components: {
    MuiAlert: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        standard: ({ ownerState }: { ownerState: ComponentsPropsList['MuiAlert'] }) => {
          const color = ownerState?.color as ComponentsPropsList['MuiAlert']['color'];
          if (color) {
            return {
              border: '1px solid',
              borderColor: paletteTheme.palette[color].main,
              background: paletteTheme.palette[color].lighter,
            };
          }
          return;
        },
        message: ({ ownerState }: { ownerState: ComponentsPropsList['MuiAlert'] }) => {
          const color = ownerState?.color as ComponentsPropsList['MuiAlert']['color'];
          if (color) {
            return {
              color: paletteTheme.palette[color].dark,
              fontSize: 14,
              alignSelf: 'center',
            };
          }
        },
        icon: ({ ownerState }: { ownerState: ComponentsPropsList['MuiAlert'] }) => {
          const color = ownerState?.color as ComponentsPropsList['MuiAlert']['color'];
          const icon = ownerState?.icon as ComponentsPropsList['MuiAlert']['icon'];
          if (icon && color) {
            return sx({
              p: 1,
              background: paletteTheme.palette[color].light,
              justifyContent: 'center',
              alignSelf: 'center',
              borderRadius: 1,
              '& svg': {
                color: paletteTheme.palette[color].main,
                fontSize: 28,
              },
            });
          }
          return;
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: sx({
          fontWeight: 700,
          fontSize: 16,
          mb: 0,
        }),
      },
    },
  },
});

export default alertTheme;
