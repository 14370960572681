import { Box, styled } from '@mui/material';

const getBackground = ({ fill = 'currentColor' }: { fill?: string }) =>
  `data:image/svg+xml;utf8,<svg width="640" height="900" viewBox="0 0 640 900" fill="${fill}" xmlns="http://www.w3.org/2000/svg"><path d="M0 211.175V900H640V219.675C640 177.675 613 119.675 566.5 71.6751C520 23.6751 449.5 -4.82483 389.5 0.675154C266.018 11.9943 221.5 131.175 208.5 159.175C190.161 198.675 149 222.675 100 227.175C59.1718 230.925 39 227.175 0 211.175Z" fill="${fill}"/></svg>`;

const LoLBackground = styled(Box)(({ theme }) => ({
  color: theme.palette.lolText?.primary,
  textAlign: 'center',
  backgroundImage: `url('${getBackground({ fill: theme.palette.lolBackground?.primary })}')`,
  backgroundColor: theme.palette.lolBackground?.secondary,
  backgroundPosition: '0px 100px',
  backgroundRepeat: 'no-repeat',
  padding: '32px 24px',
  position: 'absolute',
  width: '100%',
  height: 'auto',
  minHeight: '100%',
}));

export default LoLBackground;
