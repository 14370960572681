import { useEffect, useState } from 'react';

const getMinWidth = () => {
  const windowInnerWidth = window.innerWidth;
  const clientWidth = document.body.clientWidth;
  return Math.min(windowInnerWidth, clientWidth);
};

const getMinHeight = () => {
  const windowInnerHeight = window.innerHeight;
  const clientHeight = document.body.clientHeight;
  return Math.min(windowInnerHeight, clientHeight);
};

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: getMinWidth(),
    height: getMinHeight(),
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({
        width: getMinWidth(),
        height: getMinHeight(),
      });
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return { screenSize, setScreenSize };
};

export default useScreenSize;
