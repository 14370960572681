import { Box, Divider } from '@mui/material';
import { useMemo } from 'react';

import { Splitter, RESIZER_GAP, RESIZER_SIZE } from '../../../../Components/Base/Splitter';
import { Flex, Text, Paper } from '../../../../Components/Base';
import { QuestionType } from '../../../../Domains/useGetExaminationDetails';
import { QbQuestionType } from '../../../../generated/graphql';
import ExamArticle from '../../Components/ExamArticle';
import ExamRoomHeader from '../../Components/ExamRoomHeader';
import ExamNavigation from '../ExamNavigation';
import FormAnswerItem from '../FormAnswerItem/FormAnswerItem';
import QuestionContent from '../QuestionContent';
import theme from '../../../../Components/Base/Theme';
import { APP_BAR_HEIGHT, FOOTER_HEIGHT } from '../../../../Components/Layout';
import { useExamination } from '../../../../Contexts/ExaminationProvider';
import { ExamQuestion } from '../../../../Contexts/types';

import {
  DIVIDER_HEIGHT,
  DIVIDER_MARGIN_Y,
  EXAM_NAVIGATION_HEIGHT,
  FOOTER_MARGIN_TOP,
  PROGRESS_BAR_HEIGHT,
  TITLE_HEIGHT,
  WRAPPER_PADDING_Y,
  splitterPropsByDeviceType,
} from './constant';
import { ExaminationCBTWrapper, SplitterWay } from './ExaminationCBT';

type ExaminationCBTwithArticleProps = {
  split: SplitterWay;
  splitterProps: splitterPropsByDeviceType;
  currentQuestion: ExamQuestion;
  examMaterialId: string;
};

const ExaminationCBTwithArticle = (props: ExaminationCBTwithArticleProps) => {
  const { split, splitterProps, currentQuestion, examMaterialId } = props;
  const { examMaterialGroups, examTitle } = useExamination();

  const decimal = useMemo(() => {
    return currentQuestion?.metadata?.presition ?? 2;
  }, [currentQuestion]);

  const questionType = useMemo(() => {
    return currentQuestion?.type;
  }, [currentQuestion]);

  const examMaterialGroup = useMemo(() => {
    return examMaterialGroups?.find((group) => group.examMaterial?.id === examMaterialId);
  }, [examMaterialId, examMaterialGroups]);

  const examMaterial = useMemo(() => examMaterialGroup?.examMaterial, [examMaterialGroup]);

  const pane1MinSize = useMemo(() => {
    switch (split) {
      case SplitterWay.Vertical:
        return splitterProps.minPaneSize + RESIZER_GAP;
      case SplitterWay.Horizontal:
      default:
        return (
          WRAPPER_PADDING_Y / 2 +
          EXAM_NAVIGATION_HEIGHT +
          DIVIDER_HEIGHT +
          DIVIDER_MARGIN_Y +
          splitterProps.minPaneSize
        );
    }
  }, [split, splitterProps]);

  const pane2MinSize = useMemo(() => {
    return splitterProps.minPaneSize + RESIZER_SIZE + RESIZER_GAP;
  }, [splitterProps]);

  const splitterHeight = useMemo(() => {
    switch (split) {
      case SplitterWay.Vertical:
        return `calc(100dvh - ${
          APP_BAR_HEIGHT +
          PROGRESS_BAR_HEIGHT +
          TITLE_HEIGHT +
          (splitterProps.wrapperMarginTop ?? 0) +
          (splitterProps.wrapperPaddingY ?? 0) * 2 +
          EXAM_NAVIGATION_HEIGHT +
          DIVIDER_HEIGHT +
          DIVIDER_MARGIN_Y +
          FOOTER_MARGIN_TOP +
          FOOTER_HEIGHT
        }px)`;
      case SplitterWay.Horizontal:
      default:
        return `calc(100dvh - ${
          APP_BAR_HEIGHT + PROGRESS_BAR_HEIGHT + FOOTER_HEIGHT + FOOTER_MARGIN_TOP
        }px)`;
    }
  }, [splitterProps, split]);

  return (
    <>
      {split === SplitterWay.Vertical ? (
        <>
          <ExamRoomHeader
            examTitle={examTitle}
            maxWidth={false}
            containerSx={{ px: { xs: 2, sm: 3, lg: 8 }, py: 2 }}
          />
          <ExaminationCBTWrapper>
            <ExamNavigation />
            <Divider sx={{ my: 2 }} />
            <Splitter
              split={SplitterWay.Vertical}
              style={{
                height: splitterHeight,
              }}
              defaultSize={splitterProps.defaultRatio}
              minSize={pane1MinSize}
              maxSize={-pane2MinSize}
            >
              <ExamArticle
                examArticleName={examMaterial?.name}
                examArticleContent={examMaterial?.content}
                groupOfQuestion={examMaterialGroup?.orderGroup}
              />
              <Flex flexDirection={'column'}>
                <QuestionContent />
                <Divider sx={{ my: 2 }} />
                {questionType === QbQuestionType.FillNumber && (
                  <Text my={2} variant={'body2'}>
                    ตอบเป็นทศนิยม {decimal} ตำแหน่ง
                  </Text>
                )}
                <FormAnswerItem question={currentQuestion as QuestionType} />
              </Flex>
            </Splitter>
          </ExaminationCBTWrapper>
        </>
      ) : (
        <Splitter
          split={SplitterWay.Horizontal}
          style={{
            height: splitterHeight,
            backgroundColor: theme.palette.common.white,
            opacity: 1,
          }}
          defaultSize={splitterProps.defaultRatio}
          minSize={pane1MinSize}
          maxSize={-pane2MinSize}
          paneStyle={{
            backgroundColor: theme.palette.common.white,
            marginBottom: splitterProps.paneMarginBottom,
          }}
          resizerStyle={{
            backgroundColor: theme.palette.grey[300],
            opacity: 1,
            marginTop: '0px',
            marginRight: `${RESIZER_GAP}px`,
            marginBottom: `${RESIZER_GAP}px`,
            marginLeft: `${RESIZER_GAP}px`,
          }}
        >
          <Flex
            flexDirection={'column'}
            sx={{
              backgroundColor: theme.palette.grey[50],
              height: 'fit-content',
            }}
          >
            <ExamRoomHeader
              examTitle={examTitle}
              maxWidth={false}
              containerSx={{ px: { xs: 2, sm: 3, lg: 8 }, py: 2 }}
              sx={{
                borderRadius: 0,
              }}
            />
            <Paper
              elevation={0}
              sx={{
                px: { xs: 2, sm: 3, lg: 8 },
                mt: { xs: 1, sm: 2, lg: 3 },
                borderRadius: 0,
                width: '100dvw',
              }}
            >
              <Box
                position={'sticky'}
                top={0}
                sx={{
                  pt: { xs: 2, lg: 3 },
                  bgcolor: 'common.white',
                }}
              >
                <ExamNavigation />
                <Divider sx={{ my: 2 }} />
              </Box>
              <ExamArticle
                examArticleName={examMaterial?.name}
                examArticleContent={examMaterial?.content}
                groupOfQuestion={examMaterialGroup?.orderGroup}
              />
            </Paper>
          </Flex>
          <Paper
            elevation={0}
            sx={{
              px: { xs: 2, sm: 3, lg: 8 },
              pb: { xs: 2, lg: 3 },
              borderRadius: 0,
              bgcolor: 'common.white',
              width: '100dvw',
              height: 'fit-content',
            }}
          >
            <Flex flexDirection={'column'}>
              <QuestionContent />
              <Divider sx={{ my: 2 }} />
              {questionType === QbQuestionType.FillNumber && (
                <Text my={2} variant={'body2'}>
                  ตอบเป็นทศนิยม {decimal} ตำแหน่ง
                </Text>
              )}
              <FormAnswerItem question={currentQuestion as QuestionType} />
            </Flex>
          </Paper>
        </Splitter>
      )}
    </>
  );
};

export default ExaminationCBTwithArticle;
