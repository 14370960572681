import { useFormContext } from 'react-hook-form';

import { Flex } from '../../../../Components/Base';
import FormAnswerMultipleChoicesItem from '../../../ExaminationRoom/Containers/AnswerSheet/AnswerSheetTypeMultipleChoice/FormAnswerMultipleChoicesItem';
import { MultipleChoiceVariant } from '../../../ExaminationRoom/Components/AnswerSheet/FormAnswerMultipleChoices/type';

interface FormAnswerLabelProps {
  subType?: string | null;
  questionNo: number;
  choices?: Array<{ label: string; value: string }>;
}

const FormAnswerLabel = ({ subType, questionNo, choices }: FormAnswerLabelProps) => {
  const { control } = useFormContext();
  return (
    <Flex
      key={`${questionNo}`}
      id={`${questionNo}`}
      alignItems={'center'}
      justifyContent={{ xs: 'center' }}
    >
      <FormAnswerMultipleChoicesItem
        subType={subType}
        control={control}
        name={`answers.${questionNo - 1}.answer.value`}
        choices={choices}
        variant={MultipleChoiceVariant.TEXT}
      />
    </Flex>
  );
};

export default FormAnswerLabel;
