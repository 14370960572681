import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  Text,
} from '../Base';
import InProgressIcon from '../Icons/InProgressIcon';

type ExamNotFoundModalProps = {
  onBack?: ButtonProps['onClick'];
} & ModalProps;

const ExamNotFoundModal = (props: ExamNotFoundModalProps) => {
  const { onBack, ...restProps } = props;
  const { t } = useTranslation();
  return (
    <Modal {...restProps}>
      <ModalContent>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <InProgressIcon color="primary" sx={{ width: '100px', height: '100px', mb: 2 }} />
          <Text variant={'h2'} whiteSpace={'pre-line'} textAlign={'center'}>
            {t('STATE.EXAM_NOT_FOUND.TITLE')}
          </Text>
          <Text mt={1} whiteSpace={'pre-line'} textAlign={'center'}>
            {t('STATE.EXAM_NOT_FOUND.DESCRIPTION')}
          </Text>
        </Flex>
      </ModalContent>
      <ModalFooter px={3} pb={3}>
        <Button size={'large'} fullWidth onClick={onBack}>
          {t('BUTTON.BACK_TO_HOME')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExamNotFoundModal;
