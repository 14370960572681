import { Fragment } from 'react';
import { Divider, styled } from '@mui/material';
import { Link as ReactLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Paper } from '../../../../Components/Base';
import ExamItem from '../../../../Components/ExamItem';
import useGetExaminations from '../../../../Domains/useGetExaminations';
import useGetLatestExaminationResult, {
  QmsExamPaperStatus,
} from '../../../../Domains/useGetLatestExaminationResult';
import DataLoadingError from '../../../../Components/DataLoadingError';
import { RESPONSE_TYPE } from '../../../../Domains/constants';
import NotFoundError from '../../../../Components/NotFoundError';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

import ExamListLoading from './ExamListLoading';

const getExamStatus = (examPaperStatus?: QmsExamPaperStatus, isTimeUp?: boolean | null) => {
  const isExamPaperTimeUp =
    (examPaperStatus === QmsExamPaperStatus.InProgress && isTimeUp) ||
    examPaperStatus === QmsExamPaperStatus.TimeUp;

  if (isExamPaperTimeUp) return QmsExamPaperStatus.TimeUp;
  if (examPaperStatus === QmsExamPaperStatus.InProgress) return QmsExamPaperStatus.InProgress;
  return undefined;
};

const Link = styled(ReactLink)(() => ({
  color: 'inherit',
  textDecoration: 'none',
}));

const ExamList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { bookId = '' } = useParams();
  const {
    data: latestExaminationResultData,
    loading: latestExaminationResultLoading,
    error: lastestExaminationResultError,
  } = useGetLatestExaminationResult({
    variables: { bookId },
  });
  const latestExaminationResult =
    latestExaminationResultData?.qmsGetBookDetailsByBookId.myLatestExamPapers;

  const {
    data: examinations,
    loading: examinationsLoading,
    error: examinationsError,
    refetch,
  } = useGetExaminations({
    variables: { bookId },
  });

  const sendDataToGTM = useGoogleTagManager();

  if (examinationsLoading || latestExaminationResultLoading) {
    return <ExamListLoading />;
  }

  if (examinationsError || lastestExaminationResultError) {
    if (
      examinationsError?.message === RESPONSE_TYPE.NETWORK_ERROR ||
      lastestExaminationResultError?.message === RESPONSE_TYPE.NETWORK_ERROR
    ) {
      return <DataLoadingError onRetry={() => refetch()} mt={9} />;
    }

    const examinationsHttpStatus =
      examinationsError?.graphQLErrors[0]?.extensions?.exception.httpStatus;
    const latestExamResultHttpStatus =
      lastestExaminationResultError?.graphQLErrors[0]?.extensions?.exception.httpStatus;

    if (
      examinationsHttpStatus === RESPONSE_TYPE.NOT_FOUND ||
      latestExamResultHttpStatus === RESPONSE_TYPE.NOT_FOUND
    ) {
      return <NotFoundError onBackToHome={() => navigate('/')} open />;
    }
  }

  const handleGoToExaminationInstruction = (examTitle: string) => {
    sendDataToGTM({
      event: 'examination_item_click',
      examTitle,
    });
  };

  const currentPath = location.pathname;
  const isPublicBook = currentPath.includes('publicbooks');

  return (
    <Paper
      sx={{
        mb: { xs: 1, sm: 2 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      {examinations.map((examCard, index) => {
        const title = examCard.skuExamItem?.exam?.title ?? '';
        const latestResult = latestExaminationResult?.find(
          (examResult) => examResult.examCardId === examCard.id
        );
        const { status, isTimeUp = false } = latestResult ?? {};

        const examId = examCard.skuExamItem?.exam?.id ?? '';
        const examCardId = examCard.id;

        const linkToExamInstruction = `${currentPath}/${isPublicBook ? examId : examCardId}`;

        return (
          <Fragment key={examCard.id}>
            {index > 0 && <Divider sx={{ mx: { xs: 2, md: 3 } }} />}
            <Link to={linkToExamInstruction}>
              <ExamItem
                px={{ xs: 2, md: 3 }}
                py={2}
                title={title}
                status={getExamStatus(status, isTimeUp)}
                onClick={() => handleGoToExaminationInstruction(title)}
              />
            </Link>
          </Fragment>
        );
      })}
    </Paper>
  );
};

export default ExamList;
