import { Flex, Box, BoxProps, Text } from '../../../../Components/Base';
import LinearProgress from '../../../../Components/Base/LinearProgress';

export type LessonScoreItemProps = {
  title: string;
  score: string | number;
  fullScore: string | number;
} & BoxProps;

const parseNumber = (input: string | number) => {
  if (typeof input === 'string') return parseInt(input);
  else return input;
};

const getLessonScoreProgress = (score: string | number, fullScore: string | number) => {
  if (fullScore === 0) {
    return 0;
  }
  return (parseNumber(score) / parseNumber(fullScore)) * 100;
};

const LessonScoreItem = ({ title, score, fullScore, ...restProps }: LessonScoreItemProps) => {
  return (
    <Box px={{ xs: 2, md: 3 }} py={2} {...restProps}>
      <Text variant="subtitle1" mb={'12px'}>
        {title}
      </Text>
      <Flex justifyContent="space-between" width="100%">
        <Text variant="subtitle2" color="text.secondary">
          คะแนนที่ได้:
        </Text>
        <Text variant="subtitle2">
          {score}/{fullScore}
        </Text>
      </Flex>
      <LinearProgress
        color="accent"
        sx={{ width: '100%', height: '8px', my: 1 }}
        value={getLessonScoreProgress(score, fullScore)}
      />
      <Flex justifyContent="space-between" width="100%">
        <Text variant="caption" color="text.secondary">
          0
        </Text>
        <Text variant="caption" color="text.secondary">
          คะแนนเต็ม {fullScore}
        </Text>
      </Flex>
    </Box>
  );
};

export default LessonScoreItem;
