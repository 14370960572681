import { createTheme } from '@mui/material/styles';

import { accordionTheme } from '../Accordion';
import { alertTheme } from '../Alert';
import { buttonTheme } from '../Button';
import { containerTheme } from '../Container';
import { dialogTheme } from '../Modal';
import { fabTheme } from '../Fab';
import { linearProgressTheme } from '../LinearProgress';
import { paperTheme } from '../Paper';
import { skeletonTheme } from '../Skeleton';
import { snackbarTheme } from '../Snackbar';
import { tabsTheme } from '../Tabs';
import { textInputTheme, switchInputTheme } from '../Input';
import { toolTipTheme } from '../Tooltip';
import { labelTheme } from '../Label';

import paletteTheme from './paletteTheme';
import svgIconTheme from './svgIconTheme';
import typographyTheme from './typographyTheme';

const originalTheme = createTheme();
originalTheme.shadows[24] = '0px 12px 24px hsla(210, 11%, 71%, 0.12)';
originalTheme.shadows[4] = '0px 2px 15px hsla(210, 11%, 71%, 0.22)';

const theme = createTheme({
  palette: paletteTheme.palette,
  typography: typographyTheme.typography,
  shape: {
    borderRadius: 8,
  },
  shadows: originalTheme.shadows,
  components: {
    ...accordionTheme.components,
    ...alertTheme.components,
    ...buttonTheme.components,
    ...containerTheme.components,
    ...dialogTheme.components,
    ...fabTheme.components,
    ...linearProgressTheme.components,
    ...paperTheme.components,
    ...skeletonTheme.components,
    ...snackbarTheme.components,
    ...svgIconTheme.components,
    ...switchInputTheme.components,
    ...tabsTheme.components,
    ...textInputTheme.components,
    ...toolTipTheme.components,
    ...labelTheme.components,
  },
});

export default theme;
