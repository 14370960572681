import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

interface GoogleTagManager {
  gtmId: string;
  auth: string;
  preview: string;
}

const useGoogleTagManager = (googleTagManagerInfo?: GoogleTagManager | GoogleTagManager[]) => {
  const sendDataToGTM = (payload: object | undefined) => {
    TagManager.dataLayer({
      dataLayer: payload,
    });
  };

  const initGTM = (gtmInfo: GoogleTagManager) => {
    TagManager.initialize({
      gtmId: gtmInfo?.gtmId,
      auth: gtmInfo?.auth,
      preview: gtmInfo?.preview,
    });
  };

  useEffect(() => {
    if (!googleTagManagerInfo) return;
    if (Array.isArray(googleTagManagerInfo)) {
      googleTagManagerInfo.forEach((gtmConfig) => initGTM(gtmConfig));
      return;
    }
    initGTM(googleTagManagerInfo);
  }, [googleTagManagerInfo]);

  return sendDataToGTM;
};

export default useGoogleTagManager;
