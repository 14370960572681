import Plyr, { PlyrProps } from 'plyr-react';
import { Box } from '@mui/material';
import 'plyr-react/plyr.css';

import { youtube_parser } from './utils';

export interface VideoPlayerProps extends Partial<PlyrProps> {
  videoUrl: string;
  autoplay?: boolean;
}

const VideoPlayer = ({ videoUrl, autoplay = false, ...props }: VideoPlayerProps) => {
  return (
    <Box
      sx={{
        '.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster': {
          display: 'block !important',
        },
      }}
    >
      <Plyr
        source={{
          type: 'video',
          sources: [
            {
              src: youtube_parser(videoUrl),
              provider: 'youtube',
            },
          ],
        }}
        autoPlay={autoplay}
        options={{
          youtube: { noCookie: true, showinfo: 1 },
        }}
        {...props}
      />
    </Box>
  );
};

export default VideoPlayer;
