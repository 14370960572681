import { Box, Skeleton } from '../../../../Components/Base';
const SolutionDetailsLoading = () => {
  return (
    <Box maxWidth={'343px'} width={'100%'} mx={'auto'}>
      <Skeleton width={'100%'} height={485} />
    </Box>
  );
};

export default SolutionDetailsLoading;
