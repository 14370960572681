import { Divider } from '@mui/material';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Paper, Text } from '../../../Components/Base';
import DataLoadingError from '../../../Components/DataLoadingError';
import { RESPONSE_TYPE } from '../../../Domains/constants';
import useGetExaminationResultByLesson from '../../../Domains/useGetExaminationResultByLesson';
import { formatLessonScore } from '../../../Domains/useGetExaminationResultByLesson/utils';
import { QbQuestionType } from '../../../generated/graphql';
import LessonScoreItem from '../Components/LessonScoreItem';
import ScoreByLessonLoading from '../Components/ScoreByLessonLoading';

const ScoreByLesson = () => {
  const { examPaperId = '' } = useParams();
  const { data, loading, error, refetch } = useGetExaminationResultByLesson({
    variables: { examPaperId },
  });
  const examPaperData = data?.qmsFoGetExamPaperById;
  const { examSubjects: examSubjectData, myExamTagResults } = examPaperData || {};
  const { examSubjects } = examSubjectData || {};

  if (loading) return <ScoreByLessonLoading />;

  if (error && error.message === RESPONSE_TYPE.NETWORK_ERROR) {
    return <DataLoadingError onRetry={() => refetch({ examPaperId })} mt={9} />;
  }

  return (
    <>
      {examSubjects?.map((subject) => {
        const { tagId: subjectTagId, title: subjectTitle, examSubjectSubsections } = subject;

        return (
          <Paper
            key={subjectTagId}
            sx={{
              mb: { xs: 1, sm: 2 },
              borderRadius: { xs: 0, sm: 1 },
            }}
          >
            <Text variant="h4" px={{ xs: 2, md: 3 }} py={2}>
              {subjectTitle}
            </Text>
            {examSubjectSubsections?.map((subsection) => {
              const { tagId: subsectionTagId, title: subsectionTitle, questions } = subsection;
              const { score = 0, fullScore = 0 } =
                myExamTagResults?.find(({ tagId }) => tagId === subsectionTagId) || {};

              //Remark: Workaroud for display score by 4 decimal points
              const connectionAnswerTypqQuestionIndex =
                questions?.findIndex(
                  ({ type }: { type: QbQuestionType }) =>
                    type === QbQuestionType.MultipleSubQuestionsConnectionAnswers
                ) ?? -1;

              const hasConnectionAnswer = connectionAnswerTypqQuestionIndex > -1;

              return (
                <Fragment key={subsectionTagId}>
                  <Box px={{ xs: 2, md: 3 }}>
                    <Divider />
                  </Box>
                  <LessonScoreItem
                    title={subsectionTitle}
                    score={formatLessonScore(score, hasConnectionAnswer ? 4 : 2, fullScore)}
                    fullScore={formatLessonScore(fullScore, hasConnectionAnswer ? 4 : 2)}
                  />
                </Fragment>
              );
            })}
          </Paper>
        );
      })}
    </>
  );
};

export default ScoreByLesson;
