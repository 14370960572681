import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import BaseLayout from '../Containers/BaseLayout';
import BaseLoLLayout from '../Containers/BaseLoLLayout';
import BookLayout from '../Containers/BookLayout';
import ConvertAnonPortal from '../Containers/ConvertAnonPortal';
import ExamResultTitleLayout from '../Containers/ExamResultTitleLayout.tsx';
import ExaminationResultLayout from '../Containers/ExaminationResultLayout';
import ExamRoomLayout from '../Containers/ExaminationRoom/ExamRoomLayout';
import LoLExamRoomLayout from '../Containers/LoLExaminationRoom/LoLExamRoomLayout';
import LoLFlowController from '../Containers/LoLFlowController';
import PageTitleLayout from '../Containers/PageTitleLayout';
import ScrollToTop from '../Containers/ScrollToTop';
import { AnonymousProvider } from '../Contexts/AnonymousProvider';
import { useConfig } from '../Contexts/ConfigProvider';

import BookExaminations from './BookExaminations';
import BookResults from './BookResults';
import BookSolutions from './BookSolutions';
import ProtectedRoute from './Containers/ProtectedRoute';
import PublicRoute from './Containers/PublicRoute';
import {
  PrivateExaminationInstruction,
  PublicExaminationInstruction,
} from './ExaminationInstruction';
import ExaminationPortal from './ExaminationPortal/ExaminationPortal';
import ExaminationReview from './ExaminationReview';
import ExaminationRoom from './ExaminationRoom';
import LoLInstruction from './LoLInstruction';
import LoLExaminationRoom from './LoLExaminationRoom';
import LoLExaminations from './LoLExaminations';
import LoLExaminationInstructions from './LoLExaminationInstructions';
import LoLFullReport from './LoLFullReport';
import { CompletedLoLMilestones, LoLMilestones } from './LoLMilestones';
import LoLSignIn from './LoLSignIn';
import LoLWelcome from './LoLWelcome';
import MyBook from './MyBook';
import MyBookDetails from './MyBookDetails';
import Notfound from './NotFound';
import Profile from './Profile';
import ResultDetails from './ResultDetails';
import ResultListByExamCardId from './ResultListByExamCardId';
import ResultOverview from './ResultOverview';
import ResultSolution from './ResultSolution';
import ResultSolutionDetails from './ResultSolutionDetails';
import SignIn from './SignIn';
import SolutionDetails from './SolutionDetails';
import SolutionList from './SolutionList';
import TestUI from './TestUI';

const AppRoute = () => {
  const { routing } = useConfig();
  const landingPath = '/mybooks';
  const navigate = useNavigate();

  if (!routing) return null;

  return (
    <Routes>
      {routing?.mybook?.enable && (
        <>
          <Route
            path={'mybooks'}
            element={
              <ProtectedRoute>
                <ConvertAnonPortal>
                  <BaseLayout />
                </ConvertAnonPortal>
              </ProtectedRoute>
            }
          >
            <Route index element={<MyBook />} />
          </Route>

          <Route
            path={'mybooks'}
            element={
              <ProtectedRoute>
                <ExaminationPortal>
                  <BookLayout />
                </ExaminationPortal>
              </ProtectedRoute>
            }
          >
            <Route path={':bookId/examinations'} element={<BookExaminations />} />
            <Route path={':bookId/results'} element={<BookResults />} />
            <Route path={':bookId/solutions'} element={<BookSolutions />} />
          </Route>

          <Route
            path={'mybooks/:bookId'}
            element={
              <ProtectedRoute>
                <MyBookDetails />
              </ProtectedRoute>
            }
          />
          <Route path={'mybooks/:bookId'} element={<ExaminationPortal />}>
            {/* Book Examination Path */}
            <Route
              path={'examinations'}
              element={
                <ProtectedRoute>
                  <PageTitleLayout title={'รายละเอียดการสอบ'} />
                </ProtectedRoute>
              }
            >
              <Route path={':examCardId'} element={<PrivateExaminationInstruction />} />
            </Route>

            {/* Examination Room */}
            <Route
              path={'examinations'}
              element={
                <ProtectedRoute>
                  <ExamRoomLayout />
                </ProtectedRoute>
              }
            >
              <Route path={':examCardId/examroom'} element={<ExaminationRoom />} />
              <Route path={':examCardId/review'} element={<ExaminationReview />} />
            </Route>

            {/* Book Results Path */}
            <Route path={'results'}>
              <Route path={':examPaperId'}>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <ResultDetails />
                    </ProtectedRoute>
                  }
                />
              </Route>

              <Route
                path={':examPaperId'}
                element={
                  <ProtectedRoute>
                    <ExaminationResultLayout />
                  </ProtectedRoute>
                }
              >
                <Route path={'overview'} element={<ResultOverview />} />
                <Route path={'solutions'} element={<ResultSolution />} />
              </Route>

              <Route path={':examPaperId'}>
                <Route
                  path={'solutions'}
                  element={
                    <ProtectedRoute>
                      <PageTitleLayout title={'เฉลยเพิ่มเติม'} />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path={'question/:examPaperItemId'}
                    element={
                      <>
                        <ScrollToTop />
                        <ResultSolutionDetails />
                      </>
                    }
                  />
                </Route>
              </Route>

              <Route
                path={'examination'}
                element={
                  <ProtectedRoute>
                    <ExamResultTitleLayout />
                  </ProtectedRoute>
                }
              >
                <Route path={':examCardId'} element={<ResultListByExamCardId />} />
              </Route>
            </Route>

            {/* Book Solutions Path */}
            <Route path={'solutions'}>
              <Route path={':examId'}>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <PageTitleLayout title={'เฉลยข้อสอบ'}>
                        <SolutionList />
                      </PageTitleLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={'question/:examItemId'}
                  element={
                    <ProtectedRoute>
                      <PageTitleLayout title={'เฉลยเพิ่มเติม'}>
                        <ScrollToTop />
                        <SolutionDetails />
                      </PageTitleLayout>
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
          </Route>

          {routing?.lolbooks?.enable && (
            <Route
              path={'mybooks/:bookId/learn-o-life'}
              element={
                <ProtectedRoute>
                  <BaseLoLLayout />
                </ProtectedRoute>
              }
            >
              <Route index element={<LoLWelcome />} />

              <Route path={'examinations'}>
                <Route index element={<LoLExaminations />} />
                <Route path={':examId'} element={<LoLInstruction />} />
              </Route>

              <Route
                path={'examinations/:examId'}
                element={
                  <LoLExamRoomLayout>
                    <LoLFlowController />
                  </LoLExamRoomLayout>
                }
              >
                <Route path={'examroom'} element={<LoLExaminationRoom />} />
                <Route path={'milestones'} element={<LoLMilestones />} />
                <Route path={'instruction'} element={<LoLExaminationInstructions />} />
              </Route>

              <Route path={'milestones/:examId'} element={<CompletedLoLMilestones />} />
              <Route path={'results/:examPaperId'} element={<LoLFullReport />} />
            </Route>
          )}
        </>
      )}

      {routing?.publicbooks?.enable && (
        <Route
          path="publicbooks"
          element={
            <AnonymousProvider>
              <PublicRoute>
                <ExaminationPortal />
              </PublicRoute>
            </AnonymousProvider>
          }
        >
          <Route path=":bookId">
            {/* Public Examination Intruction */}
            <Route
              path="examinations"
              element={<PageTitleLayout title={'รายละเอียดการสอบ'} disableAction />}
            >
              <Route path=":examId" element={<PublicExaminationInstruction />} />
            </Route>

            {/* Public Examination Room */}
            <Route path="examinations" element={<ExamRoomLayout />}>
              <Route path=":examId/examroom" element={<ExaminationRoom />} />
              <Route path={':examId/review'} element={<ExaminationReview />} />
            </Route>

            {/* Public LoL path */}
            {routing?.lolbooks?.enable && (
              <Route
                path="learn-o-life"
                element={
                  <PublicRoute>
                    <BaseLoLLayout />
                  </PublicRoute>
                }
              >
                {/* LoL Welcome */}
                <Route index element={<LoLWelcome />} />

                {/* LoL Sign-in */}
                <Route path="sign-in" element={<LoLSignIn />} />

                {/* LoL Examination Intruction */}
                <Route path="examinations">
                  <Route index element={<LoLExaminations />} />
                  <Route path=":examId" element={<LoLInstruction />} />
                </Route>

                {/* LoL Examination Room */}
                <Route
                  path="examinations/:examId"
                  element={
                    <LoLExamRoomLayout>
                      <LoLFlowController />
                    </LoLExamRoomLayout>
                  }
                >
                  <Route path="examroom" element={<LoLExaminationRoom />} />
                  <Route path="milestones" element={<LoLMilestones />} />
                  <Route path="instruction" element={<LoLExaminationInstructions />} />
                </Route>

                {/* LoL mock completed milestones */}
                <Route path="milestones/:examId" element={<CompletedLoLMilestones />} />

                {/* LoL full report outside examroom */}
                <Route path="results/:examPaperId" element={<LoLFullReport />} />
              </Route>
            )}
          </Route>
        </Route>
      )}

      {routing?.profile?.enable && (
        <Route
          path={'profile'}
          element={
            <ProtectedRoute>
              <PageTitleLayout title={'โปรไฟล์'} onBack={() => navigate('/mybooks')} />
            </ProtectedRoute>
          }
        >
          <Route index element={<Profile />} />
        </Route>
      )}

      {routing?.components?.enable && (
        <Route path="components" element={<BaseLayout />}>
          <Route index element={<TestUI />} />
        </Route>
      )}

      {routing?.login?.enable && <Route path="/signin" element={<SignIn />} />}
      <Route path={'/'} element={<Navigate to={landingPath} replace />} />
      <Route
        path="*"
        element={
          <BaseLayout>
            <Notfound />
          </BaseLayout>
        }
      />
    </Routes>
  );
};

export default AppRoute;
