import { UseControllerProps, FieldValues, FieldPath } from 'react-hook-form';

enum ChoiceType {
  NUMBER,
  DIGIT,
  ENG_ALPHABET,
}

type ConnectionAnswerType = {
  sequenceNo: number;
  choicesCount?: number;
  choiceType?: ChoiceType;
  onChange?: (answer: string) => void;
};

export { ChoiceType };
export type { FieldValues, FieldPath };
export type FormSubQuestionConnectionAnswerProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> & ConnectionAnswerType;
