import { Box, styled, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '../../../../Components/Base';
import LoLButton from '../../../../Components/Base/LoLButton';
import CheckCircleRoundedIcon from '../../../../Components/Icons/CheckCircleRoundedIcon';
import { Milestone } from '../../../../Contexts/LoLExaminationProvider/types';
import useScreenSize from '../../../../Utils/useScreenSize';
import MilestoneCard from '../../Components/MilestoneCard';
import { MilestoneCardAction } from '../../types';
import { calculateDistance } from '../utils';

const Button = styled(LoLButton)`
  min-width: 0px;
  border-radius: 8px;
`;

const renderRightButton = (
  buttonText: string,
  status?: MilestoneCardAction,
  handleClick?: () => void
) => {
  switch (status) {
    case MilestoneCardAction.COMPLETED:
      return <CheckCircleRoundedIcon sx={{ fontSize: '32px', color: 'lolButton.primary' }} />;
    case MilestoneCardAction.READY:
      return <Button onClick={handleClick}>{buttonText}</Button>;
    default:
    case MilestoneCardAction.NOT_READY:
      return (
        <Button
          disabled
          sx={{
            '&.Mui-disabled': {
              bgcolor: 'lolButton.secondary36',
              color: 'lolTextButton.contrast36',
            },
          }}
        >
          {buttonText}
        </Button>
      );
  }
};

const Circle = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  marginRight: '16px',
  background: theme.palette.lolElement?.primary,
  borderColor: theme.palette.lolLine?.line2,
}));

const Timeline = styled(Box)`
  width: 3px;
  left: 11px;
  position: absolute;
`;

type MilestoneGroupProps = {
  isExamCompleted: boolean;
  milestones?: Milestone[];
  handleGoToInstruction?: () => void;
};

const MilestoneGroup = (props: MilestoneGroupProps) => {
  const { milestones, isExamCompleted, handleGoToInstruction } = props;
  const { t } = useTranslation('lolMilestones');
  const firstCircleRef = useRef();
  const lastCircleRef = useRef();
  const theme = useTheme();

  const [nextStepIndex, setNextStepIndex] = useState<number | undefined>();
  const [progress, setProgress] = useState(0);
  const [timelinePositionTop, setTimelinePositionTop] = useState(0);
  const [timelineHeight, setTimelineHeight] = useState(0);
  const { screenSize } = useScreenSize();

  useEffect(() => {
    if (firstCircleRef.current && lastCircleRef.current) {
      const timelineHeight = calculateDistance({
        firstEl: firstCircleRef.current,
        secondEl: lastCircleRef.current,
      });

      const containerEl = document.getElementById('timeline-container');
      const containerElTop = containerEl?.getBoundingClientRect().top ?? 0;
      const firstElBottom = (firstCircleRef.current as HTMLElement).getBoundingClientRect().bottom;

      setTimelinePositionTop(firstElBottom - containerElTop);

      setTimelineHeight(timelineHeight);
    }
  }, [screenSize]);

  useEffect(() => {
    if (Array.isArray(milestones)) {
      let lastEl = document.getElementById(`circle-${milestones.length - 1}`);
      let isAllCompleted = true;
      for (let index = 0; index < milestones?.length; index++) {
        if (milestones?.[index].status === MilestoneCardAction.READY) {
          lastEl = document.getElementById(`circle-${index}`);
          isAllCompleted = false;
          setNextStepIndex(index);
          break;
        }
      }

      if (firstCircleRef.current) {
        const movingTimelineHeight = calculateDistance({
          firstEl: firstCircleRef.current,
          secondEl: lastEl as HTMLDivElement,
        });
        const progress = isAllCompleted ? timelineHeight : movingTimelineHeight;
        setProgress(progress);
      }
    }
  }, [milestones, nextStepIndex, screenSize, timelineHeight]);

  return (
    <>
      <Flex width={'100%'} justifyContent={'center'} mt={2}>
        <Box position={'relative'} id="timeline-container">
          <Timeline
            bgcolor={'lolLine.line1'}
            height={`${timelineHeight}px`}
            top={`${timelinePositionTop}px`}
            id="timeline"
          />
          <Timeline
            bgcolor={'lolButton.primary'}
            top={`${timelinePositionTop}px`}
            sx={{
              '@keyframes moveline': {
                '0%': {
                  height: '0px',
                },
                '100%': {
                  height: `${progress}px`, //TODO: Polish when change screen size
                },
              },
              animation: `moveline ${isExamCompleted ? 4 : nextStepIndex}s linear forwards`,
            }}
          />

          {Array.isArray(milestones) &&
            milestones.map((milestone, index) => {
              const careers = milestone?.careers ?? [];

              const questionCount = milestone?.totalQuestionCount;
              const duration = milestone?.duration;
              const status = milestone?.status;

              if (careers?.length === 0) {
                return <></>;
              }

              let content = <></>;

              if (milestone?.careers?.length > 1) {
                content = (
                  <ul style={{ margin: 0, paddingLeft: 30 }}>
                    {milestone?.careers?.map((title: string, index) => {
                      return (
                        <li key={`career-${index}`}>
                          <Text variant="subtitle1" fontWeight={600}>
                            {title}
                          </Text>
                        </li>
                      );
                    })}
                  </ul>
                );
              } else if (milestone?.careers?.length === 1) {
                content = (
                  <Text variant="subtitle1" fontWeight={600}>
                    {milestone?.careers?.[0]}
                  </Text>
                );
              }

              const isPassed = (nextStepIndex && index < nextStepIndex) || isExamCompleted;

              return (
                <Flex
                  alignItems={'center'}
                  position={'relative'}
                  mx={'auto'}
                  mt={index > 0 ? 3 : 0}
                  key={`milestone-${index}`}
                >
                  <Circle
                    border={index === nextStepIndex ? '2px solid' : 'none'}
                    sx={{
                      background: `${
                        isPassed ? theme.palette.lolButton?.primary : theme.palette.lolLine?.line1
                      }`,
                      transition: `${isPassed && `background 1s ${index * 0.8}s ease`}`,
                    }}
                    id={`circle-${index}`}
                    ref={
                      index === 0
                        ? firstCircleRef
                        : index === milestones.length - 1
                        ? lastCircleRef
                        : null
                    }
                  />
                  <Flex
                    flex={1}
                    id={`timeline-card-${index}`}
                    className="timeline-card"
                    sx={{
                      opacity: 0,
                      '@keyframes fadeIn': {
                        '0%': {
                          opacity: 0,
                        },
                        '100%': {
                          opacity: 1,
                        },
                      },
                      animation: `fadeIn 1s linear ${index}s forwards`,
                    }}
                  >
                    <MilestoneCard
                      content={content}
                      duration={duration}
                      questionCount={questionCount}
                      rightAdorment={renderRightButton(
                        t('GO_BUTTON'),
                        status,
                        handleGoToInstruction
                      )}
                    />
                  </Flex>
                </Flex>
              );
            })}
        </Box>
      </Flex>
    </>
  );
};

export default MilestoneGroup;
