import { useFormContext } from 'react-hook-form';

import { Box, Flex, Text } from '../../../../Components/Base';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import FormBookmarkInput from '../../../../Components/FormBookmarkInput/FormBookmarkInput';
import FormAnswerFieldNumber from '../../Components/AnswerSheet/FormAnswerFieldNumber';

interface FormAnswerFillNumberProps {
  questionNo: number;
  decimal?: number;
}

const FormAnswerFillNumber = ({ decimal = 2, questionNo }: FormAnswerFillNumberProps) => {
  const { control } = useFormContext();
  return (
    <Flex
      key={`${questionNo}`}
      id={`${questionNo}`}
      py={{ xs: 0.5, md: 1 }}
      pl={{ xs: 0.5, md: 1 }}
      pr={1.5}
      alignItems={'center'}
      justifyContent={'flex-start'}
    >
      <FormTextInput name={`answers.${questionNo - 1}.questionId`} sx={{ display: 'none' }} />
      <FormTextInput name={`answers.${questionNo - 1}.examPaperItemId`} sx={{ display: 'none' }} />
      <FormBookmarkInput name={`answers.${questionNo - 1}.isBookmark`} control={control} />
      <Text
        width={32}
        mr={1}
        sx={{ typography: { xs: 'subtitle1', md: 'subtitle2' } }}
        textAlign={'center'}
      >
        {questionNo}
      </Text>
      <FormAnswerFieldNumber
        control={control}
        name={`answers.${questionNo - 1}.answer.value`}
        demical={decimal}
        sx={{ maxWidth: { xs: 1, sm: '340px' } }}
      />
    </Flex>
  );
};

export default FormAnswerFillNumber;
