import { useNavigate, useParams } from 'react-router-dom';

import { Box, Text, Paper } from '../../Components/Base';
import useGetExaminationSolutionDetail from '../../Domains/useGetExaminationSolutionDetail';
import { RESPONSE_TYPE } from '../../Domains/constants';
import DataLoadingError from '../../Components/DataLoadingError';
import NotFoundError from '../../Components/NotFoundError';

import SolutionSummary from './Containers/SolutionSummary';
import ExaminationSolutionDetails from './Containers/ExaminationSolutionDetails';

const SolutionDetails = () => {
  const { examItemId = '' } = useParams();
  const { error, refetch } = useGetExaminationSolutionDetail({
    variables: { id: examItemId },
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();
  if (error) {
    const examSolutionErrorHttpStatus = error?.graphQLErrors[0]?.extensions?.exception?.httpStatus;
    if (error.message === RESPONSE_TYPE.NETWORK_ERROR) {
      return <DataLoadingError mt={16} onRetry={() => refetch()} />;
    }
    if (examSolutionErrorHttpStatus === RESPONSE_TYPE.NOT_FOUND) {
      return <NotFoundError open={true} onBackToHome={() => navigate('/')} />;
    }
  }
  return (
    <Box>
      <SolutionSummary />
      <Paper
        sx={{
          mb: { xs: 1, sm: 2 },
          p: { xs: 2, md: 3 },
          borderRadius: { xs: 0, sm: 1 },
        }}
      >
        <Text mb={2} variant="subtitle1">
          เฉลยละเอียด
        </Text>
        <ExaminationSolutionDetails />
      </Paper>
    </Box>
  );
};

export default SolutionDetails;
