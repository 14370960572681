import { Helmet } from 'react-helmet';

import { Flex, Text } from '../../Components/Base';
import { setPageTitle } from '../../Utils/page';

import MyBookList from './Containers/MyBookList';
import RedeemBookButton from './Containers/RedeemBookButton';

const MyBook = () => {
  return (
    <>
      <Helmet>
        <title>{setPageTitle('หนังสือของฉัน')}</title>
      </Helmet>
      <Flex
        justifyContent={'center'}
        flexDirection={'column'}
        width={'100%'}
        alignSelf={'center'}
        px={{ xs: 2, sm: 0 }}
        pb={7}
      >
        <Flex alignItems={'center'} justifyContent={'space-between'} mb={2}>
          <Text variant={'h2'} mr={2}>
            หนังสือของฉัน
          </Text>
          <RedeemBookButton />
        </Flex>
        <MyBookList />
      </Flex>
    </>
  );
};

export default MyBook;
