import { styled, Toolbar } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import { Flex, Box } from '../../Components/Base';
import { AppBarWithAction, APP_BAR_HEIGHT } from '../../Components/Layout';

type PageTitleLayoutProps = {
  title: string;
  onBack?: () => void;
  children?: React.ReactNode;
  disableAction?: boolean;
};

const StyledContentLayout = styled(Flex)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: `760px`,
  },
}));

const PageTitleLayout = ({ children, title, onBack, disableAction }: PageTitleLayoutProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (typeof onBack === 'function') {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <AppBarWithAction title={title} onBack={disableAction ? undefined : handleBack} />
      <Toolbar />
      <Box bgcolor={'grey.50'}>
        <StyledContentLayout
          flexDirection={'column'}
          component="main"
          px={{ xs: 0, sm: 3 }}
          pt={{ xs: 1, sm: 2 }}
          pb={4}
          minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`}
          sx={{ flexGrow: 1 }}
        >
          {children ?? <Outlet />}
        </StyledContentLayout>
      </Box>
    </>
  );
};

export default PageTitleLayout;
