import { Button, ButtonProps } from '@mui/material';

import { Text } from '../../../../Components/Base';

type GradingPublicExaminationButtonProps = ButtonProps;

const GradingPublicExaminationButton = (props: GradingPublicExaminationButtonProps) => {
  return (
    <Button
      sx={{ maxWidth: { xs: 1, sm: '343px' }, width: 1 }}
      size={'large'}
      disabled={true}
      {...props}
    >
      <Text>กำลังตรวจคำตอบ...</Text>
    </Button>
  );
};

export default GradingPublicExaminationButton;
