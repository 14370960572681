import { useParams } from 'react-router-dom';

import useGetExaminationInstruction from '../../../../Domains/useGetExaminationInstruction';
import { QmsskuType, QuestionFieldType } from '../../../../Domains/useGetExaminationDetails';
import useGetBookDetails from '../../../../Domains/useGetBookDetails';
import { getAllAnswerTypeWord } from '../../utils';
import InstructionDetailsLoading from '../../Components/InstructionDetails/InstructionDetailsLoading';
import InstructionDetails from '../../Components/InstructionDetails';
import { QmsExamItem } from '../../../../generated/graphql';

const PrivateInstructionDetails = () => {
  const { bookId = '', examCardId = '' } = useParams();

  const { data: bookDetails, loading: bookDetailLoading } = useGetBookDetails({
    variables: { bookId },
    skip: !bookId,
  });
  const { data, loading: instructionLoading } = useGetExaminationInstruction({
    variables: { examCardId },
    skip: !examCardId,
  });

  const bookType = bookDetails?.qmsGetBookDetailsByBookId.type ?? QmsskuType.Book;

  const examItems = data?.qmsFoGetExamByExamCardId.examItems ?? [];
  const answerTypes = getAllAnswerTypeWord(examItems as QmsExamItem[]);

  if (instructionLoading || bookDetailLoading) return <InstructionDetailsLoading />;

  return <InstructionDetails bookType={bookType} answerTypes={answerTypes} />;
};

export default PrivateInstructionDetails;
