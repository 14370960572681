import { createTheme, experimental_sx as sx } from '@mui/material/styles';
import {
  alpha,
  ComponentsPropsList,
  TextField as TextInput,
  TextFieldProps as TextInputProps,
} from '@mui/material';

import paletteTheme from '../Theme/paletteTheme';

const defaultTheme = createTheme();

const textInputTheme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          variant: 'outlined',
          shrink: true,
        },
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ComponentsPropsList['MuiTextField'] }) => {
          const label = ownerState?.label as ComponentsPropsList['MuiOutlinedInput']['label'];
          return sx({
            width: 1,
            '& .MuiFormHelperText-root': {
              my: 1,
              mx: 0.5,
              '&.Mui-error': {
                color: 'error.main',
              },
            },
            '& .MuiInputLabel-shrink': {
              color: 'text.primary',
              fontSize: 14,
              fontWeight: 600,
              transform: 'translate(0, 1.5px) scale(1)',
            },
            '& .MuiInputLabel-asterisk': {
              color: 'error.main',
            },
            '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
              //16px as input padding and additional 12px for center label inside input
              transform: 'translate(16px, 12px) scale(1)',
              //label act as placeholder first then as solid label when shrink active
              color: 'text.secondary',
              '&.Mui-focused': {
                color: 'input.main',
              },
            },
            '& .MuiOutlinedInput-root': {
              color: 'grey.900',
              bgcolor: 'common.white',
              height: '48px',
              border: '1px solid',
              borderColor: paletteTheme.palette.grey['400'],
              borderRadius: '8px',
              mt: label ? 3.5 : 'inherit',
              transition: defaultTheme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
              ]),

              '&.MuiInputBase-sizeSmall': {
                height: '40px',
              },

              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },

              '& .MuiOutlinedInput-input': {
                py: 1.25,
                px: 2,
                height: 'auto',
                boxSizing: 'border-box',
              },

              //hover case
              '&:hover': {
                border: '1px solid',
                borderColor: paletteTheme.palette.input.main,
              },

              //active case
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                boxShadow: 'none',
                borderColor: 'transparent',
              },
              '&.Mui-focused': {
                borderColor: paletteTheme.palette.input.main,
                boxShadow: `${alpha(paletteTheme.palette.input.main, 0.25)} 0 0 0 0.25rem`,
              },

              //error case
              '&.Mui-error': {
                borderColor: paletteTheme.palette.error.main,
                '&.Mui-focused': {
                  boxShadow: `${alpha(paletteTheme.palette.error.main, 0.25)} 0 0 0 0.25rem`,
                },
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },

              // disabled case
              '&.Mui-disabled': {
                color: 'grey.500',
                // prevent safari show wrong color in shadow dom ref: https://github.com/mui-org/material-ui/issues/23332
                WebkitTextFillColor: paletteTheme.palette.grey['600'],
                bgcolor: 'grey.100',
                '&:hover': {
                  borderColor: paletteTheme.palette.grey['400'],
                },
                '.MuiSelect-icon': {
                  color: 'grey.500',
                },
              },
            },
          });
        },
      },
    },
  },
});

export default TextInput;
export { textInputTheme };
export type { TextInputProps };
