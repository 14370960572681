import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Skeleton, Box } from '../../../Components/Base';
import ExaminationTitle from '../../../Components/ExaminationTitle';
import useGetExaminationInstruction from '../../../Domains/useGetExaminationInstruction';
import useCheckExamStatusByExamCardId from '../../../Domains/useCheckExamStatusByExamCardId';
import { setPageTitle } from '../../../Utils/page';

import InstructionDetails from './Containers/PrivateInstructionDetails';
import ExaminationTimer from './Containers/ExaminationTimer';
import PrivateExaminationActionButton from './Containers/PrivateExaminationActionButton';
import ExaminationTimerLoading from './Containers/ExaminationTimerLoading';

const PrivateExaminationInstruction = () => {
  const { examCardId = '' } = useParams();
  const { data: examStatusData, loading: examStatusLoading } = useCheckExamStatusByExamCardId({
    variables: { examCardId },
    fetchPolicy: 'network-only',
  });
  const examStatus = examStatusData?.qmsExamRoomCheckExamStatusByExamCardId;

  const { data, loading: instructionLoading } = useGetExaminationInstruction({
    variables: { examCardId },
  });

  const examDetail = data?.qmsFoGetExamByExamCardId;

  const formSwitchMethods = useForm({
    mode: 'onTouched',
    defaultValues: { isShowTimer: true },
  });

  useEffect(() => {
    if (examStatus) {
      formSwitchMethods.setValue('isShowTimer', examStatus.isSetTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examStatus]);

  return (
    <>
      <Helmet>
        <title>{setPageTitle('Examination Instruction')}</title>
      </Helmet>
      <FormProvider {...formSwitchMethods}>
        <ExaminationTitle
          title={examDetail?.title ?? ''}
          sx={{ mb: { xs: 1, md: 2 } }}
          containerSx={{
            px: { xs: 2, sm: 3 },
          }}
        >
          {instructionLoading ? <Skeleton width={250} height={27} /> : null}
        </ExaminationTitle>
        <InstructionDetails />
        {examStatusLoading ? (
          <ExaminationTimerLoading />
        ) : (
          <ExaminationTimer
            timeLimit={examDetail?.metadata?.timeLimit ?? 0}
            isTimerDisabled={!!examStatus}
          />
        )}
        {instructionLoading ? (
          <Box px={{ xs: 2, md: 0 }}>
            <Skeleton
              width={'100%'}
              height={48}
              sx={{ borderRadius: 48, mx: 'auto', maxWidth: { xs: 1, sm: '343px' } }}
            />
          </Box>
        ) : (
          <PrivateExaminationActionButton />
        )}
      </FormProvider>
    </>
  );
};

export default PrivateExaminationInstruction;
