import { Text } from '../../Components/Base';

import ExamList from './Containers/ExamList';

const BookExaminations = () => {
  return (
    <>
      <Text variant={'h3'} sx={{ pl: 2, mb: 1 }}>
        ชุดข้อสอบทั้งหมด
      </Text>
      <ExamList />
    </>
  );
};

export default BookExaminations;
