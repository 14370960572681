import { Flex, Text } from '../../../../Components/Base';
import HTMLView from '../../../../Components/HTMLView';

interface ExamArticleProps {
  examArticleName?: string | null;
  examArticleContent?: string;
  groupOfQuestion?: number[];
}

const ExamArticle = (props: ExamArticleProps) => {
  const { examArticleName = '', examArticleContent, groupOfQuestion = [] } = props;
  const firstOrder = groupOfQuestion[0];
  const lastOrder = groupOfQuestion[groupOfQuestion.length - 1];

  const haveOneQuestion = firstOrder === lastOrder;

  return (
    <Flex flexDirection={'column'} gap={2}>
      <Text variant={'subtitle1'}>
        {`บทความเรื่อง ${examArticleName} `}{' '}
        {haveOneQuestion ? `(ข้อที่ ${firstOrder})` : `(ข้อที่ ${firstOrder} - ${lastOrder})`}
      </Text>
      <HTMLView rawContent={examArticleContent} />
    </Flex>
  );
};

export default ExamArticle;
