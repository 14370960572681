import { alpha, styled } from '@mui/material';

import {
  Button,
  Modal,
  ModalProps,
  ModalContent,
  ModalFooter,
  Text,
  ModalTitle as BaseModalTitle,
} from '../Base';

const ModalTitle = styled(BaseModalTitle)(({ theme }) => ({
  '.MuiIconButton-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[300], 0.4),
    },
  },
}));

export type LeaveExamRoomModalProps = {
  onLeave: () => void;
  onCancel: () => void;
} & ModalProps;

const LeaveExamRoomModal = ({ onLeave, onCancel, ...restProps }: LeaveExamRoomModalProps) => {
  return (
    <Modal fullWidth maxWidth={'xs'} {...restProps}>
      <ModalTitle onClose={onCancel} />
      <ModalContent sx={{ p: 3 }}>
        <Text variant={'h2'} align="center" color="text.primary" mb={1}>
          ออกจากห้องสอบ
        </Text>
        <Text align="center" whiteSpace={'pre-line'}>
          {`ระบบจะบันทึกคำตอบให้อัตโนมัติ
          คุณสามารถกลับมาทำข้อสอบต่อหรือ
          เริ่มใหม่ได้ หากหมดเวลาทำข้อสอบ`}
        </Text>
      </ModalContent>
      <ModalFooter flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={2} px={3} pb={3}>
        <Button
          fullWidth
          variant={'outlined'}
          color={'greyButton'}
          size={'large'}
          onClick={onCancel}
        >
          ทำข้อสอบต่อ
        </Button>
        <Button fullWidth size={'large'} onClick={onLeave}>
          ออกจากห้องสอบ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LeaveExamRoomModal;
