import { FieldValues, useController } from 'react-hook-form';

import { TextInput } from '../../../../../Components/Base';

import { FormAnswerFieldNumberProps } from './types';

const FormAnswerFieldNumber = <TFieldValues extends FieldValues = FieldValues>(
  props: FormAnswerFieldNumberProps<TFieldValues>
): JSX.Element => {
  const { demical: decimal = 2, ...restProps } = props;
  const { field } = useController({
    ...props,
    name: props.name,
  });
  const decimalPlaceHolder = Array.from({ length: decimal }, () => '0').join('');
  const placeHolder = `0.${decimalPlaceHolder}`;

  const allowDecimalPlace = (event: React.FocusEvent<{ value: string }>) => {
    const value = event.target.value;
    const decimalPow = Math.pow(10, decimal);

    if (value === '') {
      return undefined;
    }
    if (value.length - value.indexOf('.') > decimal) {
      const floorValue = Math.round(parseFloat(value) * decimalPow) / decimalPow;
      return floorValue.toFixed(decimal);
    }

    return parseFloat(value).toFixed(decimal);
  };

  return (
    <TextInput
      type={'number'}
      size={'small'}
      placeholder={placeHolder}
      {...field}
      onBlur={(e) => {
        field.onChange(allowDecimalPlace(e));
        field.onBlur();
      }}
      {...restProps}
    />
  );
};

export default FormAnswerFieldNumber;
