import ScrollToTop from '../../Containers/ScrollToTop';

import ScoreByLesson from './Containers/ScoreByLesson';

const ResultOverview = () => {
  return (
    <>
      <ScrollToTop />
      <ScoreByLesson />
    </>
  );
};

export default ResultOverview;
