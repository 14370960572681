import { Box } from '@mui/material';

import { Paper, Text } from '../../../../Components/Base';
import { QmsskuType } from '../../../../generated/graphql';

interface InstructionDetailsProps {
  bookType: QmsskuType;
  answerTypes: string[];
}

const InstructionDetails = (props: InstructionDetailsProps) => {
  const { bookType, answerTypes } = props;

  return (
    <Paper
      sx={{
        mb: { xs: 1, sm: 2 },
        p: { xs: 2, md: 3 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      <Text variant={'subtitle1'} mb={2}>
        คำชี้แจงการทำข้อสอบ
      </Text>
      <ol>
        <li>
          <Text>{`ข้อสอบมี ${answerTypes.length} รูปแบบคือ`}</Text>
          {answerTypes.map((answerType, index) => {
            return <Text key={`instruction-${index}`}>{`1.${index + 1} ${answerType}`}</Text>;
          })}
        </li>
        {bookType !== (QmsskuType.CbtBook || QmsskuType.PublicBook) && (
          <li>
            <Text>
              {`โปรแกรมนี้จะใช้เป็นกระดาษคำตอบเท่านั้น ให้ใช้โจทย์ที่ปรากฎภายในเล่ม โดยเช็คให้เลข Ref ของชุดข้อสอบให้ตรงกัน`}
            </Text>
          </li>
        )}
        <li>
          <Text>{`เวลาจะเริ่มจับหลังจากกดปุ่ม “เริ่มทำข้อสอบ”`}</Text>
        </li>
        <li>
          <Text>
            {
              'เมื่อต้องการจะส่งข้อสอบให้กดปุ่ม “ตรวจทานข้อสอบ” เพื่อตรวจทานก่อนส่งและหากต้องการแก้ไขให้กดไปที่ข้อที่ต้องการ เพื่อเลือกคำตอบใหม่'
            }
          </Text>
        </li>
      </ol>
    </Paper>
  );
};

export default InstructionDetails;
