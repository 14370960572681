import { useController } from 'react-hook-form';

import Switch from '../../Base/Input/Switch';

import { FormSwitchProps, FieldValues } from './types';

const FormSwitch = <TFieldValues extends FieldValues = FieldValues>(
  props: FormSwitchProps<TFieldValues>
): JSX.Element => {
  const { field } = useController({
    ...props,
    name: props.name,
  });
  return <Switch {...field} {...props} />;
};

export type { FormSwitchProps };
export default FormSwitch;
