// TODO: Must move relevant book data(Hook) to Book domain
export const getAuthorString = (author: string[]): string => {
  let authorString = '';
  if (author.length === 1) {
    authorString = author[0];
  } else if (author.length > 1) {
    authorString = author.slice(0, author.length - 1).join(' ') + ' และ ' + author.slice(-1)[0];
  }

  return authorString;
};
