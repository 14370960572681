import { QbQuestionType } from '../../../generated/graphql';
import { QmsExamPaperItemType } from '../../../Domains/useGetResultSolution';
import ResultByQuestion from '../../../Components/ResultByQuestion';
import { renderAnswer } from '../../../Domains/useGetExaminationDetails/utils';

interface ResultByQuestionItemProps {
  type?: QbQuestionType;
  examPaperItem: QmsExamPaperItemType;
}

const ResultByQuestionItem = ({ type, examPaperItem }: ResultByQuestionItemProps) => {
  const { order, isCorrect, answer, score } = examPaperItem;
  const questionNo = `ข้อที่ ${order ?? 0}`;
  switch (type) {
    case QbQuestionType.MultipleChoiceDifferentScore: {
      const examScore = score?.toFixed(2);
      return (
        <ResultByQuestion
          prefixText={questionNo}
          answer={renderAnswer(type, answer?.value)}
          score={examScore}
        />
      );
    }
    case QbQuestionType.MultipleSubQuestionsConnectionAnswers: {
      const examScore = score?.toFixed(4);
      const haveAnswers = answer?.value?.some((answer: null | string) => answer !== null);
      return (
        <ResultByQuestion
          prefixText={questionNo}
          score={examScore}
          answer={haveAnswers ? '' : 'ไม่มีคำตอบ'}
        />
      );
    }
    case QbQuestionType.MultipleSubQuestionsYesNoAnswer: {
      const examScore = score.toFixed(2);
      return <ResultByQuestion prefixText={questionNo} score={examScore} />;
    }
    case QbQuestionType.MultipleSelectWithCorrectAnswers:
    case QbQuestionType.MultipleChoice:
    case QbQuestionType.FillNumber:
    default: {
      return (
        <ResultByQuestion
          prefixText={questionNo}
          answer={renderAnswer(type, answer?.value)}
          isCorrect={isCorrect}
        />
      );
    }
  }
};

export default ResultByQuestionItem;
