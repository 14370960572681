export const EXAM_NAVIGATION_HEIGHT = 46;
export const DIVIDER_HEIGHT = 1;
export const DIVIDER_MARGIN_Y = 16 * 2;
export const WRAPPER_PADDING_Y = 24 * 2;

export const TITLE_HEIGHT = 16 * 2 + 68;
export const PROGRESS_BAR_HEIGHT = 6;
export const FOOTER_MARGIN_TOP = 16;

export type splitterPropsByDeviceType = {
  defaultRatio: string;
  minPaneSize: number;
  wrapperMarginTop?: number;
  wrapperPaddingY?: number;
  paneMarginBottom?: number;
};

export const splitterPropsByDevice: { [x: string]: splitterPropsByDeviceType } = {
  Desktop: {
    defaultRatio: '50%',
    minPaneSize: 345,
    wrapperMarginTop: 24,
    wrapperPaddingY: 24,
  },
  Tablet_Landscape: {
    defaultRatio: '50%',
    minPaneSize: 337,
    wrapperMarginTop: 16,
    wrapperPaddingY: 16,
  },
  Tablet_Portrait: {
    defaultRatio: '50%',
    minPaneSize: 128,
    paneMarginBottom: 16,
  },
  Mobile_Portrait: {
    defaultRatio: '70%',
    minPaneSize: 26,
    paneMarginBottom: 16,
  },
};
