import { Button, ButtonProps, css, darken, lighten, styled } from '@mui/material';

export type LoLButtonProps = ButtonProps & {
  buttonVariant?: 'primary' | 'secondary';
};

type ButtonVariant = 'text' | 'outlined' | 'contained';

const style = ({
  textColor,
  bgColor,
  borderColor,
}: {
  textColor: string;
  bgColor: string;
  borderColor: string;
}): Record<ButtonVariant, any> => {
  return {
    text: {
      color: textColor,
    },
    outlined: {
      color: textColor,
      backgroundColor: 'transparent',
      borderColor: borderColor,
    },
    contained: {
      color: textColor,
      backgroundColor: bgColor,
    },
  };
};

const disabledStyle = ({
  textColor,
  bgColor,
  borderColor,
}: {
  textColor: string;
  bgColor: string;
  borderColor: string;
}): Record<ButtonVariant, any> => {
  return {
    text: {
      color: lighten(bgColor, 0.5),
    },
    outlined: {
      color: lighten(borderColor, 0.5),
      backgroundColor: 'transparent',
      borderColor: lighten(borderColor, 0.5),
    },
    contained: {
      color: textColor,
      backgroundColor: lighten(bgColor, 0.5),
    },
  };
};

const hoverStyle = ({
  bgColor,
  borderColor,
}: {
  bgColor: string;
  borderColor: string;
}): Record<ButtonVariant, any> => {
  return {
    text: {
      color: darken(bgColor, 0.4),
    },
    outlined: {
      borderColor: darken(borderColor, 0.4),
    },
    contained: {
      backgroundColor: darken(bgColor, 0.2),
    },
  };
};

/*
This componet will be used in the LoLTheme only
*/

const LoLButton = styled(Button)<LoLButtonProps>`
  ${({ theme, variant = 'contained', buttonVariant = 'primary' }) => {
    let textColor = theme.palette.lolTextButton?.[buttonVariant] ?? theme.palette.common.black;
    const bgColor = theme.palette.lolButton?.[buttonVariant] ?? theme.palette.primary.main;
    const borderColor = theme.palette.lolButton?.[buttonVariant] ?? theme.palette.primary.main;

    if (buttonVariant === 'secondary') {
      textColor = theme.palette.lolTextButton?.contrast ?? theme.palette.common.black;
    }

    if (variant === 'outlined' || variant === 'text') {
      textColor = bgColor;
    }

    return css`
      ${style({ textColor, bgColor, borderColor })[variant as ButtonVariant]}
      &:hover {
        ${hoverStyle({ bgColor, borderColor })[variant as ButtonVariant]}
      }
      &:disabled {
        ${disabledStyle({ textColor, bgColor, borderColor })[variant as ButtonVariant]}
      }
    `;
  }}
`;

export default LoLButton;
