import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';

import { Box, Fab } from '../../Components/Base';
import ArrowUpwardIcon from '../../Components/Icons/ArrowUpwardIcon';

const BackToTop = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        sx={{
          position: 'fixed',
          bottom: { xs: 16, sm: 80 },
          right: { xs: 16, sm: 25 },
          display: { xs: 'none', sm: 'block', md: 'none' },
        }}
      >
        <Fab variant="circularLight">
          <ArrowUpwardIcon />
        </Fab>
      </Box>
    </Zoom>
  );
};

export default BackToTop;
