import { useState, useMemo, useEffect, useRef } from 'react';
import { Duration, intervalToDuration, secondsToMinutes } from 'date-fns';

export enum TIMER_STATUS {
  RUNNING = 'RUNNING',
  STOP = 'STOP',
  TIMEOUT = 'TIMEOUT',
}

const getSeconds = (remainTime: Duration) => {
  const { hours, minutes, seconds } = remainTime;

  const hourToMilliseconds = (hours ?? 0) * 60 * 60;
  const minuteToMilliseconds = (minutes ?? 0) * 60;
  const secondsToMilliseconds = seconds ?? 0;

  return hourToMilliseconds + minuteToMilliseconds + secondsToMilliseconds;
};

export const getDisplayTime = (remainingTime: number) => {
  const minutes = secondsToMinutes(remainingTime);
  const seconds = remainingTime % 60;

  let results = '';
  results += minutes < 10 ? `0${minutes}` : minutes;
  results += ':';
  results += seconds < 10 ? `0${seconds}` : seconds;
  return results;
};

/**
 *
 * @param endTime - time to end countdown
 * @returns
 */
const useTimer = (endTime?: Date | string | number) => {
  const endOfTime = useMemo(() => {
    if (!endTime) return;
    return new Date(endTime);
  }, [endTime]);

  const [remainingTime, setRemainingTime] = useState<number | undefined>();

  useEffect(() => {
    if (!endOfTime) return;
    const currentTime = new Date();
    const remain = intervalToDuration({
      start: currentTime,
      end: endOfTime,
    });

    const remainingSecondTime = getSeconds(remain);
    setRemainingTime(remainingSecondTime);
  }, [endOfTime]);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (typeof remainingTime === 'number') {
      timer.current = setTimeout(() => {
        if (remainingTime > 0) {
          setRemainingTime((prevTime) => (prevTime ? prevTime - 1 : undefined));
        }
      }, 1000);
      return () => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
      };
    }
  }, [endOfTime, remainingTime]);

  const timerStatus = useMemo(() => {
    let status = TIMER_STATUS.STOP;
    if (typeof remainingTime === 'number') {
      if (remainingTime > 0) status = TIMER_STATUS.RUNNING;
      else status = TIMER_STATUS.TIMEOUT;
    }
    return status;
  }, [remainingTime]);

  return {
    remainingTime,
    setRemainingTime,
    timerStatus,
  };
};

export default useTimer;
