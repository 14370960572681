import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export enum Exam_Event_Type {
  Sat = 'SAT',
}

export enum Exam_Generator_Type {
  Fixed = 'FIXED',
  FixedAll = 'FIXED_ALL',
  Random = 'RANDOM',
}

export enum Exam_Item_Reference_Type {
  Exam = 'EXAM',
  Question = 'QUESTION',
}

export enum Exam_Status {
  Deprecated = 'DEPRECATED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum Exam_Type {
  Lol = 'LOL',
  Sat = 'SAT',
}

export enum Lol_Exam_Result_Type {
  CapableCareer = 'CAPABLE_CAREER',
  CareerGroup = 'CAREER_GROUP',
  Character = 'CHARACTER',
  FirstRiasec = 'FIRST_RIASEC',
  FirstStrength = 'FIRST_STRENGTH',
  FirstValue = 'FIRST_VALUE',
  IdentityCareer = 'IDENTITY_CAREER',
  PersonalityCareer = 'PERSONALITY_CAREER',
  PotentialIdentityCareer = 'POTENTIAL_IDENTITY_CAREER',
  PreferencesCareer = 'PREFERENCES_CAREER',
  SecondRiasec = 'SECOND_RIASEC',
  SecondStrength = 'SECOND_STRENGTH',
  SecondValue = 'SECOND_VALUE',
  ThirdStrength = 'THIRD_STRENGTH',
  ThirdValue = 'THIRD_VALUE',
}

export type Mutation = {
  __typename?: 'Mutation';
  logout: Scalars['Boolean'];
  qmsBackToExamRoom: QmsExamSession;
  qmsConvertUserIdFromAnonymousUserId: Array<QmsExamCard>;
  qmsExamRoomGradeActivityAndCareers: Array<QmsExamRoomLolExamResult>;
  qmsExamRoomGradeCharacterAndCareerGroup: Array<QmsExamRoomLolExamResult>;
  qmsExamRoomGradeRIASECCoreTraitAndCapableCareer: Array<QmsExamRoomLolExamResult>;
  qmsExamRoomGradeStrengthCoreTraits: Array<QmsExamRoomLolExamResult>;
  qmsExamRoomGradeValueCoreTraitAndPersonalityCareer: Array<QmsExamRoomLolExamResult>;
  qmsExamRoomStartExam: QmsExamSession;
  qmsExamRoomStartExamInEvent: QmsExamSession;
  qmsExamRoomUpdateExamPaper: QmsExamRoomAnswer;
  qmsGenerateBookFileUrlByBookId: Scalars['String'];
  qmsRedeemBook: QmsBook;
  qmsRedeemPublicBook: Array<QmsExamCard>;
  qmsResolveNextExamModuleInfo: QmsExamRoomNextExamInfo;
  qmsSubmitExamPaper: QmsExamRoomExamPaper;
  qmsUnlockExaminationByRedeemCode: QmsBookRedeemCode;
};

export type MutationQmsBackToExamRoomArgs = {
  input: QmsBackToExamRoomInput;
};

export type MutationQmsConvertUserIdFromAnonymousUserIdArgs = {
  anonymousUserId: Scalars['String'];
};

export type MutationQmsExamRoomGradeActivityAndCareersArgs = {
  rawAnswers: Array<RawAnswerInput>;
};

export type MutationQmsExamRoomGradeCharacterAndCareerGroupArgs = {
  rawAnswers: Array<RawAnswerInput>;
};

export type MutationQmsExamRoomGradeRiasecCoreTraitAndCapableCareerArgs = {
  rawAnswers: Array<RawAnswerInput>;
};

export type MutationQmsExamRoomGradeStrengthCoreTraitsArgs = {
  rawAnswers: Array<RawAnswerInput>;
};

export type MutationQmsExamRoomGradeValueCoreTraitAndPersonalityCareerArgs = {
  rawAnswers: Array<RawAnswerInput>;
};

export type MutationQmsExamRoomStartExamArgs = {
  input: QmsStartExamInput;
};

export type MutationQmsExamRoomStartExamInEventArgs = {
  input: QmsStartExamInEventInput;
};

export type MutationQmsExamRoomUpdateExamPaperArgs = {
  examRoomExamPaperItems: Array<QmsExamRoomExamPaperItemInput>;
};

export type MutationQmsGenerateBookFileUrlByBookIdArgs = {
  id: Scalars['String'];
};

export type MutationQmsRedeemBookArgs = {
  redeemCode: Scalars['String'];
};

export type MutationQmsRedeemPublicBookArgs = {
  bookId: Scalars['String'];
  isGenerateRedeemCode?: InputMaybe<Scalars['Boolean']>;
};

export type MutationQmsResolveNextExamModuleInfoArgs = {
  examCardId: Scalars['String'];
};

export type MutationQmsSubmitExamPaperArgs = {
  submitExamInput: QmsSubmitExamInput;
};

export type MutationQmsUnlockExaminationByRedeemCodeArgs = {
  bookId: Scalars['String'];
  redeemCode: Scalars['String'];
};

export enum Next_Exam_Module_Type {
  Break = 'BREAK',
  Exam = 'EXAM',
  Finish = 'FINISH',
}

export enum QbQuestionSolutionType {
  Html = 'HTML',
  Markdown = 'MARKDOWN',
  Youtube = 'YOUTUBE',
}

export enum QbQuestionStatus {
  Deprecated = 'DEPRECATED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum QbQuestionType {
  FillInTheBlank = 'FILL_IN_THE_BLANK',
  FillNumber = 'FILL_NUMBER',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleChoiceDifferentScore = 'MULTIPLE_CHOICE_DIFFERENT_SCORE',
  MultipleSelect = 'MULTIPLE_SELECT',
  MultipleSelectWithCorrectAnswers = 'MULTIPLE_SELECT_WITH_CORRECT_ANSWERS',
  MultipleSubQuestionsConnectionAnswers = 'MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS',
  MultipleSubQuestionsYesNoAnswer = 'MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER',
  SatMathInput = 'SAT_MATH_INPUT',
}

export enum QbTagCategory {
  Bloom = 'BLOOM',
  CourseCode = 'COURSE_CODE',
  Difficulty = 'DIFFICULTY',
  ExamCampaign = 'EXAM_CAMPAIGN',
  ExamType = 'EXAM_TYPE',
  Grade = 'GRADE',
  Indicator = 'INDICATOR',
  Objective = 'OBJECTIVE',
  Owner = 'OWNER',
  QuestionExamType = 'QUESTION_EXAM_TYPE',
  SatExamModule = 'SAT_EXAM_MODULE',
  Section = 'SECTION',
  Subject = 'SUBJECT',
  Subsection = 'SUBSECTION',
  Subtopic = 'SUBTOPIC',
  Term = 'TERM',
  Topic = 'TOPIC',
}

export type QmsBackToExamRoomInput = {
  examCardId: Scalars['String'];
  isReset: Scalars['Boolean'];
};

export type QmsBook = {
  __typename?: 'QMSBook';
  authors?: Maybe<Array<Scalars['String']>>;
  bookExamItems: Array<QmsBookExamItem>;
  coverImage?: Maybe<QmsStorageObject>;
  coverImageUrl?: Maybe<Scalars['String']>;
  exams?: Maybe<Array<QmsExam>>;
  file?: Maybe<QmsStorageObject>;
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSONObject']>;
  myLatestExamPapers?: Maybe<Array<QmsExamPaper>>;
  refCode?: Maybe<Scalars['String']>;
  signedFileUrl?: Maybe<Scalars['String']>;
  skuCode: Scalars['String'];
  status: QmsskuStatus;
  title: Scalars['String'];
  type: QmsskuType;
};

export type QmsBookExamItem = {
  __typename?: 'QMSBookExamItem';
  examId: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
  skuId: Scalars['String'];
};

export type QmsBookRedeemCode = {
  __typename?: 'QMSBookRedeemCode';
  id: Scalars['String'];
  log: Scalars['JSON'];
  prefix: Scalars['String'];
  redeemCode: Scalars['String'];
  skuId: Scalars['String'];
  status: QmsskuRedeemStatus;
};

export type QmsExam = {
  __typename?: 'QMSExam';
  defaultGeneratorType: Exam_Generator_Type;
  examItems?: Maybe<Array<QmsExamItem>>;
  examSubjects?: Maybe<Array<QmsExamSubject>>;
  fullScore: Scalars['Int'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  ownerCode: Scalars['String'];
  status: Exam_Status;
  tags?: Maybe<Array<QmsExamTag>>;
  title: Scalars['String'];
  totalQuestions?: Maybe<Scalars['Int']>;
  type?: Maybe<Exam_Type>;
};

export type QmsExamCard = {
  __typename?: 'QMSExamCard';
  examEventId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  skuExamId?: Maybe<Scalars['String']>;
  skuExamItem?: Maybe<QmsskuExamItem>;
  userId: Scalars['String'];
};

export type QmsExamCardsStatusResult = {
  __typename?: 'QMSExamCardsStatusResult';
  id: Scalars['String'];
  isStarted: Scalars['Boolean'];
};

export type QmsExamEvent = {
  __typename?: 'QMSExamEvent';
  displayConfig?: Maybe<Scalars['JSON']>;
  endDate?: Maybe<Scalars['DateTime']>;
  examId?: Maybe<Scalars['String']>;
  generatorConfig?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  timeLimit?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  type?: Maybe<Exam_Event_Type>;
  userConfig?: Maybe<Scalars['JSON']>;
};

export type QmsExamEventResult = {
  __typename?: 'QMSExamEventResult';
  examEvent: QmsExamEvent;
  officialScore: Scalars['JSON'];
  scoresBySubject: Array<QmsExamEventResultScore>;
};

export type QmsExamEventResultScore = {
  __typename?: 'QMSExamEventResultScore';
  correctQuestions: Scalars['Float'];
  subject: Scalars['String'];
  subjectTagId: Scalars['String'];
  totalQuestions: Scalars['Float'];
};

export type QmsExamItem = {
  __typename?: 'QMSExamItem';
  exam?: Maybe<QmsExam>;
  examId: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
  question?: Maybe<QmsQuestion>;
  questionId?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refType?: Maybe<Exam_Item_Reference_Type>;
  score: Scalars['Float'];
};

export type QmsExamMaterial = {
  __typename?: 'QMSExamMaterial';
  content: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: QmsExamMaterialType;
};

export enum QmsExamMaterialType {
  Article = 'ARTICLE',
}

export type QmsExamPaper = {
  __typename?: 'QMSExamPaper';
  createdAt: Scalars['DateTime'];
  exam?: Maybe<QmsExam>;
  examCardId: Scalars['String'];
  examId: Scalars['String'];
  examPaperItems?: Maybe<Array<QmsExamPaperItem>>;
  examSubjects?: Maybe<QmsExamSubjectsResult>;
  fullScore: Scalars['Float'];
  id: Scalars['String'];
  isTimeUp?: Maybe<Scalars['Boolean']>;
  iteration: Scalars['Int'];
  myExamTagResults?: Maybe<Array<QmsExamPaperTagResult>>;
  refId: Scalars['String'];
  score: Scalars['Float'];
  status: QmsExamPaperStatus;
  totalCorrectQuestions: Scalars['Int'];
  totalQuestions: Scalars['Int'];
};

export type QmsExamPaperItem = {
  __typename?: 'QMSExamPaperItem';
  answer: Scalars['JSON'];
  exam?: Maybe<QmsExam>;
  examId: Scalars['String'];
  examPaperId: Scalars['String'];
  id: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  order: Scalars['Int'];
  question?: Maybe<QmsQuestion>;
  questionId?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refType?: Maybe<Exam_Item_Reference_Type>;
  score: Scalars['Float'];
};

export enum QmsExamPaperStatus {
  Finished = 'FINISHED',
  Grading = 'GRADING',
  InProgress = 'IN_PROGRESS',
  TimeUp = 'TIME_UP',
}

export type QmsExamPaperTagResult = {
  __typename?: 'QMSExamPaperTagResult';
  examPaperId: Scalars['String'];
  fullScore: Scalars['Float'];
  id: Scalars['String'];
  score: Scalars['Float'];
  tagId: Scalars['String'];
};

export type QmsExamPapersResult = {
  __typename?: 'QMSExamPapersResult';
  examPapers: Array<QmsExamPaper>;
  total: Scalars['Int'];
};

export type QmsExamRoomAnswer = {
  __typename?: 'QMSExamRoomAnswer';
  createdAt: Scalars['DateTime'];
  examPaperId: Scalars['String'];
  examRoomExamPaperItems?: Maybe<Array<QmsExamRoomExamPaperItem>>;
};

export type QmsExamRoomExam = {
  __typename?: 'QMSExamRoomExam';
  defaultGeneratorType: Exam_Generator_Type;
  examItems?: Maybe<Array<QmsExamRoomExamItem>>;
  fullScore: Scalars['Int'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  ownerCode: Scalars['String'];
  questions?: Maybe<Array<QmsQuestion>>;
  status: Exam_Status;
  tags?: Maybe<Array<QmsExamTag>>;
  title: Scalars['String'];
  totalQuestions?: Maybe<Scalars['Int']>;
  type?: Maybe<Exam_Type>;
};

export type QmsExamRoomExamEvent = {
  __typename?: 'QMSExamRoomExamEvent';
  displayConfig?: Maybe<Scalars['JSON']>;
  endDate?: Maybe<Scalars['DateTime']>;
  examId?: Maybe<Scalars['String']>;
  generatorConfig?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  timeLimit?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  type?: Maybe<Exam_Event_Type>;
  userConfig?: Maybe<Scalars['JSON']>;
};

export type QmsExamRoomExamItem = {
  __typename?: 'QMSExamRoomExamItem';
  examId: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
  question?: Maybe<QmsQuestion>;
  questionId?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refType?: Maybe<Exam_Item_Reference_Type>;
  score: Scalars['Float'];
};

export type QmsExamRoomExamPaper = {
  __typename?: 'QMSExamRoomExamPaper';
  examCardId: Scalars['String'];
  examId: Scalars['String'];
  fullScore: Scalars['Float'];
  id: Scalars['String'];
  iteration: Scalars['Int'];
  refId: Scalars['String'];
  score: Scalars['Float'];
  status: Scalars['String'];
};

export type QmsExamRoomExamPaperItem = {
  __typename?: 'QMSExamRoomExamPaperItem';
  answer: Scalars['JSON'];
  examPaperItemId: Scalars['String'];
  isBookmark: Scalars['Boolean'];
  questionId?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refType?: Maybe<Exam_Item_Reference_Type>;
};

export type QmsExamRoomExamPaperItemInput = {
  answer: Scalars['JSON'];
  examPaperItemId: Scalars['String'];
  isBookmark: Scalars['Boolean'];
  questionId?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['String']>;
  refType?: InputMaybe<Exam_Item_Reference_Type>;
};

export type QmsExamRoomLolExamReport = {
  __typename?: 'QMSExamRoomLOLExamReport';
  careers: Array<QmsExamRoomLolExamResult>;
  characters: Array<QmsExamRoomLolExamResult>;
  createdAt: Scalars['DateTime'];
  examPaperId: Scalars['String'];
  id: Scalars['String'];
  preferences: Array<QmsExamRoomLolExamResult>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type QmsExamRoomLolExamResult = {
  __typename?: 'QMSExamRoomLOLExamResult';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  imageUrl: Scalars['String'];
  refCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Lol_Exam_Result_Type;
};

export type QmsExamRoomNextExamInfo = {
  __typename?: 'QMSExamRoomNextExamInfo';
  endedAt?: Maybe<Scalars['String']>;
  examId?: Maybe<Scalars['String']>;
  type: Next_Exam_Module_Type;
};

export type QmsExamSession = {
  __typename?: 'QMSExamSession';
  deletedAt?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  examEventId?: Maybe<Scalars['String']>;
  examPaperId: Scalars['String'];
  examRoomAnswer: QmsExamRoomAnswer;
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isSetTimer: Scalars['Boolean'];
  startedAt: Scalars['DateTime'];
};

export type QmsExamSubject = {
  __typename?: 'QMSExamSubject';
  examSubjectSubsections?: Maybe<Array<QmsExamSubjectSubsection>>;
  id: Scalars['String'];
  questions: Array<QmsQuestion>;
  tagId: Scalars['String'];
  title: Scalars['String'];
  totalQuestionCount: Scalars['Int'];
};

export type QmsExamSubjectSubsection = {
  __typename?: 'QMSExamSubjectSubsection';
  id: Scalars['String'];
  questions: Array<QmsQuestion>;
  tagId: Scalars['String'];
  title: Scalars['String'];
  totalQuestionCount: Scalars['Int'];
};

export type QmsExamSubjectsResult = {
  __typename?: 'QMSExamSubjectsResult';
  examSubjects: Array<QmsExamSubject>;
  examTitle: Scalars['String'];
};

export type QmsExamTag = {
  __typename?: 'QMSExamTag';
  category: QbTagCategory;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type QmsGetMyExamPapersInput = {
  bookId: Scalars['String'];
  examCardId: Scalars['String'];
  statuses?: InputMaybe<Array<QmsExamPaperStatus>>;
};

export type QmslolExamReport = {
  __typename?: 'QMSLOLExamReport';
  careers: Array<QmslolExamResult>;
  characters: Array<QmslolExamResult>;
  createdAt: Scalars['DateTime'];
  examPaperId: Scalars['String'];
  id: Scalars['String'];
  preferences: Array<QmslolExamResult>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type QmslolExamResult = {
  __typename?: 'QMSLOLExamResult';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  imageUrl: Scalars['String'];
  refCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Lol_Exam_Result_Type;
};

export type QmsOrderInput = {
  field: Scalars['String'];
  type: QmsOrderType;
};

export enum QmsOrderType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type QmsPageInput = {
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QmsQuestion = {
  __typename?: 'QMSQuestion';
  correctAnswer?: Maybe<Scalars['JSON']>;
  difficulty: Scalars['String'];
  examMaterial?: Maybe<QmsExamMaterial>;
  examMaterialId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  question: Scalars['String'];
  refQuestionId: Scalars['String'];
  refSource: Scalars['String'];
  solution?: Maybe<Array<QmsQuestionSolution>>;
  status: QbQuestionStatus;
  subType?: Maybe<Scalars['String']>;
  tags: Array<QmsQuestionTag>;
  type: QbQuestionType;
};

export type QmsQuestionSolution = {
  __typename?: 'QMSQuestionSolution';
  content: Scalars['String'];
  type: QbQuestionSolutionType;
};

export type QmsQuestionTag = {
  __typename?: 'QMSQuestionTag';
  category: QbTagCategory;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type QmsskuExamItem = {
  __typename?: 'QMSSKUExamItem';
  exam?: Maybe<QmsExam>;
  examId: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
  skuId: Scalars['String'];
};

export enum QmsskuRedeemStatus {
  Unused = 'UNUSED',
  Used = 'USED',
}

export enum QmsskuStatus {
  ComingSoon = 'COMING_SOON',
  Deprecated = 'DEPRECATED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export enum QmsskuType {
  Book = 'BOOK',
  CbtBook = 'CBT_BOOK',
  EBook = 'E_BOOK',
  PublicBook = 'PUBLIC_BOOK',
}

export type QmsStartExamInEventInput = {
  examCardId: Scalars['String'];
  examId: Scalars['String'];
  isSetTimer: Scalars['Boolean'];
};

export type QmsStartExamInput = {
  examCardId: Scalars['String'];
  isSetTimer: Scalars['Boolean'];
};

export type QmsStorageObject = {
  __typename?: 'QMSStorageObject';
  bucket: Scalars['String'];
  key: Scalars['String'];
};

export type QmsSubmitExamInput = {
  rawAnswers: Array<RawAnswerInput>;
};

export type Query = {
  __typename?: 'Query';
  getMyProfile: User;
  qmsExamItemById: QmsExamItem;
  qmsExamRoomCheckExamStatusByBookIdAndExamId: QmsExamSession;
  qmsExamRoomCheckExamStatusByExamCardId: QmsExamSession;
  qmsExamRoomGetExam: QmsExamRoomExam;
  qmsExamRoomGetExamEvent: QmsExamRoomExamEvent;
  qmsExamRoomGetExamSessionAnswer: QmsExamSession;
  qmsExamRoomGetLOLExamReport: QmsExamRoomLolExamReport;
  qmsExamRoomGetLatestExamPaper: QmsExamRoomAnswer;
  qmsFoGetExamByExamCardId: QmsExamRoomExam;
  qmsFoGetExamById: QmsExam;
  qmsFoGetExamPaperById: QmsExamPaper;
  qmsFoGetExamPapersByCardId: Array<QmsExamPaper>;
  qmsFoMyExamPapers: QmsExamPapersResult;
  qmsGetBookByRedeemCode: QmsBook;
  qmsGetBookDetailsByBookId: QmsBook;
  qmsGetExamCardByBookIdAndExamId: QmsExamCard;
  qmsGetExamCardById: QmsExamCard;
  qmsGetExamCardStatusByIds: Array<QmsExamCardsStatusResult>;
  qmsGetExamEventResult: QmsExamEventResult;
  qmsGetExamEventsByCardIds: Array<QmsExamEvent>;
  qmsGetExamPaperItemById: QmsExamPaperItem;
  qmsGetExamSubjectByExamId: QmsExamSubjectsResult;
  qmsGetLOLExamReport: QmslolExamReport;
  qmsGetMyLatestExamPaper: QmsExamRoomExamPaper;
  qmsGetQuestionById: QmsQuestion;
  qmsMyBooks: Array<QmsBook>;
  qmsMyExamCards: Array<QmsExamCard>;
  qmsMyExamEvents: Array<QmsExamEvent>;
  qmsMySkuRedeemCodeByBookId: QmsBookRedeemCode;
  qmsSatGetQuestions: Array<QmsExamRoomExam>;
};

export type QueryQmsExamItemByIdArgs = {
  id: Scalars['String'];
};

export type QueryQmsExamRoomCheckExamStatusByBookIdAndExamIdArgs = {
  bookId: Scalars['String'];
  examId: Scalars['String'];
};

export type QueryQmsExamRoomCheckExamStatusByExamCardIdArgs = {
  examCardId: Scalars['String'];
};

export type QueryQmsExamRoomGetExamEventArgs = {
  examEventId: Scalars['String'];
};

export type QueryQmsFoGetExamByExamCardIdArgs = {
  examCardId: Scalars['String'];
};

export type QueryQmsFoGetExamByIdArgs = {
  examId: Scalars['String'];
};

export type QueryQmsFoGetExamPaperByIdArgs = {
  examPaperId: Scalars['String'];
};

export type QueryQmsFoGetExamPapersByCardIdArgs = {
  examCardId: Scalars['String'];
};

export type QueryQmsFoMyExamPapersArgs = {
  myExamPapersInput: QmsGetMyExamPapersInput;
  order?: InputMaybe<QmsOrderInput>;
  paging?: InputMaybe<QmsPageInput>;
};

export type QueryQmsGetBookByRedeemCodeArgs = {
  redeemCode: Scalars['String'];
};

export type QueryQmsGetBookDetailsByBookIdArgs = {
  bookId: Scalars['String'];
};

export type QueryQmsGetExamCardByBookIdAndExamIdArgs = {
  bookId: Scalars['String'];
  examId: Scalars['String'];
};

export type QueryQmsGetExamCardByIdArgs = {
  examCardId: Scalars['String'];
};

export type QueryQmsGetExamCardStatusByIdsArgs = {
  examCardIds: Array<Scalars['String']>;
};

export type QueryQmsGetExamEventResultArgs = {
  examCardId: Scalars['String'];
};

export type QueryQmsGetExamEventsByCardIdsArgs = {
  cardIds: Array<Scalars['String']>;
};

export type QueryQmsGetExamPaperItemByIdArgs = {
  id: Scalars['String'];
};

export type QueryQmsGetExamSubjectByExamIdArgs = {
  examId: Scalars['String'];
};

export type QueryQmsGetLolExamReportArgs = {
  examPaperId: Scalars['String'];
};

export type QueryQmsGetMyLatestExamPaperArgs = {
  examEventId: Scalars['String'];
};

export type QueryQmsGetQuestionByIdArgs = {
  questionId: Scalars['String'];
};

export type QueryQmsMyExamCardsArgs = {
  bookId?: InputMaybe<Scalars['String']>;
};

export type QueryQmsMySkuRedeemCodeByBookIdArgs = {
  bookId: Scalars['String'];
};

export type QueryQmsSatGetQuestionsArgs = {
  examId: Scalars['String'];
};

export type RawAnswerInput = {
  answer: Scalars['JSON'];
  examPaperItemId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  additional1?: Maybe<Scalars['String']>;
  additional2?: Maybe<Scalars['String']>;
  additional3?: Maybe<Scalars['String']>;
  additional4?: Maybe<Scalars['String']>;
  additional5?: Maybe<Scalars['String']>;
  additional6?: Maybe<Scalars['String']>;
  additional7?: Maybe<Scalars['String']>;
  additional8?: Maybe<Scalars['String']>;
  additional9?: Maybe<Scalars['String']>;
  additional10?: Maybe<Scalars['String']>;
  appPermissions: Array<Scalars['String']>;
  appRoles: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  /** If true email has been verified, can be null when email does not require verification eg. OAuth user */
  emailVerified?: Maybe<Scalars['Boolean']>;
  firstname: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isDisabled: Scalars['Boolean'];
  isPassportUser: Scalars['Boolean'];
  isPasswordCreatedByUser: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  lastLoginAt?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  newEmail?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  realm?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  teamIds: Array<Scalars['String']>;
  username: Scalars['String'];
};

export type Lol_Exam_ResultFragment = {
  __typename?: 'QMSLOLExamResult';
  id: string;
  title: string;
  type: Lol_Exam_Result_Type;
  imageUrl: string;
  description: string;
  createdAt: any;
  refCode?: string | null;
};

export type Lol_Exam_Room_ResultFragment = {
  __typename?: 'QMSExamRoomLOLExamResult';
  id: string;
  title: string;
  type: Lol_Exam_Result_Type;
  imageUrl: string;
  description: string;
  createdAt: any;
  refCode?: string | null;
};

export type Book_DetailsFragment = {
  __typename?: 'QMSBook';
  id: string;
  authors?: Array<string> | null;
  refCode?: string | null;
  skuCode: string;
  status: QmsskuStatus;
  title: string;
  type: QmsskuType;
};

export type QmsBackToExamRoomMutationVariables = Exact<{
  examCardId: Scalars['String'];
  isReset: Scalars['Boolean'];
}>;

export type QmsBackToExamRoomMutation = {
  __typename?: 'Mutation';
  qmsBackToExamRoom: { __typename?: 'QMSExamSession'; id: string };
};

export type QmsConvertUserIdFromAnonymousUserIdMutationVariables = Exact<{
  anonymousUserId: Scalars['String'];
}>;

export type QmsConvertUserIdFromAnonymousUserIdMutation = {
  __typename?: 'Mutation';
  qmsConvertUserIdFromAnonymousUserId: Array<{
    __typename?: 'QMSExamCard';
    id: string;
    userId: string;
  }>;
};

export type QmsExamRoomUpdateExamPaperMutationVariables = Exact<{
  examRoomExamPaperItems: Array<QmsExamRoomExamPaperItemInput> | QmsExamRoomExamPaperItemInput;
}>;

export type QmsExamRoomUpdateExamPaperMutation = {
  __typename?: 'Mutation';
  qmsExamRoomUpdateExamPaper: {
    __typename?: 'QMSExamRoomAnswer';
    createdAt: any;
    examPaperId: string;
    examRoomExamPaperItems?: Array<{
      __typename?: 'QMSExamRoomExamPaperItem';
      answer: any;
      examPaperItemId: string;
      isBookmark: boolean;
      questionId?: string | null;
    }> | null;
  };
};

export type QmsSubmitExamPaperMutationVariables = Exact<{
  submitExamInput: QmsSubmitExamInput;
}>;

export type QmsSubmitExamPaperMutation = {
  __typename?: 'Mutation';
  qmsSubmitExamPaper: { __typename?: 'QMSExamRoomExamPaper'; id: string };
};

export type GenerateSignedFileUrlMutationVariables = Exact<{
  bookId: Scalars['String'];
}>;

export type GenerateSignedFileUrlMutation = {
  __typename?: 'Mutation';
  qmsGenerateBookFileUrlByBookId: string;
};

export type QmsExamRoomGradeCharacterAndCareerGroupMutationVariables = Exact<{
  rawAnswers: Array<RawAnswerInput> | RawAnswerInput;
}>;

export type QmsExamRoomGradeCharacterAndCareerGroupMutation = {
  __typename?: 'Mutation';
  qmsExamRoomGradeCharacterAndCareerGroup: Array<{
    __typename?: 'QMSExamRoomLOLExamResult';
    id: string;
    title: string;
    type: Lol_Exam_Result_Type;
    imageUrl: string;
    description: string;
    createdAt: any;
    refCode?: string | null;
  }>;
};

export type QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationVariables = Exact<{
  rawAnswers: Array<RawAnswerInput> | RawAnswerInput;
}>;

export type QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation = {
  __typename?: 'Mutation';
  qmsExamRoomGradeRIASECCoreTraitAndCapableCareer: Array<{
    __typename?: 'QMSExamRoomLOLExamResult';
    id: string;
    title: string;
    type: Lol_Exam_Result_Type;
    imageUrl: string;
    description: string;
    createdAt: any;
    refCode?: string | null;
  }>;
};

export type QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationVariables = Exact<{
  rawAnswers: Array<RawAnswerInput> | RawAnswerInput;
}>;

export type QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation = {
  __typename?: 'Mutation';
  qmsExamRoomGradeValueCoreTraitAndPersonalityCareer: Array<{
    __typename?: 'QMSExamRoomLOLExamResult';
    id: string;
    title: string;
    type: Lol_Exam_Result_Type;
    imageUrl: string;
    description: string;
    createdAt: any;
    refCode?: string | null;
  }>;
};

export type QmsExamRoomGradeActivityAndCareersMutationVariables = Exact<{
  rawAnswers: Array<RawAnswerInput> | RawAnswerInput;
}>;

export type QmsExamRoomGradeActivityAndCareersMutation = {
  __typename?: 'Mutation';
  qmsExamRoomGradeActivityAndCareers: Array<{
    __typename?: 'QMSExamRoomLOLExamResult';
    id: string;
    title: string;
    type: Lol_Exam_Result_Type;
    imageUrl: string;
    description: string;
    createdAt: any;
    refCode?: string | null;
  }>;
};

export type QmsExamRoomGradeStrengthCoreTraitsMutationVariables = Exact<{
  rawAnswers: Array<RawAnswerInput> | RawAnswerInput;
}>;

export type QmsExamRoomGradeStrengthCoreTraitsMutation = {
  __typename?: 'Mutation';
  qmsExamRoomGradeStrengthCoreTraits: Array<{
    __typename?: 'QMSExamRoomLOLExamResult';
    id: string;
    title: string;
    type: Lol_Exam_Result_Type;
    imageUrl: string;
    description: string;
    createdAt: any;
    refCode?: string | null;
  }>;
};

export type QmsUnlockExaminationByRedeemCodeMutationVariables = Exact<{
  redeemCode: Scalars['String'];
  bookId: Scalars['String'];
}>;

export type QmsUnlockExaminationByRedeemCodeMutation = {
  __typename?: 'Mutation';
  qmsUnlockExaminationByRedeemCode: {
    __typename?: 'QMSBookRedeemCode';
    id: string;
    status: QmsskuRedeemStatus;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type QmsRedeemBookMutationVariables = Exact<{
  redeemCode: Scalars['String'];
}>;

export type QmsRedeemBookMutation = {
  __typename?: 'Mutation';
  qmsRedeemBook: { __typename?: 'QMSBook'; id: string };
};

export type QmsRedeemPublicBookMutationVariables = Exact<{
  bookId: Scalars['String'];
  isGenerateRedeemCode?: InputMaybe<Scalars['Boolean']>;
}>;

export type QmsRedeemPublicBookMutation = {
  __typename?: 'Mutation';
  qmsRedeemPublicBook: Array<{
    __typename?: 'QMSExamCard';
    id: string;
    skuExamItem?: { __typename?: 'QMSSKUExamItem'; examId: string } | null;
  }>;
};

export type QmsExamRoomStartExamMutationVariables = Exact<{
  examCardId: Scalars['String'];
  isSetTimer: Scalars['Boolean'];
}>;

export type QmsExamRoomStartExamMutation = {
  __typename?: 'Mutation';
  qmsExamRoomStartExam: { __typename?: 'QMSExamSession'; id: string };
};

export type QmsExamRoomCheckExamStatusByExamCardIdQueryVariables = Exact<{
  examCardId: Scalars['String'];
}>;

export type QmsExamRoomCheckExamStatusByExamCardIdQuery = {
  __typename?: 'Query';
  qmsExamRoomCheckExamStatusByExamCardId: {
    __typename?: 'QMSExamSession';
    id: string;
    isSetTimer: boolean;
  };
};

export type QmsExamRoomCheckExamStatusByBookIdAndExamIdQueryVariables = Exact<{
  bookId: Scalars['String'];
  examId: Scalars['String'];
}>;

export type QmsExamRoomCheckExamStatusByBookIdAndExamIdQuery = {
  __typename?: 'Query';
  qmsExamRoomCheckExamStatusByBookIdAndExamId: { __typename?: 'QMSExamSession'; id: string };
};

export type QmsGetExamCardByIdQueryVariables = Exact<{
  examCardId: Scalars['String'];
}>;

export type QmsGetExamCardByIdQuery = {
  __typename?: 'Query';
  qmsGetExamCardById: {
    __typename?: 'QMSExamCard';
    id: string;
    skuExamItem?: { __typename?: 'QMSSKUExamItem'; id: string; examId: string } | null;
  };
};

export type QmsGetExamCardByBookIdAndExamIdQueryVariables = Exact<{
  bookId: Scalars['String'];
  examId: Scalars['String'];
}>;

export type QmsGetExamCardByBookIdAndExamIdQuery = {
  __typename?: 'Query';
  qmsGetExamCardByBookIdAndExamId: { __typename?: 'QMSExamCard'; id: string };
};

export type QmsGetBookByRedeemCodeQueryVariables = Exact<{
  redeemCode: Scalars['String'];
}>;

export type QmsGetBookByRedeemCodeQuery = {
  __typename?: 'Query';
  qmsGetBookByRedeemCode: {
    __typename?: 'QMSBook';
    id: string;
    title: string;
    authors?: Array<string> | null;
    skuCode: string;
    status: QmsskuStatus;
    type: QmsskuType;
    refCode?: string | null;
    coverImageUrl?: string | null;
  };
};

export type QmsExamRoomGetExamQueryVariables = Exact<{ [key: string]: never }>;

export type QmsExamRoomGetExamQuery = {
  __typename?: 'Query';
  qmsExamRoomGetExam: {
    __typename?: 'QMSExamRoomExam';
    id: string;
    title: string;
    metadata?: any | null;
    examItems?: Array<{
      __typename?: 'QMSExamRoomExamItem';
      order: number;
      question?: {
        __typename?: 'QMSQuestion';
        id: string;
        metadata?: any | null;
        question: string;
        subType?: string | null;
        type: QbQuestionType;
        examMaterialId?: string | null;
        examMaterial?: {
          __typename?: 'QMSExamMaterial';
          id: string;
          type: QmsExamMaterialType;
          name?: string | null;
          content: string;
        } | null;
      } | null;
    }> | null;
  };
};

export type QmsLoLExamRoomGetExamQueryVariables = Exact<{ [key: string]: never }>;

export type QmsLoLExamRoomGetExamQuery = {
  __typename?: 'Query';
  qmsExamRoomGetExam: {
    __typename?: 'QMSExamRoomExam';
    id: string;
    title: string;
    metadata?: any | null;
    examItems?: Array<{
      __typename?: 'QMSExamRoomExamItem';
      order: number;
      score: number;
      question?: {
        __typename?: 'QMSQuestion';
        id: string;
        metadata?: any | null;
        question: string;
        subType?: string | null;
        type: QbQuestionType;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QbTagCategory;
        }>;
      } | null;
    }> | null;
  };
};

export type QmsExamRoomGetExamSessionAnswerQueryVariables = Exact<{ [key: string]: never }>;

export type QmsExamRoomGetExamSessionAnswerQuery = {
  __typename?: 'Query';
  qmsExamRoomGetExamSessionAnswer: {
    __typename?: 'QMSExamSession';
    id: string;
    examPaperId: string;
    endedAt?: any | null;
    isSetTimer: boolean;
    examRoomAnswer: {
      __typename?: 'QMSExamRoomAnswer';
      examPaperId: string;
      examRoomExamPaperItems?: Array<{
        __typename?: 'QMSExamRoomExamPaperItem';
        examPaperItemId: string;
        questionId?: string | null;
        answer: any;
        isBookmark: boolean;
      }> | null;
    };
  };
};

export type GetLoLLanguageByExamIdQueryVariables = Exact<{
  examId: Scalars['String'];
}>;

export type GetLoLLanguageByExamIdQuery = {
  __typename?: 'Query';
  qmsFoGetExamById: { __typename?: 'QMSExam'; id: string; metadata?: any | null };
};

export type GetExamByExamCardIdQueryVariables = Exact<{
  examCardId: Scalars['String'];
}>;

export type GetExamByExamCardIdQuery = {
  __typename?: 'Query';
  qmsFoGetExamByExamCardId: {
    __typename?: 'QMSExamRoomExam';
    id: string;
    title: string;
    metadata?: any | null;
    examItems?: Array<{
      __typename?: 'QMSExamRoomExamItem';
      order: number;
      question?: {
        __typename?: 'QMSQuestion';
        type: QbQuestionType;
        subType?: string | null;
        metadata?: any | null;
      } | null;
    }> | null;
  };
};

export type GetExamPaperItemsByLessonQueryVariables = Exact<{
  examPaperId: Scalars['String'];
}>;

export type GetExamPaperItemsByLessonQuery = {
  __typename?: 'Query';
  qmsFoGetExamPaperById: {
    __typename?: 'QMSExamPaper';
    id: string;
    examSubjects?: {
      __typename?: 'QMSExamSubjectsResult';
      examSubjects: Array<{
        __typename?: 'QMSExamSubject';
        tagId: string;
        title: string;
        questions: Array<{ __typename?: 'QMSQuestion'; id: string }>;
      }>;
    } | null;
    examPaperItems?: Array<{
      __typename?: 'QMSExamPaperItem';
      id: string;
      questionId?: string | null;
      order: number;
      isCorrect: boolean;
      score: number;
      answer: any;
      question?: {
        __typename?: 'QMSQuestion';
        id: string;
        type: QbQuestionType;
        subType?: string | null;
      } | null;
    }> | null;
  };
};

export type GetExamPaperByLessonQueryVariables = Exact<{
  examPaperId: Scalars['String'];
}>;

export type GetExamPaperByLessonQuery = {
  __typename?: 'Query';
  qmsFoGetExamPaperById: {
    __typename?: 'QMSExamPaper';
    id: string;
    examSubjects?: {
      __typename?: 'QMSExamSubjectsResult';
      examSubjects: Array<{
        __typename?: 'QMSExamSubject';
        tagId: string;
        title: string;
        examSubjectSubsections?: Array<{
          __typename?: 'QMSExamSubjectSubsection';
          tagId: string;
          title: string;
          questions: Array<{ __typename?: 'QMSQuestion'; type: QbQuestionType }>;
        }> | null;
      }>;
    } | null;
    myExamTagResults?: Array<{
      __typename?: 'QMSExamPaperTagResult';
      tagId: string;
      score: number;
      fullScore: number;
    }> | null;
  };
};

export type GetExamPaperByIdQueryVariables = Exact<{
  examPaperId: Scalars['String'];
}>;

export type GetExamPaperByIdQuery = {
  __typename?: 'Query';
  qmsFoGetExamPaperById: {
    __typename?: 'QMSExamPaper';
    id: string;
    iteration: number;
    fullScore: number;
    score: number;
    createdAt: any;
    examSubjects?: { __typename?: 'QMSExamSubjectsResult'; examTitle: string } | null;
    exam?: {
      __typename?: 'QMSExam';
      examItems?: Array<{
        __typename?: 'QMSExamItem';
        question?: { __typename?: 'QMSQuestion'; type: QbQuestionType } | null;
      }> | null;
    } | null;
  };
};

export type GetExamResultSolutionDetailsQueryVariables = Exact<{
  examPaperItemId: Scalars['String'];
}>;

export type GetExamResultSolutionDetailsQuery = {
  __typename?: 'Query';
  qmsGetExamPaperItemById: {
    __typename?: 'QMSExamPaperItem';
    id: string;
    question?: {
      __typename?: 'QMSQuestion';
      id: string;
      solution?: Array<{
        __typename?: 'QMSQuestionSolution';
        type: QbQuestionSolutionType;
        content: string;
      }> | null;
    } | null;
  };
};

export type GetExamResultSolutionSummaryQueryVariables = Exact<{
  examPaperItemId: Scalars['String'];
}>;

export type GetExamResultSolutionSummaryQuery = {
  __typename?: 'Query';
  qmsGetExamPaperItemById: {
    __typename?: 'QMSExamPaperItem';
    id: string;
    answer: any;
    order: number;
    isCorrect: boolean;
    score: number;
    exam?: { __typename?: 'QMSExam'; title: string } | null;
    question?: {
      __typename?: 'QMSQuestion';
      id: string;
      type: QbQuestionType;
      subType?: string | null;
      question: string;
      correctAnswer?: any | null;
      difficulty: string;
      tags: Array<{
        __typename?: 'QMSQuestionTag';
        id: string;
        name: string;
        category: QbTagCategory;
      }>;
    } | null;
  };
};

export type GetExamSolutionDetailQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetExamSolutionDetailQuery = {
  __typename?: 'Query';
  qmsExamItemById: {
    __typename?: 'QMSExamItem';
    id: string;
    order: number;
    exam?: { __typename?: 'QMSExam'; id: string; title: string } | null;
    question?: {
      __typename?: 'QMSQuestion';
      id: string;
      question: string;
      type: QbQuestionType;
      subType?: string | null;
      correctAnswer?: any | null;
      difficulty: string;
      tags: Array<{
        __typename?: 'QMSQuestionTag';
        id: string;
        name: string;
        category: QbTagCategory;
      }>;
      solution?: Array<{
        __typename?: 'QMSQuestionSolution';
        type: QbQuestionSolutionType;
        content: string;
      }> | null;
    } | null;
  };
};

export type GetSolutionByIdQueryVariables = Exact<{
  examId: Scalars['String'];
}>;

export type GetSolutionByIdQuery = {
  __typename?: 'Query';
  qmsFoGetExamById: {
    __typename?: 'QMSExam';
    id: string;
    title: string;
    examSubjects?: Array<{
      __typename?: 'QMSExamSubject';
      id: string;
      title: string;
      totalQuestionCount: number;
      questions: Array<{
        __typename?: 'QMSQuestion';
        id: string;
        correctAnswer?: any | null;
        type: QbQuestionType;
        subType?: string | null;
      }>;
    }> | null;
    examItems?: Array<{
      __typename?: 'QMSExamItem';
      id: string;
      questionId?: string | null;
      order: number;
    }> | null;
  };
};

export type QmsMySkuRedeemCodeByBookIdQueryVariables = Exact<{
  bookId: Scalars['String'];
}>;

export type QmsMySkuRedeemCodeByBookIdQuery = {
  __typename?: 'Query';
  qmsMySkuRedeemCodeByBookId: {
    __typename?: 'QMSBookRedeemCode';
    id: string;
    status: QmsskuRedeemStatus;
  };
};

export type GetExamPaperIdByExamCardIdQueryVariables = Exact<{
  examCardId: Scalars['String'];
}>;

export type GetExamPaperIdByExamCardIdQuery = {
  __typename?: 'Query';
  qmsFoGetExamPapersByCardId: Array<{ __typename?: 'QMSExamPaper'; id: string }>;
};

export type GetLoLExamByExamIdQueryVariables = Exact<{
  examId: Scalars['String'];
}>;

export type GetLoLExamByExamIdQuery = {
  __typename?: 'Query';
  qmsFoGetExamById: {
    __typename?: 'QMSExam';
    id: string;
    metadata?: any | null;
    examItems?: Array<{
      __typename?: 'QMSExamItem';
      order: number;
      question?: {
        __typename?: 'QMSQuestion';
        id: string;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QbTagCategory;
        }>;
      } | null;
    }> | null;
  };
};

export type GetLoLExamByExamPaperIdQueryVariables = Exact<{
  examPaperId: Scalars['String'];
}>;

export type GetLoLExamByExamPaperIdQuery = {
  __typename?: 'Query';
  qmsFoGetExamPaperById: {
    __typename?: 'QMSExamPaper';
    id: string;
    exam?: {
      __typename?: 'QMSExam';
      id: string;
      metadata?: any | null;
      examItems?: Array<{
        __typename?: 'QMSExamItem';
        id: string;
        order: number;
        question?: {
          __typename?: 'QMSQuestion';
          id: string;
          tags: Array<{
            __typename?: 'QMSQuestionTag';
            id: string;
            name: string;
            slug: string;
            category: QbTagCategory;
          }>;
        } | null;
      }> | null;
    } | null;
  };
};

export type GetCareerReportByExamPaperIdQueryVariables = Exact<{
  examPaperId: Scalars['String'];
}>;

export type GetCareerReportByExamPaperIdQuery = {
  __typename?: 'Query';
  qmsGetLOLExamReport: {
    __typename?: 'QMSLOLExamReport';
    id: string;
    careers: Array<{
      __typename?: 'QMSLOLExamResult';
      id: string;
      title: string;
      type: Lol_Exam_Result_Type;
      imageUrl: string;
      description: string;
      createdAt: any;
      refCode?: string | null;
    }>;
  };
};

export type GetPreferenceReportByExamPaperIdQueryVariables = Exact<{
  examPaperId: Scalars['String'];
}>;

export type GetPreferenceReportByExamPaperIdQuery = {
  __typename?: 'Query';
  qmsGetLOLExamReport: {
    __typename?: 'QMSLOLExamReport';
    id: string;
    preferences: Array<{
      __typename?: 'QMSLOLExamResult';
      id: string;
      title: string;
      type: Lol_Exam_Result_Type;
      imageUrl: string;
      description: string;
      createdAt: any;
      refCode?: string | null;
    }>;
  };
};

export type GetCharacterReportByExamPaperIdQueryVariables = Exact<{
  examPaperId: Scalars['String'];
}>;

export type GetCharacterReportByExamPaperIdQuery = {
  __typename?: 'Query';
  qmsGetLOLExamReport: {
    __typename?: 'QMSLOLExamReport';
    id: string;
    characters: Array<{
      __typename?: 'QMSLOLExamResult';
      id: string;
      title: string;
      type: Lol_Exam_Result_Type;
      imageUrl: string;
      description: string;
      createdAt: any;
      refCode?: string | null;
    }>;
  };
};

export type GetQmsMyBooksQueryVariables = Exact<{ [key: string]: never }>;

export type GetQmsMyBooksQuery = {
  __typename?: 'Query';
  qmsMyBooks: Array<{
    __typename?: 'QMSBook';
    coverImageUrl?: string | null;
    id: string;
    authors?: Array<string> | null;
    refCode?: string | null;
    skuCode: string;
    status: QmsskuStatus;
    title: string;
    type: QmsskuType;
  }>;
};

export type GetQmsMyBookDetailsQueryVariables = Exact<{
  bookId: Scalars['String'];
}>;

export type GetQmsMyBookDetailsQuery = {
  __typename?: 'Query';
  qmsGetBookDetailsByBookId: {
    __typename?: 'QMSBook';
    coverImageUrl?: string | null;
    id: string;
    authors?: Array<string> | null;
    refCode?: string | null;
    skuCode: string;
    status: QmsskuStatus;
    title: string;
    type: QmsskuType;
    file?: { __typename?: 'QMSStorageObject'; key: string; bucket: string } | null;
    exams?: Array<{
      __typename?: 'QMSExam';
      id: string;
      title: string;
      type?: Exam_Type | null;
      metadata?: any | null;
    }> | null;
  };
};

export type GetQmsMyBookMetadataQueryVariables = Exact<{
  bookId: Scalars['String'];
}>;

export type GetQmsMyBookMetadataQuery = {
  __typename?: 'Query';
  qmsGetBookDetailsByBookId: { __typename?: 'QMSBook'; id: string; metadata?: any | null };
};

export type GetQmsExamDetailsByBookIdQueryVariables = Exact<{
  bookId: Scalars['String'];
}>;

export type GetQmsExamDetailsByBookIdQuery = {
  __typename?: 'Query';
  qmsGetBookDetailsByBookId: {
    __typename?: 'QMSBook';
    id: string;
    bookExamItems: Array<{ __typename?: 'QMSBookExamItem'; examId: string; order: number }>;
    exams?: Array<{
      __typename?: 'QMSExam';
      id: string;
      title: string;
      type?: Exam_Type | null;
      metadata?: any | null;
    }> | null;
  };
};

export type GetMyExamCardsQueryVariables = Exact<{
  bookId: Scalars['String'];
}>;

export type GetMyExamCardsQuery = {
  __typename?: 'Query';
  qmsMyExamCards: Array<{
    __typename?: 'QMSExamCard';
    id: string;
    skuExamItem?: {
      __typename?: 'QMSSKUExamItem';
      order: number;
      exam?: { __typename?: 'QMSExam'; id: string; title: string } | null;
    } | null;
  }>;
};

export type GetMyLatestExamPapersQueryVariables = Exact<{
  bookId: Scalars['String'];
}>;

export type GetMyLatestExamPapersQuery = {
  __typename?: 'Query';
  qmsGetBookDetailsByBookId: {
    __typename?: 'QMSBook';
    id: string;
    myLatestExamPapers?: Array<{
      __typename?: 'QMSExamPaper';
      id: string;
      examCardId: string;
      status: QmsExamPaperStatus;
      isTimeUp?: boolean | null;
    }> | null;
  };
};

export type QmsFoMyExamPapersQueryVariables = Exact<{
  myExamPapersInput: QmsGetMyExamPapersInput;
  paging?: InputMaybe<QmsPageInput>;
  order?: InputMaybe<QmsOrderInput>;
}>;

export type QmsFoMyExamPapersQuery = {
  __typename?: 'Query';
  qmsFoMyExamPapers: {
    __typename?: 'QMSExamPapersResult';
    total: number;
    examPapers: Array<{
      __typename?: 'QMSExamPaper';
      id: string;
      examId: string;
      fullScore: number;
      score: number;
      status: QmsExamPaperStatus;
      createdAt: any;
      iteration: number;
      examPaperItems?: Array<{
        __typename?: 'QMSExamPaperItem';
        question?: { __typename?: 'QMSQuestion'; type: QbQuestionType } | null;
      }> | null;
    }>;
  };
};

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyProfileQuery = {
  __typename?: 'Query';
  getMyProfile: {
    __typename?: 'User';
    id: string;
    firstname: string;
    lastname: string;
    additional1?: string | null;
  };
};

export type GetQmsPublicBookDetailQueryVariables = Exact<{
  bookId: Scalars['String'];
}>;

export type GetQmsPublicBookDetailQuery = {
  __typename?: 'Query';
  qmsGetBookDetailsByBookId: {
    __typename?: 'QMSBook';
    id: string;
    type: QmsskuType;
    exams?: Array<{
      __typename?: 'QMSExam';
      id: string;
      title: string;
      metadata?: any | null;
      examItems?: Array<{
        __typename?: 'QMSExamItem';
        id: string;
        order: number;
        question?: {
          __typename?: 'QMSQuestion';
          id: string;
          question: string;
          type: QbQuestionType;
          subType?: string | null;
          metadata?: any | null;
          tags: Array<{ __typename?: 'QMSQuestionTag'; id: string; name: string; slug: string }>;
        } | null;
      }> | null;
    }> | null;
  };
};

export const Lol_Exam_ResultFragmentDoc = gql`
  fragment LOL_EXAM_RESULT on QMSLOLExamResult {
    id
    title
    type
    imageUrl
    description
    createdAt
    refCode
  }
`;
export const Lol_Exam_Room_ResultFragmentDoc = gql`
  fragment LOL_EXAM_ROOM_RESULT on QMSExamRoomLOLExamResult {
    id
    title
    type
    imageUrl
    description
    createdAt
    refCode
  }
`;
export const Book_DetailsFragmentDoc = gql`
  fragment BOOK_DETAILS on QMSBook {
    id
    authors
    refCode
    skuCode
    status
    title
    type
  }
`;
export const QmsBackToExamRoomDocument = gql`
  mutation qmsBackToExamRoom($examCardId: String!, $isReset: Boolean!) {
    qmsBackToExamRoom(input: { examCardId: $examCardId, isReset: $isReset }) {
      id
    }
  }
`;
export type QmsBackToExamRoomMutationFn = Apollo.MutationFunction<
  QmsBackToExamRoomMutation,
  QmsBackToExamRoomMutationVariables
>;

/**
 * __useQmsBackToExamRoomMutation__
 *
 * To run a mutation, you first call `useQmsBackToExamRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsBackToExamRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsBackToExamRoomMutation, { data, loading, error }] = useQmsBackToExamRoomMutation({
 *   variables: {
 *      examCardId: // value for 'examCardId'
 *      isReset: // value for 'isReset'
 *   },
 * });
 */
export function useQmsBackToExamRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsBackToExamRoomMutation,
    QmsBackToExamRoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<QmsBackToExamRoomMutation, QmsBackToExamRoomMutationVariables>(
    QmsBackToExamRoomDocument,
    options
  );
}
export type QmsBackToExamRoomMutationHookResult = ReturnType<typeof useQmsBackToExamRoomMutation>;
export type QmsBackToExamRoomMutationResult = Apollo.MutationResult<QmsBackToExamRoomMutation>;
export type QmsBackToExamRoomMutationOptions = Apollo.BaseMutationOptions<
  QmsBackToExamRoomMutation,
  QmsBackToExamRoomMutationVariables
>;
export const QmsConvertUserIdFromAnonymousUserIdDocument = gql`
  mutation qmsConvertUserIdFromAnonymousUserId($anonymousUserId: String!) {
    qmsConvertUserIdFromAnonymousUserId(anonymousUserId: $anonymousUserId) {
      id
      userId
    }
  }
`;
export type QmsConvertUserIdFromAnonymousUserIdMutationFn = Apollo.MutationFunction<
  QmsConvertUserIdFromAnonymousUserIdMutation,
  QmsConvertUserIdFromAnonymousUserIdMutationVariables
>;

/**
 * __useQmsConvertUserIdFromAnonymousUserIdMutation__
 *
 * To run a mutation, you first call `useQmsConvertUserIdFromAnonymousUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsConvertUserIdFromAnonymousUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsConvertUserIdFromAnonymousUserIdMutation, { data, loading, error }] = useQmsConvertUserIdFromAnonymousUserIdMutation({
 *   variables: {
 *      anonymousUserId: // value for 'anonymousUserId'
 *   },
 * });
 */
export function useQmsConvertUserIdFromAnonymousUserIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsConvertUserIdFromAnonymousUserIdMutation,
    QmsConvertUserIdFromAnonymousUserIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QmsConvertUserIdFromAnonymousUserIdMutation,
    QmsConvertUserIdFromAnonymousUserIdMutationVariables
  >(QmsConvertUserIdFromAnonymousUserIdDocument, options);
}
export type QmsConvertUserIdFromAnonymousUserIdMutationHookResult = ReturnType<
  typeof useQmsConvertUserIdFromAnonymousUserIdMutation
>;
export type QmsConvertUserIdFromAnonymousUserIdMutationResult =
  Apollo.MutationResult<QmsConvertUserIdFromAnonymousUserIdMutation>;
export type QmsConvertUserIdFromAnonymousUserIdMutationOptions = Apollo.BaseMutationOptions<
  QmsConvertUserIdFromAnonymousUserIdMutation,
  QmsConvertUserIdFromAnonymousUserIdMutationVariables
>;
export const QmsExamRoomUpdateExamPaperDocument = gql`
  mutation qmsExamRoomUpdateExamPaper($examRoomExamPaperItems: [QMSExamRoomExamPaperItemInput!]!) {
    qmsExamRoomUpdateExamPaper(examRoomExamPaperItems: $examRoomExamPaperItems) {
      createdAt
      examPaperId
      examRoomExamPaperItems {
        answer
        examPaperItemId
        isBookmark
        questionId
      }
    }
  }
`;
export type QmsExamRoomUpdateExamPaperMutationFn = Apollo.MutationFunction<
  QmsExamRoomUpdateExamPaperMutation,
  QmsExamRoomUpdateExamPaperMutationVariables
>;

/**
 * __useQmsExamRoomUpdateExamPaperMutation__
 *
 * To run a mutation, you first call `useQmsExamRoomUpdateExamPaperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomUpdateExamPaperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsExamRoomUpdateExamPaperMutation, { data, loading, error }] = useQmsExamRoomUpdateExamPaperMutation({
 *   variables: {
 *      examRoomExamPaperItems: // value for 'examRoomExamPaperItems'
 *   },
 * });
 */
export function useQmsExamRoomUpdateExamPaperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsExamRoomUpdateExamPaperMutation,
    QmsExamRoomUpdateExamPaperMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QmsExamRoomUpdateExamPaperMutation,
    QmsExamRoomUpdateExamPaperMutationVariables
  >(QmsExamRoomUpdateExamPaperDocument, options);
}
export type QmsExamRoomUpdateExamPaperMutationHookResult = ReturnType<
  typeof useQmsExamRoomUpdateExamPaperMutation
>;
export type QmsExamRoomUpdateExamPaperMutationResult =
  Apollo.MutationResult<QmsExamRoomUpdateExamPaperMutation>;
export type QmsExamRoomUpdateExamPaperMutationOptions = Apollo.BaseMutationOptions<
  QmsExamRoomUpdateExamPaperMutation,
  QmsExamRoomUpdateExamPaperMutationVariables
>;
export const QmsSubmitExamPaperDocument = gql`
  mutation qmsSubmitExamPaper($submitExamInput: QMSSubmitExamInput!) {
    qmsSubmitExamPaper(submitExamInput: $submitExamInput) {
      id
    }
  }
`;
export type QmsSubmitExamPaperMutationFn = Apollo.MutationFunction<
  QmsSubmitExamPaperMutation,
  QmsSubmitExamPaperMutationVariables
>;

/**
 * __useQmsSubmitExamPaperMutation__
 *
 * To run a mutation, you first call `useQmsSubmitExamPaperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsSubmitExamPaperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsSubmitExamPaperMutation, { data, loading, error }] = useQmsSubmitExamPaperMutation({
 *   variables: {
 *      submitExamInput: // value for 'submitExamInput'
 *   },
 * });
 */
export function useQmsSubmitExamPaperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsSubmitExamPaperMutation,
    QmsSubmitExamPaperMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<QmsSubmitExamPaperMutation, QmsSubmitExamPaperMutationVariables>(
    QmsSubmitExamPaperDocument,
    options
  );
}
export type QmsSubmitExamPaperMutationHookResult = ReturnType<typeof useQmsSubmitExamPaperMutation>;
export type QmsSubmitExamPaperMutationResult = Apollo.MutationResult<QmsSubmitExamPaperMutation>;
export type QmsSubmitExamPaperMutationOptions = Apollo.BaseMutationOptions<
  QmsSubmitExamPaperMutation,
  QmsSubmitExamPaperMutationVariables
>;
export const GenerateSignedFileUrlDocument = gql`
  mutation GenerateSignedFileUrl($bookId: String!) {
    qmsGenerateBookFileUrlByBookId(id: $bookId)
  }
`;
export type GenerateSignedFileUrlMutationFn = Apollo.MutationFunction<
  GenerateSignedFileUrlMutation,
  GenerateSignedFileUrlMutationVariables
>;

/**
 * __useGenerateSignedFileUrlMutation__
 *
 * To run a mutation, you first call `useGenerateSignedFileUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSignedFileUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSignedFileUrlMutation, { data, loading, error }] = useGenerateSignedFileUrlMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGenerateSignedFileUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSignedFileUrlMutation,
    GenerateSignedFileUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateSignedFileUrlMutation, GenerateSignedFileUrlMutationVariables>(
    GenerateSignedFileUrlDocument,
    options
  );
}
export type GenerateSignedFileUrlMutationHookResult = ReturnType<
  typeof useGenerateSignedFileUrlMutation
>;
export type GenerateSignedFileUrlMutationResult =
  Apollo.MutationResult<GenerateSignedFileUrlMutation>;
export type GenerateSignedFileUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateSignedFileUrlMutation,
  GenerateSignedFileUrlMutationVariables
>;
export const QmsExamRoomGradeCharacterAndCareerGroupDocument = gql`
  mutation qmsExamRoomGradeCharacterAndCareerGroup($rawAnswers: [RawAnswerInput!]!) {
    qmsExamRoomGradeCharacterAndCareerGroup(rawAnswers: $rawAnswers) {
      ...LOL_EXAM_ROOM_RESULT
    }
  }
  ${Lol_Exam_Room_ResultFragmentDoc}
`;
export type QmsExamRoomGradeCharacterAndCareerGroupMutationFn = Apollo.MutationFunction<
  QmsExamRoomGradeCharacterAndCareerGroupMutation,
  QmsExamRoomGradeCharacterAndCareerGroupMutationVariables
>;

/**
 * __useQmsExamRoomGradeCharacterAndCareerGroupMutation__
 *
 * To run a mutation, you first call `useQmsExamRoomGradeCharacterAndCareerGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomGradeCharacterAndCareerGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsExamRoomGradeCharacterAndCareerGroupMutation, { data, loading, error }] = useQmsExamRoomGradeCharacterAndCareerGroupMutation({
 *   variables: {
 *      rawAnswers: // value for 'rawAnswers'
 *   },
 * });
 */
export function useQmsExamRoomGradeCharacterAndCareerGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsExamRoomGradeCharacterAndCareerGroupMutation,
    QmsExamRoomGradeCharacterAndCareerGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QmsExamRoomGradeCharacterAndCareerGroupMutation,
    QmsExamRoomGradeCharacterAndCareerGroupMutationVariables
  >(QmsExamRoomGradeCharacterAndCareerGroupDocument, options);
}
export type QmsExamRoomGradeCharacterAndCareerGroupMutationHookResult = ReturnType<
  typeof useQmsExamRoomGradeCharacterAndCareerGroupMutation
>;
export type QmsExamRoomGradeCharacterAndCareerGroupMutationResult =
  Apollo.MutationResult<QmsExamRoomGradeCharacterAndCareerGroupMutation>;
export type QmsExamRoomGradeCharacterAndCareerGroupMutationOptions = Apollo.BaseMutationOptions<
  QmsExamRoomGradeCharacterAndCareerGroupMutation,
  QmsExamRoomGradeCharacterAndCareerGroupMutationVariables
>;
export const QmsExamRoomGradeRiasecCoreTraitAndCapableCareerDocument = gql`
  mutation qmsExamRoomGradeRIASECCoreTraitAndCapableCareer($rawAnswers: [RawAnswerInput!]!) {
    qmsExamRoomGradeRIASECCoreTraitAndCapableCareer(rawAnswers: $rawAnswers) {
      ...LOL_EXAM_ROOM_RESULT
    }
  }
  ${Lol_Exam_Room_ResultFragmentDoc}
`;
export type QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationFn = Apollo.MutationFunction<
  QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation,
  QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationVariables
>;

/**
 * __useQmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation__
 *
 * To run a mutation, you first call `useQmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation, { data, loading, error }] = useQmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation({
 *   variables: {
 *      rawAnswers: // value for 'rawAnswers'
 *   },
 * });
 */
export function useQmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation,
    QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation,
    QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationVariables
  >(QmsExamRoomGradeRiasecCoreTraitAndCapableCareerDocument, options);
}
export type QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationHookResult = ReturnType<
  typeof useQmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation
>;
export type QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationResult =
  Apollo.MutationResult<QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation>;
export type QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationOptions =
  Apollo.BaseMutationOptions<
    QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation,
    QmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutationVariables
  >;
export const QmsExamRoomGradeValueCoreTraitAndPersonalityCareerDocument = gql`
  mutation qmsExamRoomGradeValueCoreTraitAndPersonalityCareer($rawAnswers: [RawAnswerInput!]!) {
    qmsExamRoomGradeValueCoreTraitAndPersonalityCareer(rawAnswers: $rawAnswers) {
      ...LOL_EXAM_ROOM_RESULT
    }
  }
  ${Lol_Exam_Room_ResultFragmentDoc}
`;
export type QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationFn = Apollo.MutationFunction<
  QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation,
  QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationVariables
>;

/**
 * __useQmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation__
 *
 * To run a mutation, you first call `useQmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation, { data, loading, error }] = useQmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation({
 *   variables: {
 *      rawAnswers: // value for 'rawAnswers'
 *   },
 * });
 */
export function useQmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation,
    QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation,
    QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationVariables
  >(QmsExamRoomGradeValueCoreTraitAndPersonalityCareerDocument, options);
}
export type QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationHookResult = ReturnType<
  typeof useQmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation
>;
export type QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationResult =
  Apollo.MutationResult<QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation>;
export type QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationOptions =
  Apollo.BaseMutationOptions<
    QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation,
    QmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutationVariables
  >;
export const QmsExamRoomGradeActivityAndCareersDocument = gql`
  mutation qmsExamRoomGradeActivityAndCareers($rawAnswers: [RawAnswerInput!]!) {
    qmsExamRoomGradeActivityAndCareers(rawAnswers: $rawAnswers) {
      ...LOL_EXAM_ROOM_RESULT
    }
  }
  ${Lol_Exam_Room_ResultFragmentDoc}
`;
export type QmsExamRoomGradeActivityAndCareersMutationFn = Apollo.MutationFunction<
  QmsExamRoomGradeActivityAndCareersMutation,
  QmsExamRoomGradeActivityAndCareersMutationVariables
>;

/**
 * __useQmsExamRoomGradeActivityAndCareersMutation__
 *
 * To run a mutation, you first call `useQmsExamRoomGradeActivityAndCareersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomGradeActivityAndCareersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsExamRoomGradeActivityAndCareersMutation, { data, loading, error }] = useQmsExamRoomGradeActivityAndCareersMutation({
 *   variables: {
 *      rawAnswers: // value for 'rawAnswers'
 *   },
 * });
 */
export function useQmsExamRoomGradeActivityAndCareersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsExamRoomGradeActivityAndCareersMutation,
    QmsExamRoomGradeActivityAndCareersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QmsExamRoomGradeActivityAndCareersMutation,
    QmsExamRoomGradeActivityAndCareersMutationVariables
  >(QmsExamRoomGradeActivityAndCareersDocument, options);
}
export type QmsExamRoomGradeActivityAndCareersMutationHookResult = ReturnType<
  typeof useQmsExamRoomGradeActivityAndCareersMutation
>;
export type QmsExamRoomGradeActivityAndCareersMutationResult =
  Apollo.MutationResult<QmsExamRoomGradeActivityAndCareersMutation>;
export type QmsExamRoomGradeActivityAndCareersMutationOptions = Apollo.BaseMutationOptions<
  QmsExamRoomGradeActivityAndCareersMutation,
  QmsExamRoomGradeActivityAndCareersMutationVariables
>;
export const QmsExamRoomGradeStrengthCoreTraitsDocument = gql`
  mutation qmsExamRoomGradeStrengthCoreTraits($rawAnswers: [RawAnswerInput!]!) {
    qmsExamRoomGradeStrengthCoreTraits(rawAnswers: $rawAnswers) {
      ...LOL_EXAM_ROOM_RESULT
    }
  }
  ${Lol_Exam_Room_ResultFragmentDoc}
`;
export type QmsExamRoomGradeStrengthCoreTraitsMutationFn = Apollo.MutationFunction<
  QmsExamRoomGradeStrengthCoreTraitsMutation,
  QmsExamRoomGradeStrengthCoreTraitsMutationVariables
>;

/**
 * __useQmsExamRoomGradeStrengthCoreTraitsMutation__
 *
 * To run a mutation, you first call `useQmsExamRoomGradeStrengthCoreTraitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomGradeStrengthCoreTraitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsExamRoomGradeStrengthCoreTraitsMutation, { data, loading, error }] = useQmsExamRoomGradeStrengthCoreTraitsMutation({
 *   variables: {
 *      rawAnswers: // value for 'rawAnswers'
 *   },
 * });
 */
export function useQmsExamRoomGradeStrengthCoreTraitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsExamRoomGradeStrengthCoreTraitsMutation,
    QmsExamRoomGradeStrengthCoreTraitsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QmsExamRoomGradeStrengthCoreTraitsMutation,
    QmsExamRoomGradeStrengthCoreTraitsMutationVariables
  >(QmsExamRoomGradeStrengthCoreTraitsDocument, options);
}
export type QmsExamRoomGradeStrengthCoreTraitsMutationHookResult = ReturnType<
  typeof useQmsExamRoomGradeStrengthCoreTraitsMutation
>;
export type QmsExamRoomGradeStrengthCoreTraitsMutationResult =
  Apollo.MutationResult<QmsExamRoomGradeStrengthCoreTraitsMutation>;
export type QmsExamRoomGradeStrengthCoreTraitsMutationOptions = Apollo.BaseMutationOptions<
  QmsExamRoomGradeStrengthCoreTraitsMutation,
  QmsExamRoomGradeStrengthCoreTraitsMutationVariables
>;
export const QmsUnlockExaminationByRedeemCodeDocument = gql`
  mutation qmsUnlockExaminationByRedeemCode($redeemCode: String!, $bookId: String!) {
    qmsUnlockExaminationByRedeemCode(redeemCode: $redeemCode, bookId: $bookId) {
      id
      status
    }
  }
`;
export type QmsUnlockExaminationByRedeemCodeMutationFn = Apollo.MutationFunction<
  QmsUnlockExaminationByRedeemCodeMutation,
  QmsUnlockExaminationByRedeemCodeMutationVariables
>;

/**
 * __useQmsUnlockExaminationByRedeemCodeMutation__
 *
 * To run a mutation, you first call `useQmsUnlockExaminationByRedeemCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsUnlockExaminationByRedeemCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsUnlockExaminationByRedeemCodeMutation, { data, loading, error }] = useQmsUnlockExaminationByRedeemCodeMutation({
 *   variables: {
 *      redeemCode: // value for 'redeemCode'
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useQmsUnlockExaminationByRedeemCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsUnlockExaminationByRedeemCodeMutation,
    QmsUnlockExaminationByRedeemCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QmsUnlockExaminationByRedeemCodeMutation,
    QmsUnlockExaminationByRedeemCodeMutationVariables
  >(QmsUnlockExaminationByRedeemCodeDocument, options);
}
export type QmsUnlockExaminationByRedeemCodeMutationHookResult = ReturnType<
  typeof useQmsUnlockExaminationByRedeemCodeMutation
>;
export type QmsUnlockExaminationByRedeemCodeMutationResult =
  Apollo.MutationResult<QmsUnlockExaminationByRedeemCodeMutation>;
export type QmsUnlockExaminationByRedeemCodeMutationOptions = Apollo.BaseMutationOptions<
  QmsUnlockExaminationByRedeemCodeMutation,
  QmsUnlockExaminationByRedeemCodeMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const QmsRedeemBookDocument = gql`
  mutation qmsRedeemBook($redeemCode: String!) {
    qmsRedeemBook(redeemCode: $redeemCode) {
      id
    }
  }
`;
export type QmsRedeemBookMutationFn = Apollo.MutationFunction<
  QmsRedeemBookMutation,
  QmsRedeemBookMutationVariables
>;

/**
 * __useQmsRedeemBookMutation__
 *
 * To run a mutation, you first call `useQmsRedeemBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsRedeemBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsRedeemBookMutation, { data, loading, error }] = useQmsRedeemBookMutation({
 *   variables: {
 *      redeemCode: // value for 'redeemCode'
 *   },
 * });
 */
export function useQmsRedeemBookMutation(
  baseOptions?: Apollo.MutationHookOptions<QmsRedeemBookMutation, QmsRedeemBookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<QmsRedeemBookMutation, QmsRedeemBookMutationVariables>(
    QmsRedeemBookDocument,
    options
  );
}
export type QmsRedeemBookMutationHookResult = ReturnType<typeof useQmsRedeemBookMutation>;
export type QmsRedeemBookMutationResult = Apollo.MutationResult<QmsRedeemBookMutation>;
export type QmsRedeemBookMutationOptions = Apollo.BaseMutationOptions<
  QmsRedeemBookMutation,
  QmsRedeemBookMutationVariables
>;
export const QmsRedeemPublicBookDocument = gql`
  mutation qmsRedeemPublicBook($bookId: String!, $isGenerateRedeemCode: Boolean) {
    qmsRedeemPublicBook(bookId: $bookId, isGenerateRedeemCode: $isGenerateRedeemCode) {
      id
      skuExamItem {
        examId
      }
    }
  }
`;
export type QmsRedeemPublicBookMutationFn = Apollo.MutationFunction<
  QmsRedeemPublicBookMutation,
  QmsRedeemPublicBookMutationVariables
>;

/**
 * __useQmsRedeemPublicBookMutation__
 *
 * To run a mutation, you first call `useQmsRedeemPublicBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsRedeemPublicBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsRedeemPublicBookMutation, { data, loading, error }] = useQmsRedeemPublicBookMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      isGenerateRedeemCode: // value for 'isGenerateRedeemCode'
 *   },
 * });
 */
export function useQmsRedeemPublicBookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsRedeemPublicBookMutation,
    QmsRedeemPublicBookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<QmsRedeemPublicBookMutation, QmsRedeemPublicBookMutationVariables>(
    QmsRedeemPublicBookDocument,
    options
  );
}
export type QmsRedeemPublicBookMutationHookResult = ReturnType<
  typeof useQmsRedeemPublicBookMutation
>;
export type QmsRedeemPublicBookMutationResult = Apollo.MutationResult<QmsRedeemPublicBookMutation>;
export type QmsRedeemPublicBookMutationOptions = Apollo.BaseMutationOptions<
  QmsRedeemPublicBookMutation,
  QmsRedeemPublicBookMutationVariables
>;
export const QmsExamRoomStartExamDocument = gql`
  mutation qmsExamRoomStartExam($examCardId: String!, $isSetTimer: Boolean!) {
    qmsExamRoomStartExam(input: { examCardId: $examCardId, isSetTimer: $isSetTimer }) {
      id
    }
  }
`;
export type QmsExamRoomStartExamMutationFn = Apollo.MutationFunction<
  QmsExamRoomStartExamMutation,
  QmsExamRoomStartExamMutationVariables
>;

/**
 * __useQmsExamRoomStartExamMutation__
 *
 * To run a mutation, you first call `useQmsExamRoomStartExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomStartExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qmsExamRoomStartExamMutation, { data, loading, error }] = useQmsExamRoomStartExamMutation({
 *   variables: {
 *      examCardId: // value for 'examCardId'
 *      isSetTimer: // value for 'isSetTimer'
 *   },
 * });
 */
export function useQmsExamRoomStartExamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QmsExamRoomStartExamMutation,
    QmsExamRoomStartExamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<QmsExamRoomStartExamMutation, QmsExamRoomStartExamMutationVariables>(
    QmsExamRoomStartExamDocument,
    options
  );
}
export type QmsExamRoomStartExamMutationHookResult = ReturnType<
  typeof useQmsExamRoomStartExamMutation
>;
export type QmsExamRoomStartExamMutationResult =
  Apollo.MutationResult<QmsExamRoomStartExamMutation>;
export type QmsExamRoomStartExamMutationOptions = Apollo.BaseMutationOptions<
  QmsExamRoomStartExamMutation,
  QmsExamRoomStartExamMutationVariables
>;
export const QmsExamRoomCheckExamStatusByExamCardIdDocument = gql`
  query qmsExamRoomCheckExamStatusByExamCardId($examCardId: String!) {
    qmsExamRoomCheckExamStatusByExamCardId(examCardId: $examCardId) {
      id
      isSetTimer
    }
  }
`;

/**
 * __useQmsExamRoomCheckExamStatusByExamCardIdQuery__
 *
 * To run a query within a React component, call `useQmsExamRoomCheckExamStatusByExamCardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomCheckExamStatusByExamCardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsExamRoomCheckExamStatusByExamCardIdQuery({
 *   variables: {
 *      examCardId: // value for 'examCardId'
 *   },
 * });
 */
export function useQmsExamRoomCheckExamStatusByExamCardIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QmsExamRoomCheckExamStatusByExamCardIdQuery,
    QmsExamRoomCheckExamStatusByExamCardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QmsExamRoomCheckExamStatusByExamCardIdQuery,
    QmsExamRoomCheckExamStatusByExamCardIdQueryVariables
  >(QmsExamRoomCheckExamStatusByExamCardIdDocument, options);
}
export function useQmsExamRoomCheckExamStatusByExamCardIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsExamRoomCheckExamStatusByExamCardIdQuery,
    QmsExamRoomCheckExamStatusByExamCardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QmsExamRoomCheckExamStatusByExamCardIdQuery,
    QmsExamRoomCheckExamStatusByExamCardIdQueryVariables
  >(QmsExamRoomCheckExamStatusByExamCardIdDocument, options);
}
export type QmsExamRoomCheckExamStatusByExamCardIdQueryHookResult = ReturnType<
  typeof useQmsExamRoomCheckExamStatusByExamCardIdQuery
>;
export type QmsExamRoomCheckExamStatusByExamCardIdLazyQueryHookResult = ReturnType<
  typeof useQmsExamRoomCheckExamStatusByExamCardIdLazyQuery
>;
export type QmsExamRoomCheckExamStatusByExamCardIdQueryResult = Apollo.QueryResult<
  QmsExamRoomCheckExamStatusByExamCardIdQuery,
  QmsExamRoomCheckExamStatusByExamCardIdQueryVariables
>;
export const QmsExamRoomCheckExamStatusByBookIdAndExamIdDocument = gql`
  query qmsExamRoomCheckExamStatusByBookIdAndExamId($bookId: String!, $examId: String!) {
    qmsExamRoomCheckExamStatusByBookIdAndExamId(bookId: $bookId, examId: $examId) {
      id
    }
  }
`;

/**
 * __useQmsExamRoomCheckExamStatusByBookIdAndExamIdQuery__
 *
 * To run a query within a React component, call `useQmsExamRoomCheckExamStatusByBookIdAndExamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomCheckExamStatusByBookIdAndExamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsExamRoomCheckExamStatusByBookIdAndExamIdQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useQmsExamRoomCheckExamStatusByBookIdAndExamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QmsExamRoomCheckExamStatusByBookIdAndExamIdQuery,
    QmsExamRoomCheckExamStatusByBookIdAndExamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QmsExamRoomCheckExamStatusByBookIdAndExamIdQuery,
    QmsExamRoomCheckExamStatusByBookIdAndExamIdQueryVariables
  >(QmsExamRoomCheckExamStatusByBookIdAndExamIdDocument, options);
}
export function useQmsExamRoomCheckExamStatusByBookIdAndExamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsExamRoomCheckExamStatusByBookIdAndExamIdQuery,
    QmsExamRoomCheckExamStatusByBookIdAndExamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QmsExamRoomCheckExamStatusByBookIdAndExamIdQuery,
    QmsExamRoomCheckExamStatusByBookIdAndExamIdQueryVariables
  >(QmsExamRoomCheckExamStatusByBookIdAndExamIdDocument, options);
}
export type QmsExamRoomCheckExamStatusByBookIdAndExamIdQueryHookResult = ReturnType<
  typeof useQmsExamRoomCheckExamStatusByBookIdAndExamIdQuery
>;
export type QmsExamRoomCheckExamStatusByBookIdAndExamIdLazyQueryHookResult = ReturnType<
  typeof useQmsExamRoomCheckExamStatusByBookIdAndExamIdLazyQuery
>;
export type QmsExamRoomCheckExamStatusByBookIdAndExamIdQueryResult = Apollo.QueryResult<
  QmsExamRoomCheckExamStatusByBookIdAndExamIdQuery,
  QmsExamRoomCheckExamStatusByBookIdAndExamIdQueryVariables
>;
export const QmsGetExamCardByIdDocument = gql`
  query qmsGetExamCardById($examCardId: String!) {
    qmsGetExamCardById(examCardId: $examCardId) {
      id
      skuExamItem {
        id
        examId
      }
    }
  }
`;

/**
 * __useQmsGetExamCardByIdQuery__
 *
 * To run a query within a React component, call `useQmsGetExamCardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsGetExamCardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsGetExamCardByIdQuery({
 *   variables: {
 *      examCardId: // value for 'examCardId'
 *   },
 * });
 */
export function useQmsGetExamCardByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QmsGetExamCardByIdQuery, QmsGetExamCardByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QmsGetExamCardByIdQuery, QmsGetExamCardByIdQueryVariables>(
    QmsGetExamCardByIdDocument,
    options
  );
}
export function useQmsGetExamCardByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsGetExamCardByIdQuery,
    QmsGetExamCardByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QmsGetExamCardByIdQuery, QmsGetExamCardByIdQueryVariables>(
    QmsGetExamCardByIdDocument,
    options
  );
}
export type QmsGetExamCardByIdQueryHookResult = ReturnType<typeof useQmsGetExamCardByIdQuery>;
export type QmsGetExamCardByIdLazyQueryHookResult = ReturnType<
  typeof useQmsGetExamCardByIdLazyQuery
>;
export type QmsGetExamCardByIdQueryResult = Apollo.QueryResult<
  QmsGetExamCardByIdQuery,
  QmsGetExamCardByIdQueryVariables
>;
export const QmsGetExamCardByBookIdAndExamIdDocument = gql`
  query qmsGetExamCardByBookIdAndExamId($bookId: String!, $examId: String!) {
    qmsGetExamCardByBookIdAndExamId(bookId: $bookId, examId: $examId) {
      id
    }
  }
`;

/**
 * __useQmsGetExamCardByBookIdAndExamIdQuery__
 *
 * To run a query within a React component, call `useQmsGetExamCardByBookIdAndExamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsGetExamCardByBookIdAndExamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsGetExamCardByBookIdAndExamIdQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useQmsGetExamCardByBookIdAndExamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QmsGetExamCardByBookIdAndExamIdQuery,
    QmsGetExamCardByBookIdAndExamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QmsGetExamCardByBookIdAndExamIdQuery,
    QmsGetExamCardByBookIdAndExamIdQueryVariables
  >(QmsGetExamCardByBookIdAndExamIdDocument, options);
}
export function useQmsGetExamCardByBookIdAndExamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsGetExamCardByBookIdAndExamIdQuery,
    QmsGetExamCardByBookIdAndExamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QmsGetExamCardByBookIdAndExamIdQuery,
    QmsGetExamCardByBookIdAndExamIdQueryVariables
  >(QmsGetExamCardByBookIdAndExamIdDocument, options);
}
export type QmsGetExamCardByBookIdAndExamIdQueryHookResult = ReturnType<
  typeof useQmsGetExamCardByBookIdAndExamIdQuery
>;
export type QmsGetExamCardByBookIdAndExamIdLazyQueryHookResult = ReturnType<
  typeof useQmsGetExamCardByBookIdAndExamIdLazyQuery
>;
export type QmsGetExamCardByBookIdAndExamIdQueryResult = Apollo.QueryResult<
  QmsGetExamCardByBookIdAndExamIdQuery,
  QmsGetExamCardByBookIdAndExamIdQueryVariables
>;
export const QmsGetBookByRedeemCodeDocument = gql`
  query qmsGetBookByRedeemCode($redeemCode: String!) {
    qmsGetBookByRedeemCode(redeemCode: $redeemCode) {
      id
      title
      authors
      skuCode
      status
      type
      refCode
      coverImageUrl
    }
  }
`;

/**
 * __useQmsGetBookByRedeemCodeQuery__
 *
 * To run a query within a React component, call `useQmsGetBookByRedeemCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsGetBookByRedeemCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsGetBookByRedeemCodeQuery({
 *   variables: {
 *      redeemCode: // value for 'redeemCode'
 *   },
 * });
 */
export function useQmsGetBookByRedeemCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    QmsGetBookByRedeemCodeQuery,
    QmsGetBookByRedeemCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QmsGetBookByRedeemCodeQuery, QmsGetBookByRedeemCodeQueryVariables>(
    QmsGetBookByRedeemCodeDocument,
    options
  );
}
export function useQmsGetBookByRedeemCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsGetBookByRedeemCodeQuery,
    QmsGetBookByRedeemCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QmsGetBookByRedeemCodeQuery, QmsGetBookByRedeemCodeQueryVariables>(
    QmsGetBookByRedeemCodeDocument,
    options
  );
}
export type QmsGetBookByRedeemCodeQueryHookResult = ReturnType<
  typeof useQmsGetBookByRedeemCodeQuery
>;
export type QmsGetBookByRedeemCodeLazyQueryHookResult = ReturnType<
  typeof useQmsGetBookByRedeemCodeLazyQuery
>;
export type QmsGetBookByRedeemCodeQueryResult = Apollo.QueryResult<
  QmsGetBookByRedeemCodeQuery,
  QmsGetBookByRedeemCodeQueryVariables
>;
export const QmsExamRoomGetExamDocument = gql`
  query qmsExamRoomGetExam {
    qmsExamRoomGetExam {
      id
      title
      metadata
      examItems {
        order
        question {
          id
          metadata
          question
          subType
          type
          examMaterialId
          examMaterial {
            id
            type
            name
            content
          }
        }
      }
    }
  }
`;

/**
 * __useQmsExamRoomGetExamQuery__
 *
 * To run a query within a React component, call `useQmsExamRoomGetExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomGetExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsExamRoomGetExamQuery({
 *   variables: {
 *   },
 * });
 */
export function useQmsExamRoomGetExamQuery(
  baseOptions?: Apollo.QueryHookOptions<QmsExamRoomGetExamQuery, QmsExamRoomGetExamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QmsExamRoomGetExamQuery, QmsExamRoomGetExamQueryVariables>(
    QmsExamRoomGetExamDocument,
    options
  );
}
export function useQmsExamRoomGetExamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsExamRoomGetExamQuery,
    QmsExamRoomGetExamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QmsExamRoomGetExamQuery, QmsExamRoomGetExamQueryVariables>(
    QmsExamRoomGetExamDocument,
    options
  );
}
export type QmsExamRoomGetExamQueryHookResult = ReturnType<typeof useQmsExamRoomGetExamQuery>;
export type QmsExamRoomGetExamLazyQueryHookResult = ReturnType<
  typeof useQmsExamRoomGetExamLazyQuery
>;
export type QmsExamRoomGetExamQueryResult = Apollo.QueryResult<
  QmsExamRoomGetExamQuery,
  QmsExamRoomGetExamQueryVariables
>;
export const QmsLoLExamRoomGetExamDocument = gql`
  query qmsLoLExamRoomGetExam {
    qmsExamRoomGetExam {
      id
      title
      metadata
      examItems {
        order
        score
        question {
          id
          metadata
          question
          subType
          type
          tags {
            id
            name
            slug
            category
          }
        }
      }
    }
  }
`;

/**
 * __useQmsLoLExamRoomGetExamQuery__
 *
 * To run a query within a React component, call `useQmsLoLExamRoomGetExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsLoLExamRoomGetExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsLoLExamRoomGetExamQuery({
 *   variables: {
 *   },
 * });
 */
export function useQmsLoLExamRoomGetExamQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QmsLoLExamRoomGetExamQuery,
    QmsLoLExamRoomGetExamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QmsLoLExamRoomGetExamQuery, QmsLoLExamRoomGetExamQueryVariables>(
    QmsLoLExamRoomGetExamDocument,
    options
  );
}
export function useQmsLoLExamRoomGetExamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsLoLExamRoomGetExamQuery,
    QmsLoLExamRoomGetExamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QmsLoLExamRoomGetExamQuery, QmsLoLExamRoomGetExamQueryVariables>(
    QmsLoLExamRoomGetExamDocument,
    options
  );
}
export type QmsLoLExamRoomGetExamQueryHookResult = ReturnType<typeof useQmsLoLExamRoomGetExamQuery>;
export type QmsLoLExamRoomGetExamLazyQueryHookResult = ReturnType<
  typeof useQmsLoLExamRoomGetExamLazyQuery
>;
export type QmsLoLExamRoomGetExamQueryResult = Apollo.QueryResult<
  QmsLoLExamRoomGetExamQuery,
  QmsLoLExamRoomGetExamQueryVariables
>;
export const QmsExamRoomGetExamSessionAnswerDocument = gql`
  query qmsExamRoomGetExamSessionAnswer {
    qmsExamRoomGetExamSessionAnswer {
      id
      examPaperId
      endedAt
      isSetTimer
      examRoomAnswer {
        examPaperId
        examRoomExamPaperItems {
          examPaperItemId
          questionId
          answer
          isBookmark
        }
      }
    }
  }
`;

/**
 * __useQmsExamRoomGetExamSessionAnswerQuery__
 *
 * To run a query within a React component, call `useQmsExamRoomGetExamSessionAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsExamRoomGetExamSessionAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsExamRoomGetExamSessionAnswerQuery({
 *   variables: {
 *   },
 * });
 */
export function useQmsExamRoomGetExamSessionAnswerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QmsExamRoomGetExamSessionAnswerQuery,
    QmsExamRoomGetExamSessionAnswerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QmsExamRoomGetExamSessionAnswerQuery,
    QmsExamRoomGetExamSessionAnswerQueryVariables
  >(QmsExamRoomGetExamSessionAnswerDocument, options);
}
export function useQmsExamRoomGetExamSessionAnswerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsExamRoomGetExamSessionAnswerQuery,
    QmsExamRoomGetExamSessionAnswerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QmsExamRoomGetExamSessionAnswerQuery,
    QmsExamRoomGetExamSessionAnswerQueryVariables
  >(QmsExamRoomGetExamSessionAnswerDocument, options);
}
export type QmsExamRoomGetExamSessionAnswerQueryHookResult = ReturnType<
  typeof useQmsExamRoomGetExamSessionAnswerQuery
>;
export type QmsExamRoomGetExamSessionAnswerLazyQueryHookResult = ReturnType<
  typeof useQmsExamRoomGetExamSessionAnswerLazyQuery
>;
export type QmsExamRoomGetExamSessionAnswerQueryResult = Apollo.QueryResult<
  QmsExamRoomGetExamSessionAnswerQuery,
  QmsExamRoomGetExamSessionAnswerQueryVariables
>;
export const GetLoLLanguageByExamIdDocument = gql`
  query getLoLLanguageByExamId($examId: String!) {
    qmsFoGetExamById(examId: $examId) {
      id
      metadata
    }
  }
`;

/**
 * __useGetLoLLanguageByExamIdQuery__
 *
 * To run a query within a React component, call `useGetLoLLanguageByExamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoLLanguageByExamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoLLanguageByExamIdQuery({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useGetLoLLanguageByExamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoLLanguageByExamIdQuery,
    GetLoLLanguageByExamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoLLanguageByExamIdQuery, GetLoLLanguageByExamIdQueryVariables>(
    GetLoLLanguageByExamIdDocument,
    options
  );
}
export function useGetLoLLanguageByExamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoLLanguageByExamIdQuery,
    GetLoLLanguageByExamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoLLanguageByExamIdQuery, GetLoLLanguageByExamIdQueryVariables>(
    GetLoLLanguageByExamIdDocument,
    options
  );
}
export type GetLoLLanguageByExamIdQueryHookResult = ReturnType<
  typeof useGetLoLLanguageByExamIdQuery
>;
export type GetLoLLanguageByExamIdLazyQueryHookResult = ReturnType<
  typeof useGetLoLLanguageByExamIdLazyQuery
>;
export type GetLoLLanguageByExamIdQueryResult = Apollo.QueryResult<
  GetLoLLanguageByExamIdQuery,
  GetLoLLanguageByExamIdQueryVariables
>;
export const GetExamByExamCardIdDocument = gql`
  query getExamByExamCardId($examCardId: String!) {
    qmsFoGetExamByExamCardId(examCardId: $examCardId) {
      id
      title
      metadata
      examItems {
        order
        question {
          type
          subType
          metadata
        }
      }
    }
  }
`;

/**
 * __useGetExamByExamCardIdQuery__
 *
 * To run a query within a React component, call `useGetExamByExamCardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamByExamCardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamByExamCardIdQuery({
 *   variables: {
 *      examCardId: // value for 'examCardId'
 *   },
 * });
 */
export function useGetExamByExamCardIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetExamByExamCardIdQuery, GetExamByExamCardIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExamByExamCardIdQuery, GetExamByExamCardIdQueryVariables>(
    GetExamByExamCardIdDocument,
    options
  );
}
export function useGetExamByExamCardIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExamByExamCardIdQuery,
    GetExamByExamCardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExamByExamCardIdQuery, GetExamByExamCardIdQueryVariables>(
    GetExamByExamCardIdDocument,
    options
  );
}
export type GetExamByExamCardIdQueryHookResult = ReturnType<typeof useGetExamByExamCardIdQuery>;
export type GetExamByExamCardIdLazyQueryHookResult = ReturnType<
  typeof useGetExamByExamCardIdLazyQuery
>;
export type GetExamByExamCardIdQueryResult = Apollo.QueryResult<
  GetExamByExamCardIdQuery,
  GetExamByExamCardIdQueryVariables
>;
export const GetExamPaperItemsByLessonDocument = gql`
  query getExamPaperItemsByLesson($examPaperId: String!) {
    qmsFoGetExamPaperById(examPaperId: $examPaperId) {
      id
      examSubjects {
        examSubjects {
          tagId
          title
          questions {
            id
          }
        }
      }
      examPaperItems {
        id
        questionId
        order
        isCorrect
        score
        answer
        question {
          id
          type
          subType
        }
      }
    }
  }
`;

/**
 * __useGetExamPaperItemsByLessonQuery__
 *
 * To run a query within a React component, call `useGetExamPaperItemsByLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamPaperItemsByLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamPaperItemsByLessonQuery({
 *   variables: {
 *      examPaperId: // value for 'examPaperId'
 *   },
 * });
 */
export function useGetExamPaperItemsByLessonQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExamPaperItemsByLessonQuery,
    GetExamPaperItemsByLessonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExamPaperItemsByLessonQuery, GetExamPaperItemsByLessonQueryVariables>(
    GetExamPaperItemsByLessonDocument,
    options
  );
}
export function useGetExamPaperItemsByLessonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExamPaperItemsByLessonQuery,
    GetExamPaperItemsByLessonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExamPaperItemsByLessonQuery,
    GetExamPaperItemsByLessonQueryVariables
  >(GetExamPaperItemsByLessonDocument, options);
}
export type GetExamPaperItemsByLessonQueryHookResult = ReturnType<
  typeof useGetExamPaperItemsByLessonQuery
>;
export type GetExamPaperItemsByLessonLazyQueryHookResult = ReturnType<
  typeof useGetExamPaperItemsByLessonLazyQuery
>;
export type GetExamPaperItemsByLessonQueryResult = Apollo.QueryResult<
  GetExamPaperItemsByLessonQuery,
  GetExamPaperItemsByLessonQueryVariables
>;
export const GetExamPaperByLessonDocument = gql`
  query getExamPaperByLesson($examPaperId: String!) {
    qmsFoGetExamPaperById(examPaperId: $examPaperId) {
      id
      examSubjects {
        examSubjects {
          tagId
          title
          examSubjectSubsections {
            tagId
            title
            questions {
              type
            }
          }
        }
      }
      myExamTagResults {
        tagId
        score
        fullScore
      }
    }
  }
`;

/**
 * __useGetExamPaperByLessonQuery__
 *
 * To run a query within a React component, call `useGetExamPaperByLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamPaperByLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamPaperByLessonQuery({
 *   variables: {
 *      examPaperId: // value for 'examPaperId'
 *   },
 * });
 */
export function useGetExamPaperByLessonQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExamPaperByLessonQuery,
    GetExamPaperByLessonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExamPaperByLessonQuery, GetExamPaperByLessonQueryVariables>(
    GetExamPaperByLessonDocument,
    options
  );
}
export function useGetExamPaperByLessonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExamPaperByLessonQuery,
    GetExamPaperByLessonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExamPaperByLessonQuery, GetExamPaperByLessonQueryVariables>(
    GetExamPaperByLessonDocument,
    options
  );
}
export type GetExamPaperByLessonQueryHookResult = ReturnType<typeof useGetExamPaperByLessonQuery>;
export type GetExamPaperByLessonLazyQueryHookResult = ReturnType<
  typeof useGetExamPaperByLessonLazyQuery
>;
export type GetExamPaperByLessonQueryResult = Apollo.QueryResult<
  GetExamPaperByLessonQuery,
  GetExamPaperByLessonQueryVariables
>;
export const GetExamPaperByIdDocument = gql`
  query getExamPaperById($examPaperId: String!) {
    qmsFoGetExamPaperById(examPaperId: $examPaperId) {
      id
      iteration
      fullScore
      score
      createdAt
      examSubjects {
        examTitle
      }
      exam {
        examItems {
          question {
            type
          }
        }
      }
    }
  }
`;

/**
 * __useGetExamPaperByIdQuery__
 *
 * To run a query within a React component, call `useGetExamPaperByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamPaperByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamPaperByIdQuery({
 *   variables: {
 *      examPaperId: // value for 'examPaperId'
 *   },
 * });
 */
export function useGetExamPaperByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetExamPaperByIdQuery, GetExamPaperByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExamPaperByIdQuery, GetExamPaperByIdQueryVariables>(
    GetExamPaperByIdDocument,
    options
  );
}
export function useGetExamPaperByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExamPaperByIdQuery, GetExamPaperByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExamPaperByIdQuery, GetExamPaperByIdQueryVariables>(
    GetExamPaperByIdDocument,
    options
  );
}
export type GetExamPaperByIdQueryHookResult = ReturnType<typeof useGetExamPaperByIdQuery>;
export type GetExamPaperByIdLazyQueryHookResult = ReturnType<typeof useGetExamPaperByIdLazyQuery>;
export type GetExamPaperByIdQueryResult = Apollo.QueryResult<
  GetExamPaperByIdQuery,
  GetExamPaperByIdQueryVariables
>;
export const GetExamResultSolutionDetailsDocument = gql`
  query getExamResultSolutionDetails($examPaperItemId: String!) {
    qmsGetExamPaperItemById(id: $examPaperItemId) {
      id
      question {
        id
        solution {
          type
          content
        }
      }
    }
  }
`;

/**
 * __useGetExamResultSolutionDetailsQuery__
 *
 * To run a query within a React component, call `useGetExamResultSolutionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamResultSolutionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamResultSolutionDetailsQuery({
 *   variables: {
 *      examPaperItemId: // value for 'examPaperItemId'
 *   },
 * });
 */
export function useGetExamResultSolutionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExamResultSolutionDetailsQuery,
    GetExamResultSolutionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExamResultSolutionDetailsQuery,
    GetExamResultSolutionDetailsQueryVariables
  >(GetExamResultSolutionDetailsDocument, options);
}
export function useGetExamResultSolutionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExamResultSolutionDetailsQuery,
    GetExamResultSolutionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExamResultSolutionDetailsQuery,
    GetExamResultSolutionDetailsQueryVariables
  >(GetExamResultSolutionDetailsDocument, options);
}
export type GetExamResultSolutionDetailsQueryHookResult = ReturnType<
  typeof useGetExamResultSolutionDetailsQuery
>;
export type GetExamResultSolutionDetailsLazyQueryHookResult = ReturnType<
  typeof useGetExamResultSolutionDetailsLazyQuery
>;
export type GetExamResultSolutionDetailsQueryResult = Apollo.QueryResult<
  GetExamResultSolutionDetailsQuery,
  GetExamResultSolutionDetailsQueryVariables
>;
export const GetExamResultSolutionSummaryDocument = gql`
  query getExamResultSolutionSummary($examPaperItemId: String!) {
    qmsGetExamPaperItemById(id: $examPaperItemId) {
      id
      answer
      order
      exam {
        title
      }
      isCorrect
      score
      question {
        id
        type
        subType
        question
        correctAnswer
        tags {
          id
          name
          category
        }
        difficulty
      }
    }
  }
`;

/**
 * __useGetExamResultSolutionSummaryQuery__
 *
 * To run a query within a React component, call `useGetExamResultSolutionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamResultSolutionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamResultSolutionSummaryQuery({
 *   variables: {
 *      examPaperItemId: // value for 'examPaperItemId'
 *   },
 * });
 */
export function useGetExamResultSolutionSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExamResultSolutionSummaryQuery,
    GetExamResultSolutionSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExamResultSolutionSummaryQuery,
    GetExamResultSolutionSummaryQueryVariables
  >(GetExamResultSolutionSummaryDocument, options);
}
export function useGetExamResultSolutionSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExamResultSolutionSummaryQuery,
    GetExamResultSolutionSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExamResultSolutionSummaryQuery,
    GetExamResultSolutionSummaryQueryVariables
  >(GetExamResultSolutionSummaryDocument, options);
}
export type GetExamResultSolutionSummaryQueryHookResult = ReturnType<
  typeof useGetExamResultSolutionSummaryQuery
>;
export type GetExamResultSolutionSummaryLazyQueryHookResult = ReturnType<
  typeof useGetExamResultSolutionSummaryLazyQuery
>;
export type GetExamResultSolutionSummaryQueryResult = Apollo.QueryResult<
  GetExamResultSolutionSummaryQuery,
  GetExamResultSolutionSummaryQueryVariables
>;
export const GetExamSolutionDetailDocument = gql`
  query GetExamSolutionDetail($id: String!) {
    qmsExamItemById(id: $id) {
      id
      order
      exam {
        id
        title
      }
      question {
        id
        question
        type
        subType
        correctAnswer
        difficulty
        tags {
          id
          name
          category
        }
        solution {
          type
          content
        }
      }
    }
  }
`;

/**
 * __useGetExamSolutionDetailQuery__
 *
 * To run a query within a React component, call `useGetExamSolutionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamSolutionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamSolutionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExamSolutionDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExamSolutionDetailQuery,
    GetExamSolutionDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExamSolutionDetailQuery, GetExamSolutionDetailQueryVariables>(
    GetExamSolutionDetailDocument,
    options
  );
}
export function useGetExamSolutionDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExamSolutionDetailQuery,
    GetExamSolutionDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExamSolutionDetailQuery, GetExamSolutionDetailQueryVariables>(
    GetExamSolutionDetailDocument,
    options
  );
}
export type GetExamSolutionDetailQueryHookResult = ReturnType<typeof useGetExamSolutionDetailQuery>;
export type GetExamSolutionDetailLazyQueryHookResult = ReturnType<
  typeof useGetExamSolutionDetailLazyQuery
>;
export type GetExamSolutionDetailQueryResult = Apollo.QueryResult<
  GetExamSolutionDetailQuery,
  GetExamSolutionDetailQueryVariables
>;
export const GetSolutionByIdDocument = gql`
  query getSolutionById($examId: String!) {
    qmsFoGetExamById(examId: $examId) {
      id
      title
      examSubjects {
        id
        title
        totalQuestionCount
        questions {
          id
          correctAnswer
          type
          subType
        }
      }
      examItems {
        id
        questionId
        order
      }
    }
  }
`;

/**
 * __useGetSolutionByIdQuery__
 *
 * To run a query within a React component, call `useGetSolutionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSolutionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSolutionByIdQuery({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useGetSolutionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetSolutionByIdQuery, GetSolutionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSolutionByIdQuery, GetSolutionByIdQueryVariables>(
    GetSolutionByIdDocument,
    options
  );
}
export function useGetSolutionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSolutionByIdQuery, GetSolutionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSolutionByIdQuery, GetSolutionByIdQueryVariables>(
    GetSolutionByIdDocument,
    options
  );
}
export type GetSolutionByIdQueryHookResult = ReturnType<typeof useGetSolutionByIdQuery>;
export type GetSolutionByIdLazyQueryHookResult = ReturnType<typeof useGetSolutionByIdLazyQuery>;
export type GetSolutionByIdQueryResult = Apollo.QueryResult<
  GetSolutionByIdQuery,
  GetSolutionByIdQueryVariables
>;
export const QmsMySkuRedeemCodeByBookIdDocument = gql`
  query qmsMySkuRedeemCodeByBookId($bookId: String!) {
    qmsMySkuRedeemCodeByBookId(bookId: $bookId) {
      id
      status
    }
  }
`;

/**
 * __useQmsMySkuRedeemCodeByBookIdQuery__
 *
 * To run a query within a React component, call `useQmsMySkuRedeemCodeByBookIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsMySkuRedeemCodeByBookIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsMySkuRedeemCodeByBookIdQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useQmsMySkuRedeemCodeByBookIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QmsMySkuRedeemCodeByBookIdQuery,
    QmsMySkuRedeemCodeByBookIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QmsMySkuRedeemCodeByBookIdQuery, QmsMySkuRedeemCodeByBookIdQueryVariables>(
    QmsMySkuRedeemCodeByBookIdDocument,
    options
  );
}
export function useQmsMySkuRedeemCodeByBookIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QmsMySkuRedeemCodeByBookIdQuery,
    QmsMySkuRedeemCodeByBookIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QmsMySkuRedeemCodeByBookIdQuery,
    QmsMySkuRedeemCodeByBookIdQueryVariables
  >(QmsMySkuRedeemCodeByBookIdDocument, options);
}
export type QmsMySkuRedeemCodeByBookIdQueryHookResult = ReturnType<
  typeof useQmsMySkuRedeemCodeByBookIdQuery
>;
export type QmsMySkuRedeemCodeByBookIdLazyQueryHookResult = ReturnType<
  typeof useQmsMySkuRedeemCodeByBookIdLazyQuery
>;
export type QmsMySkuRedeemCodeByBookIdQueryResult = Apollo.QueryResult<
  QmsMySkuRedeemCodeByBookIdQuery,
  QmsMySkuRedeemCodeByBookIdQueryVariables
>;
export const GetExamPaperIdByExamCardIdDocument = gql`
  query getExamPaperIdByExamCardId($examCardId: String!) {
    qmsFoGetExamPapersByCardId(examCardId: $examCardId) {
      id
    }
  }
`;

/**
 * __useGetExamPaperIdByExamCardIdQuery__
 *
 * To run a query within a React component, call `useGetExamPaperIdByExamCardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamPaperIdByExamCardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamPaperIdByExamCardIdQuery({
 *   variables: {
 *      examCardId: // value for 'examCardId'
 *   },
 * });
 */
export function useGetExamPaperIdByExamCardIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExamPaperIdByExamCardIdQuery,
    GetExamPaperIdByExamCardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExamPaperIdByExamCardIdQuery, GetExamPaperIdByExamCardIdQueryVariables>(
    GetExamPaperIdByExamCardIdDocument,
    options
  );
}
export function useGetExamPaperIdByExamCardIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExamPaperIdByExamCardIdQuery,
    GetExamPaperIdByExamCardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExamPaperIdByExamCardIdQuery,
    GetExamPaperIdByExamCardIdQueryVariables
  >(GetExamPaperIdByExamCardIdDocument, options);
}
export type GetExamPaperIdByExamCardIdQueryHookResult = ReturnType<
  typeof useGetExamPaperIdByExamCardIdQuery
>;
export type GetExamPaperIdByExamCardIdLazyQueryHookResult = ReturnType<
  typeof useGetExamPaperIdByExamCardIdLazyQuery
>;
export type GetExamPaperIdByExamCardIdQueryResult = Apollo.QueryResult<
  GetExamPaperIdByExamCardIdQuery,
  GetExamPaperIdByExamCardIdQueryVariables
>;
export const GetLoLExamByExamIdDocument = gql`
  query getLoLExamByExamId($examId: String!) {
    qmsFoGetExamById(examId: $examId) {
      id
      metadata
      examItems {
        order
        question {
          id
          tags {
            id
            name
            slug
            category
          }
        }
      }
    }
  }
`;

/**
 * __useGetLoLExamByExamIdQuery__
 *
 * To run a query within a React component, call `useGetLoLExamByExamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoLExamByExamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoLExamByExamIdQuery({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useGetLoLExamByExamIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetLoLExamByExamIdQuery, GetLoLExamByExamIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoLExamByExamIdQuery, GetLoLExamByExamIdQueryVariables>(
    GetLoLExamByExamIdDocument,
    options
  );
}
export function useGetLoLExamByExamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoLExamByExamIdQuery,
    GetLoLExamByExamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoLExamByExamIdQuery, GetLoLExamByExamIdQueryVariables>(
    GetLoLExamByExamIdDocument,
    options
  );
}
export type GetLoLExamByExamIdQueryHookResult = ReturnType<typeof useGetLoLExamByExamIdQuery>;
export type GetLoLExamByExamIdLazyQueryHookResult = ReturnType<
  typeof useGetLoLExamByExamIdLazyQuery
>;
export type GetLoLExamByExamIdQueryResult = Apollo.QueryResult<
  GetLoLExamByExamIdQuery,
  GetLoLExamByExamIdQueryVariables
>;
export const GetLoLExamByExamPaperIdDocument = gql`
  query getLoLExamByExamPaperId($examPaperId: String!) {
    qmsFoGetExamPaperById(examPaperId: $examPaperId) {
      id
      exam {
        id
        metadata
        examItems {
          id
          order
          question {
            id
            tags {
              id
              name
              slug
              category
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetLoLExamByExamPaperIdQuery__
 *
 * To run a query within a React component, call `useGetLoLExamByExamPaperIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoLExamByExamPaperIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoLExamByExamPaperIdQuery({
 *   variables: {
 *      examPaperId: // value for 'examPaperId'
 *   },
 * });
 */
export function useGetLoLExamByExamPaperIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoLExamByExamPaperIdQuery,
    GetLoLExamByExamPaperIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoLExamByExamPaperIdQuery, GetLoLExamByExamPaperIdQueryVariables>(
    GetLoLExamByExamPaperIdDocument,
    options
  );
}
export function useGetLoLExamByExamPaperIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoLExamByExamPaperIdQuery,
    GetLoLExamByExamPaperIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoLExamByExamPaperIdQuery, GetLoLExamByExamPaperIdQueryVariables>(
    GetLoLExamByExamPaperIdDocument,
    options
  );
}
export type GetLoLExamByExamPaperIdQueryHookResult = ReturnType<
  typeof useGetLoLExamByExamPaperIdQuery
>;
export type GetLoLExamByExamPaperIdLazyQueryHookResult = ReturnType<
  typeof useGetLoLExamByExamPaperIdLazyQuery
>;
export type GetLoLExamByExamPaperIdQueryResult = Apollo.QueryResult<
  GetLoLExamByExamPaperIdQuery,
  GetLoLExamByExamPaperIdQueryVariables
>;
export const GetCareerReportByExamPaperIdDocument = gql`
  query getCareerReportByExamPaperId($examPaperId: String!) {
    qmsGetLOLExamReport(examPaperId: $examPaperId) {
      id
      careers {
        ...LOL_EXAM_RESULT
      }
    }
  }
  ${Lol_Exam_ResultFragmentDoc}
`;

/**
 * __useGetCareerReportByExamPaperIdQuery__
 *
 * To run a query within a React component, call `useGetCareerReportByExamPaperIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerReportByExamPaperIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerReportByExamPaperIdQuery({
 *   variables: {
 *      examPaperId: // value for 'examPaperId'
 *   },
 * });
 */
export function useGetCareerReportByExamPaperIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCareerReportByExamPaperIdQuery,
    GetCareerReportByExamPaperIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCareerReportByExamPaperIdQuery,
    GetCareerReportByExamPaperIdQueryVariables
  >(GetCareerReportByExamPaperIdDocument, options);
}
export function useGetCareerReportByExamPaperIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCareerReportByExamPaperIdQuery,
    GetCareerReportByExamPaperIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCareerReportByExamPaperIdQuery,
    GetCareerReportByExamPaperIdQueryVariables
  >(GetCareerReportByExamPaperIdDocument, options);
}
export type GetCareerReportByExamPaperIdQueryHookResult = ReturnType<
  typeof useGetCareerReportByExamPaperIdQuery
>;
export type GetCareerReportByExamPaperIdLazyQueryHookResult = ReturnType<
  typeof useGetCareerReportByExamPaperIdLazyQuery
>;
export type GetCareerReportByExamPaperIdQueryResult = Apollo.QueryResult<
  GetCareerReportByExamPaperIdQuery,
  GetCareerReportByExamPaperIdQueryVariables
>;
export const GetPreferenceReportByExamPaperIdDocument = gql`
  query getPreferenceReportByExamPaperId($examPaperId: String!) {
    qmsGetLOLExamReport(examPaperId: $examPaperId) {
      id
      preferences {
        ...LOL_EXAM_RESULT
      }
    }
  }
  ${Lol_Exam_ResultFragmentDoc}
`;

/**
 * __useGetPreferenceReportByExamPaperIdQuery__
 *
 * To run a query within a React component, call `useGetPreferenceReportByExamPaperIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreferenceReportByExamPaperIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreferenceReportByExamPaperIdQuery({
 *   variables: {
 *      examPaperId: // value for 'examPaperId'
 *   },
 * });
 */
export function useGetPreferenceReportByExamPaperIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPreferenceReportByExamPaperIdQuery,
    GetPreferenceReportByExamPaperIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPreferenceReportByExamPaperIdQuery,
    GetPreferenceReportByExamPaperIdQueryVariables
  >(GetPreferenceReportByExamPaperIdDocument, options);
}
export function useGetPreferenceReportByExamPaperIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPreferenceReportByExamPaperIdQuery,
    GetPreferenceReportByExamPaperIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPreferenceReportByExamPaperIdQuery,
    GetPreferenceReportByExamPaperIdQueryVariables
  >(GetPreferenceReportByExamPaperIdDocument, options);
}
export type GetPreferenceReportByExamPaperIdQueryHookResult = ReturnType<
  typeof useGetPreferenceReportByExamPaperIdQuery
>;
export type GetPreferenceReportByExamPaperIdLazyQueryHookResult = ReturnType<
  typeof useGetPreferenceReportByExamPaperIdLazyQuery
>;
export type GetPreferenceReportByExamPaperIdQueryResult = Apollo.QueryResult<
  GetPreferenceReportByExamPaperIdQuery,
  GetPreferenceReportByExamPaperIdQueryVariables
>;
export const GetCharacterReportByExamPaperIdDocument = gql`
  query getCharacterReportByExamPaperId($examPaperId: String!) {
    qmsGetLOLExamReport(examPaperId: $examPaperId) {
      id
      characters {
        ...LOL_EXAM_RESULT
      }
    }
  }
  ${Lol_Exam_ResultFragmentDoc}
`;

/**
 * __useGetCharacterReportByExamPaperIdQuery__
 *
 * To run a query within a React component, call `useGetCharacterReportByExamPaperIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCharacterReportByExamPaperIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCharacterReportByExamPaperIdQuery({
 *   variables: {
 *      examPaperId: // value for 'examPaperId'
 *   },
 * });
 */
export function useGetCharacterReportByExamPaperIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCharacterReportByExamPaperIdQuery,
    GetCharacterReportByExamPaperIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCharacterReportByExamPaperIdQuery,
    GetCharacterReportByExamPaperIdQueryVariables
  >(GetCharacterReportByExamPaperIdDocument, options);
}
export function useGetCharacterReportByExamPaperIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCharacterReportByExamPaperIdQuery,
    GetCharacterReportByExamPaperIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCharacterReportByExamPaperIdQuery,
    GetCharacterReportByExamPaperIdQueryVariables
  >(GetCharacterReportByExamPaperIdDocument, options);
}
export type GetCharacterReportByExamPaperIdQueryHookResult = ReturnType<
  typeof useGetCharacterReportByExamPaperIdQuery
>;
export type GetCharacterReportByExamPaperIdLazyQueryHookResult = ReturnType<
  typeof useGetCharacterReportByExamPaperIdLazyQuery
>;
export type GetCharacterReportByExamPaperIdQueryResult = Apollo.QueryResult<
  GetCharacterReportByExamPaperIdQuery,
  GetCharacterReportByExamPaperIdQueryVariables
>;
export const GetQmsMyBooksDocument = gql`
  query getQmsMyBooks {
    qmsMyBooks {
      ...BOOK_DETAILS
      coverImageUrl
    }
  }
  ${Book_DetailsFragmentDoc}
`;

/**
 * __useGetQmsMyBooksQuery__
 *
 * To run a query within a React component, call `useGetQmsMyBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQmsMyBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQmsMyBooksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQmsMyBooksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetQmsMyBooksQuery, GetQmsMyBooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQmsMyBooksQuery, GetQmsMyBooksQueryVariables>(
    GetQmsMyBooksDocument,
    options
  );
}
export function useGetQmsMyBooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQmsMyBooksQuery, GetQmsMyBooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQmsMyBooksQuery, GetQmsMyBooksQueryVariables>(
    GetQmsMyBooksDocument,
    options
  );
}
export type GetQmsMyBooksQueryHookResult = ReturnType<typeof useGetQmsMyBooksQuery>;
export type GetQmsMyBooksLazyQueryHookResult = ReturnType<typeof useGetQmsMyBooksLazyQuery>;
export type GetQmsMyBooksQueryResult = Apollo.QueryResult<
  GetQmsMyBooksQuery,
  GetQmsMyBooksQueryVariables
>;
export const GetQmsMyBookDetailsDocument = gql`
  query getQmsMyBookDetails($bookId: String!) {
    qmsGetBookDetailsByBookId(bookId: $bookId) {
      ...BOOK_DETAILS
      coverImageUrl
      file {
        key
        bucket
      }
      exams {
        id
        title
        type
        metadata
      }
    }
  }
  ${Book_DetailsFragmentDoc}
`;

/**
 * __useGetQmsMyBookDetailsQuery__
 *
 * To run a query within a React component, call `useGetQmsMyBookDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQmsMyBookDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQmsMyBookDetailsQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetQmsMyBookDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetQmsMyBookDetailsQuery, GetQmsMyBookDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQmsMyBookDetailsQuery, GetQmsMyBookDetailsQueryVariables>(
    GetQmsMyBookDetailsDocument,
    options
  );
}
export function useGetQmsMyBookDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQmsMyBookDetailsQuery,
    GetQmsMyBookDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQmsMyBookDetailsQuery, GetQmsMyBookDetailsQueryVariables>(
    GetQmsMyBookDetailsDocument,
    options
  );
}
export type GetQmsMyBookDetailsQueryHookResult = ReturnType<typeof useGetQmsMyBookDetailsQuery>;
export type GetQmsMyBookDetailsLazyQueryHookResult = ReturnType<
  typeof useGetQmsMyBookDetailsLazyQuery
>;
export type GetQmsMyBookDetailsQueryResult = Apollo.QueryResult<
  GetQmsMyBookDetailsQuery,
  GetQmsMyBookDetailsQueryVariables
>;
export const GetQmsMyBookMetadataDocument = gql`
  query getQmsMyBookMetadata($bookId: String!) {
    qmsGetBookDetailsByBookId(bookId: $bookId) {
      id
      metadata
    }
  }
`;

/**
 * __useGetQmsMyBookMetadataQuery__
 *
 * To run a query within a React component, call `useGetQmsMyBookMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQmsMyBookMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQmsMyBookMetadataQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetQmsMyBookMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQmsMyBookMetadataQuery,
    GetQmsMyBookMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQmsMyBookMetadataQuery, GetQmsMyBookMetadataQueryVariables>(
    GetQmsMyBookMetadataDocument,
    options
  );
}
export function useGetQmsMyBookMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQmsMyBookMetadataQuery,
    GetQmsMyBookMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQmsMyBookMetadataQuery, GetQmsMyBookMetadataQueryVariables>(
    GetQmsMyBookMetadataDocument,
    options
  );
}
export type GetQmsMyBookMetadataQueryHookResult = ReturnType<typeof useGetQmsMyBookMetadataQuery>;
export type GetQmsMyBookMetadataLazyQueryHookResult = ReturnType<
  typeof useGetQmsMyBookMetadataLazyQuery
>;
export type GetQmsMyBookMetadataQueryResult = Apollo.QueryResult<
  GetQmsMyBookMetadataQuery,
  GetQmsMyBookMetadataQueryVariables
>;
export const GetQmsExamDetailsByBookIdDocument = gql`
  query getQmsExamDetailsByBookId($bookId: String!) {
    qmsGetBookDetailsByBookId(bookId: $bookId) {
      id
      bookExamItems {
        examId
        order
      }
      exams {
        id
        title
        type
        metadata
      }
    }
  }
`;

/**
 * __useGetQmsExamDetailsByBookIdQuery__
 *
 * To run a query within a React component, call `useGetQmsExamDetailsByBookIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQmsExamDetailsByBookIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQmsExamDetailsByBookIdQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetQmsExamDetailsByBookIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQmsExamDetailsByBookIdQuery,
    GetQmsExamDetailsByBookIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQmsExamDetailsByBookIdQuery, GetQmsExamDetailsByBookIdQueryVariables>(
    GetQmsExamDetailsByBookIdDocument,
    options
  );
}
export function useGetQmsExamDetailsByBookIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQmsExamDetailsByBookIdQuery,
    GetQmsExamDetailsByBookIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetQmsExamDetailsByBookIdQuery,
    GetQmsExamDetailsByBookIdQueryVariables
  >(GetQmsExamDetailsByBookIdDocument, options);
}
export type GetQmsExamDetailsByBookIdQueryHookResult = ReturnType<
  typeof useGetQmsExamDetailsByBookIdQuery
>;
export type GetQmsExamDetailsByBookIdLazyQueryHookResult = ReturnType<
  typeof useGetQmsExamDetailsByBookIdLazyQuery
>;
export type GetQmsExamDetailsByBookIdQueryResult = Apollo.QueryResult<
  GetQmsExamDetailsByBookIdQuery,
  GetQmsExamDetailsByBookIdQueryVariables
>;
export const GetMyExamCardsDocument = gql`
  query getMyExamCards($bookId: String!) {
    qmsMyExamCards(bookId: $bookId) {
      id
      skuExamItem {
        order
        exam {
          id
          title
        }
      }
    }
  }
`;

/**
 * __useGetMyExamCardsQuery__
 *
 * To run a query within a React component, call `useGetMyExamCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyExamCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyExamCardsQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetMyExamCardsQuery(
  baseOptions: Apollo.QueryHookOptions<GetMyExamCardsQuery, GetMyExamCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyExamCardsQuery, GetMyExamCardsQueryVariables>(
    GetMyExamCardsDocument,
    options
  );
}
export function useGetMyExamCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyExamCardsQuery, GetMyExamCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyExamCardsQuery, GetMyExamCardsQueryVariables>(
    GetMyExamCardsDocument,
    options
  );
}
export type GetMyExamCardsQueryHookResult = ReturnType<typeof useGetMyExamCardsQuery>;
export type GetMyExamCardsLazyQueryHookResult = ReturnType<typeof useGetMyExamCardsLazyQuery>;
export type GetMyExamCardsQueryResult = Apollo.QueryResult<
  GetMyExamCardsQuery,
  GetMyExamCardsQueryVariables
>;
export const GetMyLatestExamPapersDocument = gql`
  query getMyLatestExamPapers($bookId: String!) {
    qmsGetBookDetailsByBookId(bookId: $bookId) {
      id
      myLatestExamPapers {
        id
        examCardId
        status
        isTimeUp
      }
    }
  }
`;

/**
 * __useGetMyLatestExamPapersQuery__
 *
 * To run a query within a React component, call `useGetMyLatestExamPapersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLatestExamPapersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLatestExamPapersQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetMyLatestExamPapersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyLatestExamPapersQuery,
    GetMyLatestExamPapersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyLatestExamPapersQuery, GetMyLatestExamPapersQueryVariables>(
    GetMyLatestExamPapersDocument,
    options
  );
}
export function useGetMyLatestExamPapersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLatestExamPapersQuery,
    GetMyLatestExamPapersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyLatestExamPapersQuery, GetMyLatestExamPapersQueryVariables>(
    GetMyLatestExamPapersDocument,
    options
  );
}
export type GetMyLatestExamPapersQueryHookResult = ReturnType<typeof useGetMyLatestExamPapersQuery>;
export type GetMyLatestExamPapersLazyQueryHookResult = ReturnType<
  typeof useGetMyLatestExamPapersLazyQuery
>;
export type GetMyLatestExamPapersQueryResult = Apollo.QueryResult<
  GetMyLatestExamPapersQuery,
  GetMyLatestExamPapersQueryVariables
>;
export const QmsFoMyExamPapersDocument = gql`
  query qmsFoMyExamPapers(
    $myExamPapersInput: QMSGetMyExamPapersInput!
    $paging: QMSPageInput
    $order: QMSOrderInput
  ) {
    qmsFoMyExamPapers(myExamPapersInput: $myExamPapersInput, paging: $paging, order: $order) {
      examPapers {
        id
        examId
        fullScore
        score
        status
        createdAt
        iteration
        examPaperItems {
          question {
            type
          }
        }
      }
      total
    }
  }
`;

/**
 * __useQmsFoMyExamPapersQuery__
 *
 * To run a query within a React component, call `useQmsFoMyExamPapersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmsFoMyExamPapersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmsFoMyExamPapersQuery({
 *   variables: {
 *      myExamPapersInput: // value for 'myExamPapersInput'
 *      paging: // value for 'paging'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useQmsFoMyExamPapersQuery(
  baseOptions: Apollo.QueryHookOptions<QmsFoMyExamPapersQuery, QmsFoMyExamPapersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QmsFoMyExamPapersQuery, QmsFoMyExamPapersQueryVariables>(
    QmsFoMyExamPapersDocument,
    options
  );
}
export function useQmsFoMyExamPapersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QmsFoMyExamPapersQuery, QmsFoMyExamPapersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QmsFoMyExamPapersQuery, QmsFoMyExamPapersQueryVariables>(
    QmsFoMyExamPapersDocument,
    options
  );
}
export type QmsFoMyExamPapersQueryHookResult = ReturnType<typeof useQmsFoMyExamPapersQuery>;
export type QmsFoMyExamPapersLazyQueryHookResult = ReturnType<typeof useQmsFoMyExamPapersLazyQuery>;
export type QmsFoMyExamPapersQueryResult = Apollo.QueryResult<
  QmsFoMyExamPapersQuery,
  QmsFoMyExamPapersQueryVariables
>;
export const GetMyProfileDocument = gql`
  query getMyProfile {
    getMyProfile {
      id
      firstname
      lastname
      additional1
    }
  }
`;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options
  );
}
export function useGetMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options
  );
}
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>;
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>;
export type GetMyProfileQueryResult = Apollo.QueryResult<
  GetMyProfileQuery,
  GetMyProfileQueryVariables
>;
export const GetQmsPublicBookDetailDocument = gql`
  query getQmsPublicBookDetail($bookId: String!) {
    qmsGetBookDetailsByBookId(bookId: $bookId) {
      id
      type
      exams {
        id
        title
        metadata
        examItems {
          id
          order
          question {
            id
            question
            type
            subType
            metadata
            tags {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetQmsPublicBookDetailQuery__
 *
 * To run a query within a React component, call `useGetQmsPublicBookDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQmsPublicBookDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQmsPublicBookDetailQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetQmsPublicBookDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQmsPublicBookDetailQuery,
    GetQmsPublicBookDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQmsPublicBookDetailQuery, GetQmsPublicBookDetailQueryVariables>(
    GetQmsPublicBookDetailDocument,
    options
  );
}
export function useGetQmsPublicBookDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQmsPublicBookDetailQuery,
    GetQmsPublicBookDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQmsPublicBookDetailQuery, GetQmsPublicBookDetailQueryVariables>(
    GetQmsPublicBookDetailDocument,
    options
  );
}
export type GetQmsPublicBookDetailQueryHookResult = ReturnType<
  typeof useGetQmsPublicBookDetailQuery
>;
export type GetQmsPublicBookDetailLazyQueryHookResult = ReturnType<
  typeof useGetQmsPublicBookDetailLazyQuery
>;
export type GetQmsPublicBookDetailQueryResult = Apollo.QueryResult<
  GetQmsPublicBookDetailQuery,
  GetQmsPublicBookDetailQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
