import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import { IconButton, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '../../Components/Base';
import useGoogleTagManager from '../../Utils/useGoogleTagManager';
import { Lol_Exam_Result_Type } from '../../generated/graphql';

import {
  getCategoryAndRefCode,
  getEventNameByCategory,
  getGMTParamsByResultType,
  getSharingUrl,
  shareToFacebook,
  shareToTwitter,
} from './utils';

const ShareButton = styled(IconButton)({
  color: 'inherit',
  padding: '0px',
});

type LoLShareButtonProps = {
  permalink: string;
  type?: Lol_Exam_Result_Type;
};

const LoLShareButton = ({ permalink, type }: LoLShareButtonProps) => {
  const { t } = useTranslation('lolResults');
  const sendDataToGTM = useGoogleTagManager();

  const handleSharing = ({
    social,
    shouldAddUtm = true,
    share,
  }: {
    social: string;
    share: (url: string) => void;
    shouldAddUtm?: boolean;
  }) => {
    const { category, refCode } = getCategoryAndRefCode(permalink);
    const eventName = getEventNameByCategory(category);
    const resultType = getGMTParamsByResultType(type);

    sendDataToGTM({
      event: eventName,
      social,
      resultType,
      refCode,
    });

    const url = getSharingUrl(permalink, social, 'learn-o-life', shouldAddUtm);
    share(url);
  };

  return (
    <Flex justifyContent={'center'} gap={2} color={'lolText.secondary'}>
      <Text>{t('SHARE')}</Text>
      <Flex gap={1}>
        <ShareButton onClick={() => handleSharing({ social: 'facebook', share: shareToFacebook })}>
          <FacebookRoundedIcon />
        </ShareButton>
        <ShareButton
          onClick={() =>
            handleSharing({
              social: 'twitter',
              share: (link) => shareToTwitter(link, 'ผลลัพธ์ของฉัน'),
            })
          }
        >
          <TwitterIcon />
        </ShareButton>
      </Flex>
    </Flex>
  );
};

export default LoLShareButton;
