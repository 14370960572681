import { Checkbox, CheckboxProps, styled } from '@mui/material';
import { useController, UseControllerProps } from 'react-hook-form';

import { IconButton } from '../Base';
import BookmarkBorderIcon from '../Icons/BookmarkBorderIcon';
import BookmarkIcon from '../Icons/BookmarkIcon';

type FormBookmarkInputProps = UseControllerProps & CheckboxProps;

const HiddenCheckbox = styled(Checkbox)`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: none;
`;

const FormBookmarkInput = (props: FormBookmarkInputProps): JSX.Element => {
  const { field } = useController({
    ...props,
    name: props.name,
  });
  const isBookmarked = field.value ?? false;

  return (
    <IconButton component={'label'} sx={{ bgcolor: 'transparent', p: { md: 0.25 } }}>
      {isBookmarked ? (
        <BookmarkIcon color={'warning'} />
      ) : (
        <BookmarkBorderIcon sx={{ color: 'grey.400' }} />
      )}
      <HiddenCheckbox checked={isBookmarked} {...field} {...props} />
    </IconButton>
  );
};

export default FormBookmarkInput;
