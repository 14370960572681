import { useParams } from 'react-router-dom';

import { Box, Text, Skeleton } from '../../../../Components/Base';
import useGetLatestExaminationResult, {
  QmsExamPaperStatus,
} from '../../../../Domains/useGetLatestExaminationResult';

import ExaminationSubmitButton from './ExaminationSubmitButton';
import ContinueExaminationButton from './ContinueExaminationButton';
import StartExaminationButton from './StartExaminationButton';

const PrivateExaminationActionButton = () => {
  const { bookId = '', examCardId = '' } = useParams();

  const { data: latestExaminationResultData, loading } = useGetLatestExaminationResult({
    variables: { bookId },
  });
  const latestExaminationResult =
    latestExaminationResultData?.qmsGetBookDetailsByBookId.myLatestExamPapers;
  const examinationResult = latestExaminationResult?.find((exam) => exam.examCardId === examCardId);
  const { status, isTimeUp = false } = examinationResult ?? {};
  const isExamPaperTimeUp =
    (status === QmsExamPaperStatus.InProgress && isTimeUp) || status === QmsExamPaperStatus.TimeUp;

  const renderExaminationActionButton = () => {
    if (loading) {
      return (
        <Skeleton
          width={'100%'}
          height={48}
          sx={{ borderRadius: 48, mx: 'auto', maxWidth: { xs: 1, sm: '343px' } }}
        />
      );
    }

    if (isExamPaperTimeUp) return <ExaminationSubmitButton />;
    if (status === QmsExamPaperStatus.InProgress) return <ContinueExaminationButton />;
    return <StartExaminationButton />;
  };

  return (
    <Box textAlign={'center'} px={{ xs: 2, md: 0 }} pb={2}>
      {renderExaminationActionButton()}
      {isExamPaperTimeUp && (
        <Text variant="body2" color="text.secondary" mt={3}>
          กรุณากดส่งข้อสอบ ก่อนเริ่มทำข้อสอบใหม่
          <br />
          เนื่องจากหมดเวลาสอบ
        </Text>
      )}
    </Box>
  );
};

export default PrivateExaminationActionButton;
