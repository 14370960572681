import { SvgIcon, SvgIconProps } from '@mui/material';

const CareerGroupIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <g xmlns="http://www.w3.org/2000/svg" id="RIASEC">
        <path
          id="Vector"
          d="M16.6663 32.9133V27.3333C16.6663 25.195 18.3247 23.5133 20.778 22.5533C19.1959 21.9648 17.5211 21.6645 15.833 21.6666C12.683 21.6666 9.77301 22.695 7.42134 24.4333C8.15052 26.4955 9.37482 28.3472 10.9868 29.8258C12.5987 31.3043 14.549 32.3645 16.6663 32.9133ZM31.4663 26.81C30.808 25.9216 28.6163 25 25.833 25C22.4897 25 19.9997 26.3283 19.9997 27.3333V33.3333C22.3127 33.3346 24.5863 32.7338 26.5968 31.5901C28.6073 30.4463 30.2855 28.7989 31.4663 26.81ZM15.9163 19.1666C16.9109 19.1666 17.8647 18.7716 18.568 18.0683C19.2713 17.365 19.6663 16.4112 19.6663 15.4166C19.6663 14.4221 19.2713 13.4683 18.568 12.765C17.8647 12.0617 16.9109 11.6666 15.9163 11.6666C14.9218 11.6666 13.968 12.0617 13.2647 12.765C12.5614 13.4683 12.1663 14.4221 12.1663 15.4166C12.1663 16.4112 12.5614 17.365 13.2647 18.0683C13.968 18.7716 14.9218 19.1666 15.9163 19.1666ZM25.833 20.8333C26.7171 20.8333 27.5649 20.4821 28.19 19.857C28.8152 19.2319 29.1663 18.384 29.1663 17.5C29.1663 16.6159 28.8152 15.7681 28.19 15.143C27.5649 14.5178 26.7171 14.1666 25.833 14.1666C24.949 14.1666 24.1011 14.5178 23.476 15.143C22.8509 15.7681 22.4997 16.6159 22.4997 17.5C22.4997 18.384 22.8509 19.2319 23.476 19.857C24.1011 20.4821 24.949 20.8333 25.833 20.8333ZM19.9997 36.6666C10.7947 36.6666 3.33301 29.205 3.33301 20C3.33301 10.795 10.7947 3.33331 19.9997 3.33331C29.2047 3.33331 36.6663 10.795 36.6663 20C36.6663 29.205 29.2047 36.6666 19.9997 36.6666Z"
          fill="inherit"
        />
      </g>
    </SvgIcon>
  );
};

export default CareerGroupIcon;
