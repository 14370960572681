import ExaminationProvider, {
  getInitialAnswers,
  getSubmitAnswers,
  useExamination,
} from './ExaminationProvider';
import { AnswerQuestionType, FormAnswerQuestionType } from './types';

export default ExaminationProvider;
export { getInitialAnswers, getSubmitAnswers, useExamination };
export type { AnswerQuestionType, FormAnswerQuestionType };
