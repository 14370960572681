import { SvgIcon, SvgIconProps } from '@mui/material';

const DisappointedFaceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="#FFDD67"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      />
      <path
        fill="#664E27"
        d="M9.4 10.56c.56 1.16-.16 2.64-1.56 3.32-1.4.64-3 .24-3.56-.92-.32-.76 4.8-3.16 5.12-2.4Zm5.2 0c-.56 1.16.16 2.64 1.56 3.32 1.4.64 3 .24 3.56-.92.32-.76-4.8-3.16-5.12-2.4Z"
      />
      <path
        fill="#917524"
        d="M8.28 7.12C7.2 8.44 4.6 9.64 2.88 9.64c-.24 0-.28.88 0 .88 1.96 0 4.8-1.32 6.08-2.84.2-.2-.52-.72-.68-.56Zm7.44 0c1.08 1.32 3.68 2.52 5.4 2.52.24 0 .28.88 0 .88-1.96 0-4.8-1.32-6.08-2.84-.2-.2.52-.72.68-.56Z"
      />
      <path
        fill="#664E27"
        d="M15.44 17.76c-2.16-1-4.72-1-6.88 0-.52.24.12 1.68.68 1.4 1.44-.68 3.56-.92 5.56 0 .52.24 1.2-1.12.64-1.4Z"
      />
    </SvgIcon>
  );
};

export default DisappointedFaceIcon;
