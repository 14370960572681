import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../Components/Base';
import LoLButton from '../../Components/Base/LoLButton';
import LoadingIcon from '../../Components/Icons/LoadingIcon';
import { useConfig } from '../../Contexts/ConfigProvider';
import fetch2, { METHOD } from '../../Utils/fetch';
import LoLExamFooterLayout from '../LoLExaminationRoom/LoLExamFooterLayout';

import OTPForm from './OTPForm';
import PhoneNumberForm from './PhoneNumberForm';

enum OTPFlowStep {
  PHONE_NUMBER = 0,
  OTP = 1,
}

type OTPLoginFormProps = {
  handleSuccess: (callbackUrl: string) => void;
  handleClose: () => void;
};

const OTPLoginForm = ({ handleSuccess, handleClose }: OTPLoginFormProps) => {
  const { otpConfig } = useConfig();
  const { otpUrl, sendOtpAPI, verifyOtpAPI } = otpConfig;

  const [step, setStep] = useState(OTPFlowStep.PHONE_NUMBER);
  const [refCode, setRefCode] = useState('');

  const methods = useForm({ mode: 'onChange' });
  const { watch, getValues, setValue, formState, setError, handleSubmit } = methods;
  const { errors, isSubmitting } = formState;

  const { t } = useTranslation('lolSignIn');

  const otp = watch('otp');
  const isOTPFilled = useMemo(() => otp?.every((num: string) => num !== '') ?? false, [otp]);

  const handleSendOTP = async () => {
    const { phoneNumber = '' } = getValues();
    try {
      const res = await fetch2(`${otpUrl}/${sendOtpAPI}`, null, METHOD.POST, { phoneNumber });
      if (res?.error) {
        throw Error(res?.error);
      }
      const newRefCode = res?.data?.refCode ?? '';
      setRefCode(newRefCode);
      setStep(OTPFlowStep.OTP);
    } catch (err) {
      console.log(err);
      setError('phoneNumber', { message: t('OTP.ERROR.INCORRECT_PHONE_NUMBER') });
    }
  };

  const handleVerifyOTP = async () => {
    const { phoneNumber = '', otp = [] } = getValues();
    try {
      const res = await fetch2(`${otpUrl}/${verifyOtpAPI}`, null, METHOD.POST, {
        phoneNumber,
        otp: otp.join(''),
      });
      if (!!res?.statusCode) {
        //REMARK: statudCode === 0 is valid
        throw Error(res?.message);
      }
      const callbackUrl = res?.data;
      handleSuccess(callbackUrl);
    } catch (err) {
      console.log(err);
      setError('otp', { message: t('OTP.ERROR.CODE_NOT_FOUND') });
    }
  };

  const handleBack = () => {
    switch (step) {
      case OTPFlowStep.PHONE_NUMBER:
        handleClose();
        return;
      case OTPFlowStep.OTP:
        setStep(OTPFlowStep.PHONE_NUMBER);
        setValue('otp', ['', '', '', '', '', '']);
        return;
      default:
        return;
    }
  };

  const handleConfirm = async () => {
    return new Promise<void>(async (resolve) => {
      switch (step) {
        case OTPFlowStep.PHONE_NUMBER:
          await handleSendOTP();
          resolve();
          return;
        case OTPFlowStep.OTP:
          await handleVerifyOTP();
          resolve();
          return;
        default:
          return;
      }
    });
  };

  const phoneNumber = watch('phoneNumber');

  const isPhoneNumberDirty = phoneNumber && phoneNumber !== '';

  const disabledConfirmButton =
    (step === OTPFlowStep.PHONE_NUMBER && (!isPhoneNumberDirty || errors['phoneNumber'])) ||
    (step === OTPFlowStep.OTP && !isOTPFilled) ||
    isSubmitting;

  return (
    <Box overflow={'auto'} textAlign={'center'}>
      <FormProvider {...methods}>
        <form
          onSubmit={debounce(
            (e) => {
              e.preventDefault();
              handleSubmit(handleConfirm)();
            },
            3000,
            { leading: true, trailing: false }
          )}
        >
          {step === OTPFlowStep.PHONE_NUMBER && <PhoneNumberForm />}
          {step === OTPFlowStep.OTP && (
            <OTPForm
              refCode={refCode}
              handleResendOTP={handleSendOTP}
              isSubmitting={isSubmitting}
            />
          )}
          <LoLExamFooterLayout>
            <Flex flexDirection={'column'} gap={2} pb={1} flex={1}>
              <LoLButton
                type="submit"
                size="large"
                disabled={disabledConfirmButton}
                startIcon={isSubmitting ? <LoadingIcon /> : null}
              >
                {t('OTP.BUTTON.SUBMIT')}
              </LoLButton>
              <LoLButton
                buttonVariant="secondary"
                onClick={handleBack}
                size="large"
                disabled={isSubmitting}
              >
                {t('OTP.BUTTON.BACK')}
              </LoLButton>
            </Flex>
          </LoLExamFooterLayout>
        </form>
      </FormProvider>
    </Box>
  );
};

export default OTPLoginForm;
