import { QueryHookOptions } from '@apollo/client';
import { arrange, asc, tidy } from '@tidyjs/tidy';
import { useMemo } from 'react';

import {
  GetMyExamCardsQuery,
  GetMyExamCardsQueryVariables,
  useGetMyExamCardsQuery,
} from '../../generated/graphql';

type UseGetExaminationsProps = QueryHookOptions<GetMyExamCardsQuery, GetMyExamCardsQueryVariables>;

const useGetExaminations = (apolloProps: UseGetExaminationsProps) => {
  const result = useGetMyExamCardsQuery(apolloProps);
  const examinations = result.data?.qmsMyExamCards;

  const sortedExaminations = useMemo(() => {
    if (examinations) {
      return tidy(
        examinations,
        arrange((currentItem, nextItem) => {
          const currentSkuExamItemOrder = currentItem.skuExamItem?.order ?? 0;
          const nextSkuExamItemOrder = nextItem.skuExamItem?.order ?? 0;

          return currentSkuExamItemOrder - nextSkuExamItemOrder;
        })
      );
    }
    return [];
  }, [examinations]);
  return { ...result, data: sortedExaminations };
};

export default useGetExaminations;
