import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
// import { CheckboxProps } from '@mui/material';

enum MultipleChoiceVariant {
  TEXT,
  NUMBER,
  ICON,
}

type AnswerMultipleChoiceType = {
  answerableCount?: number;
  choicesCount?: number;
  choices?: Array<{ label: string; value: string }>;
  variant?: MultipleChoiceVariant;
};

export type { FieldPath, FieldValues };
export type FormAnswerMultipleChoiceProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> & AnswerMultipleChoiceType;
export { MultipleChoiceVariant };
