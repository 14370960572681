import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Box, Container, Paper, Skeleton } from '../../Components/Base';
import DataLoadingError from '../../Components/DataLoadingError';
import ExaminationTitle from '../../Components/ExaminationTitle';
import NotFoundError from '../../Components/NotFoundError';
import { RESPONSE_TYPE } from '../../Domains/constants';
import useGetSolutionById from '../../Domains/useGetSolutionById';
import { setPageTitle } from '../../Utils/page';

import SolutionBySubject from './Containers/SolutionBySubject';

const SolutionList = () => {
  const { examId = '' } = useParams();
  const { data, loading, error, refetch } = useGetSolutionById({
    variables: { examId },
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();
  if (error) {
    const solutionErrorHttpStatus = error?.graphQLErrors[0]?.extensions?.exception?.httpStatus;
    if (error.message === RESPONSE_TYPE.NETWORK_ERROR) {
      return <DataLoadingError mt={16} onRetry={() => refetch()} />;
    }
    if (solutionErrorHttpStatus === RESPONSE_TYPE.NOT_FOUND) {
      return <NotFoundError open={true} onBackToHome={() => navigate('/')} />;
    }
  }

  return (
    <Box>
      {loading ? (
        <Paper sx={{ borderRadius: { xs: 0, sm: 1 } }}>
          <Container sx={{ p: { xs: 2, md: 3 } }}>
            <Skeleton height={28} width={180} />
          </Container>
        </Paper>
      ) : (
        <>
          <Helmet>
            <title>{setPageTitle(`เฉลยข้อสอบ | ${data?.qmsFoGetExamById?.title ?? ''}`)}</title>
          </Helmet>
          <ExaminationTitle
            title={data?.qmsFoGetExamById?.title ?? ''}
            containerSx={{
              px: { xs: 2, sm: 3 },
            }}
          />
        </>
      )}
      <Box mt={1} />
      <SolutionBySubject />
    </Box>
  );
};

export default SolutionList;
