import { Box, Label, Flex, Text } from '../../../../Components/Base';

const LabelView = () => {
  return (
    <Box>
      <Text variant="body2" fontWeight={600}>
        Capsule Label
      </Text>
      <Flex mt={2}>
        <Label color={'info'}>Ref: TC001</Label>
      </Flex>
    </Box>
  );
};

export default LabelView;
