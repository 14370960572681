import { useFormContext } from 'react-hook-form';

import { Flex } from '../../../../Components/Base';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import SubQuestionGroup from '../../Components/SubQuestionGroup/SubQuestionGroup';

interface FormAnswerYesNoTypeProps {
  subQuestions: {
    label?: string;
    value?: string;
  }[];
  questionNo: number;
}

const FormAnswerYesNoType = ({ subQuestions = [], questionNo }: FormAnswerYesNoTypeProps) => {
  const formContext = useFormContext();
  return (
    <Flex
      key={`${questionNo}`}
      id={`${questionNo}`}
      px={0.5}
      alignItems={'center'}
      minWidth={{ xs: '343px', sm: 'auto' }}
    >
      <FormTextInput name={`answers.${questionNo - 1}.questionId`} sx={{ display: 'none' }} />
      <FormTextInput name={`answers.${questionNo - 1}.examPaperItemId`} sx={{ display: 'none' }} />
      <SubQuestionGroup
        formContext={formContext}
        questionNo={questionNo}
        subQuestions={subQuestions}
      />
    </Flex>
  );
};

export default FormAnswerYesNoType;
