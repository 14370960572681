import { useMediaQuery, useTheme } from '@mui/material';
import { useMatch, useParams } from 'react-router-dom';

import ExaminationFooter from '../../../Components/Layout/ExaminationFooter/ExaminationFooter';
import useGetBookDetails from '../../../Domains/useGetBookDetails';
import ExaminationSubmitButton from '../../../Routes/ExaminationReview/Containers/ExaminationSubmitButton';
import {
  NextQuestionButton,
  PreviousQuestionButton,
} from '../../../Routes/ExaminationRoom/Containers/NavigationButton';
import ReviewAnswerButton from '../../../Routes/ExaminationRoom/Containers/ReviewAnswerButton/ReviewAnswerButton';
import { PRIVAITE_EXAMROOM_PATH, PUBLIC_EXAMROOM_PATH } from '../../../Routes/constant';
import { QmsskuType } from '../../../generated/graphql';

const ExamRoomFooter = () => {
  const { bookId = '' } = useParams();
  const matchPrivateExam = useMatch({ path: PRIVAITE_EXAMROOM_PATH });
  const matchPublicExam = useMatch({ path: PUBLIC_EXAMROOM_PATH });
  const isExamMatch = !!matchPrivateExam || !!matchPublicExam;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: bookDetails } = useGetBookDetails({
    variables: { bookId },
    skip: !bookId,
  });

  const bookType = bookDetails?.qmsGetBookDetailsByBookId.type ?? QmsskuType.Book;

  if (bookType === QmsskuType.CbtBook || bookType === QmsskuType.PublicBook) {
    return (
      <ExaminationFooter>
        {isExamMatch ? (
          <>
            <PreviousQuestionButton isMobile={isMobile} />
            <ReviewAnswerButton />
            <NextQuestionButton isMobile={isMobile} />
          </>
        ) : (
          <ExaminationSubmitButton />
        )}
      </ExaminationFooter>
    );
  }

  return (
    <ExaminationFooter>
      {isExamMatch ? <ReviewAnswerButton /> : <ExaminationSubmitButton />}
    </ExaminationFooter>
  );
};

export default ExamRoomFooter;
