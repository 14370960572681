import { styled } from '@mui/material';

import { Box, BoxProps, Container } from '../../Base';
import { FOOTER_HEIGHT } from '../constants';

type ExaminationFooterProps = BoxProps;

const ExaminationFooterContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${FOOTER_HEIGHT}px;
  width: 100%;
`;

const ExaminationFooter = ({ children, ...restProps }: ExaminationFooterProps) => {
  return (
    <Box bottom={0} left={0} position={'fixed'} width={1} bgcolor={'common.white'} {...restProps}>
      <ExaminationFooterContainer sx={{ px: { xs: 2, lg: 0 } }}>
        {children}
      </ExaminationFooterContainer>
    </Box>
  );
};

export default ExaminationFooter;
