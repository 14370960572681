import { useMediaQuery } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Flex, Text } from '../../../../Components/Base';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import FormBookmarkInput from '../../../../Components/FormBookmarkInput/FormBookmarkInput';
import { ChoiceType } from '../../Components/AnswerSheet/FormSubQuestionConnectionAnswer/type';
import ConnectionAnswerItem from '../ConnectionAnswerItem';

const answerChoiceTypeList = [ChoiceType.DIGIT, ChoiceType.DIGIT, ChoiceType.ENG_ALPHABET];

//To display the maximum answerable
const SUB_QUESTION_NUMBER = 5;

//To display 2 column on Desktop
const TWO_COLUMN_BREAKPOINT = 720;

interface FormAnswerConnectionProps {
  questionNo: number;
}

const FormAnswerConnectionType = ({ questionNo }: FormAnswerConnectionProps) => {
  const { control } = useFormContext();
  //For MultipleSubQuestionsConnectionAnswers only!
  //Design Reference: https://www.figma.com/file/FxZUYlP7SJiC86GO8ADPQc/%E2%AD%90%EF%B8%8F-New-%5BTCASter%5D-Exam-Hub-(QMS-B2C)?type=design&node-id=9090-390914&t=2NzrPrj8v1KO6YVu-0
  const isMobileDevice = useMediaQuery('(max-width:718px)');
  const [secondPanelWidth, setSecondPanelWidth] = useState<number>(0);
  const [examinationType, setExaminationType] = useState<'PBT' | 'NORMAL_CBT' | 'ARTICLE_CBT'>();

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const secondPanel = document.getElementsByClassName('Pane2')[0];
      if (secondPanel) {
        const width = secondPanel.clientWidth;
        if (width !== secondPanelWidth) {
          setSecondPanelWidth(width);
        }
      }
    },
    [secondPanelWidth]
  );

  useEffect(() => {
    const secondPanel = document.getElementsByClassName('Pane2')[0];
    const examCBTAnswerSheet = document.getElementById('Normal_CBT');

    if (!secondPanel) {
      setSecondPanelWidth(0);
      if (examCBTAnswerSheet) {
        setExaminationType('NORMAL_CBT');
      } else {
        setExaminationType('PBT');
      }
      return;
    }

    setExaminationType('ARTICLE_CBT');
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(secondPanel);

    return () => {
      resizeObserver.unobserve(secondPanel);
    };
  }, [handleResize]);

  const gridTemplateColumns = useMemo(() => {
    if (isMobileDevice) {
      return 'auto';
    }

    switch (examinationType) {
      case 'NORMAL_CBT':
        return `repeat(2, fit-content(100%))`;
      case 'ARTICLE_CBT':
        if (secondPanelWidth < TWO_COLUMN_BREAKPOINT) {
          return {
            sm: `repeat(2, fit-content(100%))`,
            md: `repeat(1, fit-content(100%))`,
          };
        } else {
          return `repeat(2, fit-content(100%))`;
        }
      case 'PBT':
      default:
        return {
          sm: `repeat(2, fit-content(100%))`,
          lg: `repeat(3, 1fr)`,
        };
    }
  }, [isMobileDevice, secondPanelWidth, examinationType]);

  const isArticleCBT = useMemo(() => {
    return examinationType === 'ARTICLE_CBT';
  }, [examinationType]);

  return (
    <Flex
      key={`${questionNo}`}
      id={`${questionNo}`}
      py={isArticleCBT ? 0.5 : { xs: 0.5, md: 1 }}
      pl={isArticleCBT ? 0.5 : { xs: 0.5, md: 1 }}
      pr={isArticleCBT ? 0.5 : 1.5}
      alignItems={'flex-start'}
      flexDirection={'column'}
      justifyContent={{ xs: 'center' }}
    >
      <FormTextInput name={`answers.${questionNo - 1}.questionId`} sx={{ display: 'none' }} />
      <FormTextInput name={`answers.${questionNo - 1}.examPaperItemId`} sx={{ display: 'none' }} />
      <Flex alignItems={'center'}>
        <FormBookmarkInput name={`answers.${questionNo - 1}.isBookmark`} control={control} />
        <Text
          width={32}
          mr={1}
          sx={{ typography: { xs: 'subtitle1', md: 'subtitle2' } }}
          textAlign={'center'}
        >
          {questionNo}
        </Text>
      </Flex>
      <Box
        mt={2}
        display="grid"
        gridAutoFlow="row"
        gridTemplateColumns={gridTemplateColumns}
        alignItems={'center'}
        rowGap={{ xs: 3, sm: 4, lg: 3 }}
        columnGap={{ xs: 'auto', sm: 2, lg: isArticleCBT ? 2 : 1 }}
        justifyContent={{
          xs: 'space-evenly',
          sm: `center`,
          lg: isArticleCBT ? `center` : `space-evenly`,
        }}
        mx={isArticleCBT ? undefined : 'auto'}
        width={isArticleCBT ? 1 : undefined}
      >
        {Array.from({ length: SUB_QUESTION_NUMBER }).map((_: unknown, index: number) => {
          return (
            <ConnectionAnswerItem
              key={`${questionNo}.${index}`}
              answerChoiceTypeList={answerChoiceTypeList}
              questionNo={questionNo}
              subQuestionNo={index + 1}
              isArticleCBT={isArticleCBT}
            />
          );
        })}
      </Box>
    </Flex>
  );
};

export default FormAnswerConnectionType;
