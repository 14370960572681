import { Flex, FlexProps, Text } from '../../../../Components/Base';
import MyExamResultIcon from '../../../../Components/Icons/MyExamResultIcon';

const EmptyExaminationResults = (props: FlexProps) => {
  return (
    <Flex flexDirection={'column'} alignItems={'center'} px={3} pt={6} {...props}>
      <MyExamResultIcon color={'primary'} sx={{ fontSize: 100 }} />
      <Text variant={'h3'} color="text.primary" align="center" mb={1} mt={3}>
        ยังไม่มีผลการสอบ
      </Text>
      <Text align="center" color="text.primary" whiteSpace={'pre-line'}>
        {`น้อง ๆ สามารถฝึกทำข้อสอบได้ที่เมนู “ทำข้อสอบ” 
       เพื่อกลับมาดูผลวิเคราะห์การสอบได้ที่นี่`}
      </Text>
    </Flex>
  );
};

export default EmptyExaminationResults;
