const calculateDistance = ({
  firstEl,
  secondEl,
}: {
  firstEl: HTMLDivElement;
  secondEl: HTMLDivElement;
}): number => {
  const div1Rect = firstEl.getBoundingClientRect();
  const div2Rect = secondEl.getBoundingClientRect();

  const x1 = div1Rect.left + div1Rect.width / 2;
  const y1 = div1Rect.top + div1Rect.height / 2;

  const x2 = div2Rect.left + div2Rect.width / 2;
  const y2 = div2Rect.top + div2Rect.height / 2;

  const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

  return distance;
};

export { calculateDistance };
