import { createTheme } from '@mui/material/styles';

const snackbarTheme = createTheme({
  components: {
    MuiSnackbar: {
      defaultProps: {
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
    },
  },
});

export default snackbarTheme;
