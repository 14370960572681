import { styled } from '@mui/material';

import { Text, Image, Box, Flex, FlexProps, Button, Label } from '../Base';
import DownloadIcon from '../Icons/DownloadIcon';
import ErrorOutlineIcon from '../Icons/ErrorOutlineIcon';

export type BookDetailsType = {
  title: string;
  imageUrl: string;
  author: string;
  refNo: string;
  isComing?: boolean;
  onDownload?: () => void;
} & FlexProps;

const BookDetailWrapper = styled(Flex)<{ disabled: boolean }>(({ disabled }) => ({
  opacity: disabled ? 0.5 : 'initial',
  '&:hover': {
    cursor: disabled && 'not-allowed',
  },
}));

const EllipsisText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BookDetails = (props: BookDetailsType) => {
  const { imageUrl, title, author, refNo, onDownload, isComing = false, ...restProps } = props;
  return (
    <BookDetailWrapper disabled={isComing} {...restProps}>
      <Box height={125} width={88} borderRadius={0.5} overflow={'hidden'} flex={'0 0 auto'}>
        <Image
          src={imageUrl}
          alt="my-book-image"
          width={'100%'}
          height={'100%'}
          sx={{ objectFit: 'cover' }}
        />
      </Box>
      <Flex flexDirection={'column'} justifyContent={'space-between'} width={'100%'} ml={2}>
        <Box>
          <EllipsisText variant={'subtitle1'}>{title}</EllipsisText>
          <EllipsisText variant={'caption'} color={'text.secondary'}>
            เขียนโดย {author}
          </EllipsisText>
        </Box>
        <Flex justifyContent={'space-between'} alignItems={'flex-end'}>
          {isComing ? (
            <Label size={'small'} color={'greyLabel'}>
              <ErrorOutlineIcon sx={{ fontSize: 16 }} />
              <Text
                fontWeight={'600'}
                fontSize={12}
                lineHeight={1.37}
                ml={0.5}
              >{`พร้อมใช้งานเร็ว ๆ นี้`}</Text>
            </Label>
          ) : (
            <Label color={'info'} size={'small'}>{`Ref: ${refNo}`}</Label>
          )}
          {typeof onDownload === 'function' ? (
            <Button
              component={'a'}
              onClick={onDownload}
              download={title}
              variant={'outlined'}
              size={'small'}
            >
              <DownloadIcon color={'primary'} fontSize={'small'} sx={{ mr: 0.5 }} />
              ไฟล์หนังสือ
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </BookDetailWrapper>
  );
};

export default BookDetails;
