import { useParams } from 'react-router-dom';

import useGetPublicBookDetails from '../../../../Domains/useGetPublicBookDetails';
import { QmsExamItem, QmsskuType } from '../../../../generated/graphql';
import InstructionDetails from '../../Components/InstructionDetails';
import InstructionDetailsLoading from '../../Components/InstructionDetails/InstructionDetailsLoading';
import { getAllAnswerTypeWord } from '../../utils';

const PublicInstructionDetails = () => {
  const { bookId = '', examId = '' } = useParams();

  const { data: bookData, loading } = useGetPublicBookDetails({
    variables: { bookId },
    fetchPolicy: 'network-only',
  });

  const bookType = bookData?.qmsGetBookDetailsByBookId.type;

  const examDetail = bookData?.qmsGetBookDetailsByBookId.exams?.find((exam) => exam.id === examId);
  const examItems = examDetail?.examItems ?? [];
  const answerTypes = getAllAnswerTypeWord(examItems as QmsExamItem[]);

  if (loading) return <InstructionDetailsLoading />;

  return <InstructionDetails bookType={bookType as QmsskuType} answerTypes={answerTypes} />;
};

export default PublicInstructionDetails;
