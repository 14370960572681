import { useMediaQuery, useTheme } from '@mui/material';

import { Flex, Image, Text } from '../Base';

const Maintenance = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
      <Image
        src={process.env.PUBLIC_URL + '/assets/maintenance-logo.svg'}
        width={1}
        height={'auto'}
        maxWidth={{ xs: '267px', sm: '351px', md: '500px' }}
      />
      <Text variant={mobile ? 'h2' : 'h3'}>ขออภัยในความไม่สะดวก</Text>
      <Text variant={mobile ? 'body1' : 'body2'}>ขณะนี้ระบบปิดปรับปรุงชั่วคราว</Text>
    </Flex>
  );
};

export default Maintenance;
