import { Helmet } from 'react-helmet';

import { Box } from '../../Components/Base';
import { setPageTitle } from '../../Utils/page';

import MenuItems from './Containers/MenuItems';
import UserInformation from './Containers/UserInformation';

const Profile = () => {
  return (
    <>
      <Helmet>
        <title>{setPageTitle('My Profile')}</title>
      </Helmet>
      <Box>
        <UserInformation />
        <Box mt={1} />
        <MenuItems />
      </Box>
    </>
  );
};

export default Profile;
