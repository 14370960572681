import { useTranslation } from 'react-i18next';

import { Image, Text } from '../../../../Components/Base';
import LoLButton, { LoLButtonProps } from '../../../../Components/Base/LoLButton';
import { useAnonymous } from '../../../../Contexts/AnonymousProvider';
import { useAuthentication } from '../../../../Contexts/AuthenticationProvider';
import { useConfig } from '../../../../Contexts/ConfigProvider';

type FacebookLoginButtonProps = {
  redirectPath: string;
  shouldOpenResultModal: boolean;
} & LoLButtonProps;

const FacebookLoginButton = (props: FacebookLoginButtonProps) => {
  const { redirectPath, shouldOpenResultModal, ...restProps } = props;
  const {
    facebookConfig: { facebookLogoUrl = '' },
  } = useConfig();
  const { t } = useTranslation('lolSignIn');
  const { loginWithFacebook } = useAuthentication();
  const { anonymousUser } = useAnonymous();
  const handleFacebookLogin = () => {
    loginWithFacebook({
      redirectPath,
      shouldOpenResultModal,
      anonymousUserId: anonymousUser?.id ?? '',
    });
  };
  return (
    <LoLButton
      onClick={handleFacebookLogin}
      size="large"
      sx={{
        bgcolor: 'common.white',
        color: 'common.black',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          color: 'lolTextButton.primary',
        },
      }}
      fullWidth
      {...restProps}
    >
      <Image
        src={facebookLogoUrl}
        alt={'facebook-logo'}
        sx={{ mr: 2, maxWidth: '24px', maxHeight: '24px', width: '100', height: 'auto' }}
      />
      <Text marginY={'auto'} fontWeight={600}>
        {t('LOGIN_PROVIDER.FACEBOOK')}
      </Text>
    </LoLButton>
  );
};

export default FacebookLoginButton;
