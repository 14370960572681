import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface CustomPaletteColorOption {
    primary?: string;
    primary72?: string;
    primary36?: string;
    primaryDark?: string;
    gray?: string;
    gray36?: string;
    secondary?: string;
    secondary36?: string;
    contrast?: string;
    contrast36?: string;
    line1?: string;
    line2?: string;
    line3?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }

  interface Palette {
    // Imply greybutton palette to same as primary palette
    greyButton: Palette['primary'];
    greyLabel: Palette['primary'];
    input: Palette['primary'];
    accent: Palette['primary'];
    neutral: Palette['primary'];
    lolIcon?: CustomPaletteColorOption;
    lolText?: CustomPaletteColorOption;
    lolTextButton?: CustomPaletteColorOption;
    lolLine?: CustomPaletteColorOption;
    lolButton?: CustomPaletteColorOption;
    lolElement?: CustomPaletteColorOption;
    lolCard?: CustomPaletteColorOption;
    lolBackground?: CustomPaletteColorOption;
  }
  interface PaletteOptions {
    greyButton?: PaletteOptions['primary'];
    greyLabel?: PaletteOptions['primary'];
    input?: PaletteOptions['primary'];
    accent?: Palette['primary'];
    neutral?: Palette['primary'];
    lolIcon?: CustomPaletteColorOption;
    lolText?: CustomPaletteColorOption;
    lolTextButton?: CustomPaletteColorOption;
    lolLine?: CustomPaletteColorOption;
    lolButton?: CustomPaletteColorOption;
    lolElement?: CustomPaletteColorOption;
    lolCard?: CustomPaletteColorOption;
    lolBackground?: CustomPaletteColorOption;
  }
}

const bluePalette = {
  darker: 'hsla(212, 73%, 20%, 1)',
  dark: 'hsla(212, 73%, 40%, 1)',
  main: 'hsla(212, 73%, 58%, 1)',
  light: 'hsla(212, 73%, 90%, 1)',
  lighter: 'hsla(212, 73%, 97%, 1)',
};

const paletteTheme = createTheme({
  palette: {
    primary: {
      dark: 'hsla(54, 76%, 48%, 1)',
      main: 'hsla(54, 90%, 54%, 1)',
      light: 'hsla(54, 90%, 54%, 0.72)',
      lighter: 'hsla(54, 90%, 54%, 0.36)',
    },
    error: {
      darker: 'hsla(6, 97%, 20%, 1)',
      dark: 'hsla(6, 97%, 40%, 1)',
      main: 'hsla(6, 97%, 48%, 1)',
      light: 'hsla(6, 97%, 90%, 1)',
      lighter: 'hsla(6, 97%, 97%, 1)',
      contrastText: 'hsla(210, 17%, 98%, 1)',
    },
    warning: {
      darker: 'hsla(43, 100%, 20%, 1)',
      dark: 'hsla(39, 98%, 42%, 1)',
      main: 'hsla(42, 98%, 50%, 1)',
      light: 'hsla(44, 100%, 85%, 1)',
      lighter: 'hsla(44, 100%, 95%, 1)',
      contrastText: 'hsla(210, 10%, 23%, 1)',
    },
    info: {
      darker: 'hsla(208, 100%, 20%, 1)',
      dark: 'hsla(208, 90%, 35%, 1)',
      main: 'hsla(208, 90%, 46%, 1)',
      light: 'hsla(208, 100%, 90%, 1)',
      lighter: 'hsla(208, 100%, 97%, 1)',
      contrastText: 'hsla(210, 17%, 98%, 1)',
    },
    success: {
      darker: 'hsla(145, 63%, 20%, 1)',
      dark: 'hsla(145, 63%, 40%, 1)',
      main: 'hsla(145, 63%, 48%, 1)',
      light: 'hsla(145, 63%, 87%, 1)',
      lighter: 'hsla(145, 63%, 95%, 1)',
      contrastText: 'hsla(210, 17%, 98%, 1)',
    },
    grey: {
      50: 'hsla(210, 17%, 98%, 1)',
      100: 'hsla(210, 17%, 95%, 1)',
      200: 'hsla(210, 16%, 93%, 1)',
      300: 'hsla(210, 14%, 89%, 1)',
      400: 'hsla(210, 14%, 83%, 1)',
      500: 'hsla(210, 11%, 71%, 1)',
      600: 'hsla(210, 7%, 56%, 1)',
      700: 'hsla(210, 9%, 31%, 1)',
      800: 'hsla(210, 10%, 23%, 1)',
      900: 'hsla(210, 11%, 15%, 1)',
    },
    // Custom palette
    greyButton: {
      darker: 'hsla(210, 11%, 10%, 1)',
      dark: 'hsla(210, 11%, 15%, 1)',
      main: 'hsla(210, 10%, 23%, 1)',
      light: 'hsla(210, 7%, 56%, 1)',
      lighter: 'hsla(210, 7%, 85%, 1)',
      contrastText: 'hsla(210, 17%, 98%, 1)',
    },
    greyLabel: {
      darker: 'hsla(210, 11%, 15%, 1)',
      dark: 'hsla(210, 10%, 23%, 1)',
      main: 'hsla(210, 7%, 56%, 1)',
      light: 'hsla(210, 14%, 89%, 1)',
      lighter: 'hsla(210, 17%, 95%, 1)',
      contrastText: 'hsla(210, 17%, 98%, 1)',
    },
    input: {
      ...bluePalette,
      contrastText: 'hsla(210, 17%, 98%, 1)',
    },
    accent: {
      darker: 'hsla(176, 73%, 20%, 1)',
      dark: 'hsla(176, 73%, 30%, 1)',
      main: 'hsla(176, 73%, 40%, 1)',
      light: 'hsla(169, 73%, 80%, 1)',
      lighter: 'hsla(176, 73%, 90%, 1)',
      contrastText: 'hsla(210, 17%, 98%, 1)',
    },
    neutral: {
      darker: 'hsla(0, 0%,12%, 1)',
      dark: 'hsla(0, 0%, 15%, 1)',
      main: 'hsla(0, 0%, 25%, 1)',
      light: 'hsla(0, 0%, 40%, 1)',
      lighter: 'hsla(0, 0%, 60%, 1)',
      contrastText: 'hsla(210, 17%, 98%, 1)',
    },
  },
});

export default paletteTheme;
