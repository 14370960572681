import { Fragment, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { tidy, arrange, asc } from '@tidyjs/tidy';

import SubjectAccordion from '../../../../Components/SubjectAccordion';
import useGetResultSolution, {
  QmsExamPaperItemType,
} from '../../../../Domains/useGetResultSolution';
import { Paper } from '../../../../Components/Base';
import { RESPONSE_TYPE } from '../../../../Domains/constants';
import DataLoadingError from '../../../../Components/DataLoadingError';
import { ResultListByQuestion } from '../../Components';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

import ResultListBySubjectLoading from './ResultListBySubjectLoading';

const ResultListBySubject = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { examPaperId = '' } = useParams();
  const [expandedSubject, setExpandedSubject] = useState<number | false>(
    parseInt(location.hash.replace('#', '') || '0')
  );

  const { data, loading, error, refetch } = useGetResultSolution({ variables: { examPaperId } });
  const examPaperData = data?.qmsFoGetExamPaperById;
  const { examSubjects: examSubjectData, examPaperItems } = examPaperData || {};
  const { examSubjects } = examSubjectData || {};
  const sendDataToGTM = useGoogleTagManager();

  const sortedExamPaperItems = useMemo(() => {
    if (examPaperItems) {
      return tidy(examPaperItems, arrange([asc('order')]));
    }
    return [];
  }, [examPaperItems]);

  const handleExamSolutionDetailsTracking = (subjectTitle: string, questionOrder: number) => {
    sendDataToGTM({
      event: 'question_result_item_click',
      subjectTitle: subjectTitle,
      questionOrder: questionOrder,
    });
  };

  const handleGoToResultSolutionDetails = (resultId: string) => {
    navigate(`${location.pathname}/question/${resultId}`);
  };

  const handleAccordionChange =
    (accordionIndex: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedSubject(newExpanded ? accordionIndex : false);
      navigate(`${location.pathname}#${accordionIndex}`, { replace: true });
    };

  if (loading) return <ResultListBySubjectLoading />;

  if (error && error.message === RESPONSE_TYPE.NETWORK_ERROR) {
    return <DataLoadingError onRetry={() => refetch({ examPaperId })} mt={9} />;
  }

  return (
    <>
      {examSubjects?.map(({ tagId, title, questions }, subjectIndex) => (
        <Paper
          key={tagId}
          sx={{
            mb: { xs: 1, sm: 2 },
            borderRadius: { xs: 0, sm: 1 },
          }}
        >
          <SubjectAccordion
            title={title}
            totalQuestion={questions.length}
            expanded={expandedSubject === subjectIndex}
            onChange={handleAccordionChange(subjectIndex)}
          >
            {sortedExamPaperItems.map((examPaperItem, index) => {
              const questionItem = questions?.find((item) => item.id === examPaperItem.questionId);

              if (!questionItem) return null;

              const { id: examPaperItemId, question } = examPaperItem || {};
              const questionType = question?.type;
              const questionOrder = index + 1;
              return (
                <Fragment key={examPaperItemId}>
                  <Divider sx={{ mx: { xs: 2, md: 3 } }} />
                  <ResultListByQuestion
                    key={examPaperItemId}
                    questionType={questionType}
                    examPaperItemId={examPaperItemId}
                    examPaperItem={examPaperItem as QmsExamPaperItemType}
                    handleGoToResultSolutionDetails={(resultId) => {
                      handleExamSolutionDetailsTracking(title, questionOrder);
                      handleGoToResultSolutionDetails(resultId);
                    }}
                  />
                </Fragment>
              );
            })}
          </SubjectAccordion>
        </Paper>
      ))}
    </>
  );
};

export default ResultListBySubject;
