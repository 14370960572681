import fetch from 'unfetch';

const resolveArgument = (value) => (typeof value === 'string' ? value : JSON.stringify(value));

export const argsToString = (args = {}) => {
  const keys = Object.keys(args);
  if (keys.length === 0) return '';
  return '?' + keys.map((key) => `${key}=${resolveArgument(args[key])}`).join('&');
};

const parseValue = (res, parseAsJson = true) => {
  if (res.status === 204) return {};
  return parseAsJson ? res.json() : res.text();
};

const DEFAULT_FETCH_OPTIONS = {
  parseJson: true,
  mode: 'cors',
  credentials: 'include',
  accessToken: null,
};
const fetch2 = async (path, params = null, method = METHOD.GET, body, fetchOptions) => {
  const options = {
    method,
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  fetchOptions = { ...DEFAULT_FETCH_OPTIONS, ...fetchOptions };

  if (fetchOptions.mode) {
    options.mode = fetchOptions.mode;
  }

  if (fetchOptions.credentials && !fetchOptions.accessToken) {
    options.credentials = fetchOptions.credentials;
  }

  if (fetchOptions.accessToken) {
    options.headers['Authorization'] = `Bearer ${fetchOptions.accessToken}`;
  }

  const callPath = params ? `${path}${argsToString(params)}` : path;
  return fetch(callPath, options).then((res) => {
    return parseValue(res, fetchOptions.parseJson);
  });
};

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

fetch2.METHOD = METHOD;

export default fetch2;
