import { Button, Flex, FlexProps, Text } from '../../../Components/Base';
import CheckCircleOutlineRoundedIcon from '../../../Components/Icons/CheckCircleOutlineRoundedIcon';

export type MyBookSuccessProps = {
  onClick: () => void;
} & Omit<FlexProps, 'onClick'>;

const MyBookSuccess = ({ onClick }: MyBookSuccessProps) => {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      maxWidth={350}
      height={{ xs: '100%', sm: 'auto' }}
      mt={{ sm: 13 }}
      mx={'auto'}
    >
      <CheckCircleOutlineRoundedIcon color={'success'} sx={{ fontSize: '100px' }} />
      <Text sx={{ typography: { xs: 'h3', md: 'h2' } }} mt={{ xs: 3, sm: 4 }} mb={1}>
        เพิ่มหนังสือเรียบร้อยแล้ว
      </Text>
      <Text mb={3}>ไปที่ “หนังสือของฉัน” เพื่อเริ่มทำข้อสอบได้เลย</Text>
      <Button size={'large'} fullWidth onClick={onClick}>
        <Text>ไปที่หนังสือของฉัน</Text>
      </Button>
    </Flex>
  );
};

export default MyBookSuccess;
