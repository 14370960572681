import { Divider } from '@mui/material';

import { Box, Image, Text } from '../../Components/Base';
import BackToTop from '../../Containers/BackToTop';

import AlertView from './Containers/AlertView';
import AnswerMultipleChoices from './Containers/AnswerMultipleChoices';
import AnswerMultipleSubQuestionYesNoChoices from './Containers/AnswerMultipleSubQuestionYesNoChoices';
import BookDetails from './Containers/BookDetails';
import BoxView from './Containers/BoxView';
import Button from './Containers/Button';
import FlexView from './Containers/FlexView';
import InputView from './Containers/InputView';
import LabelView from './Containers/LabelView';
import ModalView from './Containers/ModalView';
import SnackbarView from './Containers/SnackbarView';
import TabsView from './Containers/TabsView';
import TextView from './Containers/TextView';
import AnswerSubQuestionConnectionAnswer from './Containers/AnswerSubQuestionConnectionAnswer';

const TestUI = () => {
  return (
    <Box>
      <Text variant="h1">
        Hello I'm from <code>base</code> UI
      </Text>
      <Divider sx={{ my: 2 }} />

      {/* Text */}
      <Text variant="h3">Text</Text>
      <TextView />
      <Divider sx={{ my: 2 }} />

      {/* Box */}
      <Text variant="h3">Box</Text>
      <BoxView />
      <Divider sx={{ my: 2 }} />

      {/* Flex */}
      <Text variant="h3">Flex</Text>
      <FlexView />
      <Divider sx={{ my: 2 }} />

      {/* Button */}
      <Text variant="h3">Button</Text>
      <Button />
      <Divider sx={{ my: 2 }} />

      {/* Image */}
      <Text variant="h3">Image</Text>
      <Image src={'https://picsum.photos/1003/1181'} alt={'test image tag'} width={150} />
      <Divider sx={{ my: 2 }} />

      {/* Input */}
      <InputView />
      <Divider sx={{ my: 2 }} />

      {/* Modal */}
      <Text variant="h3">Modal</Text>
      <ModalView />
      <Divider sx={{ my: 2 }} />

      {/* Tabs */}
      <Text variant="h3">Label</Text>
      <LabelView />
      <Divider sx={{ my: 2 }} />

      {/* Tabs */}
      <Text variant="h3">Tabs</Text>
      <TabsView />
      <Divider sx={{ my: 2 }} />

      {/* Alert */}
      <Text variant="h3">Alert</Text>
      <AlertView />
      <Divider sx={{ my: 2 }} />

      {/* Alert */}
      <Text variant="h3">Snackbar</Text>
      <SnackbarView />
      <Divider sx={{ my: 2 }} />

      {/* Answer Multiple Choice */}
      <Text variant="h3">Answer Multiple Choice</Text>
      <AnswerMultipleChoices />
      <Divider sx={{ my: 2 }} />

      {/* Answer Multiple Sub Question True or False Choices */}
      <Text variant="h3">Answer Multiple Sub Question True or False Choices</Text>
      <AnswerMultipleSubQuestionYesNoChoices />
      <Divider sx={{ my: 2 }} />

      {/* Multiple Answer with Answer combination */}
      <Text variant="h3">Answer Sub Question Connection Answer</Text>
      <AnswerSubQuestionConnectionAnswer />
      <Divider sx={{ my: 2 }} />

      {/*BookDetails*/}
      <Text variant={'h3'}>Book Details</Text>
      <BookDetails />
      <Divider sx={{ my: 2 }} />

      {/*BackToTop*/}
      <BackToTop />
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

export default TestUI;
