import { FieldValues } from 'react-hook-form';

import FormAnswerMultipleChoices, {
  FormAnswerMultipleChoiceProps,
} from '../../../Components/AnswerSheet/FormAnswerMultipleChoices';

type FormAnswerMultipleChoicesItemType = {
  subType?: string | null;
  choices?: Array<{ label: string; value: string }>;
} & FormAnswerMultipleChoiceProps<FieldValues>;

const FormAnswerMultipleChoicesItem = (props: FormAnswerMultipleChoicesItemType) => {
  const { subType, choices, ...restProps } = props;
  switch (subType) {
    case 'MUL1/4':
    case 'MUL1/5':
    case 'MDS4':
    case 'MDS5': {
      const choiceCount = parseInt(subType.charAt(subType.length - 1));
      return (
        <FormAnswerMultipleChoices choicesCount={choiceCount} choices={choices} {...restProps} />
      );
    }
    case 'MMA4':
    case 'MMA5': {
      const choiceCount = parseInt(subType.charAt(subType.length - 1));
      return (
        <FormAnswerMultipleChoices
          answerableCount={choiceCount}
          choicesCount={choiceCount}
          {...restProps}
        />
      );
    }
    default:
      return null;
  }
};

export default FormAnswerMultipleChoicesItem;
