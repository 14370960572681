import urlJoin from 'url-join';

import { Paper } from '../../../Components/Base';
import { useAuthentication } from '../../../Contexts/AuthenticationProvider';
import ConfirmationSignOutModal from '../Components/ConfirmationSignOutModal';
import EditRoundedIcon from '../../../Components/Icons/EditRoundedIcon';
import ExitToAppRoundedIcon from '../../../Components/Icons/ExitToAppRoundedIcon';
import MenuItem from '../Components/MenuItem';
import useDisclosure from '../../../Utils/useDisclosure';
import { useConfig } from '../../../Contexts/ConfigProvider';

const MenuItems = () => {
  const { userApi, userPortal } = useConfig();

  const { id: clientId, buCode } = userPortal.client;
  const editProfileUrl = userPortal.userPortalUrl?.updateProfile;

  const { logout } = useAuthentication();
  const {
    isOpen: isConfirmModalOpen,
    open: openConfirmModal,
    close: closeConfirmModal,
  } = useDisclosure();

  const handleEditProfile = async () => {
    const redirectUri = location.href;
    const userTokenUrl = urlJoin(userApi.path, userApi.userToken);

    const token = await fetch(userTokenUrl, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

    if (token) {
      location.replace(
        `${editProfileUrl}/?redirect_uri=${redirectUri}&bu_code=${buCode}&client_id=${clientId}&token=${token}`
      );
    }
  };

  return (
    <>
      <Paper sx={{ borderRadius: { xs: 0, sm: 1 }, overflow: 'hidden' }}>
        <MenuItem
          icon={<EditRoundedIcon />}
          title={'แก้ไขข้อมูลส่วนตัว'}
          hasBackLink
          onClick={handleEditProfile}
        />
      </Paper>
      <Paper sx={{ mt: 1, borderRadius: { xs: 0, sm: 1 }, overflow: 'hidden' }}>
        <MenuItem icon={<ExitToAppRoundedIcon />} title={'ออกจากระบบ'} onClick={openConfirmModal} />
        <ConfirmationSignOutModal
          open={isConfirmModalOpen}
          onCancel={closeConfirmModal}
          onSignOut={logout}
        />
      </Paper>
    </>
  );
};

export default MenuItems;
