import { SvgIcon, SvgIconProps } from '@mui/material';

const NeutralFaceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="#FFDD67"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      />
      <path
        fill="#664E27"
        d="M7.4 12.24a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM16.6 12.24a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM14.76 18.4H9.24c-.6 0-.6-1.6 0-1.6h5.48c.64 0 .64 1.6.04 1.6Z"
      />
    </SvgIcon>
  );
};

export default NeutralFaceIcon;
