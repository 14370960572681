import { ExamAnswerType, ExamBySections } from '../../../../Contexts/LoLExaminationProvider/types';

export const calculateTraitGroupScore = (
  examQuestionBySections: ExamBySections[],
  currentAnswers: ExamAnswerType,
  traitGroup: any[]
) => {
  return examQuestionBySections.map(({ section, questions, fullScore = 0 }) => {
    let score = 0;
    questions?.forEach(({ order, score: questionScore = 0 }) => {
      const answer = currentAnswers?.[order - 1]?.answer?.value;
      if (answer !== undefined) {
        const scoreRatio =
          questions
            ?.find(({ order: questionNo }) => questionNo === order)
            ?.metadata?.choices.find(({ value }) => value === answer)?.scoreRatio ?? 0;
        score += scoreRatio * questionScore;
      }
    });
    const trait = traitGroup.find(({ title }) => title.toUpperCase() === section?.toUpperCase());
    const percentage = Math.round((score / fullScore) * 100);
    return { ...trait, percentage };
  });
};
