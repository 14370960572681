import { useController } from 'react-hook-form';

import { Flex, Text } from '../../Base';
import TextInput from '../../Base/Input/TextInput';
import ErrorIcon from '../../Icons/ErrorIcon';

import { FormTextInputProps, FieldValues } from './type';

const FormTextInput = <TFieldValues extends FieldValues = FieldValues>(
  props: FormTextInputProps<TFieldValues>
): JSX.Element => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    ...props,
    name: props.name,
  });
  return (
    <TextInput
      {...field}
      {...props}
      type={props?.type ?? 'text'}
      error={invalid}
      helperText={
        error?.message ? (
          <Flex alignItems={'center'}>
            <ErrorIcon fontSize={'small'} />
            {/* Margin top 2px used for adjusting the text to center icon */}
            <Text variant={'caption'} ml={1} mt={'2px'}>
              {error.message}
            </Text>
          </Flex>
        ) : null
      }
    />
  );
};

export type { FormTextInputProps };
export default FormTextInput;
