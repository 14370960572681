import { Box, CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { APP_BAR_HEIGHT } from '../../Components/Layout';
import LoLExamRoomAppBar from '../../Containers/LoLExaminationRoom/LoLExamRoomAppBar';
import { AnswerQuestionType } from '../../Contexts/ExaminationProvider';
import { useLoLExamination } from '../../Contexts/LoLExaminationProvider';
import { setPageTitle } from '../../Utils/page';

import LoLExamination from './Containers/LoLExamination';
import { examTitles } from './constants';

const LoLExaminationRoom = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace('examroom', 'milestones');
    navigate(newPath);
  };

  const { t } = useTranslation('lolCommon');
  const { control } = useFormContext();
  const { milestones, currentQuestionNo = 0 } = useLoLExamination();

  const currentMilstones = useMemo(() => {
    return milestones?.find(
      ({ startQuestionNo, endQuestionNo }) =>
        startQuestionNo <= currentQuestionNo && currentQuestionNo <= endQuestionNo
    );
  }, [currentQuestionNo, milestones]);

  const answers: AnswerQuestionType[] = useWatch({ control, name: 'answers' });
  const answeredCount = useMemo(() => {
    const startQuestionNo = currentMilstones?.startQuestionNo ?? 1;
    const endQuestionNo = currentMilstones?.endQuestionNo ?? 1;
    return answers.slice(startQuestionNo - 1, endQuestionNo - 1).filter(({ answer }) => {
      return answer?.value !== undefined;
    }).length;
  }, [answers, currentMilstones?.endQuestionNo, currentMilstones?.startQuestionNo]);

  const totalQuestion = useMemo(() => {
    return currentMilstones?.totalQuestionCount;
  }, [currentMilstones]);

  const examProgressPercentage = useMemo(() => {
    if (!totalQuestion) {
      return 0;
    }
    return totalQuestion > 0 ? (answeredCount / totalQuestion) * 100 : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answeredCount, totalQuestion]);

  const examinationTitle = useMemo(() => {
    const currentSectionTags = currentMilstones?.tags ?? [];
    const currentTitle = examTitles.find(({ tags = [] }) => {
      return tags.some((tag) => currentSectionTags.includes(tag));
    });
    return t(`EXAM_TITLE.${currentTitle?.title ?? ''}`, {
      defaultValue: `${t('TITLE', { ns: 'lolMilestones' })}`,
    });
  }, [currentMilstones, t]);

  return (
    <>
      <Helmet>
        <title>{setPageTitle('LoLxExamHub Examination Room')}</title>
      </Helmet>
      <LoLExamRoomAppBar
        title={examinationTitle}
        onBack={handleBack}
        rightAdornment={
          <CircularProgress
            variant="determinate"
            value={examProgressPercentage}
            thickness={6}
            size={24}
            sx={{
              color: 'lolElement.gray',
            }}
            aria-label="exam-progress"
          />
        }
      />
      <Box pt={3} minHeight={`calc(min(680px, 100dvh) - ${APP_BAR_HEIGHT}px)`}>
        <LoLExamination />
      </Box>
    </>
  );
};

export default LoLExaminationRoom;
