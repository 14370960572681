import { Divider } from '@mui/material';
import { Fragment } from 'react';

import { Box, Flex, Paper, Skeleton } from '../../../../Components/Base';
import ChevronRightRoundedIcon from '../../../../Components/Icons/ChevronRightRoundedIcon';

const ResultListBySubjectLoading = () => (
  <Paper
    sx={{
      mb: { xs: 1, sm: 2 },
      borderRadius: { xs: 0, sm: 1 },
    }}
  >
    <Box px={2}>
      <Box py={2}>
        <Skeleton height={24} width={120} />
      </Box>
      {Array.from({ length: 5 }).map((_, index) => (
        <Fragment key={index}>
          <Divider />
          <Flex py={2}>
            <Skeleton height={24} width={60} sx={{ my: 0.5 }} />
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            <Flex justifyContent={'space-between'} alignItems={'center'} flexGrow="1">
              <Skeleton height={24} width={50} />
              <Flex>
                <Skeleton height={26} width={56} sx={{ borderRadius: '16px' }} />
                <ChevronRightRoundedIcon color="primary" sx={{ ml: 1 }} />
              </Flex>
            </Flex>
          </Flex>
        </Fragment>
      ))}
    </Box>
  </Paper>
);

export default ResultListBySubjectLoading;
