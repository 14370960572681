import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Text } from '../../../../Components/Base';
import LoLButton, { LoLButtonProps } from '../../../../Components/Base/LoLButton';
import { useGetExamPaperIdByExamCardIdQuery as useGetExamPaperIdByExamCardId } from '../../../../generated/graphql';

type SeeLoLExaminationResultButtonProps = LoLButtonProps & {
  examCardIdByBookIdAndExamId?: string;
};

const SeeLoLExaminationResultButton = (props: SeeLoLExaminationResultButtonProps) => {
  const { examCardIdByBookIdAndExamId = '', ...restProps } = props;
  const { examId = '' } = useParams();
  const { t } = useTranslation('lolCommon');
  const navigate = useNavigate();

  const { data, loading } = useGetExamPaperIdByExamCardId({
    variables: { examCardId: examCardIdByBookIdAndExamId },
    fetchPolicy: 'network-only',
  });

  const examPaperId = data?.qmsFoGetExamPapersByCardId?.[0]?.id ?? '';

  const goToExaminationResult = () => {
    const currentPath = window.location.pathname;
    let newPath = '';
    if (currentPath.includes('milestones')) {
      newPath = currentPath.replace('milestones', 'results').replace(examId, examPaperId);
    } else if (currentPath.includes('examinations')) {
      newPath = currentPath.replace('examinations', 'milestones');
    }
    navigate(newPath);
  };

  if (loading) return <Skeleton width={'100%'} height={48} />;

  return (
    <LoLButton size={'large'} fullWidth onClick={goToExaminationResult} {...restProps}>
      <Text>{t('BUTTON.MY_RESULTS')}</Text>
    </LoLButton>
  );
};

export default SeeLoLExaminationResultButton;
