import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Flex } from '../../../../Components/Base';
import { useLoLExamination } from '../../../../Contexts/LoLExaminationProvider';
import { ExamAnswerType } from '../../../../Contexts/LoLExaminationProvider/types';
import TraitItem from '../../Components/TraitItem';

import { calculateTraitGroupScore } from './utils';
import { valueTraitGroup } from './constants';

const ValueTraitGroup = () => {
  const { examQuestionBySections, currentQuestionNo } = useLoLExamination();

  const [shouldCalculateScore, setShouldCalculcateScore] = useState(true);
  const { getValues } = useFormContext();
  const valueMinireport = useMemo(() => {
    return examQuestionBySections?.filter(({ tag }) => tag === 'VALUE') ?? [];
  }, [examQuestionBySections]);
  const [traits, setTraits] = useState<any[]>([]);

  useEffect(() => {
    if (currentQuestionNo && currentQuestionNo % 3 === 0) {
      setShouldCalculcateScore(true);
    } else {
      setShouldCalculcateScore(false);
    }
  }, [currentQuestionNo]);

  useEffect(() => {
    if (shouldCalculateScore) {
      const calculatedTraitGroup = calculateTraitGroupScore(
        valueMinireport,
        getValues().answers as ExamAnswerType,
        valueTraitGroup
      ).sort((a, b) => b.percentage - a.percentage);
      setTraits((prev) => {
        return calculatedTraitGroup.map((trait) => {
          const prevTrait = prev.find((prevTrait) => prevTrait.title === trait.title);
          if (prevTrait) {
            const diffPercentage = trait.percentage - prevTrait.percentage;
            const showDiff = diffPercentage !== 0;
            return {
              ...trait,
              showDiff,
              diffPercentage,
            };
          }
          return trait;
        });
      });
      setShouldCalculcateScore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionNo, valueMinireport, shouldCalculateScore]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTraits((prev) =>
        prev.map((restTrait) => {
          return {
            ...restTrait,
            showDiff: false,
          };
        })
      );
    }, 3000);

    return () => clearTimeout(timer);
  }, [traits]);

  const [animationParent] = useAutoAnimate();

  return (
    <Flex ref={animationParent} overflow={'auto'} sx={{ listStyleType: 'none' }} gap={1}>
      {traits &&
        traits?.map(({ icon, title, percentage, diffPercentage, showDiff }, index) => {
          return (
            <Box
              key={title}
              component={'li'}
              ml={index === 0 ? 3 : 0}
              mr={index === traits.length - 1 ? 3 : 0}
            >
              <TraitItem
                icon={icon}
                percentage={percentage}
                diffPercentage={diffPercentage}
                showDiff={showDiff}
              />
            </Box>
          );
        })}
    </Flex>
  );
};

export default ValueTraitGroup;
