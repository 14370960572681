import { Avatar, SvgIconProps } from '@mui/material';
import CharacterIcon from '@mui/icons-material/AccessibilityNewRounded';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '../../../../Components/Base';
import LockIcon from '../../../../Components/Icons/LockIcon';
import { Lol_Exam_Result_Type } from '../../../../generated/graphql';
import HeartCheckIcon from '../../../../Components/Icons/HeartCheckIcon';
import ArrowTrendingSparkle from '../../../../Components/Icons/ArrowTrendingSparkle';
import HeadStarIcon from '../../../../Components/Icons/HeadStarIcon';
import LikeIcon from '../../../../Components/Icons/LikeIcon';
import CrownIcon from '../../../../Components/Icons/CrownIcon';
import CareerGroupIcon from '../../../../Components/Icons/CareerGroupIcon';
import RealisticIcon from '../../../../Components/Icons/Trait/RealisticIcon';
import InvestigativeIcon from '../../../../Components/Icons/Trait/InvestigativeIcon';
import ArtisticIcon from '../../../../Components/Icons/Trait/ArtisticIcon';
import SocialIcon from '../../../../Components/Icons/Trait/SocialIcon';
import EnterprisingIcon from '../../../../Components/Icons/Trait/EnterprisingIcon';
import ConventionalIcon from '../../../../Components/Icons/Trait/ConventionalIcon';
import AchievementIcon from '../../../../Components/Icons/Trait/AchievementIcon';
import IndependenceIcon from '../../../../Components/Icons/Trait/IndependenceIcon';
import RecognitionIcon from '../../../../Components/Icons/Trait/RecognitionIcon';
import RelationshipsIcon from '../../../../Components/Icons/Trait/RelationshipsIcon';
import SupportIcon from '../../../../Components/Icons/Trait/SupportIcon';
import WorkingConditionsIcon from '../../../../Components/Icons/Trait/WorkingConditionsIcon';

const defaultIconProps: SvgIconProps = {
  sx: {
    width: 36,
    height: 36,
    px: 0.25,
    color: 'inherit',
  },
};

const renderCoreTraitIcon = (refCode?: string) => {
  switch (refCode) {
    case 'realistic':
      return <RealisticIcon {...defaultIconProps} />;
    case 'investigative':
      return <InvestigativeIcon {...defaultIconProps} />;
    case 'artistic':
      return <ArtisticIcon {...defaultIconProps} />;
    case 'social':
      return <SocialIcon {...defaultIconProps} />;
    case 'enterprising':
      return <EnterprisingIcon {...defaultIconProps} />;
    case 'conventional':
      return <ConventionalIcon {...defaultIconProps} />;
    case 'achievement':
      return <AchievementIcon {...defaultIconProps} />;
    case 'independence':
      return <IndependenceIcon {...defaultIconProps} />;
    case 'recognition':
      return <RecognitionIcon {...defaultIconProps} />;
    case 'relationships':
      return <RelationshipsIcon {...defaultIconProps} />;
    case 'support':
      return <SupportIcon {...defaultIconProps} />;
    case 'working-conditions':
      return <WorkingConditionsIcon {...defaultIconProps} />;
    default:
      const firstLetterOfRefCode = refCode?.charAt(0);
      return (
        <Avatar
          sx={{
            bgcolor: 'lolBackground.contrast',
            color: 'lolIcon.secondary',
            fontWeight: 800,
            fontSize: 28,
          }}
        >
          {firstLetterOfRefCode?.toUpperCase()}
        </Avatar>
      );
  }
};

const renderIconByResultType = (resultType?: Lol_Exam_Result_Type, refCode?: string) => {
  switch (resultType) {
    case Lol_Exam_Result_Type.IdentityCareer:
      return <HeartCheckIcon {...defaultIconProps} />;
    case Lol_Exam_Result_Type.PotentialIdentityCareer:
      return <ArrowTrendingSparkle {...defaultIconProps} />;
    case Lol_Exam_Result_Type.PersonalityCareer:
      return <HeadStarIcon {...defaultIconProps} />;
    case Lol_Exam_Result_Type.PreferencesCareer:
      return <LikeIcon {...defaultIconProps} />;
    case Lol_Exam_Result_Type.CapableCareer:
      return <CrownIcon {...defaultIconProps} />;
    case Lol_Exam_Result_Type.CareerGroup:
      return <CareerGroupIcon {...defaultIconProps} />;
    case Lol_Exam_Result_Type.FirstRiasec:
    case Lol_Exam_Result_Type.SecondRiasec:
    case Lol_Exam_Result_Type.FirstValue:
    case Lol_Exam_Result_Type.SecondValue:
    case Lol_Exam_Result_Type.ThirdValue:
    case Lol_Exam_Result_Type.FirstStrength:
    case Lol_Exam_Result_Type.SecondStrength:
    case Lol_Exam_Result_Type.ThirdStrength:
      return renderCoreTraitIcon(refCode);
    case Lol_Exam_Result_Type.Character:
      return <CharacterIcon {...defaultIconProps} />;
    default:
      return <LockIcon {...defaultIconProps} />;
  }
};

type AcheivementCardProps = {
  title: string;
  conditionNumber: number;
  resultType?: Lol_Exam_Result_Type;
  locked?: boolean;
  onClick?: () => void;
  refCode?: string;
};

const AcheivementCard = (props: AcheivementCardProps) => {
  const { title, conditionNumber, locked, resultType, onClick, refCode } = props;
  const { t } = useTranslation('lolResults');

  return (
    <Flex
      p={2}
      gap={2}
      sx={{
        bgcolor: locked ? 'lolCard.primary' : 'lolButton.primary',
        borderRadius: 1,
        border: '1px solid',
        borderColor: locked ? 'lolLine.line1' : 'lolElement.primary36',
        cursor: 'pointer',
      }}
      alignItems={'center'}
      onClick={onClick}
    >
      <Avatar
        key={`achievement-${title}`}
        sx={{
          width: { xs: 70, sm: 56 },
          height: { xs: 70, sm: 56 },
          borderStyle: locked ? 'dashed' : 'solid',
          borderColor: locked ? 'lolElement.gray' : 'lolBackground.contrast',
          borderWidth: 1,
          bgcolor: locked ? 'lolCard.primary' : 'lolBackground.contrast',
          color: 'lolIcon.secondary',
        }}
      >
        {locked ? (
          <LockIcon
            sx={{
              width: 36,
              height: 36,
              color: 'lolElement.gray',
            }}
          />
        ) : (
          renderIconByResultType(resultType, refCode)
        )}
      </Avatar>
      <Flex
        flexDirection={'column'}
        gap={1}
        color={locked ? 'lolText.primary' : 'lolText.contrast'}
      >
        <Text variant="subtitle1" fontWeight={600}>
          {title}
        </Text>
        <Text variant="body2" color={locked ? 'lolText.secondary' : 'inherit'}>
          {`${t('UNLOCK_CONDITION')} ${conditionNumber}`}
        </Text>
      </Flex>
    </Flex>
  );
};

export default AcheivementCard;
