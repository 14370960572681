import { BoxProps, styled } from '@mui/material';
import React from 'react';

import { Flex, FlexProps } from '../../Base';

type CheckboxTextSelectionProps = {
  checked: boolean;
  name?: string;
  label?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: string | number;
} & BoxProps;

const HiddenCheckboxTextSelectionInput = styled('input')({
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const CheckboxTextSelectionWrapper = (props: FlexProps) => {
  const { ...restProps } = props;

  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      px={3}
      py={2}
      width={'100%'}
      borderRadius={1}
      position={'relative'}
      //Remark: Handle specify styling for LOL Only
      sx={{
        '@media(max-width: 389px)': {
          py: 0.5,
          px: 2,
          '& > p': {
            fontSize: '14px',
          },
        },
      }}
      {...restProps}
    />
  );
};

const CheckboxTextSelection = (checkboxProps: CheckboxTextSelectionProps) => {
  const { checked = false, label = '', name, onBlur, onChange, value, ...props } = checkboxProps;

  return (
    //REMARK: Handle specify styling for LOL Only
    <CheckboxTextSelectionWrapper
      bgcolor={checked ? 'lolElement.gray36' : 'lolElement.gray'}
      color={'lolTextButton.contrast'}
      border={'1px solid'}
      borderColor={checked ? 'lolElement.gray' : 'transparent'}
      width={'100%'}
      {...props}
    >
      {label}
      <HiddenCheckboxTextSelectionInput
        type={'checkbox'}
        value={value}
        name={name}
        checked={checked}
        onBlur={onBlur}
        onChange={(e) => onChange?.(e)}
      />
    </CheckboxTextSelectionWrapper>
  );
};

export default CheckboxTextSelection;
