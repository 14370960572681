import { Box, Skeleton } from '@mui/material';
import { groupBy, summarize, tidy } from '@tidyjs/tidy';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Text } from '../../Components/Base';
import Loading from '../../Components/Loading';
import LoLExamRoomAppBar from '../../Containers/LoLExaminationRoom/LoLExamRoomAppBar';
import { LAST_QUESTION_NO_OF_FIRST_ACTIVITY } from '../../Containers/LoLExaminationRoom/constants';
import { LoginState, useAuthentication } from '../../Contexts/AuthenticationProvider';
import { DURATION_PER_QUESTION } from '../../Contexts/LoLExaminationProvider/constants';
import {
  LoLExaminationSection,
  LoLExaminationTag,
  Milestone,
} from '../../Contexts/LoLExaminationProvider/types';
import useCheckExamStatusByBookIdAndExamId from '../../Domains/useCheckStatusExamByBookIdAndExamId/useCheckStatusExamByBookIdAndExamId';
import { setPageTitle } from '../../Utils/page';
import {
  useQmsGetExamCardByBookIdAndExamIdQuery as useGetExamCardByBookIdAndExamId,
  useGetLoLExamByExamIdQuery as useGetLoLExamByExamId,
} from '../../generated/graphql';
import GradingLoLExaminationButton from '../LoLInstruction/Containers/LoLExaminationActionButton/GradingLoLExaminationButton';
import SeeLoLExaminationResultButton from '../LoLInstruction/Containers/LoLExaminationActionButton/SeeLoLExaminationResultButton';
import { CHECK_EXAM_STATUS_INTERVAL, PublicExamStatusErrorCode } from '../constant';

import LogoutButton from './Containers/LogoutButton';
import MilestoneGroup from './Containers/MilestoneGroup';
import { DEFAULT_MILESTONES } from './constants';
import { MilestoneCardAction } from './types';

const CompletedLoLMilestones = () => {
  const { bookId = '', examId = '' } = useParams();
  const navigate = useNavigate();
  const { loginState } = useAuthentication();
  const { t } = useTranslation('lolMilestones');

  const { data: examinationData, loading: examinationLoading } = useGetLoLExamByExamId({
    variables: { examId },
    fetchPolicy: 'network-only',
  });

  const {
    error,
    loading: examStatusLoading,
    refetch: checkExamStatusByBookIdAndExamId,
  } = useCheckExamStatusByBookIdAndExamId({
    variables: { bookId, examId },
    fetchPolicy: 'network-only',
  });

  const { data: examCard, loading: examCardLoading } = useGetExamCardByBookIdAndExamId({
    variables: { bookId, examId },
    onError: (error) => {
      console.error(error);
    },
  });

  const examStatus = error?.graphQLErrors?.[0].extensions?.exception?.errors?.[0]?.code;

  const examCardId = examCard?.qmsGetExamCardByBookIdAndExamId?.id;

  const examData = examinationData?.qmsFoGetExamById;
  const examItems = examData?.examItems ?? [];
  const sortedExamItems = [...examItems].sort((current, next) => current.order - next.order);

  const lastTrialQuestionNo = examData?.metadata?.endOfTrialAtQuestionNo;

  const examQuestions = sortedExamItems.map((examItem) => ({
    id: examItem.question?.id,
    order: examItem.order,
    tags: examItem.question?.tags,
  }));

  const examQuestionByTags = tidy(
    examQuestions.map((examItem) => {
      return {
        order: examItem?.order,
        tag: examItem.tags?.find(({ category }) => category === 'SUBJECT')?.name,
      };
    }),
    groupBy('tag', summarize({ questions: (items) => items.map((item) => item) }))
  );

  const examQuestionByMilestones = examQuestionByTags.flatMap(({ tag, questions }) => {
    const tempExamByTag = [];
    //RIASEC type is separated to 2 part
    if (tag === LoLExaminationTag.RIASEC) {
      tempExamByTag.push({
        tag: LoLExaminationSection.RIASEC_PART_1,
        questions: questions.slice(0, lastTrialQuestionNo),
      });
      if (questions.length > lastTrialQuestionNo) {
        tempExamByTag.push({
          tag: LoLExaminationSection.RIASEC_PART_2,
          questions: questions.slice(lastTrialQuestionNo),
        });
      }
      return [...tempExamByTag];
    } else if (tag === LoLExaminationTag.ACTIVITY) {
      tempExamByTag.push({
        tag: LoLExaminationSection.ACTIVITY_PART_1,
        questions: questions.slice(0, LAST_QUESTION_NO_OF_FIRST_ACTIVITY),
      });
      if (questions.length > LAST_QUESTION_NO_OF_FIRST_ACTIVITY) {
        tempExamByTag.push({
          tag: LoLExaminationSection.ACTIVITY_PART_2,
          questions: questions.slice(LAST_QUESTION_NO_OF_FIRST_ACTIVITY),
        });
      }
      return [...tempExamByTag];
    }

    return { tag, questions };
  });

  const allTags = examQuestionByMilestones.map(({ tag }) => tag);

  const milestones: Milestone[] = useMemo(() => {
    return DEFAULT_MILESTONES.filter((milestone) => {
      return milestone.tags.some((tag) => allTags.includes(tag));
    }).map((milestone) => {
      const examItems = examQuestionByMilestones.filter(
        ({ tag }) => !!tag && milestone.tags.includes(tag as LoLExaminationSection)
      );

      const examQuestionItems = examItems.flatMap((examItem) => {
        return examItem.questions;
      });
      const sortedExamQuestionItems = [...examQuestionItems].sort(
        (current, next) => current.order - next.order
      );

      const questionCountByTag = examItems.map(({ questions, tag = '' }) => {
        return {
          tag,
          questionCount: questions.length,
        };
      });

      const totalQuestionCount = examItems.reduce((acc, exam) => acc + exam.questions.length, 0);

      const startQuestionNo = sortedExamQuestionItems?.[0]?.order ?? 1;

      const endQuestionNo =
        sortedExamQuestionItems?.[sortedExamQuestionItems.length - 1]?.order ?? 1;

      const status = MilestoneCardAction.COMPLETED;

      const examTags = examItems.map(({ tag }) => tag) as LoLExaminationSection[];

      const careers = milestone.careers ?? [];
      const tranlatedCareers = careers.map((career) => t(`CAREER.${career}`, { ns: 'lolCommon' }));

      return {
        ...milestone,
        tags: examTags,
        careers: tranlatedCareers,
        questionCountByTag,
        totalQuestionCount,
        status,
        duration: Math.floor((totalQuestionCount * DURATION_PER_QUESTION) / 60), //MINUTE UNIT
        startQuestionNo,
        endQuestionNo,
      };
    });
  }, [allTags, examQuestionByMilestones, t]);

  useEffect(() => {
    if (examStatus == PublicExamStatusErrorCode.GRADING) {
      const intervalId = setInterval(() => {
        checkExamStatusByBookIdAndExamId();
      }, CHECK_EXAM_STATUS_INTERVAL);
      return () => clearInterval(intervalId);
    }
  }, [checkExamStatusByBookIdAndExamId, examStatus]);

  const renderExaminationActionButton = () => {
    if (examStatusLoading || examCardLoading) {
      return (
        <Skeleton
          width={'100%'}
          height={48}
          sx={{
            borderRadius: 1,
            mx: 'auto',
          }}
        />
      );
    }

    switch (examStatus) {
      case PublicExamStatusErrorCode.GRADING:
        return <GradingLoLExaminationButton />;
      case PublicExamStatusErrorCode.FINISHED:
      default:
        return <SeeLoLExaminationResultButton examCardIdByBookIdAndExamId={examCardId} />;
    }
  };

  const handleGoToInstruction = () => {
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace('milestones', 'instruction');
    navigate(newPath);
  };

  if (examCardLoading || examinationLoading) return <Loading open />;

  return (
    <>
      <Helmet>
        <title>{setPageTitle('LoLxExamHub Examination | Milestones')}</title>
      </Helmet>
      <LoLExamRoomAppBar
        title={t('TITLE')}
        rightAdornment={loginState === LoginState.LOGGED_IN ? <LogoutButton /> : <></>}
      />
      <Box pt={3} pb={10} px={3} overflow={'auto'} color={'lolText.primary'}>
        <Text variant="h2">{t('DETAILS.MAIN')}</Text>
        <Text variant="caption" color={'lolText.secondary'} component={'div'} marginTop={1}>
          {t('DETAILS.SUB')}
        </Text>
        <MilestoneGroup
          milestones={milestones}
          isExamCompleted={true}
          handleGoToInstruction={handleGoToInstruction}
        />
        <Box mt={5}>{renderExaminationActionButton()}</Box>
      </Box>
    </>
  );
};

export default CompletedLoLMilestones;
