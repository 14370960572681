import { Fragment, useState } from 'react';
import { Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Paper } from '../../../../Components/Base';
import useGetExaminations from '../../../../Domains/useGetExaminations';
import useGetExaminationResults, {
  QmsExamPaperStatus,
} from '../../../../Domains/useGetExaminationResults';
import ExaminationResultItem from '../../Components/ExaminationResultItem';
import EmptyExaminationResults from '../../Components/EmptyExaminationResult';
import ExaminationResultByRound from '../ExaminationResultByRound';
import ExaminationResultListLoading from '../../Components/ExaminationResultListLoading';
import DataLoadingError from '../../../../Components/DataLoadingError';
import { RESPONSE_TYPE } from '../../../../Domains/constants';
import NotFoundError from '../../../../Components/NotFoundError';

const ExamList = () => {
  const navigate = useNavigate();
  const { bookId = '' } = useParams();
  const { data: examsData, error: examsError } = useGetExaminations({ variables: { bookId } });
  const {
    data: examResultsData,
    loading,
    error: examResultsError,
    refetch,
  } = useGetExaminationResults({
    bookId,
    examCards: examsData,
    statuses: [QmsExamPaperStatus.Grading, QmsExamPaperStatus.Finished],
  });
  const [currentExpandIndex, setCurrentExpandIndex] = useState<number | null>(
    parseInt(location.hash.replace('#', '')) ?? null
  );
  const isEmptyResult = examResultsData && examResultsData.length === 0;

  const handleExpansion = (index: number) => {
    if (index === currentExpandIndex) {
      setCurrentExpandIndex(null);
      navigate(`${location.pathname}`, { replace: true });
    } else {
      setCurrentExpandIndex(index);
      navigate(`${location.pathname}#${index}`, { replace: true });
    }
  };

  if (examsError || examResultsError) {
    const examsErrorHttpStatus = examsError?.graphQLErrors[0]?.extensions?.exception.httpStatus;

    const examResultsErrorHttpStatus =
      examResultsError?.graphQLErrors[0]?.extensions?.exception.httpStatus;

    if (
      examsErrorHttpStatus === RESPONSE_TYPE.NOT_FOUND ||
      examResultsErrorHttpStatus === RESPONSE_TYPE.NOT_FOUND
    ) {
      return <NotFoundError open onBackToHome={() => navigate('/')} />;
    }

    if (examsError?.message === RESPONSE_TYPE.NETWORK_ERROR || examResultsError?.message) {
      return <DataLoadingError onRetry={() => refetch()} mt={9} />;
    }
  }

  if (isEmptyResult) return <EmptyExaminationResults />;

  return (
    <Paper sx={{ borderRadius: { xs: 0, sm: 1 }, mb: 3 }}>
      {loading && <ExaminationResultListLoading />}
      {examResultsData &&
        examResultsData.map(({ examCard, examResults }, index) => {
          const skuExamItem = examCard.skuExamItem;
          const examCardId = examCard.id ?? '';
          const currentExamId = examCard.skuExamItem?.exam?.id;
          const title = skuExamItem?.exam?.title ?? '';
          return (
            <Fragment key={examCardId}>
              {index > 0 && <Divider sx={{ mx: { xs: 2, sm: 3 } }} />}
              <ExaminationResultItem
                title={title}
                onChange={() => handleExpansion(index)}
                expanded={currentExpandIndex === index}
              >
                <ExaminationResultByRound
                  examCardId={examCardId}
                  currentExamId={currentExamId}
                  examResults={examResults}
                  title={title}
                />
              </ExaminationResultItem>
            </Fragment>
          );
        })}
    </Paper>
  );
};

export default ExamList;
