import { DialogTitle as MuiDialogTitle, DialogTitleProps, IconButton, styled } from '@mui/material';

import CloseRoundedIcon from '../../../Icons/CloseRoundedIcon';
import Text from '../../Text';

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    maxWidth: `1080px`,
  },
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

type ModalTitleProps = {
  onClose?: () => void;
} & DialogTitleProps;

const ModalTitle = (props: ModalTitleProps) => {
  const { children, onClose, ...restProps } = props;
  return (
    <DialogTitle {...restProps}>
      {typeof children === 'string' ? (
        <Text component="p" variant="subtitle1">
          {children}
        </Text>
      ) : (
        children
      )}
      {typeof onClose === 'function' ? (
        <IconButton color={'inherit'} onClick={onClose} sx={{ ml: 'auto', alignSelf: 'start' }}>
          <CloseRoundedIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default ModalTitle;
export type { ModalTitleProps };
