import { Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useCheckExamStatusByBookIdAndExamId from '../../../../Domains/useCheckStatusExamByBookIdAndExamId';
import { useQmsGetExamCardByBookIdAndExamIdQuery as useGetExamCardByBookIdAndExamId } from '../../../../generated/graphql';
import { CHECK_EXAM_STATUS_INTERVAL, PublicExamStatusErrorCode } from '../../../constant';

import ContinueLoLExaminationButton from './ContinueLoLExaminationButton';
import GradingLoLExaminationButton from './GradingLoLExaminationButton';
import SeeLoLExaminationResultButton from './SeeLoLExaminationResultButton';
import StartLoLExaminationButton from './StartLoLExaminationButton';

const LoLExaminationActionButton = () => {
  const { bookId = '', examId = '' } = useParams();

  const {
    error,
    loading: examStatusLoading,
    refetch: checkExamStatusByBookIdAndExamId,
  } = useCheckExamStatusByBookIdAndExamId({
    variables: { bookId, examId },
    fetchPolicy: 'network-only',
  });

  const examStatus = error?.graphQLErrors?.[0].extensions?.exception?.errors?.[0]?.code;

  const { data: examCard, loading: examCardLoading } = useGetExamCardByBookIdAndExamId({
    variables: { bookId, examId },
    onError: (error) => {
      console.error(error);
    },
  });

  const examCardId = examCard?.qmsGetExamCardByBookIdAndExamId?.id;

  useEffect(() => {
    if (examStatus == PublicExamStatusErrorCode.GRADING) {
      const intervalId = setInterval(() => {
        checkExamStatusByBookIdAndExamId();
      }, CHECK_EXAM_STATUS_INTERVAL);
      return () => clearInterval(intervalId);
    }
  }, [checkExamStatusByBookIdAndExamId, examStatus]);

  const renderExaminationActionButton = () => {
    if (examStatusLoading || examCardLoading) {
      return (
        <Skeleton
          width={'100%'}
          height={48}
          sx={{
            borderRadius: 1,
            mx: 'auto',
          }}
        />
      );
    }

    switch (examStatus) {
      case PublicExamStatusErrorCode.NOT_START_NO_EXAM_CARD:
        return <StartLoLExaminationButton />;
      case PublicExamStatusErrorCode.NOT_START_NO_EXAM_PAPER:
        return <StartLoLExaminationButton examCardIdByBookIdAndExamId={examCardId} alreadyRedeem />;
      case PublicExamStatusErrorCode.GRADING:
        return <GradingLoLExaminationButton />;
      case PublicExamStatusErrorCode.FINISHED:
        return <SeeLoLExaminationResultButton examCardIdByBookIdAndExamId={examCardId} />;
      default:
        return <ContinueLoLExaminationButton examCardIdByBookIdAndExamId={examCardId} />;
    }
  };

  return renderExaminationActionButton();
};

export default LoLExaminationActionButton;
