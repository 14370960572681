import {
  AchievementIcon,
  ArtisticIcon,
  ConventionalIcon,
  EnterprisingIcon,
  IndependenceIcon,
  InvestigativeIcon,
  RealisticIcon,
  RecognitionIcon,
  RelationshipsIcon,
  SocialIcon,
  SupportIcon,
  WorkingConditionsIcon,
} from '../../../../Components/Icons/Trait';

const iconStyles = {
  width: '24px',
  height: '24px',
  sx: { position: 'absolute' },
};

const valueTraitGroup = [
  {
    icon: <AchievementIcon {...iconStyles} titleAccess="Achievement" />,
    title: 'Achievement',
  },
  {
    icon: <IndependenceIcon {...iconStyles} titleAccess="Independence" />,
    title: 'Independence',
  },
  {
    icon: <RecognitionIcon {...iconStyles} titleAccess="Recognition" />,
    title: 'Recognition',
  },
  {
    icon: <RelationshipsIcon {...iconStyles} titleAccess="Relationships" />,
    title: 'Relationships',
  },
  {
    icon: <SupportIcon {...iconStyles} titleAccess="Support" />,
    title: 'Support',
  },
  {
    icon: <WorkingConditionsIcon {...iconStyles} titleAccess="Working Conditions" />,
    title: 'Working Conditions',
  },
];

const riasecTraitGroup = [
  {
    icon: <RealisticIcon {...iconStyles} titleAccess="Realistic" />,
    title: 'Realistic',
  },
  {
    icon: <InvestigativeIcon {...iconStyles} titleAccess="Investigative" />,
    title: 'Investigative',
  },
  {
    icon: <SocialIcon {...iconStyles} titleAccess="Social" />,
    title: 'Social',
  },
  {
    icon: <ArtisticIcon {...iconStyles} titleAccess="Artistic" />,
    title: 'Artistic',
  },
  {
    icon: <ConventionalIcon {...iconStyles} titleAccess="Conventional" />,
    title: 'Conventional',
  },
  {
    icon: <EnterprisingIcon {...iconStyles} titleAccess="Enterprising" />,
    title: 'Enterprising',
  },
];

export { valueTraitGroup, riasecTraitGroup };
