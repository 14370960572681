import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Modal, Text } from '../../../../Components/Base';
import LoLButton, { LoLButtonProps } from '../../../../Components/Base/LoLButton';
import PhoneCircleIcon from '../../../../Components/Icons/PhoneCircleIcon';
import { APP_BAR_HEIGHT } from '../../../../Components/Layout';
import FixedMobileLayout from '../../../../Containers/FixedMobileLayout';
import OTPLoginForm from '../../../../Containers/OTPLoginForm';
import { useAnonymous } from '../../../../Contexts/AnonymousProvider';
import { useAuthentication } from '../../../../Contexts/AuthenticationProvider';
import useDisclosure from '../../../../Utils/useDisclosure';

type PhoneNumberLoginButtonProps = {
  redirectPath: string;
  shouldOpenResultModal: boolean;
} & LoLButtonProps;

const PhoneNumberLoginButton = (props: PhoneNumberLoginButtonProps) => {
  const { redirectPath, shouldOpenResultModal, ...restProps } = props;
  const { isOpen: isOpenOTPModal, open: openOTPModal, close: closeOTPModal } = useDisclosure();
  const { t } = useTranslation('lolSignIn');
  const { loginWithPhoneNumber } = useAuthentication();
  const { anonymousUser } = useAnonymous();

  const handlePhoneNumberLogin = () => {
    openOTPModal();
  };

  const handleSuccess = (callbackUrl: string) => {
    const storageObject = {
      anonymousUserId: anonymousUser?.id ?? '',
      redirectPath,
      shouldOpenResultModal,
    };
    loginWithPhoneNumber(storageObject, callbackUrl);
  };
  return (
    <>
      <Modal open={isOpenOTPModal} fullScreen PaperProps={{ sx: { bgcolor: 'transparent' } }}>
        <FixedMobileLayout>
          <Box
            px={3}
            pt={`${APP_BAR_HEIGHT}px`}
            height={'auto'}
            minHeight={'100%'}
            bgcolor={'lolBackground.primary'}
            color={'lolText.primary'}
          >
            <OTPLoginForm handleClose={closeOTPModal} handleSuccess={handleSuccess} />
          </Box>
        </FixedMobileLayout>
      </Modal>
      <LoLButton
        onClick={handlePhoneNumberLogin}
        size="large"
        sx={{
          bgcolor: 'common.white',
          color: 'common.black',
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            color: 'lolTextButton.primary',
          },
        }}
        fullWidth
        {...restProps}
      >
        <PhoneCircleIcon sx={{ mr: 2 }} />
        <Text marginY={'auto'} fontWeight={600}>
          {t('LOGIN_PROVIDER.PHONE_NUMBER')}
        </Text>
      </LoLButton>
    </>
  );
};

export default PhoneNumberLoginButton;
