import Tooltip, { TooltipProps, toolTipTheme } from './Tooltip';

// Export components
export default Tooltip;

// Export theme components
export { toolTipTheme };

// Export input type
export type { TooltipProps };
