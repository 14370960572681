import ExpandLessIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, Button, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useDisclosure from '../../Utils/useDisclosure';
import { Flex, Image, Text } from '../Base';
import HTMLView from '../HTMLView';

const roundedImageStyle = {
  width: '100%',
  maxWidth: '200px',
  borderRadius: '100%',
  border: '3px solid',
  borderColor: 'lolElement.gray',
};

const normalImageStyle = {
  width: '100%',
  height: '180px',
  borderRadius: '4px',
};

type LoLResultDetailsProps = {
  title: string;
  imgUrl: string;
  descriptions: string[];
  resultTitle?: string;
  isRoundedImage?: boolean;
};

const LoLResultDetailsCard = (props: LoLResultDetailsProps) => {
  const { title = '', imgUrl, descriptions, resultTitle, isRoundedImage } = props;
  const { isOpen, open, close } = useDisclosure();
  const { t } = useTranslation('lolResults');

  const imageStyle = isRoundedImage ? roundedImageStyle : normalImageStyle;

  const handleClick = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  return (
    <Box
      p={3}
      borderRadius={1}
      bgcolor={'lolCard.primary'}
      color={'lolText.primary'}
      border={'1px solid'}
      borderColor={'lolLine.line1'}
      height={'max-content'}
      sx={{
        '.lol-result-details-card': {
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: '16px',
          borderRadius: '8px',
          alignItems: 'center',
          bgcolor: 'lolCard.secondary',
          border: '1px solid',
          borderColor: 'lolLine.line2',
        },
      }}
    >
      <Flex gap={2} flexDirection={'column'} alignItems={'center'}>
        {resultTitle && (
          <Text variant="h2" textAlign={'center'}>
            {resultTitle}
          </Text>
        )}
        <Image
          src={imgUrl}
          alt={title}
          {...imageStyle}
          sx={{ aspectRatio: isRoundedImage ? '1' : 'auto', objectFit: 'cover' }}
        />
        <Text variant="h2" textAlign={'center'}>
          {title}
        </Text>
        <Box width={1}>
          {descriptions?.[0] && (
            <HTMLView sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
              {descriptions?.[0]}
            </HTMLView>
          )}
          {descriptions?.[1] && (
            <Collapse in={isOpen}>
              <HTMLView sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {descriptions?.[1]}
              </HTMLView>
            </Collapse>
          )}
        </Box>
        <Button
          variant={'text'}
          onClick={handleClick}
          endIcon={
            isOpen ? (
              <ExpandLessIcon sx={{ width: 24, height: 24 }} />
            ) : (
              <ExpandMoreIcon sx={{ width: 24, height: 24 }} />
            )
          }
          sx={{
            color: 'lolText.secondary',
            mt: 0,
            px: 1,
            py: 0.5,
            '&:hover': {
              color: 'lolText.secondary',
              bgcolor: 'transparent',
            },
          }}
        >
          <Text>{isOpen ? t('EXPANDED.LESS') : t('EXPANDED.MORE')}</Text>
        </Button>
      </Flex>
    </Box>
  );
};

export default LoLResultDetailsCard;
