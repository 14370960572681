import { useEffect } from 'react';
import { styled } from '@mui/material';

import { Box } from '../../../../Components/Base';
import { useExamination } from '../../../../Contexts/ExaminationProvider';
import AnswerSheetByGroup from '../../Components/AnswerSheet/AnswerSheetByGroup';
import BackToTop from '../../../../Containers/BackToTop';

const ExaminationQuestionWrapper = styled(Box)(({ theme }) => ({
  '@keyframes highlightBackground': {
    from: {
      background: theme.palette.warning.lighter,
    },
    to: {
      background: 'transparent',
    },
  },
}));

const Examination = () => {
  const { questionGroups } = useExamination();

  const anchorId = location.hash.replace('#', '');

  useEffect(() => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement !== null) {
      window.scrollTo({
        behavior: 'smooth',
        top: anchorElement.getBoundingClientRect().top + window.pageYOffset - 75,
      });

      const observer = new IntersectionObserver(
        () => {
          anchorElement.style.animation = 'highlightBackground 3s ease-in 1';
        },
        {
          root: document.body,
          rootMargin: '0px',
          threshold: 1.0,
        }
      );
      observer.observe(anchorElement);
    }
  }, [anchorId]);

  return (
    <ExaminationQuestionWrapper>
      {questionGroups.map((questionGroup) => {
        const { group, questions } = questionGroup;
        return <AnswerSheetByGroup key={group} group={group} questions={questions} />;
      })}
      <BackToTop />
    </ExaminationQuestionWrapper>
  );
};

export default Examination;
