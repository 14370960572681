import { styled, alpha } from '@mui/material';

import { Box, Flex, FlexProps, Text } from '../Base';
import ChevronRightRoundedIcon from '../Icons/ChevronRightRoundedIcon';
import ExamIcon from '../Icons/ExamIcon';

const TitleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExamItemWrapper = styled(Flex)(({ theme }) => ({
  background: 'transparent',
  cursor: 'pointer',
  '&:hover': {
    background: alpha(theme.palette.primary.main, 0.04),
  },
}));

const getExaminationStatus = (status?: 'IN_PROGRESS' | 'TIME_UP') => {
  switch (status) {
    case 'IN_PROGRESS': {
      return { color: 'info.main', message: 'กำลังทำข้อสอบ' };
    }
    case 'TIME_UP': {
      return { color: 'error.main', message: 'หมดเวลาทำข้อสอบ' };
    }
    default:
      return {};
  }
};

export type ExamItemType = {
  title: string;
  status?: 'IN_PROGRESS' | 'TIME_UP';
} & FlexProps;

const ExamItem = (props: ExamItemType) => {
  const { title, status, ...restProps } = props;
  const { color: statusColor = '', message: statusMessage = '' } = getExaminationStatus(status);

  return (
    <ExamItemWrapper alignItems={'center'} {...restProps}>
      <Flex p={1} bgcolor={'primary.light'} borderRadius={1} mr={2}>
        <ExamIcon color={'primary'} />
      </Flex>
      <Box width={'100%'}>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Box>
            <TitleText variant="subtitle1">{title}</TitleText>
            {status && (
              <Text component={'p'} variant="caption" color={statusColor}>
                {statusMessage}
              </Text>
            )}
          </Box>
          <ChevronRightRoundedIcon sx={{ ml: 1.5, color: 'text.secondary' }} />
        </Flex>
      </Box>
    </ExamItemWrapper>
  );
};

export default ExamItem;
