import { SvgIcon, SvgIconProps } from '@mui/material';

const PhoneCircleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 25 24" fill="inherit" {...props}>
    <path
      fill="#000"
      d="M12.5 24c6.617 0 12-5.383 12-12s-5.383-12-12-12S.5 5.383.5 12s5.383 12 12 12Zm0-22c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10Zm4.364 11.971 1.302 1c.446.446.446 1.17 0 1.616l-.522.6a2.804 2.804 0 0 1-2 .813c-3.714 0-9.143-5.143-9.143-9.143 0-.723.261-1.449.813-2l.6-.522a1.143 1.143 0 0 1 1.616 0l1 1.302c.446.446.446 1.17 0 1.616l-.851 1.069c.901 2.244 2.429 3.71 4.5 4.5l1.069-.851a1.143 1.143 0 0 1 1.616 0Z"
    />
  </SvgIcon>
);
export default PhoneCircleIcon;
