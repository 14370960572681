import { Divider } from '@mui/material';

import { Box, Flex, Paper, Skeleton } from '../../../../Components/Base';

const SolutionSummaryLoading = () => (
  <>
    <Paper
      sx={{
        mb: { xs: 1, sm: 2 },
        p: { xs: 2, md: 3 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      <Box mb={2}>
        <Skeleton width={'100%'} height={27.5} />
      </Box>
      <Divider />
      <Flex my={2} gap={1}>
        <Skeleton width={60} height={26} />
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <Flex justifyContent={'space-between'} width={'100%'}>
          <Skeleton width={100} height={26} />
        </Flex>
      </Flex>
      <Divider />
      <Box>
        <Flex mt={1.5} gap={1} justifyContent={'space-between'}>
          <Skeleton width={42} height={24} />
          <Skeleton width={150} height={24} />
        </Flex>
        <Flex mt={1.5} gap={1} justifyContent={'space-between'}>
          <Skeleton width={42} height={24} />
          <Skeleton width={60} height={24} />
        </Flex>

        <Flex mt={1.5} gap={1} justifyContent={'space-between'} alignItems={'center'}>
          <Skeleton width={110} height={24} />
          <Skeleton width={80} height={24} />
        </Flex>
      </Box>
    </Paper>
  </>
);

export default SolutionSummaryLoading;
