import { alpha, styled } from '@mui/material';

import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle as BaseModalTitle,
  Text,
} from '../../../../Components/Base';

const ModalTitle = styled(BaseModalTitle)(({ theme }) => ({
  '.MuiIconButton-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[300], 0.4),
    },
  },
}));

type ResumeExaminationModalProps = {
  onRestart?: ButtonProps['onClick'];
  onResume?: ButtonProps['onClick'];
  onCancel?: () => void;
} & Omit<ModalProps, 'onClose'>;

const ResumeExaminationModal = (props: ResumeExaminationModalProps) => {
  const { onResume, onRestart, onCancel, ...restProps } = props;
  return (
    <Modal {...restProps}>
      <ModalTitle onClose={onCancel} />
      <ModalContent>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <Text variant={'h2'}>{`ทำข้อสอบต่อ?`}</Text>
          <Text
            mt={3}
            textAlign={'center'}
          >{`คุณมีข้อสอบที่ทำค้างไว้ ต้องการทำข้อสอบต่อหรือไม่`}</Text>
        </Flex>
      </ModalContent>
      <ModalFooter flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={2} px={3} pb={3}>
        {typeof onRestart === 'function' && (
          <Button
            fullWidth
            variant={'outlined'}
            color={'greyButton'}
            size={'large'}
            onClick={onRestart}
          >
            เริ่มใหม่
          </Button>
        )}
        {typeof onResume === 'function' && (
          <Button fullWidth size={'large'} onClick={onResume}>
            ทำต่อ
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ResumeExaminationModal;
