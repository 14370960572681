import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGES, LANGUAGES_LABEL } from './constants';
// Thai Translation Files
import commonTH from './th/common.json';
import lolCommonTH from './th/lol/common.json';
import lolSignInTH from './th/lol/sign-in.json';
import lolExamroomTH from './th/lol/examroom.json';
import lolInstructionsTH from './th/lol/instructions.json';
import lolMilestonesTH from './th/lol/milestones.json';
import lolResultsTH from './th/lol/results.json';
// English Translation Files
import commonEN from './en/common.json';
import lolCommonEN from './en/lol/common.json';
import lolSignInEN from './en/lol/sign-in.json';
import lolExamroomEN from './en/lol/examroom.json';
import lolInstructionsEN from './en/lol/instructions.json';
import lolMilestonesEN from './en/lol/milestones.json';
import lolResultsEN from './en/lol/results.json';
import { handleChangeLanguage } from './utils';

const resources = {
  [LANGUAGES.TH]: {
    common: commonTH,
    lolCommon: lolCommonTH,
    lolSignIn: lolSignInTH,
    lolExamroom: lolExamroomTH,
    lolInstructions: lolInstructionsTH,
    lolMilestones: lolMilestonesTH,
    lolResults: lolResultsTH,
  },
  [LANGUAGES.EN]: {
    common: commonEN,
    lolCommon: lolCommonEN,
    lolSignIn: lolSignInEN,
    lolExamroom: lolExamroomEN,
    lolInstructions: lolInstructionsEN,
    lolMilestones: lolMilestonesEN,
    lolResults: lolResultsEN,
  },
};

i18next.use(initReactI18next).init(
  {
    lng: LANGUAGES.TH,
    debug: true,
    fallbackLng: [LANGUAGES.TH, LANGUAGES.EN],
    resources,
    defaultNS: 'common',
  },
  (err, t) => {
    if (err) {
      return console.error('i18n init error', err);
    }

    console.log('i18n initialized');
    const localStorageLang = localStorage.getItem('i18nextLng');

    if (!localStorageLang) {
      handleChangeLanguage(LANGUAGES_LABEL.TH);
    } else if (localStorageLang !== i18next.language) {
      handleChangeLanguage(localStorageLang.split('-')[0]);
    }
  }
);
