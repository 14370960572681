import { Theme, createTheme } from '@mui/material/styles';
import { isObjectLike, merge } from 'lodash';
import { DeepPartial } from 'react-hook-form';

import examhubTheme from '../Theme';

import buttonTheme from './buttonTheme';
import paletteTheme from './paletteTheme';

function getDefaultTheme(): Theme {
  return createTheme({
    palette: paletteTheme.palette,
    typography: examhubTheme.typography,
    shape: {
      borderRadius: 8,
    },
    shadows: examhubTheme.shadows,
    components: {
      ...examhubTheme.components,
      ...buttonTheme.components,
    },
  });
}

function mergeTheme(overrideTheme: DeepPartial<Theme>, baseTheme: Theme): Theme {
  const mergedTheme = merge({}, baseTheme, overrideTheme);

  return mergedTheme;
}

function extendTheme(overrideTheme: DeepPartial<Theme> = {}, baseTheme?: Theme): Theme {
  if (!baseTheme) baseTheme = getDefaultTheme();

  if (!isObjectLike(overrideTheme)) {
    return baseTheme;
  } else {
    return mergeTheme(overrideTheme, baseTheme);
  }
}

export { extendTheme, getDefaultTheme, mergeTheme };
