import { createTheme, experimental_sx as sx } from '@mui/material/styles';

const dialogTheme = createTheme({
  components: {
    MuiDialog: {
      defaultProps: {
        disableEscapeKeyDown: true,
      },
      styleOverrides: {
        paperWidthXs: {
          maxWidth: 480,
        },
      },
    },
  },
});

export default dialogTheme;
