import { useTranslation } from 'react-i18next';

import { Flex, Image, Text, Box } from '../../../../Components/Base';
import { useConfig } from '../../../../Contexts/ConfigProvider';

const LoLSeeResultInstruction = () => {
  const { t } = useTranslation('lolSignIn');
  const {
    lol: { lolResultLogoUrl = '', imgPrefixPath },
  } = useConfig();
  return (
    <Flex
      borderRadius={1}
      border={`1px solid`}
      borderColor={'lolLine.line1'}
      color={'lolText.primary'}
      p={2}
      bgcolor={'lolCard.primary'}
      textAlign={'center'}
      rowGap={2}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <Text variant="h3" fontWeight={400} lineHeight={1.4} textAlign={'center'}>
        {t('INSTRUCTIONS.HEADER')}
      </Text>
      <Image
        src={`${imgPrefixPath}/${lolResultLogoUrl}`}
        alt="lol-result-logo"
        width={'100%'}
        height={'auto'}
        maxWidth={'200px'}
        sx={{ aspectRatio: '1/1' }}
      />
      <Box>
        <Text variant="h2" fontWeight={500} sx={{ whiteSpace: 'pre-line' }}>
          {t('INSTRUCTIONS.MAIN')}
        </Text>
        <Text variant="body1" fontWeight={300} mt={0.5}>
          {t('INSTRUCTIONS.SUB')}
        </Text>
      </Box>
    </Flex>
  );
};

export default LoLSeeResultInstruction;
