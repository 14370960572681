import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Text } from '../../../../Components/Base';

const LoLInstructionDetails = () => {
  const { t } = useTranslation('lolInstructions');

  return (
    <Box
      borderRadius={1}
      border={'1px solid'}
      borderColor={'lolLine.line1'}
      bgcolor={'lolCard.primary'}
      color={'lolText.primary'}
      p={2}
    >
      <Text variant="subtitle1" mb={1} textAlign={'center'}>
        {t('EXAM.TITLE')}
      </Text>
      <Text variant="body2">{t('EXAM.DESCRIPTION')}</Text>
      <Divider
        aria-hidden="true"
        sx={{
          borderColor: 'lolLine.line2',
          borderStyle: 'dashed',
          my: 3,
        }}
      />
      <Text variant="body2" color={'lolText.secondary'} whiteSpace={'pre-line'} fontSize={10}>
        {t('EXAM.DISCLAIMER')}
      </Text>
    </Box>
  );
};

export default LoLInstructionDetails;
