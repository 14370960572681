import { DialogTitle, Divider, Drawer as MUIDrawer, List, styled } from '@mui/material';

import { Box, IconButton } from '../../Components/Base';
import { MENU_DRAWER_WIDTH } from '../../Components/Layout';
import CloseRoundedIcon from '../../Components/Icons/CloseRoundedIcon';
import MenuIcon from '../../Components/Icons/MenuIcon';
import useDisclosure from '../../Utils/useDisclosure';
import useAppMenu from '../../Domains/useAppMenu';
import MenuItem from '../MenuItem';

const Drawer = styled(MUIDrawer)(({ theme }) => ({
  display: 'block',
  '& .MuiPaper-root': {
    boxSizing: 'border-box',
    width: MENU_DRAWER_WIDTH,
    padding: theme.spacing(2),
  },
}));

const HamburgerMenu = () => {
  const { isOpen, open, close } = useDisclosure();
  const { appMenu } = useAppMenu();

  return (
    <>
      <IconButton color={'inherit'} onClick={open}>
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={close}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DialogTitle sx={{ p: 0 }}>
          <IconButton color={'inherit'} onClick={close}>
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <List sx={{ pt: 2 }}>
          {appMenu.map((menu) => {
            return (
              <Box key={menu.label} onClick={close}>
                <Divider />
                <MenuItem {...menu} />
              </Box>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
