import { Dialog as Modal } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '../../Components/Base';
import LoLButton from '../../Components/Base/LoLButton';
import { APP_BAR_HEIGHT } from '../../Components/Layout';
import LoLResultItem from '../../Components/LoLResultItem';
import { useConfig } from '../../Contexts/ConfigProvider';
import LockReport from '../../Routes/LoLFullReport/Components/LockReport';
import {
  Lol_Exam_Result_Type,
  QmsExamRoomLolExamResult,
  QmslolExamResult,
  useGetLoLExamByExamPaperIdQuery as useGetLoLExamByExamPaperId,
} from '../../generated/graphql';
import { getShortCurrentLanguage } from '../../i18n/utils';
import FixedMobileLayout from '../FixedMobileLayout';
import LoLExamFooterLayout from '../LoLExaminationRoom/LoLExamFooterLayout';
import LoLExamRoomAppBar from '../LoLExaminationRoom/LoLExamRoomAppBar';
import { FOOTER_HEIGHT } from '../LoLExaminationRoom/constants';

import { breakDescription, getLoLResultCategory, getRoundedImageBoolean } from './utils';

type LoLReportModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  displayData?: QmslolExamResult | QmsExamRoomLolExamResult;
  title?: string;
  conditionNumber?: number;
};

const EmptyLoLResult = (props: { handleStartExam: () => void; conditionNumber: number }) => {
  const { handleStartExam, conditionNumber } = props;
  const { t } = useTranslation('lolCommon');
  return (
    <>
      <Box p={3} height={'auto'}>
        <LockReport conditionNumber={conditionNumber} />
      </Box>
      <LoLExamFooterLayout>
        <LoLButton fullWidth size="large" onClick={handleStartExam}>
          {t('BUTTON.START')}
        </LoLButton>
      </LoLExamFooterLayout>
    </>
  );
};

const LoLReportModal = (props: LoLReportModalProps) => {
  const { isOpen, handleClose, title, conditionNumber = 1, displayData } = props;

  const { examPaperId = '' } = useParams();
  const { lol = {} } = useConfig();
  const currentLanguage = getShortCurrentLanguage();

  const { data: examData } = useGetLoLExamByExamPaperId({
    variables: {
      examPaperId: examPaperId,
    },
  });

  const navigate = useNavigate();

  const hasData = useMemo(() => {
    return displayData !== undefined;
  }, [displayData]);

  const handleStartExam = () => {
    const examId = examData?.qmsFoGetExamPaperById.exam?.id ?? '';
    const newPath = `../examinations/${examId}/instruction`;
    navigate(newPath);
    handleClose();
  };

  const renderResults = () => {
    if (!hasData) {
      return <EmptyLoLResult conditionNumber={conditionNumber} handleStartExam={handleStartExam} />;
    }

    const {
      type,
      description: resultDescription,
      imageUrl,
      title,
      refCode,
    } = displayData as QmslolExamResult;

    const isRoundedImage = getRoundedImageBoolean(type);
    const descriptions = breakDescription(resultDescription, type);

    const isCareerGroup = type === Lol_Exam_Result_Type.CareerGroup;
    const { sharingWebUrl } = lol;
    const permalink =
      type && refCode
        ? `${sharingWebUrl}/${currentLanguage}/${getLoLResultCategory(type)}/${refCode}`
        : undefined;

    return (
      <Box p={3} pb={7} height={'auto'}>
        <LoLResultItem
          permalink={permalink}
          title={title}
          descriptions={descriptions}
          imageUrl={imageUrl}
          index={0}
          isShowSharedButton={!isCareerGroup}
          isRoundedImage={isRoundedImage}
          type={type}
        />
      </Box>
    );
  };

  return (
    <Modal open={isOpen} fullScreen PaperProps={{ sx: { bgcolor: 'transparent' } }}>
      <FixedMobileLayout>
        <LoLExamRoomAppBar onBack={handleClose} title={title} />
        <Box
          overflow={'auto'}
          minHeight={`calc(100% - ${APP_BAR_HEIGHT + FOOTER_HEIGHT}px)`}
          height={'100%'}
          bgcolor={'lolBackground.primary'}
        >
          {renderResults()}
        </Box>
      </FixedMobileLayout>
    </Modal>
  );
};

export default LoLReportModal;
