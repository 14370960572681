import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import QuestionDetail from '../../../../Components/QuestionDetail';
import {
  renderAnswerResult,
  renderCorrectAnswer,
} from '../../../../Domains/useGetExaminationDetails/utils';
import useGetExaminationResultSolutionSummary, {
  examResultSolutionSummaryType as QuestionSummaryType,
} from '../../../../Domains/useGetExaminationResultSolutionSummary';
import { setPageTitle } from '../../../../Utils/page';

import ResultSolutionSummaryLoading from './ResultSolutionSummaryLoading';

const formatQuestionSolutionDetail = (question: QuestionSummaryType['qmsGetExamPaperItemById']) => {
  const { exam, order, question: questionItem, isCorrect, answer, score } = question;
  const correctAnswer = questionItem?.correctAnswer.value;
  return {
    examTitle: exam?.title ?? '',
    questionNo: order,
    partTitle: questionItem?.tags.find((tag) => tag.category === 'SUBJECT')?.name ?? '',
    section: questionItem?.tags.find((tag) => tag.category === 'SUBSECTION')?.name ?? '',
    difficulty: questionItem?.tags.find((tag) => tag.category === 'DIFFICULTY')?.name,
    correctAnswer: renderCorrectAnswer(questionItem?.type, correctAnswer),
    type: questionItem?.type,
    isCorrect: isCorrect,
    answer: renderAnswerResult(questionItem?.type, answer.value),
    score,
  };
};

const ResultSolutionSummary = () => {
  const { examPaperItemId = '' } = useParams();
  // The following line is a mockup examPaperItemId from the backend. It is used for testing purpose.
  // const examPaperItemId = '4a284de0-40f9-4883-aae5-a726bdaa4533';
  const { data, loading, error } = useGetExaminationResultSolutionSummary({
    variables: { examPaperItemId },
  });
  const solutionSummary = data?.qmsGetExamPaperItemById;

  if (loading) return <ResultSolutionSummaryLoading />;
  if (error) return <div>Error</div>;

  const question = formatQuestionSolutionDetail(
    solutionSummary as QuestionSummaryType['qmsGetExamPaperItemById']
  );

  return (
    <>
      <Helmet>
        <title>
          {setPageTitle(`ผลสอบ | ${question.examTitle} | ข้อที่ ${question.questionNo}`)}
        </title>
      </Helmet>
      <QuestionDetail {...question} variant={'EXAMINATION_RESULT'} />
    </>
  );
};

export default ResultSolutionSummary;
