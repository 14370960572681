import { LoLExaminationSection } from '../../Contexts/LoLExaminationProvider/types';

export const examTitles = [
  {
    tags: [LoLExaminationSection.RIASEC_PART_1],
    title: 'CAREER_GROUP',
  },
  {
    tags: [LoLExaminationSection.RIASEC_PART_2],
    title: 'CAPABLE_CAREER',
  },
  {
    tags: [LoLExaminationSection.ACTIVITY_PART_1, LoLExaminationSection.VALUE],
    title: 'PERSONALITY_CAREER',
  },
  {
    tags: [LoLExaminationSection.ACTIVITY_PART_2, LoLExaminationSection.STRENGTH],
    title: 'POTENTIAL_IDENTITY_CAREER',
  },
];
