import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowTrendingSparkle = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 36 36" {...props}>
      <path
        fill="inherit"
        d="M7.281 12.219c.468.47.82 1.042 1.028 1.671l.671 2.066a.816.816 0 0 0 1.54 0l.671-2.066a4.254 4.254 0 0 1 2.697-2.694l2.068-.672a.816.816 0 0 0 0-1.538l-.042-.01-2.067-.672A4.26 4.26 0 0 1 11.15 5.61l-.671-2.066a.816.816 0 0 0-1.54 0L8.266 5.61l-.017.051a4.254 4.254 0 0 1-2.638 2.643l-2.068.672a.816.816 0 0 0 0 1.537l2.068.672c.63.21 1.2.564 1.669 1.034ZM30 10.5a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 1 1-3 0v-3.879l-8.69 8.69a1.5 1.5 0 0 1-2.12 0L15 21.62l-7.94 7.94a1.5 1.5 0 0 1-2.12-2.122l9-9a1.5 1.5 0 0 1 2.12 0l2.69 2.69 7.629-7.629H22.5a1.5 1.5 0 0 1 0-3H30Zm.026 16.448 1.148.372.023.006a.454.454 0 0 1 0 .854l-1.148.372a2.367 2.367 0 0 0-1.5 1.497l-.372 1.148a.454.454 0 0 1-.854 0l-.374-1.146a2.362 2.362 0 0 0-1.498-1.503l-1.148-.373a.455.455 0 0 1 0-.855l1.148-.372a2.366 2.366 0 0 0 1.476-1.497l.373-1.148a.453.453 0 0 1 .855 0l.373 1.146a2.364 2.364 0 0 0 1.498 1.498Z"
      />
    </SvgIcon>
  );
};

export default ArrowTrendingSparkle;
