import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../../Base';

const SingleInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: none;
  text-align: center;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.common.black};
  font-weight: 400;
  line-height: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: inherit;
  :focus-visible {
    outline: none;
  }
`;

const SingleInputWrapper = styled((props) => (
  <Box maxWidth="50px" minHeight="50px" borderRadius="8px" {...props} />
))`
  position: relative;
  ${({ value, error }) => {
    if (error) {
      return css`
        border: 2px solid ${({ theme }) => theme.palette.error.main};
      `;
    }
    if (value === '') {
      return css`
        border: 2px solid ${({ theme }) => theme.palette.neutral.main};
      `;
    }
    return css`
      border: 2px solid ${({ theme }) => theme.palette.neutral.light};
    `;
  }}
`;

interface SingleOTPInputProps
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'onBlur' | 'onKeyDown' | 'onFocus' | 'value' | 'onPaste'
  > {
  error: boolean;
  disabled?: boolean;
}

const SingleOTPInput = forwardRef<HTMLInputElement, SingleOTPInputProps>(
  (
    {
      onChange,
      onBlur,
      onKeyDown,
      onFocus,
      onPaste,
      value,
      error,
      disabled = false,
      ...props
    }: SingleOTPInputProps,
    ref
  ): JSX.Element => {
    return (
      <SingleInputWrapper value={value ?? ''} error={error} {...props}>
        <SingleInput
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onPaste={onPaste}
          value={value ?? ''}
          ref={ref}
          type="tel"
          maxLength={1}
          disabled={disabled}
        />
      </SingleInputWrapper>
    );
  }
);
SingleOTPInput.displayName = 'SingleOTPInput';
export default SingleOTPInput;
