import { Skeleton } from '@mui/material';

import { Box, Flex } from '../../../../Components/Base';
import ExaminationResultsLoading from '../../../ResultListByExamCardId/Components/ExaminationResultsLoading';

const ExaminationResultListLoading = () => (
  <Box sx={{ px: { xs: 2, md: 3 } }}>
    <Flex height={75} alignItems={'center'} justifyContent={'space-between'}>
      <Flex alignItems={'center'}>
        <Skeleton width={43} height={43} sx={{ mr: 2 }} />
        <Skeleton width={300} height={24} />
      </Flex>
      <Skeleton width={24} height={24} />
    </Flex>
    <ExaminationResultsLoading />
  </Box>
);

export default ExaminationResultListLoading;
