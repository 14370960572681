import { Button, Modal, ModalContent, ModalFooter, Text } from '../../../../Components/Base';
import useDisclosure from '../../../../Utils/useDisclosure';

const ModalView = () => {
  const { isOpen, open, close } = useDisclosure();

  return (
    <>
      <Button onClick={open}>Open Modal</Button>
      <Modal open={isOpen}>
        <ModalContent sx={{ py: 3 }}>
          <Text variant={'h2'}>Modal View</Text>
          <Text>
            {`Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Phasellus lobortis et felis eget iaculis. Aenean ultricies,
          orci vitae sagittis mattis, nisi justo gravida elit,
          vehicula hendrerit risus ex a lacus.`}
          </Text>
        </ModalContent>
        <ModalFooter flexDirection={'column'} px={3} pb={3}>
          <Button size={'large'} sx={{ width: 1 }} onClick={close}>
            Confirm
          </Button>
          <Button
            variant={'outlined'}
            color={'greyButton'}
            size={'large'}
            sx={{ mt: 3, width: 1 }}
            onClick={close}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalView;
