import queryString from 'query-string';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

import useGetMyProfile from '../../Domains/User/useGetMyProfile';
import useLogout from '../../Domains/User/useLogout';
import { useConfig } from '../ConfigProvider';

import { MyProfile } from './type';
import { formatUserProfile } from './utils';

export enum LoginState {
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
}

type LoginFunctionProps = {
  anonymousUserId: string;
  redirectPath?: string;
  shouldOpenResultModal?: boolean;
};

export type Authentication = {
  userProfile: MyProfile | null;
  loginState: LoginState;
  logout: () => void;
  clearClientSession: () => void;
  registerLearnId: (loginProps: LoginFunctionProps) => void;
  loginWithFacebook: (loginProps: LoginFunctionProps) => void;
  loginWithGoogle: (loginProps: LoginFunctionProps) => void;
  loginWithLearnId: (loginProps: LoginFunctionProps) => void;
  loginWithPhoneNumber: (loginProps: LoginFunctionProps, callbackUrl: string) => void;
};

const AuthenticationContext = createContext<Authentication | undefined>(undefined);

const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const {
    userPortal,
    facebookConfig: { facebookPortalUrl = '' },
    googleConfig: { googlePortalUrl = '' },
  } = useConfig();

  const [loginState, setLoginState] = useState(LoginState.LOADING);

  const userPortalLoginUrl = userPortal.userPortalUrl?.login;
  const redirectLoginUrl = userPortal.redirectUrl?.login;

  const userPortalRegisterUrl = userPortal.userPortalUrl?.register;
  const redirectRegisterUrl = userPortal.redirectUrl?.register;

  const { id, buCode } = userPortal.client;

  const userPortalLoginPath = useMemo(() => {
    const searchParam = queryString.stringify({
      redirect_uri: redirectLoginUrl,
      bu_code: buCode,
      client_id: id,
    });

    const loginUrl = `${userPortalLoginUrl}/?${searchParam}`;
    return loginUrl;
  }, [buCode, id, redirectLoginUrl, userPortalLoginUrl]);

  const userPortalRegisterPath = useMemo(() => {
    const searchParam = queryString.stringify({
      redirect_uri: redirectRegisterUrl,
      bu_code: buCode,
      client_id: id,
    });

    const registerUrl = `${userPortalRegisterUrl}/?${searchParam}`;
    return registerUrl;
  }, [buCode, id, redirectRegisterUrl, userPortalRegisterUrl]);

  const { data, loading, client } = useGetMyProfile({
    onError: (error) => console.error(error),
  });

  const clearClientSession = () => {
    client.cache.modify({ fields: { getMyProfile: () => null } });
  };

  const [logout] = useLogout({
    onCompleted: () => {
      client.cache.modify({ fields: { getMyProfile: () => null } });
      setLoginState(LoginState.NOT_LOGGED_IN);
    },
    onError: (error) => console.error(error),
  });

  const handleRegisterLearnId = (loginProps: LoginFunctionProps) => {
    const { anonymousUserId, redirectPath, shouldOpenResultModal } = loginProps;
    localStorage.setItem('anonymousUserId', anonymousUserId);
    localStorage.setItem('redirectPath', redirectPath ?? '/mybooks');
    localStorage.setItem('shouldOpenResultModal', shouldOpenResultModal ? 'true' : 'false');
    window.location.href = userPortalRegisterPath;
  };

  const handleLoginWithLearnId = (loginProps: LoginFunctionProps) => {
    const { anonymousUserId, redirectPath, shouldOpenResultModal } = loginProps;
    localStorage.setItem('anonymousUserId', anonymousUserId);
    localStorage.setItem('redirectPath', redirectPath ?? '/mybooks');
    localStorage.setItem('shouldOpenResultModal', shouldOpenResultModal ? 'true' : 'false');
    window.location.href = userPortalLoginPath;
  };

  const handleLoginWithFacebook = (loginProps: LoginFunctionProps) => {
    const { anonymousUserId, redirectPath, shouldOpenResultModal } = loginProps;
    localStorage.setItem('anonymousUserId', anonymousUserId);
    localStorage.setItem('redirectPath', redirectPath ?? '/mybooks');
    localStorage.setItem('shouldOpenResultModal', shouldOpenResultModal ? 'true' : 'false');
    window.location.href = facebookPortalUrl;
  };

  const handleLoginWithGoogle = (loginProps: LoginFunctionProps) => {
    const { anonymousUserId, redirectPath, shouldOpenResultModal } = loginProps;
    localStorage.setItem('anonymousUserId', anonymousUserId);
    localStorage.setItem('redirectPath', redirectPath ?? '/mybooks');
    localStorage.setItem('shouldOpenResultModal', shouldOpenResultModal ? 'true' : 'false');
    window.location.href = googlePortalUrl;
  };

  const handleLoginWithPhoneNumber = (loginProps: LoginFunctionProps, callbackUrl: string) => {
    const { anonymousUserId, redirectPath, shouldOpenResultModal } = loginProps;
    localStorage.setItem('anonymousUserId', anonymousUserId);
    localStorage.setItem('redirectPath', redirectPath ?? '/mybooks');
    localStorage.setItem('shouldOpenResultModal', shouldOpenResultModal ? 'true' : 'false');
    window.location.href = callbackUrl;
  };

  const myProfile = data?.getMyProfile;
  const userProfile = myProfile ? formatUserProfile(myProfile) : null;

  useEffect(() => {
    if (!loading) {
      if (userProfile) setLoginState(LoginState.LOGGED_IN);
      else setLoginState(LoginState.NOT_LOGGED_IN);
    }
  }, [loading, userProfile]);

  return (
    <AuthenticationContext.Provider
      value={{
        clearClientSession,
        userProfile,
        loginState,
        logout,
        registerLearnId: handleRegisterLearnId,
        loginWithFacebook: handleLoginWithFacebook,
        loginWithGoogle: handleLoginWithGoogle,
        loginWithLearnId: handleLoginWithLearnId,
        loginWithPhoneNumber: handleLoginWithPhoneNumber,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = (): Authentication => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error('useAuthentication must be used within AuthenticationProvider');
  }
  return context;
};

export default AuthenticationProvider;
export { AuthenticationContext, useAuthentication };
