import { groupBy, summarize, tidy } from '@tidyjs/tidy';
import { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import Loading from '../../../Components/Loading';
import { getInitialAnswers } from '../../../Contexts/ExaminationProvider/ExaminationProvider';
import {
  ExamAnswerType,
  FormAnswerQuestionType,
} from '../../../Contexts/ExaminationProvider/types';
import { LoLExaminationProvider } from '../../../Contexts/LoLExaminationProvider';
import {
  LoLExaminationSection,
  LoLExaminationTag,
} from '../../../Contexts/LoLExaminationProvider/types';
import { ExamQuestion } from '../../../Contexts/types';
import useCheckExamStatusByBookIdAndExamId from '../../../Domains/useCheckStatusExamByBookIdAndExamId/useCheckStatusExamByBookIdAndExamId';
import { QmsExamSession } from '../../../Domains/useGetExaminationSession';
import { PublicExamStatusErrorCode } from '../../../Routes/constant';
import {
  useQmsExamRoomGetExamSessionAnswerLazyQuery as useGetExaminationSession,
  useQmsLoLExamRoomGetExamLazyQuery as useGetLoLExamRoomGetExam,
} from '../../../generated/graphql';
import { LAST_QUESTION_NO_OF_FIRST_ACTIVITY } from '../constants';

// For debuging to automate answer by answer count
// const mockAnswers = ({
//   answers,
//   answerCount,
// }: {
//   answers?: FormAnswerQuestionType[];
//   answerCount: number;
// }) => {
//   const mock =
//     answers?.slice(0, answerCount).map((answer) => {
//       return { ...answer, answer: { value: Math.floor(Math.random() * 4 + 1).toString() } };
//     }) ?? [];
//   if (!!answers)
//     return [
//       ...mock,
//       ...answers.slice(answerCount, answers.length).map((answer) => {
//         return { ...answer, answer: { value: undefined } };
//       }),
//     ];
//   else {
//     return [...mock];
//   }
// };

type LoLExamRoomLayoutProps = {
  children?: React.ReactNode;
};

const LoLExamRoomLayout = ({ children }: LoLExamRoomLayoutProps) => {
  const { bookId = '', examId = '' } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [getLoLExamRoom, { data: examinationData }] = useGetLoLExamRoomGetExam();

  const [getExaminationSession, { data: examSession }] = useGetExaminationSession();

  const { error } = useCheckExamStatusByBookIdAndExamId({
    variables: { bookId, examId },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      Promise.all([
        getLoLExamRoom({
          fetchPolicy: 'network-only',
        }),
        getExaminationSession({
          fetchPolicy: 'network-only',
        }),
      ]).then(() => {
        setLoading(false);
      });
    },
  });

  if (loading) return <Loading open />;

  const examPaperId = examSession?.qmsExamRoomGetExamSessionAnswer?.examPaperId ?? '';

  const examStatus = error?.graphQLErrors?.[0].extensions?.exception?.errors?.[0]?.code;

  if (
    examStatus === PublicExamStatusErrorCode.FINISHED ||
    examStatus === PublicExamStatusErrorCode.GRADING
  ) {
    navigate(`/mybooks/${bookId}/learn-o-life/milestones/${examId}`);
  }

  const examData = examinationData?.qmsExamRoomGetExam;
  const examItems = examData?.examItems ?? [];
  const sortedExamItems = [...examItems].sort((current, next) => current.order - next.order);

  const lastTrialQuestionNo = examData?.metadata?.endOfTrialAtQuestionNo;

  // Get examination details
  const examQuestions = sortedExamItems.map((examItem) => ({
    id: examItem.question?.id,
    order: examItem.order,
    question: examItem.question?.question,
    metadata: examItem.question?.metadata,
    type: examItem.question?.type,
    subType: examItem.question?.subType,
    tags: examItem.question?.tags,
    score: examItem?.score,
  }));

  const examQuestionBySections = tidy(
    examQuestions
      .filter(
        ({ tags }) =>
          tags && tags.find(({ category, name }) => category === 'SUBJECT' && name !== 'ACTIVITY')
      )
      .map((examItem) => {
        return {
          order: examItem?.order,
          question: examItem?.question,
          section: examItem.tags?.find(({ category }) => category === 'SECTION')?.name,
          tag: examItem.tags?.find(({ category }) => category === 'SUBJECT')?.name,
          metadata: examItem?.metadata,
          score: examItem?.score,
        };
      }),
    groupBy(
      ['section', 'tag'],
      summarize({
        questions: (items) => items.map((item) => item),
        fullScore: (items) => items.reduce((a, b) => a + b.score, 0),
      })
    )
  );

  const examQuestionByTags = tidy(
    examQuestions.map((examItem) => {
      return {
        order: examItem?.order,
        question: examItem?.question,
        tag: examItem.tags?.find(({ category }) => category === 'SUBJECT')?.name,
      };
    }),
    groupBy('tag', summarize({ questions: (items) => items.map((item) => item) }))
  );

  const examQuestionByMilestones = examQuestionByTags.flatMap(({ tag, questions }) => {
    const tempExamByTag = [];
    //RIASEC type is separated to 2 part
    if (tag === LoLExaminationTag.RIASEC) {
      tempExamByTag.push({
        tag: LoLExaminationSection.RIASEC_PART_1,
        questions: questions.slice(0, lastTrialQuestionNo),
      });
      if (questions.length > lastTrialQuestionNo) {
        tempExamByTag.push({
          tag: LoLExaminationSection.RIASEC_PART_2,
          questions: questions.slice(lastTrialQuestionNo),
        });
      }
      return [...tempExamByTag];
    } else if (tag === LoLExaminationTag.ACTIVITY) {
      tempExamByTag.push({
        tag: LoLExaminationSection.ACTIVITY_PART_1,
        questions: questions.slice(0, LAST_QUESTION_NO_OF_FIRST_ACTIVITY),
      });
      if (questions.length > LAST_QUESTION_NO_OF_FIRST_ACTIVITY) {
        tempExamByTag.push({
          tag: LoLExaminationSection.ACTIVITY_PART_2,
          questions: questions.slice(LAST_QUESTION_NO_OF_FIRST_ACTIVITY),
        });
      }
      return [...tempExamByTag];
    }

    return { tag, questions };
  });

  // Get examination answer
  const examSessionAnswer = (examSession?.qmsExamRoomGetExamSessionAnswer ?? {}) as QmsExamSession;
  const examAnswer = examSessionAnswer?.examRoomAnswer?.examRoomExamPaperItems ?? [];
  const examAnswerWithOrder = examAnswer?.map((answer) => {
    const order = examQuestions.find(({ id }) => id === answer?.questionId)?.order ?? 0;
    return { ...answer, order };
  });

  const initialAnswers = getInitialAnswers((examAnswerWithOrder ?? []) as ExamAnswerType) ?? [];
  const answersMapping = sortedExamItems.map(({ question }) => {
    const answer = initialAnswers.find((answer) => answer.questionId === question?.id);
    return { ...answer };
  });
  // For debuging to automate answer by answer count
  // const mock = mockAnswers({
  //   answers: answersMapping as FormAnswerQuestionType[],
  //   answerCount: 80,
  // });

  // console.log(mock);

  return (
    <LoLExaminationProvider
      examId={examData?.id}
      examPaperId={examPaperId}
      answers={answersMapping as FormAnswerQuestionType[]}
      // answers={mock as FormAnswerQuestionType[]} // For debuging to automate answer by answer count
      examQuestions={examQuestions as ExamQuestion[]}
      lastTrialQuestionNo={lastTrialQuestionNo}
      examQuestionByMilestones={examQuestionByMilestones}
      examQuestionBySections={examQuestionBySections}
    >
      {children ?? <Outlet />}
    </LoLExaminationProvider>
  );
};

export default LoLExamRoomLayout;
