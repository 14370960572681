import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useQmsConvertUserIdFromAnonymousUserIdMutation as useConvertUserIdFromAnonymousUserId } from '../../generated/graphql';

type ConvertAnonPortalProps = {
  children?: JSX.Element;
};

const ConvertAnonPortal = (props: ConvertAnonPortalProps) => {
  const { children } = props;

  const anonymousUserId = localStorage.getItem('anonymousUserId');
  const redirectPath = localStorage.getItem('redirectPath');
  const navigate = useNavigate();
  const [convertAnonymousUser] = useConvertUserIdFromAnonymousUserId();

  const clearAnonymousStorage = () => {
    localStorage.removeItem('anonymousUserId');
    localStorage.removeItem('redirectPath');
  };

  useEffect(() => {
    if (anonymousUserId) {
      convertAnonymousUser({ variables: { anonymousUserId: anonymousUserId } }).then(() => {
        if (redirectPath) {
          navigate(redirectPath);
          clearAnonymousStorage();
        }
      });
    } else if (redirectPath) {
      navigate(redirectPath, { replace: true });
      clearAnonymousStorage();
    }
  }, [anonymousUserId, convertAnonymousUser, navigate, redirectPath]);

  return children ?? <Outlet />;
};

export default ConvertAnonPortal;
