import { createContext, useContext, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ChangeDeviceModal from '../../Components/ChangeDeviceModal';
import ExamNotFoundModal from '../../Components/ExamNotFoundModal';
import { getLearnIdFormat } from '../../Domains/User/utils';
import { QuestionType } from '../../Domains/useGetExaminationDetails';
import { formatQuestionListByType } from '../../Domains/useGetExaminationDetails/utils';
import useUpdateExaminationPaper from '../../Domains/useUpdateExaminationPaper';
import useAnimationFrame from '../../Utils/useAnimationFrame';
import useDisclosure from '../../Utils/useDisclosure';
import { QmsExamRoomExamPaperItemInput } from '../../generated/graphql';
import { useAuthentication } from '../AuthenticationProvider';

import {
  ExamAnswerType,
  ExaminationProviderType,
  FormAnswerQuestionType,
  IExamnationContext,
  QuestionsByAnswerType,
} from './types';

export const getInitialAnswers = (examAnswer: ExamAnswerType): ExamAnswerType => {
  return examAnswer.map((answer) => ({
    order: answer.order,
    answer: answer.answer,
    examPaperItemId: answer.examPaperItemId,
    isBookmark: answer.isBookmark,
    questionId: answer.questionId,
  }));
};

export const getSubmitAnswers = (answers: FormAnswerQuestionType[]) => {
  const rawAnswers = answers.map(({ answer, examPaperItemId = '' }) => ({
    examPaperItemId,
    answer,
  }));
  return rawAnswers;
};

const ExaminationContext = createContext<IExamnationContext | undefined>(undefined);

const MILLISECONDS_PER_SECOND = 1000;

const ExaminationProvider = (props: ExaminationProviderType) => {
  const {
    answers,
    examQuestions = [],
    bookType,
    examMaterialGroups = [],
    examTitle = '',
    children,
  } = props;
  const questionGroups = formatQuestionListByType(examQuestions as QuestionType[]);

  const navigate = useNavigate();
  const {
    isOpen: isOpenChangeDeviceModal,
    open: openChangeDeviceModal,
    close: closeChangeDeviceModal,
  } = useDisclosure();
  const { isOpen: isOpenExamNotFoundModal, open: openExamNotFoundModal } = useDisclosure();
  const { userProfile, clearClientSession } = useAuthentication();
  const learnId = getLearnIdFormat(userProfile?.learnId ?? '');

  const [updateExaminationPaper, { error }] = useUpdateExaminationPaper();
  const lastTimestampRef = useRef<number>(new Date().valueOf());

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      answers: (answers ?? []) as FormAnswerQuestionType[],
    },
  });

  const { setAnimationFrameStatus } = useAnimationFrame(() => {
    const now = new Date().valueOf();
    const elapsed = now - lastTimestampRef.current;

    if (elapsed >= 60 * MILLISECONDS_PER_SECOND) {
      handleUpdateExamPaper();
      lastTimestampRef.current = now;
    }
  });

  const handleUpdateExamPaper = async () => {
    const formatAnswer: QmsExamRoomExamPaperItemInput[] = formContext.getValues().answers?.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ order, ...answer }: { order: number }) => answer as QmsExamRoomExamPaperItemInput
    );
    await updateExaminationPaper({
      variables: { examRoomExamPaperItems: formatAnswer },
      onError: (error) => {
        const graphQLExceptions = error.graphQLErrors[0].extensions?.exception;
        const errorCode = graphQLExceptions.errors[0]?.code;
        if (errorCode === 'EXAM_SESSION_EXPIRED') {
          openChangeDeviceModal();
        } else if (errorCode === 'NOT_FOUND_EXAM_PAPER') {
          openExamNotFoundModal();
        }
      },
    });
  };

  return (
    <ExaminationContext.Provider
      value={{
        questionGroups: questionGroups as QuestionsByAnswerType[],
        examQuestions,
        examMaterialGroups,
        examTitle,
        setAnimationFrameStatus,
        bookType,
      }}
    >
      <FormProvider {...formContext}>
        <>
          {children}
          <ChangeDeviceModal
            learnId={learnId}
            open={error?.message.includes('401') || isOpenChangeDeviceModal}
            onBack={() => {
              closeChangeDeviceModal();
              if (error?.message.includes('401')) clearClientSession();
              else navigate('/', { replace: true });
            }}
          />
          <ExamNotFoundModal
            open={isOpenExamNotFoundModal}
            PaperProps={{ sx: { width: '100%', maxWidth: '480px' } }}
            onBack={() => {
              closeChangeDeviceModal();
              if (error?.message.includes('401')) clearClientSession();
              navigate('/', { replace: true });
            }}
          />
        </>
      </FormProvider>
    </ExaminationContext.Provider>
  );
};

export const useExamination = () => {
  const context = useContext(ExaminationContext);
  if (context === undefined) {
    throw new Error('useExamination must by used within ExaminationContext');
  }
  return context;
};

export default ExaminationProvider;
