import { Navigate, useLocation } from 'react-router-dom';

import Loading from '../../Components/Loading';
import { LoginState, useAuthentication } from '../../Contexts/AuthenticationProvider';
import { SIGNIN_PATH } from '../constant';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { loginState } = useAuthentication();

  if (loginState === LoginState.LOADING) {
    return <Loading open />;
  }

  if (loginState === LoginState.LOGGED_IN) return children;

  return <Navigate to={SIGNIN_PATH} state={{ from: location }} />;
};

export default ProtectedRoute;
