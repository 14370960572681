import { createTheme } from '@mui/material/styles';
import sx from '@mui/system/sx';

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    accent: true;
  }
}

const linearProgressTheme = createTheme({
  components: {
    MuiLinearProgress: {
      defaultProps: {
        variant: 'determinate',
        color: 'success',
      },
      styleOverrides: {
        root: sx({ bgcolor: 'grey.200', borderRadius: 5, height: 6 }),
        bar: sx({ borderRadius: 5 }),
      },
      variants: [
        {
          props: { color: 'accent' },
          style: {
            root: sx({
              bgcolor: 'accent.main',
            }),
          },
        },
      ],
    },
  },
});

export default linearProgressTheme;
