import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ChangeDeviceModal from '../../Components/ChangeDeviceModal';
import ExamNotFoundModal from '../../Components/ExamNotFoundModal';
import Loading from '../../Components/Loading';
import LoLRedeemCodeModal from '../../Containers/LoLRedeemCodeModal';
import { getLearnIdFormat } from '../../Domains/User/utils';
import useExamRoomSubmitExamPaper from '../../Domains/useExamRoomSubmitExamPaper';
import { QuestionType } from '../../Domains/useGetExaminationDetails';
import { formatQuestionListByType } from '../../Domains/useGetExaminationDetails/utils';
import useUpdateExaminationPaper from '../../Domains/useUpdateExaminationPaper';
import { DEFAULT_MILESTONES } from '../../Routes/LoLMilestones/constants';
import { MilestoneCardAction } from '../../Routes/LoLMilestones/types';
import useAnimationFrame from '../../Utils/useAnimationFrame';
import useDisclosure from '../../Utils/useDisclosure';
import {
  QmsExamRoomExamPaperItemInput,
  QmsExamRoomLolExamResult,
  RawAnswerInput,
  useQmsExamRoomGradeCharacterAndCareerGroupMutation as useExamRoomGradeCharacterAndCareerGroup,
  useQmsExamRoomGradeRiasecCoreTraitAndCapableCareerMutation as useExamRoomGradeRiasecCoreTraitAndCapableCareer,
  useQmsExamRoomGradeValueCoreTraitAndPersonalityCareerMutation as useExamRoomGradeValueCoreTraitAndPersonalityCareer,
  useGetCareerReportByExamPaperIdLazyQuery as useGetCareerReportByExamPaperId,
  useGetCharacterReportByExamPaperIdLazyQuery as useGetCharacterReportByExamPaperId,
  useQmsMySkuRedeemCodeByBookIdQuery as useMySkuRedeemCodeByBookId,
  useQmsExamRoomGradeActivityAndCareersMutation as useQmsExamRoomGradeActivityAndCareers,
  useQmsExamRoomGradeStrengthCoreTraitsMutation as useQmsExamRoomGradeStrengthCoreTraits,
} from '../../generated/graphql';
import { useAuthentication } from '../AuthenticationProvider';
import { useConfig } from '../ConfigProvider';
import { FormAnswerQuestionType } from '../ExaminationProvider';
import { QuestionsByAnswerType } from '../ExaminationProvider/types';

import { DURATION_PER_QUESTION, MILLISECONDS_PER_SECOND } from './constants';
import {
  ILoLExaminationContext,
  LoLExaminationProviderType,
  LoLExaminationSection,
  Milestone,
} from './types';
import { compareArrays } from './utils';

const LoLExaminationContext = createContext<ILoLExaminationContext | undefined>(undefined);

const LoLExaminationProvider = (props: LoLExaminationProviderType) => {
  const {
    answers,
    examId = '',
    examPaperId = '',
    examQuestions = [],
    lastTrialQuestionNo = 1,
    examQuestionByMilestones = [],
    examQuestionBySections = [],
    children,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { bookId = '' } = useParams();
  const {
    lolExamRoom: { enabledRedeemCodeModal = false },
  } = useConfig();
  const { t } = useTranslation('lolCommon');

  const questionGroups = formatQuestionListByType(examQuestions as QuestionType[]);

  const [getCareerReportByExamPaperId] = useGetCareerReportByExamPaperId({
    variables: { examPaperId },
  });

  const [getCharacterReportByExamPaperId] = useGetCharacterReportByExamPaperId({
    variables: { examPaperId },
  });

  const [submitExamination] = useExamRoomSubmitExamPaper({
    onCompleted: () => {
      const currentPath = window.location.pathname;
      let newPath = '';
      newPath = currentPath
        .replace('milestones', '')
        .replace('examinations', 'results')
        .replace(examId, examPaperId)
        .split('/')
        .filter(Boolean)
        .join('/');
      navigate(`/${newPath}`, { replace: true });
    },
    onError: (error) => {
      //TODO: Handle Error Snackbar or Modal
      console.log('submitExamination', error);
    },
  });

  const [isExamCompleted, setIsExamCompleted] = useState(
    answers?.every(({ answer }) => answer?.value !== undefined) ?? false
  );

  const [isUnlockedExam, setIsUnlockedExam] = useState<boolean | null>(null);

  const [latestExamResult, setLatestExamResult] = useState<QmsExamRoomLolExamResult[]>([]);

  const defaultCurrentQuestionNo = useMemo(() => {
    const lastOrder = (answers?.[answers?.length - 1]?.order ?? 0) + 1;
    const currentQuestionOrder = answers?.find(({ answer }) => answer?.value === undefined)?.order;
    if (currentQuestionOrder !== undefined) {
      return currentQuestionOrder;
    }

    return isExamCompleted ? lastOrder : 1;
  }, [answers, isExamCompleted]);

  const [currentQuestionNo, setCurrentQuestionNo] = useState<number | undefined>(
    defaultCurrentQuestionNo
  );

  const allTags = examQuestionByMilestones.map(({ tag }) => tag);

  const milestones: Milestone[] = useMemo(() => {
    return DEFAULT_MILESTONES.filter((milestone) => {
      return milestone.tags.some((tag) => allTags.includes(tag));
    }).map((milestone) => {
      const examItems = examQuestionByMilestones.filter(
        ({ tag }) => !!tag && milestone.tags.includes(tag as LoLExaminationSection)
      );

      const questionCountByTag = examItems.map(({ questions, tag = '' }) => {
        return {
          tag,
          questionCount: questions.length,
        };
      });

      const totalQuestionCount = examItems.reduce((acc, exam) => acc + exam.questions.length, 0);

      const examQuestionItems = examItems.flatMap((examItem) => {
        return examItem.questions;
      });
      const sortedExamQuestionItems = [...examQuestionItems].sort(
        (current, next) => current.order - next.order
      );

      const startQuestionNo = sortedExamQuestionItems?.[0]?.order ?? 1;

      const endQuestionNo =
        sortedExamQuestionItems?.[sortedExamQuestionItems.length - 1]?.order ?? 1;

      let status = MilestoneCardAction.COMPLETED;
      if (currentQuestionNo === undefined) {
        if (isExamCompleted && answers && answers?.length > 0) {
          status = MilestoneCardAction.COMPLETED;
        } else {
          status = MilestoneCardAction.NOT_READY;
        }
      } else if (currentQuestionNo >= startQuestionNo && currentQuestionNo <= endQuestionNo) {
        status = MilestoneCardAction.READY;
      } else if (currentQuestionNo < startQuestionNo) {
        status = MilestoneCardAction.NOT_READY;
      }

      const examTags = examItems.map(({ tag }) => tag) as LoLExaminationSection[];

      const careers = milestone.careers ?? [];
      const tranlatedCareers = careers.map((career) => t(`CAREER.${career}`, { ns: 'lolCommon' }));

      return {
        ...milestone,
        careers: tranlatedCareers,
        tags: examTags,
        questionCountByTag,
        totalQuestionCount,
        status,
        duration: Math.floor((totalQuestionCount * DURATION_PER_QUESTION) / 60), //MINUTE UNIT
        startQuestionNo,
        endQuestionNo,
      };
    });
  }, [allTags, answers, currentQuestionNo, examQuestionByMilestones, isExamCompleted, t]);
  //TODO: Handle error modal
  const {
    isOpen: isOpenChangeDeviceModal,
    open: openChangeDeviceModal,
    close: closeChangeDeviceModal,
  } = useDisclosure();

  const {
    isOpen: isOpenUpdatingExamModal,
    open: openUpdatingExamModal,
    close: closeUpdatingExamModal,
  } = useDisclosure();

  //TODO: Handle error modal
  const {
    isOpen: isOpenExamNotFoundModal,
    open: openExamNotFoundModal,
    close: closeExamNotFoundModal,
  } = useDisclosure();
  const {
    isOpen: isOpenRedeemModal,
    open: openRedeemModal,
    close: closeRedeemModal,
  } = useDisclosure();

  const { userProfile, clearClientSession } = useAuthentication();
  const learnId = getLearnIdFormat(userProfile?.learnId ?? '');

  const [updateExaminationPaper, { error }] = useUpdateExaminationPaper();

  const [examRoomGradeCharacterAndCareerGroup] = useExamRoomGradeCharacterAndCareerGroup();

  const [examRoomGradeRIASECCoreTraitAndCapableCareer] =
    useExamRoomGradeRiasecCoreTraitAndCapableCareer();

  const [examRoomGradeValueCoreTraitAndPersonalityCareer] =
    useExamRoomGradeValueCoreTraitAndPersonalityCareer();

  const [examRoomGradeActivityAndCareers] = useQmsExamRoomGradeActivityAndCareers();

  const [examRoomGradeStrengthCoreTraits] = useQmsExamRoomGradeStrengthCoreTraits();

  const { error: redeemCodeError, refetch: refetchCheckingMySkuRedeemCode } =
    useMySkuRedeemCodeByBookId({
      variables: { bookId },
      fetchPolicy: 'network-only',
    });
  const redeemErrorCodeStatus =
    redeemCodeError?.graphQLErrors?.[0].extensions?.exception?.errors?.[0]?.code;

  const lastTimestampRef = useRef<number>(new Date().valueOf());

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      answers: (answers ?? []) as FormAnswerQuestionType[],
    },
  });

  const { setAnimationFrameStatus } = useAnimationFrame(() => {
    const now = new Date().valueOf();
    const elapsed = now - lastTimestampRef.current;

    if (elapsed >= 60 * MILLISECONDS_PER_SECOND) {
      handleUpdateExamPaper();
      lastTimestampRef.current = now;
    }
  });

  const handleSubmitExamination = async () => {
    const submitAnswers: RawAnswerInput[] = formContext
      .getValues()
      .answers?.map(
        ({
          order,
          isBookmark,
          questionId,
          ...answer
        }: QmsExamRoomExamPaperItemInput & { order: number }) => answer as RawAnswerInput
      );

    openUpdatingExamModal();
    await submitExamination({ variables: { submitExamInput: { rawAnswers: submitAnswers } } });
    closeUpdatingExamModal();
  };

  const handleGradeCharacterAndCareerGroup = async (answers: RawAnswerInput[]) => {
    return await examRoomGradeCharacterAndCareerGroup({
      variables: { rawAnswers: answers },
      onError: (error) => {
        console.log('examRoomGradeCharacterAndCareerGroup: ', error);
      },
    }).then((res) => {
      return res?.data?.qmsExamRoomGradeCharacterAndCareerGroup;
    });
  };

  const handleGradeRIASECCoreTraitAndCapableCareer = async (answers: RawAnswerInput[]) => {
    return await examRoomGradeRIASECCoreTraitAndCapableCareer({
      variables: { rawAnswers: answers },
      onError: (error) => {
        console.log('examRoomGradeRIASECCoreTraitAndCapableCareer: ', error);
      },
    }).then((res) => {
      return res?.data?.qmsExamRoomGradeRIASECCoreTraitAndCapableCareer;
    });
  };

  const handleGradeValueCoreTraitAndPersonalityCareer = async (answers: RawAnswerInput[]) => {
    return await examRoomGradeValueCoreTraitAndPersonalityCareer({
      variables: { rawAnswers: answers },
      onError: (error) => {
        console.log('examRoomGradeValueCoreTraitAndPersonalityCareer: ', error);
      },
    }).then((res) => {
      return res?.data?.qmsExamRoomGradeValueCoreTraitAndPersonalityCareer;
    });
  };

  const handleGradeActivityAndCareers = async (answers: RawAnswerInput[]) => {
    return await examRoomGradeActivityAndCareers({
      variables: { rawAnswers: answers },
      onError: (error) => {
        console.log('examRoomGradeActivityAndCareers: ', error);
      },
    }).then((res) => {
      return res?.data?.qmsExamRoomGradeActivityAndCareers;
    });
  };

  const handleGradeStrengthCoreTraits = async (answers: RawAnswerInput[]) => {
    return await examRoomGradeStrengthCoreTraits({
      variables: { rawAnswers: answers },
      onError: (error) => {
        console.log('examRoomGradeStrengthCoreTraits: ', error);
      },
    }).then((res) => {
      return res?.data?.qmsExamRoomGradeStrengthCoreTraits;
    });
  };

  const handleGradingExamPaper = async (currentExamPart: string[]) => {
    const formatAnswer: RawAnswerInput[] = formContext
      .getValues()
      .answers?.map(
        ({
          order,
          isBookmark,
          questionId,
          ...answer
        }: QmsExamRoomExamPaperItemInput & { order: number }) => answer as RawAnswerInput
      );

    if (compareArrays(currentExamPart, [LoLExaminationSection.RIASEC_PART_1])) {
      const characterAndCareerGroupResult = await handleGradeCharacterAndCareerGroup(formatAnswer);
      if (characterAndCareerGroupResult) {
        setLatestExamResult(characterAndCareerGroupResult);
      }
    } else if (compareArrays(currentExamPart, [LoLExaminationSection.RIASEC_PART_2])) {
      const coreTraitAndCapableCareerResult = await handleGradeRIASECCoreTraitAndCapableCareer(
        formatAnswer
      );
      if (coreTraitAndCapableCareerResult) {
        setLatestExamResult(coreTraitAndCapableCareerResult);
      }
    } else if (
      compareArrays(currentExamPart, [
        LoLExaminationSection.ACTIVITY_PART_1,
        LoLExaminationSection.VALUE,
      ])
    ) {
      const coreTraitAndPersonalityCareer = await handleGradeValueCoreTraitAndPersonalityCareer(
        formatAnswer
      );
      if (coreTraitAndPersonalityCareer) {
        setLatestExamResult(coreTraitAndPersonalityCareer);
      }
    } else if (compareArrays(currentExamPart, [LoLExaminationSection.ACTIVITY_PART_2])) {
      const careerResult = await handleGradeActivityAndCareers(formatAnswer);
      if (careerResult) {
        setLatestExamResult(careerResult);
      }
    } else if (
      compareArrays(currentExamPart, [
        LoLExaminationSection.ACTIVITY_PART_2,
        LoLExaminationSection.STRENGTH,
      ])
    ) {
      const careerResult = await handleGradeActivityAndCareers(formatAnswer);
      const coreTraitResult = await handleGradeStrengthCoreTraits(formatAnswer);
      const combinedResult = coreTraitResult?.concat(careerResult ?? []);
      if (combinedResult) {
        setLatestExamResult(combinedResult);
      }
    }
    localStorage.setItem('shouldOpenResultModal', 'true');
  };

  const handleUpdateExamPaper = async () => {
    const formatAnswer: QmsExamRoomExamPaperItemInput[] = formContext.getValues().answers?.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ order, ...answer }: { order: number }) => answer as QmsExamRoomExamPaperItemInput
    );
    return await updateExaminationPaper({
      variables: { examRoomExamPaperItems: formatAnswer },
      onError: (error) => {
        const graphQLExceptions = error?.graphQLErrors?.[0]?.extensions?.exception;
        const errorCode = graphQLExceptions?.errors?.[0]?.code ?? graphQLExceptions?.code;

        if (errorCode === 'EXAM_SESSION_EXPIRED' || errorCode === 'ERR_INVALID_ARG_TYPE') {
          openChangeDeviceModal();
        } else if (errorCode === 'NOT_FOUND_EXAM_PAPER') {
          openExamNotFoundModal();
        }
      },
    });
  };

  const handleUpdateExamPaperWithLoading = async (tags: string[]) => {
    openUpdatingExamModal();
    await handleUpdateExamPaper();
    await handleGradingExamPaper(tags);
    closeUpdatingExamModal();
  };

  const goToExamRoom = () => {
    const parts = location.pathname.split('/').filter(Boolean);
    parts[parts.length - 1] = 'examroom';
    const examroomPath = '/' + parts.join('/');
    navigate(examroomPath);
  };

  const handleValidateRedeemCodeModal = async () => {
    if (isUnlockedExam) {
      goToExamRoom();
    } else {
      try {
        const result = await refetchCheckingMySkuRedeemCode();
        if (result?.data?.qmsMySkuRedeemCodeByBookId?.status === 'USED') {
          closeRedeemModal();
          goToExamRoom();
          setIsUnlockedExam(true);
        }
      } catch (error: any) {
        const redeemCodeErrorStatus =
          error?.graphQLErrors?.[0]?.extensions?.exception?.errors?.[0]?.code;
        if (redeemCodeErrorStatus === 'NOT_FOUND_SKU_REDEEM_CODE') {
          openRedeemModal();
          setIsUnlockedExam(false);
        } else {
          console.log('handleValidateRedeemCodeModal: ', redeemCodeErrorStatus);
        }
      }
    }
  };

  const isTrialFinished = useMemo(() => {
    return currentQuestionNo !== undefined && currentQuestionNo > lastTrialQuestionNo;
  }, [lastTrialQuestionNo, currentQuestionNo]);

  useEffect(() => {
    if (isTrialFinished && redeemErrorCodeStatus === 'NOT_FOUND_SKU_REDEEM_CODE') {
      setIsUnlockedExam(false);
    } else if (isTrialFinished) {
      setIsUnlockedExam(true);
    }
  }, [isTrialFinished, redeemErrorCodeStatus]);

  useEffect(() => {
    if (
      isTrialFinished &&
      isUnlockedExam === false &&
      (location.pathname.includes('examroom') || location.pathname.includes('instruction'))
    ) {
      const parts = location.pathname.split('/').filter(Boolean); // Remove empty segments after the last path
      parts[parts.length - 1] = 'milestones';
      const milestonesPath = '/' + parts.join('/');
      navigate(milestonesPath);
    }
  }, [isTrialFinished, isUnlockedExam, location.pathname, navigate]);

  const currentAnswer = formContext.watch(`answers.${(currentQuestionNo ?? 1) - 1}`);

  useEffect(() => {
    const allAnswers = formContext.getValues('answers');
    if (allAnswers.every(({ answer }) => answer?.value !== undefined)) {
      setIsExamCompleted(true);
    }
  }, [currentAnswer, formContext]);

  //Tech debt: We need to show result modal(only RIASEC_PART_1) once when come back from login.
  useEffect(() => {
    const shouldOpenResultModal = localStorage.getItem('shouldOpenResultModal') ?? 'false';

    if (shouldOpenResultModal === 'true') {
      Promise.all([getCareerReportByExamPaperId(), getCharacterReportByExamPaperId()]).then(
        (result) => {
          const careerResult = result[0]?.data?.qmsGetLOLExamReport.careers;
          const characterResult = result[1]?.data?.qmsGetLOLExamReport.characters;
          const combinedResult = careerResult?.concat(
            characterResult ?? []
          ) as QmsExamRoomLolExamResult[];
          setLatestExamResult(combinedResult);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <LoLExaminationContext.Provider
      value={{
        examPaperId,
        questionGroups: questionGroups as QuestionsByAnswerType[],
        examQuestions,
        isExamCompleted,
        isUnlockedExam,
        latestExamResult,
        milestones,
        setAnimationFrameStatus,
        isTrialFinished,
        currentQuestionNo,
        setCurrentQuestionNo,
        handleUpdateExamPaper: handleUpdateExamPaperWithLoading,
        examQuestionBySections,
        handleSubmitExamination,
        handleValidateRedeemCodeModal,
        openRedeemModal,
      }}
    >
      {enabledRedeemCodeModal && (
        <LoLRedeemCodeModal
          open={isOpenRedeemModal}
          onSubmit={handleValidateRedeemCodeModal}
          onClose={closeRedeemModal}
        />
      )}
      <FormProvider {...formContext}>
        <ChangeDeviceModal
          learnId={learnId}
          open={error?.message.includes('401') || isOpenChangeDeviceModal}
          onBack={() => {
            closeChangeDeviceModal();
            if (error?.message.includes('401')) clearClientSession();
            else
              navigate(`/mybooks/${bookId}/learn-o-life/examinations/${examId}`, { replace: true });
          }}
        />
        <ExamNotFoundModal
          open={isOpenExamNotFoundModal}
          PaperProps={{ sx: { width: '100%', maxWidth: '480px' } }}
          onBack={() => {
            closeExamNotFoundModal();
            if (error?.message.includes('401')) clearClientSession();
            navigate(`/mybooks/${bookId}/learn-o-life/examinations/${examId}`, { replace: true });
          }}
        />
        {isOpenUpdatingExamModal ? <Loading open={isOpenUpdatingExamModal} /> : children}
      </FormProvider>
    </LoLExaminationContext.Provider>
  );
};

export const useLoLExamination = () => {
  const context = useContext(LoLExaminationContext);
  if (context === undefined) {
    throw new Error('useLoLExamination must by used within LoLExaminationContext');
  }
  return context;
};

export default LoLExaminationProvider;
