import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '../../../Components/Base';
import AcheivementCard, { AcheivementCardLoading } from '../Components/AcheivementCard';
import { careerList } from '../constants';
import {
  QmslolExamResult,
  useGetCareerReportByExamPaperIdQuery as useGetCareerReportByExamPaperId,
} from '../../../generated/graphql';
import useDisclosure from '../../../Utils/useDisclosure';
import { MilestoneTags } from '../types';
import { LoLReportModal } from '../../../Containers/LoLResultModal';

type CareerListProps = {
  examPaperId?: string;
  milestones?: MilestoneTags[];
};

const CareerList = (props: CareerListProps) => {
  const { examPaperId = '', milestones = [] } = props;
  const { isOpen, open, close } = useDisclosure();
  const { t } = useTranslation('lolResults');
  const [currentCareer, setCurrentCareer] = useState<QmslolExamResult>();
  const [currentResultGeneralData, setCurrentResultGeneralData] = useState<{
    title?: string;
    conditionNumber: number;
  }>();

  const { data: careerData, loading } = useGetCareerReportByExamPaperId({
    variables: {
      examPaperId,
    },
    fetchPolicy: 'network-only',
  });

  const careers = careerData?.qmsGetLOLExamReport.careers;

  const handleCardClick = (conditionNumber: number, title?: string, career?: QmslolExamResult) => {
    setCurrentCareer(career ?? undefined);
    setCurrentResultGeneralData({ title, conditionNumber });
    open();
  };

  const handleCloseModal = () => {
    close();
  };

  const milestoneTags = milestones.flatMap((milestone) => {
    return milestone.tags;
  });

  const filterCareerList = careerList.filter((career) => {
    const tags = career?.tags ?? [];
    const hasTagsInMilestoneTags = tags.every((tag) => milestoneTags.includes(tag));

    return hasTagsInMilestoneTags;
  });

  const hasCareerList = filterCareerList.length > 0;

  return (
    <>
      {hasCareerList && (
        <Flex flexDirection={'column'} gap={2}>
          <Box>
            <Text
              variant="h2"
              fontWeight={500}
              mr={1}
              display={'inline-block'}
              color={'lolText.primary'}
            >
              {t('CAREER')}
            </Text>
            <Text
              variant="h3"
              fontWeight={400}
              lineHeight={1.4}
              color={'lolText.secondary'}
              display={'inline-block'}
            >
              {` (${careers?.length ?? 0}/${filterCareerList.length})`}
            </Text>
          </Box>
          <Flex flexDirection={'column'} gap={2}>
            {filterCareerList.map((career) => {
              const { type, title } = career;
              const matchCareer = careers?.find((careerResult) => careerResult.type === type);
              const isLocked = matchCareer === undefined;

              const tags = career?.tags ?? [];
              const lastTag = tags[tags.length - 1];

              const indexOfLastTagInMilestones = milestones.findIndex((milestone) =>
                milestone.tags.includes(lastTag)
              );

              const conditionNumber =
                indexOfLastTagInMilestones !== -1
                  ? indexOfLastTagInMilestones + 1
                  : career?.conditionNumber;

              const careerTitle = t(`CAREER.${title}`, { ns: 'lolCommon' });

              if (loading) {
                return <AcheivementCardLoading key={type} />;
              }

              return (
                <AcheivementCard
                  key={type}
                  title={careerTitle}
                  conditionNumber={conditionNumber}
                  resultType={type}
                  locked={isLocked}
                  onClick={() => handleCardClick(conditionNumber, careerTitle, matchCareer)}
                />
              );
            })}
          </Flex>
        </Flex>
      )}
      <LoLReportModal
        isOpen={isOpen}
        handleClose={handleCloseModal}
        displayData={currentCareer}
        title={currentResultGeneralData?.title}
        conditionNumber={currentResultGeneralData?.conditionNumber}
      />
    </>
  );
};

export default CareerList;
