import { Divider } from '@mui/material';
import { useMemo } from 'react';

import { Box, Paper, PaperProps, Text } from '../../../../../Components/Base';
import {
  getExamTypeDetail,
  getRangeOfQuestion,
} from '../../../../../Domains/useGetExaminationDetails/utils';
import { AnswerSheetByGroupProps } from '../../../Components/AnswerSheet/AnswerSheetByGroup/types';
import FormAnswerItem from '../../FormAnswerItem/FormAnswerItem';

const AnswerSheetTypeFieldNumber = ({
  group,
  questions,
  ...restProps
}: AnswerSheetByGroupProps & PaperProps) => {
  const questionRangeText = getRangeOfQuestion(questions);

  const decimal = useMemo(() => {
    return questions?.[0]?.metadata?.precision ?? 2;
  }, [questions]);

  return (
    <Paper
      elevation={4}
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 1, md: 2 },
        borderRadius: { xs: 0, sm: 1 },
        bgcolor: 'common.white',
      }}
      {...restProps}
    >
      <Text mb={2} variant={'subtitle2'}>
        {`${questionRangeText} ${getExamTypeDetail(group)}`}
      </Text>
      <Divider />
      <Text my={2} variant={'body2'}>
        ตอบเป็นทศนิยม 2 ตำแหน่ง
      </Text>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: {
            xs: 'auto',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          },
          gridGap: '24px',
          justifyContent: 'center',
        }}
      >
        {questions.map((question, index) => {
          const questionType = question?.type;

          return <FormAnswerItem key={`${questionType}_${index}`} question={question} />;
        })}
      </Box>
    </Paper>
  );
};

export default AnswerSheetTypeFieldNumber;
