import { styled, Toolbar } from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Flex, Box } from '../../Components/Base';
import { AppBarWithAction, APP_BAR_HEIGHT } from '../../Components/Layout';
import useGetSolutionById from '../../Domains/useGetSolutionById';

type ExamResultTitleLayoutProps = {
  onBack?: () => void;
  children?: React.ReactNode;
};

const StyledContentLayout = styled(Flex)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: `760px`,
  },
}));

const ExamResultTitleLayout = ({ children, onBack }: ExamResultTitleLayoutProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (typeof onBack === 'function') {
      onBack();
    } else {
      navigate(-1);
    }
  };
  const { examId = '' } = useParams();
  const { data } = useGetSolutionById({ variables: { examId } });
  const description = data?.qmsFoGetExamById?.title;

  return (
    <>
      <AppBarWithAction title={'ผลการสอบทั้งหมด'} description={description} onBack={handleBack} />
      <Toolbar />
      <Box bgcolor={'grey.50'}>
        <StyledContentLayout
          flexDirection={'column'}
          component="main"
          px={{ xs: 0, sm: 3 }}
          pt={{ xs: 1, sm: 2 }}
          minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`}
          sx={{ flexGrow: 1 }}
        >
          {children ?? <Outlet />}
        </StyledContentLayout>
      </Box>
    </>
  );
};

export default ExamResultTitleLayout;
