import { createTheme, experimental_sx as sx } from '@mui/material/styles';

const tabsTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: sx({
          fontSize: 16,
          fontWeight: 600,
          textTransform: 'none',
          py: 1,
          px: 2,
          color: 'grey.700',
          width: 1,
        }),
      },
    },
  },
});

export default tabsTheme;
