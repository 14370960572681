import styled from 'styled-components';

import { Text } from '../../Base';

const HelperText = styled((props) => (
  <Text variant="caption" as="p" color={props.error ? 'error.main' : ''} {...props} />
))`
  margin: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(0.5)}`};
`;

export default HelperText;
