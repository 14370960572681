import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { FormAnswerQuestionType } from '../../Contexts/ExaminationProvider';
import { checkAnswerdQuestions } from '../../Routes/ExaminationReview/utils';
import { Flex, FlexProps } from '../Base';
import ChevronLeftRoundedIcon from '../Icons/ChevronLeftRoundedIcon';
import ChevronRightRoundedIcon from '../Icons/ChevronRightRoundedIcon';
import QuestionNoItem from '../QuestionNoItem';

const ArrowWrapper = styled((props) => (
  <Flex
    flex={'0 0 auto'}
    width={32}
    height={32}
    justifyContent={'center'}
    alignItems={'center'}
    {...props}
  />
))`
  cursor: pointer;
`;

type ExamNavigationProps = {
  examContents: Array<Record<string, unknown>>;
  currentItemNumber?: number;
  currentPageIndex?: number;
  onNavigationItemClick: (itemNumber: number) => void;
} & FlexProps;

const ExamNavigation = (props: ExamNavigationProps) => {
  const {
    examContents,
    currentItemNumber = 1,
    currentPageIndex: startingPageIndex = 0,
    onNavigationItemClick,
    ...restProps
  } = props;

  const [currentPageIndex, setCurrentPageIndex] = useState(startingPageIndex);
  const [pageDividers, setPageDividers] = useState([0]);
  const { breakpoints, palette } = useTheme();

  const isMobileSize = useMediaQuery(breakpoints.between('xs', 'sm'));
  const isTabletSize = useMediaQuery(breakpoints.between('sm', 'md'));
  const isDesktopSize = useMediaQuery(breakpoints.up('md'));

  const totalPages = useMemo(() => {
    return pageDividers.length;
  }, [pageDividers]);

  useEffect(() => {
    const _pageDividers = [0];
    let widthCount = 0;

    examContents.forEach((item, index) => {
      widthCount += 1;

      if (isDesktopSize && widthCount > 15) {
        _pageDividers.push(index);
        // reset widthCount for the next iteration
        widthCount = 1;
      }

      if (isTabletSize && widthCount > 10) {
        _pageDividers.push(index);
        widthCount = 1;
      }

      if (isMobileSize && widthCount > 5) {
        _pageDividers.push(index);
        widthCount = 1;
      }
    });

    setPageDividers(_pageDividers);
  }, [examContents, isDesktopSize, isMobileSize, isTabletSize]);

  useEffect(() => {
    if (totalPages > 1) {
      const currentItemIndex = examContents.findIndex((item) => item.order === currentItemNumber);

      let pageDividerIndex = 0;
      pageDividers.forEach((startingItemIndex, index) => {
        if (currentItemIndex >= startingItemIndex) {
          pageDividerIndex = index;
        }
      });

      setCurrentPageIndex(pageDividerIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemNumber, examContents, totalPages]);

  const handlePrevious = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentPageIndex < totalPages - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  const _examContents = examContents.filter((item, index, arr) => {
    if (currentPageIndex === totalPages - 1) {
      return index >= pageDividers[currentPageIndex] && index < arr.length;
    } else {
      return index >= pageDividers[currentPageIndex] && index < pageDividers[currentPageIndex + 1];
    }
  });

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} {...restProps}>
      <ArrowWrapper onClick={handlePrevious}>
        <ChevronLeftRoundedIcon
          htmlColor={currentPageIndex === 0 ? palette.text.disabled : palette.grey[800]}
          sx={{ cursor: currentPageIndex === 0 ? 'not-allowed' : 'pointer' }}
        />
      </ArrowWrapper>
      <Flex width={'840px'} alignItems="center" justifyContent={'center'}>
        {_examContents.map((content, index) => {
          const { isBookmark = false, order } = content ?? {};
          const isAnswered = checkAnswerdQuestions(content as FormAnswerQuestionType);

          return (
            <QuestionNoItem
              key={`${order}`}
              questionNo={order as string}
              isActive={order === currentItemNumber}
              isAnswered={isAnswered}
              isMark={isBookmark as boolean}
              minWidth={45}
              maxWidth={'fit-content'}
              onClick={() => onNavigationItemClick(order as number)}
              mr={index === _examContents.length - 1 ? 0 : { xs: 1, sm: 1.25 }}
            />
          );
        })}
      </Flex>
      <ArrowWrapper onClick={handleNext}>
        <ChevronRightRoundedIcon
          htmlColor={
            currentPageIndex === totalPages - 1 ? palette.text.disabled : palette.grey[800]
          }
          sx={{ cursor: currentPageIndex === totalPages - 1 ? 'not-allowed' : 'pointer' }}
        />
      </ArrowWrapper>
    </Flex>
  );
};

export default ExamNavigation;
