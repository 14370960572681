import { SvgIcon, SvgIconProps } from '@mui/material';

const SupportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        d="M36.1834 14.5167C38.2667 12.4334 37.3167 10 36.1834 8.81671L31.1834 3.81671C29.0834 1.73338 26.6667 2.68338 25.4834 3.81671L22.65 6.66671H18.3334C15.1667 6.66671 13.3334 8.33338 12.4 10.25L5.00004 17.65V24.3167L3.81671 25.4834C1.73338 27.5834 2.68338 30 3.81671 31.1834L8.81671 36.1834C9.71671 37.0834 10.6834 37.4167 11.6 37.4167C12.7834 37.4167 13.8667 36.8334 14.5167 36.1834L19.0167 31.6667H25C27.8334 31.6667 29.2667 29.9 29.7834 28.1667C31.6667 27.6667 32.7 26.2334 33.1167 24.8334C35.7 24.1667 36.6667 21.7167 36.6667 20V15H35.6834L36.1834 14.5167ZM33.3334 20C33.3334 20.75 33.0167 21.6667 31.6667 21.6667H30V23.3334C30 24.0834 29.6834 25 28.3334 25H26.6667V26.6667C26.6667 27.4167 26.35 28.3334 25 28.3334H17.65L12.1834 33.8C11.6667 34.2834 11.3667 34 11.1834 33.8167L6.20004 28.85C5.71671 28.3334 6.00004 28.0334 6.18338 27.85L8.33338 25.6834V19.0167L11.6667 15.6834V18.3334C11.6667 20.35 13 23.3334 16.6667 23.3334C20.3334 23.3334 21.6667 20.35 21.6667 18.3334H33.3334V20ZM33.8167 12.15L30.9834 15H18.3334V18.3334C18.3334 19.0834 18.0167 20 16.6667 20C15.3167 20 15 19.0834 15 18.3334V13.3334C15 12.5667 15.2834 10 18.3334 10H24.0167L27.8167 6.20004C28.3334 5.71671 28.6334 6.00004 28.8167 6.18338L33.8 11.15C34.2834 11.6667 34 11.9667 33.8167 12.15Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SupportIcon;
