import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ExhExamNavigation from '../../../../Components/ExamNavigation';
import { FormAnswerQuestionType } from '../../../../Contexts/ExaminationProvider';

const ExamNavigation = () => {
  const { watch } = useFormContext();
  const examQuestionAnswers: FormAnswerQuestionType[] = watch().answers;

  const navigate = useNavigate();

  const anchorId = location.hash.replace('#', '');
  const questionNo = useMemo(
    () => parseInt(anchorId.trim() === '' ? '1' : anchorId) ?? 1,
    [anchorId]
  );

  const handlePagination = (order: number) => {
    navigate(`.#${order}`);
  };

  return (
    <ExhExamNavigation
      examContents={examQuestionAnswers}
      currentItemNumber={questionNo}
      onNavigationItemClick={handlePagination}
    />
  );
};

export default ExamNavigation;
