import { Fragment } from 'react';
import { Divider, Skeleton } from '@mui/material';

import { Flex, Box } from '../../../../Components/Base';

const ExaminationResultsLoading = () => {
  return (
    <Box>
      {Array.from({ length: 5 }).map((_, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider />}
          <Flex py={2} pl={1.25}>
            <Fragment key={index}>
              <Box mr={1.5}>
                <Skeleton variant="circular" width={24} height={24} />
              </Box>
              <Flex flexDirection={'column'} width={'100%'}>
                <Flex alignItems={'center'} justifyContent={'space-between'} pb={0.5}>
                  <Skeleton width={70} />
                  <Skeleton width={45} />
                </Flex>
                <Flex alignItems={'center'} justifyContent={'space-between'}>
                  <Skeleton width={140} />
                  <Flex>
                    <Skeleton width={100} />
                  </Flex>
                </Flex>
              </Flex>
            </Fragment>
          </Flex>
        </Fragment>
      ))}
    </Box>
  );
};

export default ExaminationResultsLoading;
