import { useNavigate, useParams } from 'react-router-dom';

import { Button, ButtonProps } from '../../../../Components/Base';
import ArrowForwardIcon from '../../../../Components/Icons/ArrowForwardIcon';
import Loading from '../../../../Components/Loading';
import useBackToExam from '../../../../Domains/useBackToExam';
import useDisclosure from '../../../../Utils/useDisclosure';
import ResumeExaminationModal from '../../Components/ResumeExaminationModal';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

type ContinueExaminationButtonProps = ButtonProps;

const ContinueExaminationButton = (props: ContinueExaminationButtonProps) => {
  const { examCardId = '' } = useParams();
  const { isOpen, open, close } = useDisclosure();
  const navigate = useNavigate();
  const sendDataToGTM = useGoogleTagManager();

  const [backToExam, { loading: backToExamLoading }] = useBackToExam();

  const handleEnterExamRoom = async () => {
    sendDataToGTM({
      event: 'examination_instruction_cta_click',
      buttonCategory: 'restart examination',
    });

    try {
      await backToExam({ variables: { examCardId, isReset: true } });
      navigate(`${location.pathname}/examroom`, { replace: true });
    } catch (error) {
      console.log('Enter examination room error: ', error);
    }
  };

  const handleBackToExamRoom = async () => {
    sendDataToGTM({
      event: 'examination_instruction_cta_click',
      buttonCategory: 'continue examination',
    });

    try {
      await backToExam({ variables: { examCardId, isReset: false } });
      navigate(`${location.pathname}/examroom`, { replace: true });
    } catch (error) {
      console.log('Back to examination error: ', error);
    }
  };

  return (
    <>
      <Button
        sx={{ maxWidth: { xs: 1, sm: '343px' }, width: 1 }}
        size={'large'}
        onClick={open}
        disabled={backToExamLoading}
        {...props}
      >
        ทำข้อสอบต่อ
        <ArrowForwardIcon sx={{ ml: 0.5 }} />
      </Button>
      <ResumeExaminationModal
        open={isOpen}
        onCancel={close}
        onResume={handleBackToExamRoom}
        onRestart={handleEnterExamRoom}
      />
      <Loading open={backToExamLoading} />
    </>
  );
};

export default ContinueExaminationButton;
