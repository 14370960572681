import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { Button, ButtonProps, styled } from '@mui/material';

import { useExamination } from '../../../../Contexts/ExaminationProvider';

const NavigationButton = styled((props: ButtonProps) => (
  <Button variant={'outlined'} color={'inherit'} {...props} />
))`
  border-radius: 8px;
  border-color: ${({ theme }) => `${theme.palette.grey[300]}`};
  min-width: 112px;
  cursor: pointer;
`;

const PreviousQuestionButton = ({ isMobile = false }: { isMobile: boolean }) => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();

  const anchorId = parseInt(hash.replace('#', ''));

  const handlePrevious = () => {
    if (anchorId >= 1) navigate(`${pathname}#${anchorId - 1}`);
  };

  if (isMobile) {
    return (
      <NavigationButton
        sx={{ minWidth: '44px', mr: 2 }}
        onClick={handlePrevious}
        disabled={anchorId === 1}
      >
        <ArrowBackIosRounded />
      </NavigationButton>
    );
  }
  return (
    <NavigationButton
      startIcon={<ArrowBackIosRounded />}
      sx={{ mr: 'auto' }}
      onClick={handlePrevious}
      disabled={anchorId === 1}
    >
      ข้อก่อนหน้า
    </NavigationButton>
  );
};

const NextQuestionButton = ({ isMobile = false }: { isMobile: boolean }) => {
  const { examQuestions } = useExamination();
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();

  const anchorId = parseInt(hash.replace('#', ''));

  const totalQuestion = examQuestions.length;

  const handleNext = () => {
    if (anchorId !== totalQuestion) navigate(`${pathname}#${anchorId + 1}`);
  };

  if (isMobile) {
    return (
      <NavigationButton
        sx={{ minWidth: '44px', ml: 2 }}
        onClick={handleNext}
        disabled={anchorId === totalQuestion}
      >
        <ArrowForwardIosRounded />
      </NavigationButton>
    );
  }
  return (
    <NavigationButton
      endIcon={<ArrowForwardIosRounded />}
      onClick={handleNext}
      disabled={anchorId === totalQuestion}
      sx={{ ml: 'auto' }}
    >
      ข้อถัดไป
    </NavigationButton>
  );
};

export { NextQuestionButton, PreviousQuestionButton };
