import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import LoLButton from '../../../../Components/Base/LoLButton';
import { useAuthentication } from '../../../../Contexts/AuthenticationProvider';

const LogoutButton = () => {
  const { t } = useTranslation('lolCommon');
  const navigate = useNavigate();
  const { logout } = useAuthentication();

  const privateBookMatched = useMatch('mybooks/:bookId/learn-o-life/*');
  const publicBookMatched = useMatch('publicbooks/:bookId/learn-o-life/*');

  const handleLogout = () => {
    const privateBase = privateBookMatched ? privateBookMatched.pathnameBase : null;
    const publicBase = publicBookMatched ? publicBookMatched.pathnameBase : null;

    const toPath = privateBase ?? publicBase ?? '/mybooks';

    logout();

    navigate(toPath, { replace: true });
  };

  return (
    <LoLButton
      variant="outlined"
      size="small"
      sx={{ minWidth: '64px', borderColor: 'lolLine.line2', color: 'lolTextButton.contrast' }}
      aria-label={t('BUTTON.LOGOUT')}
      onClick={handleLogout}
    >
      {t('BUTTON.LOGOUT')}
    </LoLButton>
  );
};

export default LogoutButton;
