export const SIGNIN_PATH = '/signin';

export const MYBOOKS_BASE_PATH = '/mybooks';
export const MYBOOKS_ID = '/mybooks/:bookId';

export const MYBOOKS_EXAMINATIONS = `${MYBOOKS_ID}/examinations`;
export const MYBOOKS_RESULTS = `${MYBOOKS_ID}/results`;
export const MYBOOKS_SOLUTIONS = `${MYBOOKS_ID}/solutions`;

const PUBLICBOOKS_BASE_PATH = '/publicbooks';
const PUBLICBOOKS_ID = `${PUBLICBOOKS_BASE_PATH}/:bookId`;
export const PUBLICBOOKS_EXAMINATIONS = `${PUBLICBOOKS_ID}/examinations`;

export const PRIVAITE_EXAMROOM_PATH = '/mybooks/:bookId/examinations/:examId/examroom';
export const PUBLIC_EXAMROOM_PATH = `${PUBLICBOOKS_EXAMINATIONS}/:examId/examroom`;

export const CHECK_EXAM_STATUS_INTERVAL = 3000;

export enum PublicExamStatusErrorCode {
  NOT_START_NO_EXAM_CARD = 'EXAM_CARD_NOT_FOUND',
  NOT_START_NO_EXAM_PAPER = 'EXAM_PAPER_NOT_FOUND',
  GRADING = 'EXAM_PAPER_IS_GRADING',
  FINISHED = 'EXAM_PAPER_IS_FINISHED',
}
