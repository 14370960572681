import { Flex, Text, FlexProps } from '../../../Components/Base';

export type SulutionItemType = {
  isSubQuestion?: boolean;
  questionNo: string | number;
  solution?: string;
} & FlexProps;

const SolutionItem = (props: SulutionItemType) => {
  const { isSubQuestion = false, questionNo, solution, ...restProps } = props;

  return (
    <Flex width={'100%'} {...restProps}>
      <Text
        mr={2}
        variant={isSubQuestion ? 'body1' : 'subtitle1'}
        flex={isSubQuestion ? '0 0 32px' : '0 0 60px'}
      >
        {questionNo}
      </Text>
      <Flex alignItems={'center'}>
        <Text variant="body1" minWidth={'60px'}>
          {solution}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SolutionItem;
