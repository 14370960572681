import { Box } from '@mui/system';
import { AnimatePresence, motion } from 'framer-motion';

import { Flex } from '../Base';
import LoLResultDetailsCard from '../LoLResultDetailsCard';
import LoLShareButton from '../../Containers/LoLShareButton';
import { Lol_Exam_Result_Type } from '../../generated/graphql';

type LoLResultItemProps = {
  title: string;
  resultTitle?: string;
  imageUrl: string;
  descriptions: string[];
  index: number;
  isRoundedImage: boolean;
  isShowSharedButton: boolean;
  permalink?: string;
  type?: Lol_Exam_Result_Type;
};

const LoLResultItem = (props: LoLResultItemProps) => {
  const {
    permalink,
    title,
    resultTitle,
    imageUrl,
    descriptions,
    index,
    isRoundedImage,
    isShowSharedButton,
    type,
  } = props;
  return (
    <Box
      // HACK: Below styles for hiding first entry of another items from framer motion
      sx={{
        '& > :nth-child(n+2)': {
          display: 'none!important',
          width: '100%!important',
        },
      }}
    >
      <AnimatePresence
        onExitComplete={() => {
          const element = document.getElementById('scroll-top');
          element?.scrollIntoView();
        }}
      >
        <motion.div
          key={`result-${resultTitle}-${index}`}
          initial={{
            width: '100%',
            x: '0px',
            opacity: 0,
            zIndex: 0,
          }}
          animate={{ width: '100%', x: 0, opacity: 1, zIndex: 1 }}
          transition={{
            opacity: { delay: index == 0 ? 0 : 0.4, duration: 0.2, ease: 'easeIn' },
            x: { duration: 0.5, ease: 'easeIn' },
          }}
          exit={{
            width: '100%',
            x: '-1000px',
          }}
        >
          <Flex flexDirection={'column'} gap={3}>
            <LoLResultDetailsCard
              key={title}
              resultTitle={resultTitle}
              title={title}
              imgUrl={imageUrl}
              descriptions={descriptions}
              isRoundedImage={isRoundedImage}
            />
            {isShowSharedButton && permalink && (
              <LoLShareButton permalink={permalink} type={type} />
            )}
          </Flex>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default LoLResultItem;
