import Flex, { FlexProps } from '../Flex';
import Text from '../Text';

const getStylesBySize = (size: 'small' | 'medium') => {
  switch (size) {
    case 'small': {
      return {
        labelStyles: {
          py: 0.5,
          px: 1.25,
        },
        typographyStyles: {
          fontSize: 12,
          lineHeight: 1.37,
        },
      };
    }
    case 'medium':
    default: {
      return {
        labelStyles: {
          px: 1.5,
          py: 0.75,
        },
        typographyStyles: {
          fontSize: 14,
          lineHeight: 1.46,
        },
      };
    }
  }
};

type LegacyLabelProps = {
  children?: React.ReactNode | string;
  color?: FlexProps['color'];
  variant?: 'contained' | 'outlined';
  size?: 'small' | 'medium';
} & Omit<FlexProps, 'color'>;

const LegacyLabel = (props: LegacyLabelProps) => {
  const {
    children,
    color = 'primary',
    variant = 'outlined',
    size = 'medium',
    ...restProps
  } = props;

  const styles = getStylesBySize(size);

  switch (variant) {
    case 'contained': {
      return (
        <Flex
          width={'fit-content'}
          bgcolor={`${color}.dark`}
          borderRadius={3.5}
          {...styles.labelStyles}
          {...restProps}
        >
          {typeof children === 'string' ? (
            <Text fontWeight={'600'} color={'common.white'} {...styles.typographyStyles}>
              {children}
            </Text>
          ) : (
            children
          )}
        </Flex>
      );
    }
    case 'outlined':
    default: {
      return (
        <Flex
          width={'fit-content'}
          border={'1px solid'}
          borderColor={`${color}.light`}
          bgcolor={`${color}.lighter`}
          color={`${color}.dark`}
          borderRadius={3.5}
          {...styles.labelStyles}
          {...restProps}
        >
          {typeof children === 'string' ? (
            <Text fontWeight={'600'} {...styles.typographyStyles}>
              {children}
            </Text>
          ) : (
            children
          )}
        </Flex>
      );
    }
  }
};

export default LegacyLabel;
export type { LegacyLabelProps };
