import { Divider, Paper, Skeleton } from '@mui/material';
import { Fragment } from 'react';

import { Flex, Box } from '../../../Components/Base';

const SolutionItemLoading = () => {
  return (
    <>
      {Array.from({ length: 2 }).map((_, itemIndex) => (
        <Paper
          sx={{
            mb: 1,
            borderRadius: { xs: 0, sm: 1 },
          }}
          key={itemIndex}
        >
          <Box px={2}>
            <Box py={2}>
              <Skeleton height={24} width={120} />
            </Box>
            {Array.from({ length: 5 }).map((_, index) => (
              <Fragment key={index}>
                <Divider />
                <Flex py={2}>
                  <Flex justifyContent={'space-between'} alignItems={'center'} flexGrow="1">
                    <Skeleton height={24} width={50} />
                    <Flex>
                      <Skeleton height={26} width={56} sx={{ borderRadius: '16px' }} />
                    </Flex>
                  </Flex>
                </Flex>
              </Fragment>
            ))}
          </Box>
        </Paper>
      ))}
    </>
  );
};

export default SolutionItemLoading;
