import { Box, ContainerProps, IconButtonProps } from '@mui/material';

import { Flex, IconButton, Text } from '../../Base';
import ArrowBackRoundedIcon from '../../Icons/ArrowBackRoundedIcon';
import CloseRoundedIcon from '../../Icons/CloseRoundedIcon';
import AppBar from '../AppBar';
import { AppBarProps } from '../AppBar/AppBar';

export type AppBarWithActionType = AppBarProps & {
  onBack?: () => void;
  onClose?: () => void;
  title?: string;
  titlePosition?: 'left' | 'center';
  description?: string;
  rightAdornment?: React.ReactNode;
  containerSx?: ContainerProps['sx'];
  containerMaxWidth?: ContainerProps['maxWidth'];
  iconSize?: IconButtonProps['size'];
  theme?: 'light' | 'dark' | 'lol';
};

const darkStyles = {
  iconColor: 'common.white',
  titleColor: 'common.white',
  subtitleColor: 'neutral.lighter',
  iconBgColor: 'transparent',
  bgColor: 'neutral.darker',
};

const lightStyles = {
  iconColor: 'text.primary',
  titleColor: 'text.primary',
  subtitleColor: 'text.secondary',
  iconBgColor: 'grey.200',
  bgColor: 'common.white',
};

const lolStyles = {
  iconColor: 'lolIcon.contrast',
  titleColor: 'lolText.primary',
  subtitleColor: 'lolText.primary',
  iconBgColor: 'trasparent',
  bgColor: 'lolBackground.primary',
};

const AppBarWithAction = (props: AppBarWithActionType) => {
  const {
    title,
    titlePosition = 'center',
    rightAdornment,
    description,
    onBack,
    onClose,
    iconSize = 'medium',
    theme = 'light',
    ...restProps
  } = props;

  let appbarTheme = lightStyles;

  if (theme === 'dark') appbarTheme = darkStyles;
  if (theme === 'lol') appbarTheme = lolStyles;

  const { iconColor, titleColor, subtitleColor, iconBgColor, bgColor } = appbarTheme;

  return (
    <AppBar {...restProps} sx={{ ...restProps.sx, bgcolor: bgColor }}>
      <Flex width={1} alignItems={'center'} position={'relative'} justifyContent={'space-between'}>
        {titlePosition === 'center' && title && (
          <Box
            width={1}
            px={4.5}
            textAlign={'center'}
            position={'absolute'}
            sx={{ left: '50%', transform: 'translateX(-50%)' }}
          >
            <Text
              color={titleColor}
              variant={'subtitle1'}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
            >
              {title}
            </Text>
            {description && (
              <Text variant={'caption'} color={subtitleColor}>
                {description}
              </Text>
            )}
          </Box>
        )}
        {typeof onBack === 'function' && (
          <IconButton
            onClick={onBack}
            size={iconSize}
            sx={{ color: iconColor, bgcolor: iconBgColor, ':hover': { color: titleColor } }}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
        )}
        {typeof onClose === 'function' && (
          <IconButton
            onClick={onClose}
            size={iconSize}
            sx={{ color: iconColor, bgcolor: iconBgColor }}
          >
            <CloseRoundedIcon />
          </IconButton>
        )}
        {titlePosition === 'left' && title && (
          <Text color={titleColor} variant={'subtitle1'}>
            {title}
          </Text>
        )}

        {rightAdornment}
      </Flex>
    </AppBar>
  );
};

export default AppBarWithAction;
