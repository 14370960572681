import { Flex as BasicFlex, Box, BoxProps } from '../../../../Components/Base';

const Item = (props: BoxProps) => {
  return (
    <Box
      p={1}
      m={1}
      bgcolor={'grey.100'}
      color={'grey.800'}
      border={'1px solid'}
      borderColor={'grey.300'}
      borderRadius={2}
      fontWeight={'700'}
      {...props}
    />
  );
};

const Flex = () => {
  return (
    <>
      <BasicFlex p={1} m={1} bgcolor={'background.paper'} borderRadius={1}>
        <Item>Item 1</Item>
        <Item>Item 2</Item>
        <Item>Item 3</Item>
      </BasicFlex>
      <BasicFlex
        flexDirection={'row-reverse'}
        p={1}
        m={1}
        bgcolor={'background.paper'}
        borderRadius={1}
      >
        <Item>Item 1</Item>
        <Item>Item 2</Item>
        <Item>Item 3</Item>
      </BasicFlex>
      <BasicFlex
        alignItems={'flex-start'}
        flexDirection={'column'}
        p={1}
        m={1}
        bgcolor={'background.paper'}
        borderRadius={1}
      >
        <Item>Item 1</Item>
        <Item>Item 2</Item>
        <Item>Item 3</Item>
      </BasicFlex>
      <BasicFlex
        flexDirection={'column-reverse'}
        alignItems={'flex-start'}
        p={1}
        m={1}
        bgcolor={'background.paper'}
        borderRadius={1}
      >
        <Item>Item 1</Item>
        <Item>Item 2</Item>
        <Item>Item 3</Item>
      </BasicFlex>
    </>
  );
};

export default Flex;
export { Flex };
