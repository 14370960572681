import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Paper } from '../../../Components/Base';
import SubjectAccordion from '../../../Components/SubjectAccordion';
import useGetSolutionById, { QbQuestionType } from '../../../Domains/useGetSolutionById';
import SolutionItemLoading from '../Components/SolutionItemLoading';
import useGoogleTagManager from '../../../Utils/useGoogleTagManager';

import SolutionQuestionList from './SolutionQuestionList';

type QuestionsType = {
  __typename?: 'QMSQuestion' | undefined;
  id: string;
  correctAnswer?: any;
  type: QbQuestionType;
  subType?: string | null;
};

type ExamItemsType = {
  __typename?: 'QMSExamItem' | undefined;
  id: string;
  questionId: string;
  order: number;
};

const mappingQuestionWithOrder = (question: QuestionsType[], examItems: ExamItemsType[]) => {
  const questionWithOrder = question
    .map((question) => {
      const examItemByQuestionId = examItems.find((item) => item.questionId === question.id);
      return {
        ...question,
        order: examItemByQuestionId?.order ?? 0,
        examItemId: examItemByQuestionId?.id ?? '',
      };
    })
    .sort((current, next) => current.order - next.order);
  return questionWithOrder;
};

const SolutionBySubject = () => {
  const { examId = '' } = useParams();

  const sendDataToGTM = useGoogleTagManager();

  const { data, loading } = useGetSolutionById({ variables: { examId } });
  const examTitle = data?.qmsFoGetExamById?.title ?? '';
  const subjectList = data?.qmsFoGetExamById?.examSubjects ?? [];
  const examItems = data?.qmsFoGetExamById?.examItems ?? [];
  const [expandedSubjectIndex, setExpandedSubjectIndex] = useState<number | null>(
    parseInt(location.hash.replace('#', '') || '0')
  );
  const navigate = useNavigate();

  const handleAccordionChange =
    (accordionIndex: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedSubjectIndex(newExpanded ? accordionIndex : null);
      navigate(`${location.pathname}#${accordionIndex}`, { replace: true });
    };

  const handleQuestionItemTracking = (subjectTitle: string) => {
    sendDataToGTM({
      event: 'examination_solution_question_click',
      examTitle,
      subjectTitle,
    });
  };

  if (loading) return <SolutionItemLoading />;

  return (
    <>
      {subjectList.map((subject, index) => {
        const questions = subject.questions;
        const questionsWithOrder = mappingQuestionWithOrder(
          questions,
          examItems as ExamItemsType[]
        );
        return (
          <Paper
            key={`subject-${index + 1}`}
            sx={{
              mb: 1,
              borderRadius: { xs: 0, sm: 1 },
            }}
          >
            <SubjectAccordion
              title={subject.title}
              totalQuestion={subject.totalQuestionCount}
              expanded={expandedSubjectIndex === index}
              onChange={handleAccordionChange(index)}
            >
              <SolutionQuestionList
                questions={questionsWithOrder}
                onTracking={() => handleQuestionItemTracking(subject.title)}
              />
            </SubjectAccordion>
          </Paper>
        );
      })}
    </>
  );
};

export default SolutionBySubject;
