import { Outlet } from 'react-router-dom';

import Loading from '../../Components/Loading';
import { AnonymousState, useAnonymous } from '../../Contexts/AnonymousProvider';
import { LoginState, useAuthentication } from '../../Contexts/AuthenticationProvider';

type PublicRouteProps = {
  children?: JSX.Element;
};

const PublicRoute = ({ children }: PublicRouteProps) => {
  const { loginState } = useAuthentication();
  const { anonymousUserState } = useAnonymous();

  if (loginState === LoginState.LOADING || anonymousUserState === AnonymousState.LOADING) {
    return <Loading open />;
  }

  if (loginState === LoginState.LOGGED_IN || anonymousUserState === AnonymousState.LOGGED_IN)
    return children ?? <Outlet />;

  return children ?? <Outlet />;
};

export default PublicRoute;
