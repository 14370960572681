import { Fragment } from 'react';

import { Box, Button, ButtonProps, Flex, FlexProps, Text } from '../../../../Components/Base';

const ButtonWrapper = (props: FlexProps) => (
  <Flex alignItems={'center'} flexWrap={'wrap'} gap={2} {...props} />
);

const COLOR: ButtonProps['color'][] = [
  'primary',
  'secondary',
  'success',
  'warning',
  'error',
  'info',
  'greyButton',
];

const ButtonView = () => {
  return (
    <Flex flexDirection={'column'} justifyContent={'space-around'}>
      {/* Variant */}
      <Box>
        <Text variant="body2" fontWeight={600}>
          Button Variant
        </Text>
        <ButtonWrapper>
          <Button variant={'contained'}>Test</Button>
          <Button variant={'outlined'}>Test</Button>
          <Button>Test</Button>
        </ButtonWrapper>
      </Box>
      {/* Size */}
      <Box mt={2}>
        <Text variant="body2" fontWeight={600}>
          Button Size
        </Text>
        <ButtonWrapper>
          <Button variant={'contained'} size={'large'}>
            Test
          </Button>
          <Button variant={'contained'}>Test</Button>
          <Button variant={'contained'} size={'small'}>
            Test
          </Button>
        </ButtonWrapper>
      </Box>
      {/* Disabled */}
      <Box mt={2}>
        <Text variant="body2" fontWeight={600}>
          Button Disabled
        </Text>
        <ButtonWrapper>
          <Button variant={'contained'} disabled>
            Test
          </Button>
          <Button variant={'outlined'} disabled>
            Test
          </Button>
          <Button variant={'text'} disabled>
            Test
          </Button>
        </ButtonWrapper>
      </Box>
      {/* Color */}
      <Box mt={2}>
        <Text variant="body2" fontWeight={600}>
          Button Color
        </Text>
        <ButtonWrapper>
          {COLOR.map((color, index) => (
            <Fragment key={index}>
              <Button variant={'contained'} color={color}>
                Test
              </Button>
              <Button variant={'outlined'} color={color}>
                Test
              </Button>
            </Fragment>
          ))}
        </ButtonWrapper>
      </Box>
    </Flex>
  );
};

export default ButtonView;
