import { useTranslation } from 'react-i18next';

import LoLButton, { LoLButtonProps } from '../../../../Components/Base/LoLButton';

type LearnIDLoginButtonProps = LoLButtonProps;

const LearnIDLoginButton = (props: LearnIDLoginButtonProps) => {
  const { t } = useTranslation('lolSignIn');
  return (
    <LoLButton
      size="large"
      sx={{
        bgcolor: 'common.white',
        color: 'common.black',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          color: 'lolTextButton.primary',
        },
      }}
      {...props}
    >
      {t('LOGIN_PROVIDER.LEARN_ID.LOGIN')}
    </LoLButton>
  );
};

export default LearnIDLoginButton;
