import { styled } from '@mui/material';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  Box,
  Flex,
  Text,
} from '../Base';

const TitleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type SubjectAccordionProps = {
  title: string;
  totalQuestion: number | string;
} & AccordionProps;

const SubjectAccordion = (props: SubjectAccordionProps) => {
  const { title, totalQuestion, children, ...restProps } = props;

  return (
    <Accordion disableGutters {...restProps}>
      <AccordionSummary>
        <Flex alignItems={'center'}>
          <Box width={'100%'}>
            <TitleText variant="subtitle1">
              {title} | {totalQuestion} ข้อ
            </TitleText>
          </Box>
        </Flex>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SubjectAccordion;
