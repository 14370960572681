import { styled, ListItemButton as MUIListItemButton, ListItemIcon } from '@mui/material';
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';
import isUrl from 'is-url';

import { Box, Text } from '../../Components/Base';

type MenuItemProps = {
  label: string;
  icon: React.ReactNode;
  link: string;
};

const Link = styled(RouterLink)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  textDecoration: 'none',
  padding: `${theme.spacing(2.5)} ${theme.spacing(1)}`,
}));

const ListItemButton = styled(MUIListItemButton)(({ theme }) => ({
  padding: 0,
  marginTop: 0,
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
}));

type NavigationLinkType = {
  isExternalNavigation: boolean;
  linkUrl: string;
  children: React.ReactNode;
};

const NavigationLink = (props: NavigationLinkType) => {
  const { isExternalNavigation, linkUrl, ...restProps } = props;
  if (isExternalNavigation) {
    return (
      <Box
        component={'a'}
        href={linkUrl}
        target={'_blank'}
        display={'flex'}
        alignItems={'center'}
        sx={{ flex: 1, textDecoration: 'none' }}
        px={1}
        py={2.5}
        {...restProps}
      />
    );
  }
  return <Link to={linkUrl} {...restProps} />;
};

const MenuItem = (props: MenuItemProps) => {
  const { icon, link, label } = props;
  const isExternalNavigation = isUrl(link);

  const resolved = useResolvedPath(link);
  const match = useMatch({ path: `${resolved.pathname}/*` });

  return (
    <ListItemButton selected={!!match}>
      <NavigationLink isExternalNavigation={isExternalNavigation} linkUrl={link}>
        <ListItemIcon sx={{ color: match ? 'primary.main' : 'grey.700' }}>{icon}</ListItemIcon>
        <Text color={match ? 'primary.main' : 'grey.700'} sx={{ textDecoration: 'none' }}>
          {label}
        </Text>
      </NavigationLink>
    </ListItemButton>
  );
};

export default MenuItem;
