import { Fragment } from 'react';
import { Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Paper } from '../../Components/Base';
import ResultRoundItem from '../../Components/ResultRoundItem';
import { MYBOOKS_RESULTS } from '../constant';
import useGetExaminationResultsByExamCardId, {
  QmsExamPaperStatus,
  QmsOrderType,
} from '../../Domains/useGetExaminationResultsByExamId';
import EmptyExaminationResults from '../BookResults/Components/EmptyExaminationResult';
import DataLoadingError from '../../Components/DataLoadingError';
import { RESPONSE_TYPE } from '../../Domains/constants';
import { formatFullScore, formatScore } from '../../Domains/useGetExaminationResultByLesson/utils';
import { QbQuestionType } from '../../generated/graphql';
import { useQmsGetExamCardById } from '../../Domains/ExamCard/useGetExamCardById';

import ExaminationResultsLoading from './Components/ExaminationResultsLoading';

const ResultListByExamCardId = () => {
  const { bookId = '', examCardId = '' } = useParams();
  const navigate = useNavigate();
  const myBookResultPath = MYBOOKS_RESULTS.replace(':bookId', bookId ?? '');

  const examinationResultOptions = {
    myExamPapersInput: {
      bookId,
      examCardId,
      statuses: [QmsExamPaperStatus.Grading, QmsExamPaperStatus.Finished],
    },
    paging: {
      pageSize: 200,
      currentPage: 0,
    },
    order: {
      field: 'createdAt',
      type: QmsOrderType.Desc,
    },
  };

  const { data: examCardData, loading: examCardLoading } = useQmsGetExamCardById({
    variables: { examCardId },
  });

  const {
    data: examResultsData,
    loading: examResultsLoading,
    error,
    refetch,
  } = useGetExaminationResultsByExamCardId({
    variables: examinationResultOptions,
  });

  const examPapers = examResultsData?.qmsFoMyExamPapers;
  const total = examPapers?.total ?? 0;
  const examResults = examPapers?.examPapers ?? [];

  const allExamIdList = examResults.map((paper) => paper.examId);
  const uniqueExamIdList = [...new Set(allExamIdList)];
  const isShowCurrentExamLabel = uniqueExamIdList.length > 1;
  const currentExamId = examCardData?.qmsGetExamCardById.skuExamItem?.examId ?? '';

  const handleGoToResultOverview = (examPaperId: string) => {
    navigate(`${myBookResultPath}/${examPaperId}`, { replace: true });
  };

  if (examResultsData && total === 0) {
    return <EmptyExaminationResults />;
  }

  if (error && error.message === RESPONSE_TYPE.NETWORK_ERROR) {
    return <DataLoadingError onRetry={() => refetch(examinationResultOptions)} mt={9} />;
  }

  return (
    <Paper
      sx={{
        mb: 6,
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      {examResultsLoading && examCardLoading && <ExaminationResultsLoading />}
      {examResults.map((result, index) => {
        const {
          id: examPaperId,
          examId: examIdByExamPaper,
          score,
          fullScore,
          createdAt,
          status,
          examPaperItems,
          iteration,
        } = result;

        //Remark: Workaroud for display score by 4 decimal points
        const connectionAnswerTypqQuestionIndex =
          examPaperItems?.findIndex(
            (item) => item?.question?.type === QbQuestionType.MultipleSubQuestionsConnectionAnswers
          ) ?? -1;

        const hasConnectionAnswer = connectionAnswerTypqQuestionIndex > -1;

        return (
          <Fragment key={examPaperId}>
            {index > 0 && <Divider sx={{ mx: { xs: 2, md: 3 } }} />}
            <ResultRoundItem
              iteration={iteration}
              score={formatScore(score, fullScore, hasConnectionAnswer ? 4 : 2)}
              fullScore={formatFullScore(fullScore)}
              startedExamAt={createdAt}
              variant={status === QmsExamPaperStatus.Finished ? 'FINISHED' : 'GRADING'}
              onClick={() => {
                if (status === QmsExamPaperStatus.Finished) {
                  handleGoToResultOverview(examPaperId);
                }
              }}
              px={{ xs: 2, md: 3 }}
              py={2}
              isCurrentExam={isShowCurrentExamLabel && currentExamId === examIdByExamPaper}
            />
          </Fragment>
        );
      })}
    </Paper>
  );
};

export default ResultListByExamCardId;
