import { createTheme, experimental_sx as sx } from '@mui/material';

const skeletonTheme = createTheme({
  components: {
    MuiSkeleton: {
      defaultProps: {
        variant: 'rectangular',
      },
      styleOverrides: {
        root: sx({
          backgroundColor: 'grey.100',
        }),
        rectangular: sx({
          borderRadius: 1,
        }),
      },
    },
  },
});

export default skeletonTheme;
