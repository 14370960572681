import { styled } from '@mui/material';

import { Paper, Text, PaperProps, Container, ContainerProps } from '../Base';

type ExaminationTitleProps = {
  title: string;
  containerSx?: ContainerProps['sx'];
  maxWidth?: ContainerProps['maxWidth'];
} & PaperProps;

const TitleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExaminationTitle = ({
  title,
  children,
  containerSx,
  maxWidth,
  ...restProps
}: ExaminationTitleProps) => {
  return (
    <Paper
      elevation={4}
      {...restProps}
      sx={{
        borderRadius: { xs: 0, sm: 1 },
        bgcolor: 'common.white',
        ...restProps.sx,
      }}
    >
      <Container
        maxWidth={maxWidth}
        sx={{ px: { xs: 2, sm: 3, lg: 0 }, py: { xs: 2, md: 2 }, ...containerSx }}
      >
        <TitleText variant="h4">{title}</TitleText>
        {children}
      </Container>
    </Paper>
  );
};

export default ExaminationTitle;
export { ExaminationTitle };
