import Portal from '@mui/material/Portal';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, ButtonProps } from '../../../../Components/Base';
import SubmitExaminationSuccessModal from '../../../../Components/SubmitExaminationSuccessModal';
import {
  FormAnswerQuestionType,
  getSubmitAnswers,
  useExamination,
} from '../../../../Contexts/ExaminationProvider';
import { useExaminationTimer } from '../../../../Contexts/ExaminationTimerProvider/ExaminationTimerProvider';
import useExamRoomSubmitExamPaper from '../../../../Domains/useExamRoomSubmitExamPaper';
import useUpdateExaminationPaper from '../../../../Domains/useUpdateExaminationPaper';
import { UpdateExamPaperType } from '../../../../Domains/useUpdateExaminationPaper/useUpdateExaminationPaper';
import { AnimationFrameStatus } from '../../../../Utils/useAnimationFrame';
import useDisclosure from '../../../../Utils/useDisclosure';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';
import { QmsskuType } from '../../../../generated/graphql';
import ExaminationSubmitModal from '../../Components/ExaminationSubmitModal';
import SubmitExamErrorSnackbar from '../../Components/SubmitExamErrorSnackbar';

type ExaminationSubmitButtonProps = ButtonProps;

const ExaminationSubmitButton = (props: ExaminationSubmitButtonProps) => {
  const navigate = useNavigate();
  const { bookId = '', examId = '' } = useParams();
  const { bookType } = useExamination();
  const { setAnimationFrameStatus } = useExamination();
  const { setRemainingTime } = useExaminationTimer();

  const {
    isOpen: isSubmitModalOpen,
    open: openSubmitModal,
    close: closeSubmitModal,
  } = useDisclosure();
  const {
    isOpen: isSubmitSuccessModalOpen,
    open: openSubmitSuccessModal,
    close: closeSubmitSuccessModal,
  } = useDisclosure();
  const { isOpen: isSnackbarOpen, open: openSnackbar, close: closeSnackbar } = useDisclosure();

  const sendDataToGTM = useGoogleTagManager();

  const [updateAnswer, { loading: updateLoading }] = useUpdateExaminationPaper({
    onError: () => {
      openSnackbar();
    },
  });
  const [submitExam, { loading: submitLoading }] = useExamRoomSubmitExamPaper({
    onError: () => {
      openSnackbar();
    },
  });

  const { getValues } = useFormContext();

  const handleUpdateAnswer = async () => {
    sendDataToGTM({ event: 'examination_submit_button_click' });

    const formatAnswer = getValues('answers')?.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ order, ...answer }: { order: number }) => answer
    );

    const { data: updatedData } = await updateAnswer({
      variables: { examRoomExamPaperItems: formatAnswer },
    });
    if (!updateLoading && updatedData) {
      const answers = updatedData.qmsExamRoomUpdateExamPaper.examRoomExamPaperItems;
      handleSubmitExamPaper(answers);
    }
  };

  const handleSubmitExamPaper = async (
    answers: UpdateExamPaperType['qmsExamRoomUpdateExamPaper']['examRoomExamPaperItems']
  ) => {
    const rawAnswers = getSubmitAnswers(answers as FormAnswerQuestionType[]);

    const { data: submitExamData } = await submitExam({
      variables: { submitExamInput: { rawAnswers: rawAnswers } },
    });

    if (!submitLoading && submitExamData) {
      closeSubmitModal();
      setAnimationFrameStatus(AnimationFrameStatus.STOP);
      setRemainingTime(undefined);
      openSubmitSuccessModal();
    }
  };

  const goToExaminationResult = () => {
    closeSubmitSuccessModal();
    if (bookType === QmsskuType.PublicBook) {
      /*
      We need to pass examId for publicbooks only, because this container is shared between 2 book type but diffrence use case (examCardId for private and examId for public).
      For public book type, it will be passed examId as examCardId in params
      */
      navigate(`/publicbooks/${bookId}/examinations/${examId}`, { replace: true });
    } else {
      navigate(`/mybooks/${bookId}/results`, { replace: true });
    }
  };

  const goToExamination = () => {
    closeSubmitSuccessModal();
    navigate(`/mybooks/${bookId}/examinations`, { replace: true });
  };

  const isSubmitting = updateLoading || submitLoading;

  return (
    <>
      <Button
        sx={{ maxWidth: { xs: 1, sm: '200px' }, width: 1 }}
        size="large"
        onClick={openSubmitModal}
        {...props}
      >
        ส่งข้อสอบ
      </Button>
      <ExaminationSubmitModal
        open={isSubmitModalOpen}
        onSubmit={handleUpdateAnswer}
        onCancel={closeSubmitModal}
        isSubmitting={isSubmitting}
      />
      <SubmitExaminationSuccessModal
        open={isSubmitSuccessModalOpen}
        onSeeExaminationSolution={goToExaminationResult}
        onClose={goToExamination}
      />
      <Portal>
        <SubmitExamErrorSnackbar
          open={isSnackbarOpen}
          onClose={closeSnackbar}
          autoHideDuration={5000}
        />
      </Portal>
    </>
  );
};

export default ExaminationSubmitButton;
