import { Box } from '../../../../Components/Base';
import SubmitLoLExamButton from '../../../../Containers/SubmitLoLExamButton';
import { useLoLExamination } from '../../../../Contexts/LoLExaminationProvider';
import LoLContinueButton from '../LoLContinueButton';
import SeeLoLExamResultButton from '../SeeLoLExamResultButton';

type MilestoneActionButtonProps = {
  goToInstruction: () => void;
};

const MilestoneActionButton = (props: MilestoneActionButtonProps) => {
  const { goToInstruction } = props;
  const { isExamCompleted } = useLoLExamination();

  return (
    <>
      {isExamCompleted ? (
        <SubmitLoLExamButton />
      ) : (
        <Box width={1} mb={4}>
          <LoLContinueButton sx={{ mb: 2 }} onClick={goToInstruction} />
          <SeeLoLExamResultButton />
        </Box>
      )}
    </>
  );
};

export default MilestoneActionButton;
