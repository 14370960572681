import { Box } from '@mui/material';
import { useMemo } from 'react';

import { useLoLExamination } from '../../../../Contexts/LoLExaminationProvider';
import LoLQuestionContent from '../LoLQuestionContent';
import { RiasecTraitGroup, ValueTraitGroup } from '../TraitGroup';

const LoLExamination = () => {
  const { examQuestions, currentQuestionNo = 1 } = useLoLExamination();

  const currentQuestion = useMemo(() => {
    const currentExam = examQuestions.find((question) => question.order === currentQuestionNo);
    return currentExam;
  }, [examQuestions, currentQuestionNo]);

  const showRIASECMiniReport = useMemo(() => {
    const tags = currentQuestion?.tags;

    return tags && tags.findIndex(({ name }) => name === 'RIASEC') > -1;
  }, [currentQuestion]);

  const showVALUEMiniReport = useMemo(() => {
    const tags = currentQuestion?.tags;

    return tags && tags.findIndex(({ name }) => name === 'VALUE') > -1;
  }, [currentQuestion]);

  const showMiniReport = showRIASECMiniReport || showVALUEMiniReport;

  return (
    <Box position={'relative'} height={'100%'} sx={{ overflowY: 'auto' }}>
      <Box px={3} mb={showMiniReport ? 23 : 10}>
        <LoLQuestionContent />
      </Box>
      <Box
        position={'fixed'}
        overflow={'hidden'}
        width={'100%'}
        bottom={0}
        left={0}
        bgcolor={'lolBackground.primary'}
        pb={{ xs: 1, sm: 0 }}
      >
        {showRIASECMiniReport && <RiasecTraitGroup />}
        {showVALUEMiniReport && <ValueTraitGroup />}
      </Box>
    </Box>
  );
};

export default LoLExamination;
