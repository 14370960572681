import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Loading from '../../Components/Loading';
import { LoginState, useAuthentication } from '../../Contexts/AuthenticationProvider';
import { useConfig } from '../../Contexts/ConfigProvider';
import { MYBOOKS_BASE_PATH } from '../constant';

const SignIn = () => {
  const { loginState } = useAuthentication();
  const { userPortal } = useConfig();
  const navigate = useNavigate();

  const userPortalUrl = userPortal.userPortalUrl?.login;
  const redirectUrl = userPortal.redirectUrl?.login;
  const { id, buCode } = userPortal.client;

  const getLoginUrl = () => {
    const searchParam = queryString.stringify({
      redirect_uri: redirectUrl,
      bu_code: buCode,
      client_id: id,
    });

    const loginUrl = `${userPortalUrl}/?${searchParam}`;
    return loginUrl;
  };

  const loginPath = getLoginUrl();
  useEffect(() => {
    if (loginState === LoginState.LOGGED_IN) {
      navigate(MYBOOKS_BASE_PATH, { replace: true });
      return;
    }

    if (loginState === LoginState.NOT_LOGGED_IN) {
      if (loginPath && loginPath.startsWith('/')) {
        navigate(loginPath, { replace: true });
      } else {
        window.location.href = loginPath ?? '/';
      }
      return;
    }
  }, [loginState, navigate, loginPath]);

  return <Loading open />;
};

export default SignIn;
