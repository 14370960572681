import { AnswerQuestionType, FormAnswerQuestionType } from '../../Contexts/ExaminationProvider';

export const checkAnswerdQuestions = (answer: AnswerQuestionType | FormAnswerQuestionType) => {
  const answers = answer.answer?.value;
  if (typeof answers === 'undefined' || answers === null || answers === '') {
    return false;
  }

  if (Array.isArray(answers)) {
    const isConnectionAnswerType = answers.every(
      (answer) => typeof answer === 'string' || answer === null || answer === undefined
    );

    if (isConnectionAnswerType) {
      if (answers.some((answer) => typeof answer === 'string' && !answer.includes('–'))) {
        return true;
      } else {
        return false;
      }
    }

    const isYesNoAnswerType = answers.every(
      (answer) => typeof answer === 'boolean' || answer === null || answer === undefined
    );

    if (isYesNoAnswerType) {
      if (answers.every((answer) => typeof answer === 'boolean')) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  return true;
};
