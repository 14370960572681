import { createTheme, experimental_sx as sx } from '@mui/material/styles';
import { alpha, ComponentsPropsList } from '@mui/material';

import paletteTheme from './paletteTheme';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    greyButton: true;
  }
}

const buttonTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          minWidth: '100px',
          borderRadius: '8px',
          textTransform: 'initial',
          boxShadow: 'none',
          color: paletteTheme.palette.common.black,

          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedSizeLarge: {
          boxShadow: 'none',
        },
        contained: ({ ownerState }: { ownerState: ComponentsPropsList['MuiButton'] }) => {
          const color = ownerState?.color as ComponentsPropsList['MuiButton']['color'];
          const variant = ownerState?.variant as ComponentsPropsList['MuiButton']['variant'];
          if (variant === 'contained' && color && color !== 'inherit') {
            return {
              '&.Mui-disabled': {
                backgroundColor: alpha(paletteTheme.palette[color].main, 0.4),
                color: paletteTheme.palette.neutral.main,
              },
            };
          }
        },
        sizeSmall: sx({
          fontSize: 14,
          lineHeight: 1.37,
          py: 1,
          px: 1.5,
        }),
        sizeMedium: sx({
          py: 1,
          px: 1.5,
        }),
        sizeLarge: sx({
          fontSize: 16,
          lineHeight: 1.5,
          p: 1.5,
        }),
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'greyButton' },
          style: sx({
            borderColor: 'greyButton.light',
          }),
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: sx({
          p: 0.75,
        }),
        colorInherit: sx({
          bgcolor: 'grey.200',
          color: 'grey.700',
          '&:hover': {
            bgcolor: 'grey.300',
          },
        }),
      },
    },
  },
});

export default buttonTheme;
