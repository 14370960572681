import React from 'react';
import { FormControlLabel as RadioSelectionInput, Radio } from '@mui/material';

interface RadioSelectionProps {
  checked: boolean;
  label: React.ReactNode;
  name?: string;
  disabled?: boolean;
  value?: boolean | string | number;
  groupValue?: boolean | string | number;
}

const RadioSelection = (props: RadioSelectionProps) => {
  const { label = '', checked = false, name, groupValue, value, disabled = false } = props;

  return (
    <RadioSelectionInput
      checked={checked}
      label={label}
      value={value}
      name={name}
      disabled={disabled}
      sx={{
        m: 0,
      }}
      control={
        <Radio
          color="default"
          sx={{
            '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path': {
              color: value === groupValue ? 'text.primary' : 'grey.400',
            },
            color: 'text.primary',
          }}
        />
      }
    />
  );
};

export default RadioSelection;
