import { Fragment } from 'react';
import { Divider, styled } from '@mui/material';
import { Link as ReactLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Paper } from '../../../../Components/Base';
import ExamItem from '../../../../Components/ExamItem';
import useGetExaminations from '../../../../Domains/useGetExaminations';
import DataLoadingError from '../../../../Components/DataLoadingError';
import { RESPONSE_TYPE } from '../../../../Domains/constants';
import NotFoundError from '../../../../Components/NotFoundError';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

import SolutionListLoading from './SolutionListLoading';

const Link = styled(ReactLink)(() => ({
  color: 'inherit',
  textDecoration: 'none',
}));

const SolutionList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { bookId = '' } = useParams();

  const sendDataToGTM = useGoogleTagManager();

  const {
    data: examinations,
    loading: examinationsLoading,
    error: examinationsError,
    refetch,
  } = useGetExaminations({
    variables: { bookId },
  });

  const handleExamItemTracking = (title: string) => {
    sendDataToGTM({
      event: 'examination_solution_item_click',
      examTitle: title,
    });
  };

  if (examinationsLoading) return <SolutionListLoading />;

  if (examinationsError) {
    if (examinationsError.message === RESPONSE_TYPE.NETWORK_ERROR) {
      return <DataLoadingError onRetry={() => refetch()} mt={9} />;
    }

    const examErrorHttpStatus =
      examinationsError.graphQLErrors[0]?.extensions?.exception.httpStatus;

    if (examErrorHttpStatus === RESPONSE_TYPE.NOT_FOUND)
      return <NotFoundError open onBackToHome={() => navigate('/')} />;
  }

  return (
    <Paper
      sx={{
        mb: { xs: 1, sm: 2 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      {examinations.map((examCard, index) => {
        const examId = examCard.skuExamItem?.exam?.id ?? '';
        const title = examCard.skuExamItem?.exam?.title ?? '';

        return (
          <Fragment key={examCard.id}>
            {index > 0 && <Divider sx={{ mx: { xs: 2, md: 3 } }} />}
            <Link to={`${location.pathname}/${examId}`}>
              <ExamItem
                px={{ xs: 2, md: 3 }}
                py={2}
                title={title}
                onClick={() => handleExamItemTracking(title)}
              />
            </Link>
          </Fragment>
        );
      })}
    </Paper>
  );
};

export default SolutionList;
