import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Text } from '../../../../Components/Base';
import LoLButton, { LoLButtonProps } from '../../../../Components/Base/LoLButton';

type GradingLoLExaminationButtonProps = LoLButtonProps;

const GradingLoLExaminationButton = (props: GradingLoLExaminationButtonProps) => {
  const { t } = useTranslation('lolCommon');
  return (
    <LoLButton
      fullWidth
      size={'large'}
      disabled
      startIcon={<CircularProgress size={24} />}
      {...props}
    >
      <Text>{t('BUTTON.GRADING')}</Text>
    </LoLButton>
  );
};

export default GradingLoLExaminationButton;
