import { useNavigate, useParams } from 'react-router-dom';

import { Button, ButtonProps } from '../../../../Components/Base';
import ArrowForwardIcon from '../../../../Components/Icons/ArrowForwardIcon';
import Loading from '../../../../Components/Loading';
import useBackToExam from '../../../../Domains/useBackToExam';
import useDisclosure from '../../../../Utils/useDisclosure';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';
import { useQmsGetExamCardByBookIdAndExamIdQuery as useGetExamCardByBookIdAndExamId } from '../../../../generated/graphql';
import ResumeExaminationModal from '../../Components/ResumeExaminationModal';

type ContinuePublicExaminationButtonProps = ButtonProps;

const ContinuePublicExaminationButton = (props: ContinuePublicExaminationButtonProps) => {
  const { bookId = '', examId = '' } = useParams();
  const { isOpen, open, close } = useDisclosure();
  const navigate = useNavigate();
  const sendDataToGTM = useGoogleTagManager();

  const { data: examCard } = useGetExamCardByBookIdAndExamId({
    variables: { bookId, examId },
  });

  const [backToExam, { loading: backToExamLoading }] = useBackToExam();

  const handleBackToExamRoom = async () => {
    // It is possible when the users entry from mybooks path, examId will be examCardId instead
    const examCardId = examCard?.qmsGetExamCardByBookIdAndExamId.id ?? examId;

    if (!examCardId) return; //TODO: Do someting if there is no exam card id

    sendDataToGTM({
      event: 'public_examination_instruction_cta_click',
      buttonCategory: 'continue examination',
    });

    try {
      await backToExam({
        variables: { examCardId: examCardId, isReset: false },
      });
      const path = `/publicbooks/${bookId}/examinations/${examId}/examroom`;
      navigate(path, { replace: true });
    } catch (error) {
      console.error('Back to examination error: ', error);
    }
  };

  return (
    <>
      <Button
        sx={{ maxWidth: { xs: 1, sm: '343px' }, width: 1 }}
        size={'large'}
        onClick={open}
        disabled={backToExamLoading}
        {...props}
      >
        ทำข้อสอบต่อ
        <ArrowForwardIcon sx={{ ml: 0.5 }} />
      </Button>
      <ResumeExaminationModal open={isOpen} onCancel={close} onResume={handleBackToExamRoom} />
      <Loading open={backToExamLoading} />
    </>
  );
};

export default ContinuePublicExaminationButton;
