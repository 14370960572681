import { alpha, styled } from '@mui/material';

import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle as BaseModalTitle,
  Text,
} from '../../../../Components/Base';

export type ConfirmationSignOutModalProps = {
  onCancel: () => void;
  onSignOut: () => void;
} & Omit<ModalProps, 'onClose'>;

const ModalTitle = styled(BaseModalTitle)(({ theme }) => ({
  '.MuiIconButton-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[300], 0.4),
    },
  },
}));

const ConfirmationSignOutModal = (props: ConfirmationSignOutModalProps) => {
  const { onSignOut, onCancel, ...restProps } = props;
  return (
    <Modal fullWidth maxWidth={'xs'} {...restProps}>
      <ModalTitle onClose={onCancel} />
      <ModalContent>
        <Text variant={'h2'} align="center" whiteSpace={'pre-line'}>
          {`คุณแน่ใจใช่ไหมว่า
          ต้องการออกจากระบบ?`}
        </Text>
      </ModalContent>
      <ModalFooter flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={2} px={3} pb={3}>
        <Button
          fullWidth
          variant={'outlined'}
          color={'greyButton'}
          size={'large'}
          onClick={onCancel}
        >
          ยกเลิก
        </Button>
        <Button fullWidth size={'large'} onClick={onSignOut}>
          ออกจากระบบ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationSignOutModal;
