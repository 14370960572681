import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import LoLButton, { LoLButtonProps } from '../../../../Components/Base/LoLButton';
import Loading from '../../../../Components/Loading';
import useRedeemPublicBook from '../../../../Domains/useRedeemPublicBook';
import useStartExamination from '../../../../Domains/useStartExamination';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';

type StartLoLExaminationButtonProps = LoLButtonProps & {
  alreadyRedeem?: boolean;
  examCardIdByBookIdAndExamId?: string;
};

const StartLoLExaminationButton = (props: StartLoLExaminationButtonProps) => {
  const { alreadyRedeem, examCardIdByBookIdAndExamId, ...restProps } = props;
  const { bookId = '', examId = '' } = useParams();
  const { t } = useTranslation('lolCommon');
  const navigate = useNavigate();
  const sendDataToGTM = useGoogleTagManager();

  const [redeemPublicBook, { loading: redeemBookLoading }] = useRedeemPublicBook();
  const [startExamination, { loading: startExaminationLoading }] = useStartExamination();

  const handleRedeemPublicBook = async () => {
    try {
      const result = await redeemPublicBook({
        variables: { bookId, isGenerateRedeemCode: false },
      });
      return result.data?.qmsRedeemPublicBook?.find((exam) => exam.skuExamItem?.examId == examId)
        ?.id;
    } catch (error) {
      console.error('Redeem public book error: ', error);
    }
  };

  const handleEnterExamRoom = async () => {
    let examCardId = examCardIdByBookIdAndExamId;
    if (!alreadyRedeem) {
      examCardId = await handleRedeemPublicBook();
    }

    if (examCardId === null || examCardId === undefined) return;
    //TODO: Handle GA
    // sendDataToGTM({
    //   event: 'lol_examination_instruction_cta_click',
    //   buttonCategory: 'start examination',
    // });

    try {
      await startExamination({
        variables: { examCardId, isSetTimer: false },
      });
      navigate('instruction');
    } catch (error) {
      console.error('Start examination error: ', error);
    }
  };

  const isLoading = startExaminationLoading || redeemBookLoading;

  return (
    <>
      <LoLButton
        fullWidth
        size={'large'}
        onClick={handleEnterExamRoom}
        disabled={isLoading}
        {...restProps}
      >
        {t('BUTTON.START')}
      </LoLButton>
      <Loading open={isLoading} />
    </>
  );
};

export default StartLoLExaminationButton;
