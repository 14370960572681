import { Box, Flex, Paper, Text, Tooltip, Skeleton } from '../../../../Components/Base';
import InfoOutlinedIcon from '../../../../Components/Icons/InfoOutlinedIcon';
import TimerOutlinedIcon from '../../../../Components/Icons/TimerOutlinedIcon';

const ExaminationTimerLoading = () => {
  return (
    <Paper
      sx={{
        mb: { xs: 3, sm: 4 },
        p: { xs: 2, md: 3 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      <Flex>
        <Box width={73}>
          <Skeleton width={44} height={24} sx={{ my: 1 }} />
        </Box>
        <Box>
          <Flex alignItems={'center'}>
            <Text variant="subtitle1" mr={1}>
              โหมดจับเวลาเสมือนจริง
            </Text>
            <Tooltip
              arrow
              PopperProps={{
                sx: {
                  textAlign: 'center',
                },
              }}
              placement="left"
              title={
                <Text variant="caption" whiteSpace={'pre-line'}>
                  {`หาก “เปิด” เวลาจะนับถอยหลัง
                เมื่อเวลาหมดระบบจะบังคับส่งอัตโนมัติ
                (เหมาะกับฝึกเสมือนจริง)
                หาก “ปิด” จะไม่มีการจับเวลา
                และส่งเมื่อไหร่ก็ได้`}
                </Text>
              }
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </Flex>
          <Flex alignItems={'center'} color={'text.secondary'}>
            <TimerOutlinedIcon fontSize={'small'} sx={{ mr: 0.5 }} />
            <Skeleton variant={'text'} width={104} height={20} />
          </Flex>
        </Box>
      </Flex>
    </Paper>
  );
};

export default ExaminationTimerLoading;
