import { Alert, AlertTitle, Snackbar, Text, SnackbarProps } from '../../../Components/Base';
import ErrorOutlineIcon from '../../../Components/Icons/ErrorOutlineIcon';

const ImportErrorSnackbar = (props: SnackbarProps) => (
  <Snackbar {...props}>
    <Alert color={'error'} icon={<ErrorOutlineIcon />}>
      <AlertTitle>ไม่สามารถดำเนินการได้ขณะนี้</AlertTitle>
      <Text variant={'body2'} color={'text.secondary'}>
        กรุณาลองใหม่อีกครั้ง
      </Text>
    </Alert>
  </Snackbar>
);

export default ImportErrorSnackbar;
