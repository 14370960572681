import { SvgIcon, SvgIconProps } from '@mui/material';

const HeadStarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 33 36" {...props}>
      <path
        fill="inherit"
        d="M18.164 3.273c6.396 0 11.54 5.138 11.54 11.454 0 4.582-2.688 8.493-6.595 10.326v7.674H11.57v-4.909H9.922a3.274 3.274 0 0 1-3.297-3.273v-4.909H4.152c-.692 0-1.088-.818-.692-1.325l3.165-4.14a11.423 11.423 0 0 1 3.573-7.741 11.59 11.59 0 0 1 7.966-3.157Zm0-3.273C10.598 0 4.334 5.596 3.427 12.927L.855 16.364H.806l-.033.049A4.02 4.02 0 0 0 .46 20.65a4.172 4.172 0 0 0 2.868 2.16v1.735c0 3.027 2.11 5.596 4.945 6.332V36h18.133v-9C30.527 24.267 33 19.735 33 14.727 33 6.595 26.34 0 18.164 0Zm7.138 13.582-3.231.834 2.374 2.39c.577.556.577 1.505 0 2.078-.577.572-1.533.572-2.094 0l-2.39-2.357-.857 3.207a1.44 1.44 0 0 1-1.764 1.048 1.504 1.504 0 0 1-.925-.684 1.482 1.482 0 0 1-.163-1.133l.873-3.207-3.23.867a1.512 1.512 0 0 1-1.578-.552 1.483 1.483 0 0 1-.252-.527c-.198-.737.264-1.555 1.055-1.752l3.23-.85-2.373-2.373a1.466 1.466 0 0 1 .067-2.012 1.489 1.489 0 0 1 2.026-.066l2.407 2.356.841-3.207a1.454 1.454 0 0 1 1.797-1.047 1.458 1.458 0 0 1 1.055 1.8l-.874 3.223 3.248-.867a1.471 1.471 0 0 1 1.813 1.047 1.442 1.442 0 0 1-1.055 1.784Z"
      />
    </SvgIcon>
  );
};

export default HeadStarIcon;
