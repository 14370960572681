import { SvgIcon, SvgIconProps } from '@mui/material';

const SmilingFaceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="#FFDD67"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      />
      <path
        fill="#664E27"
        d="M7.4 11.84a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM16.6 11.84a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM17.04 15.32c-3.24 2.28-6.84 2.24-10.08 0-.4-.28-.72.2-.48.64 1 1.6 2.96 3.08 5.52 3.08 2.56 0 4.52-1.44 5.52-3.08.24-.44-.08-.92-.48-.64Z"
      />
    </SvgIcon>
  );
};

export default SmilingFaceIcon;
