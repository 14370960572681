import { Helmet } from 'react-helmet';
import { Divider } from '@mui/material';
import { useLocation, useNavigate, useParams, Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import useGenerateSignedFileUrl from '../../Domains/useGenerateSignedFileUrl';
import { Flex, Tabs, Tab, Paper, TabsProps, Skeleton } from '../../Components/Base';
import BookDetails from '../../Components/BookDetails';
import useGetBookDetails from '../../Domains/useGetBookDetails';
import { getAuthorString } from '../../Domains/Book/utils';
import PageTitleLayout from '../PageTitleLayout';
import NotFoundError from '../../Components/NotFoundError';
import { RESPONSE_TYPE } from '../../Domains/constants';
import DataLoadingError from '../../Components/DataLoadingError';
import { setPageTitle } from '../../Utils/page';
import useGoogleTagManager from '../../Utils/useGoogleTagManager';

const itemLists = [
  {
    label: 'ทำข้อสอบ',
    value: 'examinations',
  },
  {
    label: 'ผลสอบ',
    value: 'results',
  },
  {
    label: 'เฉลย',
    value: 'solutions',
  },
];

const BookDetailsLoading = () => (
  <Flex width={1} py={3}>
    <Skeleton width={'75px'} height={'100px'} sx={{ borderRadius: 0.5 }} />
    <Flex flexDirection={'column'} justifyContent={'space-between'} ml={2} width={1}>
      <Skeleton width={'100%'} variant={'text'} />
      <Skeleton width={'75%'} variant={'text'} />
      <Flex justifyContent={'space-between'}>
        <Skeleton width={'100px'} height={'30px'} sx={{ borderRadius: 3.5 }} />
        <Skeleton width={'100px'} height={'30px'} sx={{ borderRadius: 3.5 }} />
      </Flex>
    </Flex>
  </Flex>
);

const BookLayout = ({ children }: { children?: React.ReactChild }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { bookId = '' } = useParams();

  const sendDataToGTM = useGoogleTagManager();

  const { data, loading, error, refetch } = useGetBookDetails({ variables: { bookId } });
  const bookDetails = data?.qmsGetBookDetailsByBookId;

  const activePathName = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const activeTab = itemLists.map(({ value }) => value).includes(activePathName)
    ? activePathName
    : 'examinations';

  useEffect(() => {
    if (activeTab) {
      sendDataToGTM({ event: `book_details_${activeTab}_tab` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleChange: TabsProps['onChange'] = (
    event: React.SyntheticEvent<Element, Event>,
    tabValue: string
  ) => {
    navigate(`./${bookId}/${tabValue}`);
  };

  const handleDownloadLinkTracking = (bookTitle: string) => {
    sendDataToGTM({ event: 'book_download_link_button_click', bookTitle });
  };

  const [signedFileUrl] = useGenerateSignedFileUrl();
  const generateDownloadLink = () => {
    signedFileUrl({
      variables: { bookId },
      onCompleted(data) {
        if (data?.qmsGenerateBookFileUrlByBookId) {
          window.location.href = data.qmsGenerateBookFileUrlByBookId;
        }
      },
    });
  };

  const hasDownloadUrl = bookDetails?.file?.key && bookDetails?.file?.bucket;

  const backToHome = () => {
    navigate('/');
  };

  if (error) {
    if (error.message === RESPONSE_TYPE.NETWORK_ERROR) {
      return <DataLoadingError onRetry={() => refetch()} mt={9} />;
    }
    if (error.graphQLErrors[0].extensions?.exception?.httpStatus === RESPONSE_TYPE.NOT_FOUND) {
      return <NotFoundError open={true} onBackToHome={backToHome} />;
    }
  }

  if (data?.qmsGetBookDetailsByBookId.status === 'COMING_SOON') {
    return <NotFoundError open={true} onBackToHome={backToHome} />;
  }

  return (
    <>
      <PageTitleLayout title={'รายละเอียดหนังสือ'} onBack={() => navigate('/mybooks')}>
        <Paper
          sx={{
            px: { xs: 2, sm: 3 },
            borderRadius: { xs: 0, sm: 1 },
            mb: 3,
          }}
        >
          {loading && <BookDetailsLoading />}
          {bookDetails && (
            <>
              <Helmet>
                <title>{setPageTitle(`หนังสือ | ${bookDetails.title}`)}</title>
              </Helmet>
              {/* TODO: Should have default image for cover image, and add book url to download file */}

              <BookDetails
                py={3}
                author={getAuthorString(bookDetails?.authors ?? [])}
                imageUrl={bookDetails?.coverImageUrl ?? ''}
                refNo={bookDetails?.refCode ?? ''}
                title={bookDetails.title}
                onDownload={
                  hasDownloadUrl
                    ? () => {
                        handleDownloadLinkTracking(bookDetails.title);
                        generateDownloadLink();
                      }
                    : undefined
                }
              />
            </>
          )}
          <Divider />
          <Tabs onChange={handleChange} value={activeTab}>
            {itemLists?.map((item, index): JSX.Element => {
              return (
                <Tab
                  key={index}
                  value={item.value}
                  label={item.label}
                  iconPosition="start"
                  sx={{ width: `${100 / itemLists.length}%` }}
                />
              );
            })}
          </Tabs>
        </Paper>
        {children ?? <Outlet />}
      </PageTitleLayout>
    </>
  );
};

export default BookLayout;
