import { InfoRounded } from '@mui/icons-material';
import { Divider, Skeleton, styled } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ModalTitle,
  Flex,
  Modal,
  ModalContent,
  ModalProps,
  Text,
  TextInput,
  Box,
  Image,
} from '../../Components/Base';
import LoLButton from '../../Components/Base/LoLButton';
import LoadingIcon from '../../Components/Icons/LoadingIcon';
import { useConfig } from '../../Contexts/ConfigProvider';
import {
  useGetQmsMyBookMetadataQuery as useGetMyBookMetadata,
  useQmsUnlockExaminationByRedeemCodeMutation as useUnlockExaminationByRedeemCode,
} from '../../generated/graphql';
import FixedMobileLayout from '../FixedMobileLayout';

type LoLRedeemCodeModalProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
} & Omit<ModalProps, 'onClose' | 'open' | 'onSubmit'>;

enum UNLOCK_ERROR_CODE {
  NOT_FOUND_SKU_REDEEM_CODE = 'NOT_FOUND_SKU_REDEEM_CODE',
  SKU_REDEEM_CODE_HAS_BEEN_USED = 'SKU_REDEEM_CODE_HAS_BEEN_USED',
}

/*TODO: Polish ui styling*/
const TooltipLink = styled('a')(({ theme }) => ({
  color: theme.palette.lolText?.primary,
}));

const LoLRedeemCodeModal = (props: LoLRedeemCodeModalProps) => {
  const { open, onSubmit: handleSubmit, onClose: handleClose, ...restProps } = props;
  const { bookId = '' } = useParams<{ bookId: string }>();
  const [redeemCode, setRedeemCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation('lolExamroom');
  const { data, loading: metadataLoading } = useGetMyBookMetadata({
    skip: !bookId,
    variables: { bookId },
  });
  const { provider = '' } = data?.qmsGetBookDetailsByBookId?.metadata;

  const {
    lolExamRoom: { unlockCodeUrlByProvider = {} },
    lol: { redeemImageInstructionUrl = {} },
  } = useConfig();

  const [unlock, { loading: unlockLoading }] = useUnlockExaminationByRedeemCode();

  const unlockCodeUrl = unlockCodeUrlByProvider.hasOwnProperty(provider)
    ? unlockCodeUrlByProvider[provider]
    : '';
  const redeemImageInstruction = redeemImageInstructionUrl.hasOwnProperty(provider)
    ? redeemImageInstructionUrl[provider]
    : '';

  const debouncedUnlockExam = debounce(async () => {
    await unlock({
      variables: { redeemCode, bookId },
      onError: (error) => {
        const errorCode = error?.graphQLErrors?.[0]?.extensions?.exception?.error?.code;
        if (errorCode === UNLOCK_ERROR_CODE.NOT_FOUND_SKU_REDEEM_CODE) {
          setErrorMessage(t('REDEEM.ERROR.NOT_FOUND'));
        } else if (errorCode === UNLOCK_ERROR_CODE.SKU_REDEEM_CODE_HAS_BEEN_USED) {
          setErrorMessage(t('REDEEM.ERROR.USED'));
        } else {
          setErrorMessage(t('REDEEM.ERROR.COMMON'));
        }
      },
      onCompleted: (result) => {
        if (result?.qmsUnlockExaminationByRedeemCode?.id) {
          handleSubmit();
        }
      },
    });
  }, 500);

  const handleUnlockExamination = useCallback(async () => {
    debouncedUnlockExam();
  }, [debouncedUnlockExam]);

  const handleClick = useCallback(() => {
    handleUnlockExamination();
  }, [handleUnlockExamination]);

  const handleCloseModal = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') {
        setRedeemCode('');
        setErrorMessage('');
        handleClose();
      }
      return;
    },
    [handleClose]
  );

  /*TODO: Handle call every update answer after pass trial*/

  return (
    <Modal
      fullScreen
      open={open}
      PaperProps={{ sx: { bgcolor: 'transparent' } }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        '& .MuiPaper-root': {
          color: 'lolText.primary',
          boxShadow: 'none',
        },
      }}
      onClose={handleCloseModal}
      {...restProps}
    >
      <FixedMobileLayout>
        <Box
          minHeight={1}
          height={'auto'}
          overflow={'auto'}
          bgcolor={'lolBackground.primary'}
          color={'lolText.primary'}
        >
          <ModalTitle
            sx={{
              fontSize: '16px',
              '& .MuiIconButton-root': {
                bgcolor: 'transparent',
              },
              '& svg': {
                color: 'lolLine.line1',
              },
            }}
            onClose={handleClose}
          >
            {t('REDEEM.PREFIX')} {provider === 'TARO' ? provider : 'Unlock'} {t('REDEEM.SUFFIX')}
          </ModalTitle>
          <ModalContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              '& img': {
                alignSelf: 'center',
              },
            }}
          >
            <Flex flexDirection={'column'}>
              <Divider sx={{ mb: 2, borderStyle: 'dashed', borderColor: 'lolLine.line1' }} />
              {redeemImageInstruction && (
                <Image
                  src={redeemImageInstruction}
                  alt={'personality-logo'}
                  height={'auto'}
                  width={'342px'}
                  mb={2}
                  px={{ xs: 0, md: 3 }}
                  style={{
                    objectFit: 'contain',
                  }}
                />
              )}
              <TextInput
                placeholder={t('REDEEM.PLACEHOLDER')}
                onChange={(e) => {
                  setErrorMessage('');
                  setRedeemCode(e?.target?.value);
                }}
                sx={{
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    color: 'lolText.secondary',
                    borderColor: 'lolLine.line2',
                  },
                }}
              />
              {errorMessage !== '' && (
                <Text
                  textAlign={'right'}
                  display={'block'}
                  variant="caption"
                  color={'red'}
                  sx={{ mt: 0.5, width: '100%' }}
                >
                  {errorMessage}
                </Text>
              )}
            </Flex>
            <Box>
              {/* TODO: Handle button state */}
              <LoLButton
                variant={'contained'}
                disabled={redeemCode === ''}
                fullWidth
                onClick={handleClick}
                startIcon={unlockLoading && <LoadingIcon />}
                sx={{
                  mt: 4,
                  mb: 2,
                  p: '16px 24px',
                  fontSize: '20px',
                  fontWeight: '400',
                  lineHeight: '1.4',
                  '&.Mui-disabled': {
                    bgcolor: 'lolButton.secondary36',
                    color: 'lolTextButton.contrast36',
                  },
                }}
              >
                {t('REDEEM.SUBMIT')}
              </LoLButton>
              {metadataLoading ? (
                <Skeleton variant="text" width={'100%'} />
              ) : (
                unlockCodeUrl && (
                  <Flex justifyContent={'center'} alignItems={'center'} mb={2}>
                    <InfoRounded sx={{ mr: 1, color: 'lolLine.line2' }} fontSize="small" />
                    <TooltipLink href={unlockCodeUrl} target="_blank">
                      <Text fontSize={'14px'} fontWeight={300}>
                        {t('REDEEM.HOW_TO')}
                      </Text>
                    </TooltipLink>
                  </Flex>
                )
              )}
            </Box>
          </ModalContent>
        </Box>
      </FixedMobileLayout>
    </Modal>
  );
};

export default LoLRedeemCodeModal;
