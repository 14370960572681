import {
  useQmsFoMyExamPapersQuery as useGetExaminationResultsByExamCardId,
  QmsFoMyExamPapersQuery as QmsFoMyExamPapersQueryType,
  QmsExamPaperStatus,
  QmsOrderType,
} from '../../generated/graphql';

export default useGetExaminationResultsByExamCardId;
export { QmsExamPaperStatus, QmsOrderType };
export type { QmsFoMyExamPapersQueryType };
