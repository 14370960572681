import { Box, BoxProps, styled, useTheme } from '@mui/material';

import { Flex, Text } from '../../../../Components/Base';

import WaterWaveAnimation from './WaterWaveAnimation';

type TraitItemProps = {
  percentage: number;
  diffPercentage: number;
  showDiff?: boolean;
  icon?: JSX.Element;
} & BoxProps;

//Custom breakpoints
const TraitItemWrapper = styled(Box)`
  min-width: 120px;
  div.water-wave-wrapper {
    margin-bottom: 16px;
  }
  padding-top: 16px;
  padding-bottom: 16px;
  @media only screen and (max-width: 389px) {
    min-width: 106px;
    padding-bottom: 8px;
    div.water-wave-wrapper {
      margin-bottom: 4px;
    }
  }
`;

const TraitItem = (props: TraitItemProps) => {
  const { icon, percentage, diffPercentage, showDiff, ...restProps } = props;
  const theme = useTheme();

  const fillColor = theme.palette.lolElement?.primary ?? theme.palette.primary.main;

  const isPositiveInteger = diffPercentage && diffPercentage > 0;

  return (
    <TraitItemWrapper
      border={'1px solid'}
      borderColor={'lolElement.gray'}
      px={1.7}
      position={'relative'}
      {...restProps}
    >
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Flex
          className="water-wave-wrapper"
          position={'relative'}
          justifyContent={'center'}
          alignItems={'center'}
          width={56}
          height={56}
          borderRadius={'100%'}
          overflow={'hidden'}
          bgcolor={'lolElement.gray'}
          color={'lolIcon.primary'}
        >
          <WaterWaveAnimation percentage={percentage} fillColor={fillColor} />
          {icon}
        </Flex>
        <Text variant="caption" color={'lolText.secondary'}>
          {percentage}%
        </Text>
      </Flex>
      <Box
        position={'absolute'}
        top={4}
        right={4}
        p={0.5}
        borderRadius={0.5}
        bgcolor={'lolElement.primary'}
        visibility={showDiff ? 'visible' : 'hidden'}
        sx={{
          opacity: showDiff ? 1 : 0,
          transition: showDiff
            ? 'visibility 0s, opacity 0.3s ease-in-out'
            : 'visibility 1s linear, opacity 0.3s ease-in-out',
        }}
      >
        <Text fontSize={8} fontWeight={600} color={'lolCard.primary'}>
          {`${isPositiveInteger ? '+' : ''}${diffPercentage}%`}
        </Text>
      </Box>
    </TraitItemWrapper>
  );
};

export default TraitItem;
