import { alpha, styled } from '@mui/material';

import {
  Button,
  Modal,
  ModalProps,
  ModalContent,
  ModalFooter,
  Text,
  ModalTitle as BaseModalTitle,
} from '../../../../Components/Base';

const ModalTitle = styled(BaseModalTitle)(({ theme }) => ({
  '.MuiIconButton-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[300], 0.4),
    },
  },
}));

export type ExaminationSubmitModalProps = {
  onSubmit: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
} & ModalProps;

const ExaminationSubmitModal = ({
  onSubmit,
  onCancel,
  isSubmitting = false,
  ...restProps
}: ExaminationSubmitModalProps) => {
  return (
    <Modal fullWidth maxWidth={'xs'} {...restProps}>
      <ModalTitle onClose={onCancel} />
      <ModalContent sx={{ p: 3 }}>
        <Text variant={'h2'} align="center" color="text.primary" mb={1}>
          ส่งข้อสอบ
        </Text>
        <Text align="center">คุณยืนยันที่จะส่งข้อสอบใช่หรือไม่</Text>
      </ModalContent>
      <ModalFooter
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        display={'grid'}
        gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }}
        gap={2}
        px={3}
        pb={3}
      >
        <Button
          fullWidth
          variant={'outlined'}
          color={'greyButton'}
          size={'large'}
          onClick={onCancel}
          disabled={isSubmitting}
        >
          ยกเลิก
        </Button>
        <Button fullWidth size={'large'} onClick={onSubmit} disabled={isSubmitting}>
          ส่งข้อสอบ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExaminationSubmitModal;
