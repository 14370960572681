import { Fragment } from 'react';
import { Skeleton, styled } from '@mui/material';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { Box } from '../../../Components/Base';
import { getAuthorString } from '../../../Domains/Book/utils';
import EmptyMyBookResult from '../Components/EmptyMyBookResult';
import MyBookItem from '../Components/MyBookItem';
import useDisclosure from '../../../Utils/useDisclosure';
import useGetMyBooks from '../../../Domains/useGetMyBooks';
import DataLoadingError from '../../../Components/DataLoadingError';
import useGoogleTagManager from '../../../Utils/useGoogleTagManager';
import { GetQmsMyBookDetailsQuery } from '../../../generated/graphql';

import ImportBookModal from './ImportBookModal';

const Link = styled(RouterLink)({
  color: 'inherit',
  textDecoration: 'none',
});

const LinkWrapper = ({
  isComingBook = false,
  children,
  ...restProps
}: { isComingBook?: boolean } & LinkProps) => {
  if (isComingBook) return <Box children={children} />;
  return <Link children={children} {...restProps} />;
};

const MyBookList = () => {
  const {
    isOpen: isRedeemBookOpen,
    open: openRedeemBook,
    close: closeRedeemBook,
  } = useDisclosure();
  const { data, loading, error, refetch } = useGetMyBooks();
  const sendDataToGTM = useGoogleTagManager();
  const myBooks = data?.qmsMyBooks;

  if (loading) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} sx={{ mt: index === 0 ? 0 : 2 }} width={'100%'} height={132} />
        ))}
      </>
    );
  }

  if (error?.networkError) {
    return <DataLoadingError onRetry={() => refetch()} mt={9} />;
  }

  if (myBooks && myBooks.length === 0) {
    return (
      <>
        <EmptyMyBookResult onRedeemBook={openRedeemBook} />
        <ImportBookModal isOpen={isRedeemBookOpen} onClose={closeRedeemBook} />
      </>
    );
  }

  const handleGoToBookDetails = (book: GetQmsMyBookDetailsQuery['qmsGetBookDetailsByBookId']) => {
    const { title, skuCode } = book;
    sendDataToGTM({
      event: 'book_item_click',
      skuCode,
      bookTitle: title,
    });
  };

  return (
    <>
      {myBooks &&
        myBooks.map((book, index: number) => {
          const { id, coverImageUrl, title, authors, skuCode } = book;
          const authorString = getAuthorString(authors ?? []);
          const isComingBook = book.status === 'COMING_SOON';

          return (
            <Fragment key={`book-${skuCode}`}>
              <Box mt={index === 0 ? 0 : 2} />
              <LinkWrapper isComingBook={isComingBook} to={`/mybooks/${id}`}>
                <MyBookItem
                  title={title}
                  imageUrl={coverImageUrl ?? ''} // TODO: Should have default image for cover image
                  refNo={skuCode}
                  author={authorString}
                  isComing={isComingBook}
                  onClick={() => handleGoToBookDetails(book)}
                />
              </LinkWrapper>
            </Fragment>
          );
        })}
    </>
  );
};

export default MyBookList;
