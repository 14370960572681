import { useTranslation } from 'react-i18next';

import { Box, Flex, FlexProps, Text } from '../../../../Components/Base';
import { MilestoneCardAction } from '../../types';

type MilestoneCardProps = {
  content?: JSX.Element;
  questionCount?: number;
  duration?: number;
  status?: MilestoneCardAction;
  rightAdorment?: JSX.Element;
} & FlexProps;

const MilestoneCard = (props: MilestoneCardProps) => {
  const {
    content = <></>,
    questionCount = 0,
    duration = 0,
    rightAdorment = <></>,
    ...flexProps
  } = props;
  const { t } = useTranslation('lolCommon');

  return (
    <Flex
      p={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      borderRadius={1}
      border={'1px solid'}
      borderColor={'lolLine.line1'}
      bgcolor={'lolCard.primary'}
      maxWidth={'300px'}
      flex={1}
      {...flexProps}
    >
      <Box mr={rightAdorment ? 2 : 0}>
        <Text variant="caption">{t('EXAM_PREFIX', { ns: 'lolMilestones' })}</Text>
        {content}
        <Text
          variant="caption"
          color="lolText.secondary"
          lineHeight={1.25}
          component={'div'}
          marginTop={1}
        >
          {`${t('UNIT.TOTAL')} ${questionCount} ${t('UNIT.QUESTIONS')}, ~ ${duration} ${t(
            'UNIT.MINUTES'
          )}`}
        </Text>
      </Box>
      {rightAdorment}
    </Flex>
  );
};

export default MilestoneCard;
