import { SvgIcon, SvgIconProps } from '@mui/material';

const LikeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 38 38" {...props}>
      <path
        fill="inherit"
        d="m23.631 15.973-1.866-.312a1.892 1.892 0 0 0 1.866 2.204v-1.892Zm-16.648 0v-1.892a1.893 1.893 0 0 0-1.892 1.892h1.892ZM10.01 33h17.193v-3.784H10.01V33Zm19.01-18.919H23.63v3.784h5.389V14.08Zm-3.523 2.204 1.22-7.32-3.732-.622-1.22 7.318 3.732.624ZM23.36 5h-.324v3.784h.324V5Zm-5.676 2.865-3.81 5.71 3.149 2.1 3.808-5.712-3.147-2.098Zm-4.752 6.216H6.983v3.784h5.948V14.08Zm-7.84 1.892v12.108h3.784V15.973H5.09Zm26.936 13.072 1.816-9.08-3.71-.742-1.816 9.08 3.71.742Zm-18.153-15.47a1.129 1.129 0 0 1-.943.506v3.784a4.919 4.919 0 0 0 4.09-2.19l-3.147-2.1Zm12.843-4.61A3.405 3.405 0 0 0 23.36 5v3.784a.386.386 0 0 1-.29-.134.38.38 0 0 1-.084-.307l3.732.622Zm2.303 8.9a1.134 1.134 0 0 1 1.113 1.358l3.71.741a4.92 4.92 0 0 0-4.822-5.883v3.784h-.002ZM27.203 33a4.919 4.919 0 0 0 4.824-3.955l-3.71-.741a1.136 1.136 0 0 1-1.114.912V33ZM23.035 5a6.432 6.432 0 0 0-5.352 2.865l3.147 2.098a2.649 2.649 0 0 1 2.205-1.18V5ZM10.01 29.216a1.135 1.135 0 0 1-1.135-1.135H5.09A4.919 4.919 0 0 0 10.01 33v-3.784Z"
      />
      <path stroke="currentColor" strokeWidth={2.5} d="M13.037 15.973v15.135" />
    </SvgIcon>
  );
};

export default LikeIcon;
