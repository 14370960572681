import { Paper, PaperProps } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import paletteTheme from '../Theme/paletteTheme';

const paperTheme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 4,
        sx: {
          bgcolor: paletteTheme.palette.common.white,
        },
      },
    },
  },
});

export type { PaperProps };
export { paperTheme };
export default Paper;
