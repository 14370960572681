import { Alert, AlertTitle, Box, Text } from '../../../../Components/Base';
import TimerOutlinedIcon from '../../../../Components/Icons/TimerOutlinedIcon';

const AlertView = () => {
  return (
    <>
      <Alert color="info" icon={<TimerOutlinedIcon />}>
        <AlertTitle>เหลือเวลาอีก 30 นาที</AlertTitle>
        <Text variant={'body2'} color={'text.secondary'}>
          ในการทำข้อสอบ เร่งมือหน่อยนะ
        </Text>
      </Alert>
      <Box mt={2} />
      <Alert color="warning" icon={<TimerOutlinedIcon />}>
        <AlertTitle>เหลือเวลาอีก 15 นาที</AlertTitle>
        <Text variant={'body2'} color={'text.secondary'}>
          ในการทำข้อสอบ เร่งมือหน่อยนะ
        </Text>
      </Alert>
      <Box mt={2} />
      <Alert color="error" icon={<TimerOutlinedIcon />}>
        <AlertTitle>เหลือเวลาอีก 5 นาที</AlertTitle>
        <Text variant={'body2'} color={'text.secondary'}>
          ในการทำข้อสอบ เร่งมือหน่อยนะ
        </Text>
      </Alert>
    </>
  );
};

export default AlertView;
