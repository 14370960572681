import { createContext, useContext, useEffect, useState } from 'react';

import Loading from '../../Components/Loading';
import Maintenance from '../../Components/Maintenance';
import firebase, { firebaseRemoteConfig } from '../../Libs/firebase';
import { defaultConfig as initialConfig, mergeConfigOverrides } from '../../Utils/config';

type InitialConfigType = typeof initialConfig;

interface IConfigContext extends Partial<InitialConfigType> {
  [key: string]: any;
}

declare global {
  interface Window {
    appConfig: any;
  }
}

const remoteConfig = firebaseRemoteConfig.getRemoteConfig(firebase);
remoteConfig.settings.minimumFetchIntervalMillis =
  process.env.NODE_ENV === 'production' ? 3600000 : 3000;

remoteConfig.defaultConfig = {
  version: 0,
  logoUrl: '/assets/logo/TCASterLogo.svg',
  facebookConfig: JSON.stringify({
    facebookLogoUrl: '/assets/logo/facebookLogo.svg',
    facebookPortalUrl: 'https://api.dev.user.examhub.staging.tcaster.net/user/api/login/facebook',
  }),
  googleConfig: JSON.stringify({
    googleLogoUrl: '/assets/logo/googleLogo.svg',
    googlePortalUrl: 'https://api.dev.user.examhub.staging.tcaster.net/user/api/login/google',
  }),
  otpConfig: JSON.stringify({
    otpUrl: 'https://asia-southeast1-lpc-qms-exam-hub-staging.cloudfunctions.net',
    sendOtpAPI: 'send-otp',
    verifyOtpAPI: 'verify-otp',
  }),
  appShell: JSON.stringify({
    sideNavMenuItems: [
      { label: 'หนังสือของฉัน', url: '/', iconName: 'BOOK_ICON' },
      { label: 'สอบถามและแจ้งปัญหา', url: '/help-center', iconName: 'HELP_ICON' },
    ],
  }),
  googletagmanager: JSON.stringify([
    { gtmId: 'GTM-TPPDGH2', auth: '4cmMqYeAW5bWPEtnxfE1VA', preview: 'env-1' },
  ]),
  examroom: JSON.stringify({
    enabledCBT: false,
  }),
  lol: JSON.stringify({
    imgPrefixPath: '/assets/logo',
    lolLogoUrl: 'lolLogo.png',
    learnCorpLogoUrl: 'learnCorpLogo.svg',
    taroLogoUrl: 'taroLogo.svg',
    personalityLogoUrl: 'personality-test.svg',
    lolResultLogoUrl: 'lolResultLogo.svg',
    sharingWebUrl: 'https://staging-qms-sharing-web-vn5rqii6eq-as.a.run.app/lol-share',
    providerLogo: {
      DEFAULT: 'lolLogoUrl',
    },
    logosByProvider: {
      DEFAULT: ['learnCorpLogoUrl'],
    },
    redeemImageInstructionUrl: {
      TARO: '/assets/lol/how-to-get-taro-redeem-code.svg',
    },
  }),
  lolExamRoom: JSON.stringify({
    enabledRedeemCodeModal: false,
    unlockCodeUrlByProvider: {},
  }),
  solutions: JSON.stringify({
    enableGoogleDriveLinkFix: false,
  }),
};

const ConfigContext = createContext<IConfigContext>({});

const resolveValue = (remoteConfigValue: string) => {
  const remoteConfigValueAsNumber = Number(remoteConfigValue);

  if (!Number.isNaN(remoteConfigValueAsNumber)) return remoteConfigValueAsNumber;

  try {
    const remoteConfigValueAsJSON = JSON.parse(remoteConfigValue);
    return remoteConfigValueAsJSON;
  } catch (error) {
    return remoteConfigValue;
  }
};

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useState<IConfigContext>(
    mergeConfigOverrides(window?.appConfig, { appState: 'INITIALIZE' })
  );

  useEffect(() => {
    firebaseRemoteConfig
      .ensureInitialized(remoteConfig)
      .then(() => console.log('Firebase Remote Config is initialized'))
      .catch((error: Error) => console.error('Firebase Remote Config failed to initialize', error))
      .then(() =>
        firebaseRemoteConfig
          .fetchAndActivate(remoteConfig)
          .then(() => {
            const appConfigValue: Record<string, unknown> = {};
            const remoteConfigValus = firebaseRemoteConfig.getAll(remoteConfig);

            Object.entries(remoteConfigValus).forEach(([key, value]) => {
              const remoteConfigValue = (value as firebaseRemoteConfig.Value & { _value: string })
                ._value;
              const resolvedConfig = resolveValue(remoteConfigValue);
              appConfigValue[key] = resolvedConfig;
            });

            setConfig((prev) => mergeConfigOverrides(appConfigValue, prev));
          })
          .catch((error) => {
            console.error(error);
            setConfig((prev) =>
              mergeConfigOverrides(remoteConfig.defaultConfig, { appState: 'MAINTENANCE' })
            );
          })
      );
  }, []);

  if (config.appState === 'MAINTENANCE') {
    return <Maintenance />;
  }

  if (config.appState === 'INITIALIZE') return <Loading open />;

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within ConfigContext');
  }
  return context;
};

export { ConfigProvider, initialConfig, useConfig };
