import { QuestionType } from '../../Domains/useGetExaminationDetails';
import { GroupType } from '../../Domains/useGetExaminationDetails/utils';
import { MilestoneCardAction } from '../../Routes/LoLMilestones/types';
import { AnimationFrameStatus } from '../../Utils/useAnimationFrame';
import { QmsExamRoomLolExamResult } from '../../generated/graphql';
import { ExamQuestion } from '../types';

export type ExamAnswerType = {
  __typename?: 'QMSExamRoomExamPaperItem' | undefined;
  order?: number;
  examPaperItemId: string;
  questionId: string;
  answer: { value?: string };
}[];

// Answer response from api
export type AnswerQuestionType = {
  id: string;
  questionId: string;
  answer?: {
    value?: string | (boolean | string | null)[] | undefined;
  };
};

export type ExamByMilestoneType = {
  questions: Array<{
    order: number;
    question?: string;
    tag?: string;
  }>;
  tag?: string;
};

export type ExamBySections = {
  section?: string;
  tag?: string;
  fullScore?: number;
  questions: {
    order: number;
    question?: string;
    section?: string;
    tag?: string;
    score?: number;
    metadata?: {
      choices: {
        label: string;
        value: string;
        scoreRatio: number;
      }[];
    };
  }[];
};

export type Milestone = {
  careers: string[];
  duration: number;
  questionCountByTag: {
    tag: string;
    questionCount: number;
  }[];
  totalQuestionCount: number;
  startQuestionNo: number;
  endQuestionNo: number;
  tags: string[];
  status?: MilestoneCardAction;
};

export type QuestionsByAnswerType = {
  group: GroupType;
  questions: QuestionType[];
};

export type FormAnswerQuestionType = {
  answer: {
    value?: Array<string | boolean | null> | string | undefined;
  };
  order: number;
  examPaperItemId: string;
  questionId: string;
};

export type ILoLExaminationContext = {
  questionGroups: QuestionsByAnswerType[];
  examQuestions: ExamQuestion[];
  examPaperId: string;
  setAnimationFrameStatus: React.Dispatch<React.SetStateAction<AnimationFrameStatus>>;
  setCurrentQuestionNo: React.Dispatch<React.SetStateAction<number | undefined>>;
  handleUpdateExamPaper: (tags: string[]) => Promise<any>;
  handleSubmitExamination: () => Promise<any>;
  handleValidateRedeemCodeModal: (callBack?: () => void) => Promise<void>;
  isExamCompleted: boolean;
  isUnlockedExam: boolean | null;
  latestExamResult: QmsExamRoomLolExamResult[];
  currentQuestionNo?: number;
  isTrialFinished?: boolean;
  milestones?: Milestone[];
  examQuestionBySections?: ExamBySections[];
  openRedeemModal: () => void;
};

export type LoLExaminationProviderType = {
  children: React.ReactNode;
  examPaperId?: string;
  examId?: string;
  answers?: FormAnswerQuestionType[];
  lastTrialQuestionNo?: number;
  examQuestions?: ExamQuestion[];
  questionGroups?: QuestionsByAnswerType[];
  examQuestionByMilestones?: ExamByMilestoneType[];
  examQuestionBySections?: ExamBySections[];
};

export enum LoLExaminationTag {
  RIASEC = 'RIASEC',
  VALUE = 'VALUE',
  ACTIVITY = 'ACTIVITY',
  STRENGTH = 'STRENGTH',
}

export enum LoLExaminationSection {
  RIASEC_PART_1 = 'RIASEC_PART_1',
  RIASEC_PART_2 = 'RIASEC_PART_2',
  VALUE = 'VALUE',
  ACTIVITY_PART_1 = 'ACTIVITY_PART_1',
  ACTIVITY_PART_2 = 'ACTIVITY_PART_2',
  STRENGTH = 'STRENGTH',
}
