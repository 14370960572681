import AnswerSheetTypeMultipleChoice from '../../../Containers/AnswerSheet/AnswerSheetTypeMultipleChoice';
import AnswerSheetTypeFieldNumber from '../../../Containers/AnswerSheet/AnswerSheetTypeFieldNumber';
import AnswerSheetTypeConnectionAnswer from '../../../Containers/AnswerSheet/AnswerSheetTypeConnectionAnswer';
import { GroupType } from '../../../../../Domains/useGetExaminationDetails/utils';
import AnswerSheetTypeMultipleSubQuestionsYesNoAnswerChoice from '../../../Containers/AnswerSheet/AnswerSheetTypeMultipleSubQuestionsYesNoAnswerChoice';

import { AnswerSheetByGroupProps } from './types';

const AnswerSheetByGroup = (props: AnswerSheetByGroupProps) => {
  const { group, questions } = props;
  if (group === GroupType.MultipleChoices5 || group === GroupType.MultipleChoices4) {
    return <AnswerSheetTypeMultipleChoice group={group} questions={questions} />;
  }

  if (group === GroupType.FillNumber) {
    return <AnswerSheetTypeFieldNumber group={group} questions={questions} />;
  }

  if (group === GroupType.MultipleSubQuestionsConnectionAnswers) {
    return <AnswerSheetTypeConnectionAnswer group={group} questions={questions} />;
  }

  if (group === GroupType.MultipleSubQuestionsYesNoAnswer) {
    return (
      <AnswerSheetTypeMultipleSubQuestionsYesNoAnswerChoice group={group} questions={questions} />
    );
  }

  return null;
};

export default AnswerSheetByGroup;
