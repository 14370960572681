import Divider from '@mui/material/Divider';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, BoxProps, Flex, Grid, Paper, PaperProps, Text } from '../../../../Components/Base';
import { FormAnswerQuestionType } from '../../../../Contexts/ExaminationProvider';
import QuestionNoItem from '../../../../Components/QuestionNoItem';
import { checkAnswerdQuestions } from '../../utils';

export type RecheckAnswerProps = {
  answers: FormAnswerQuestionType[];
} & PaperProps;

export type StatusBoxProps = {
  bgcolor: string;
  borderColor: string;
} & BoxProps;

const StatusBox = ({ bgcolor, borderColor, ...restProps }: StatusBoxProps) => {
  return (
    <Box
      width="24px"
      height="24px"
      bgcolor={bgcolor}
      border="1px solid"
      borderColor={borderColor}
      borderRadius="6px"
      mr={1}
      {...restProps}
    />
  );
};

const RecheckAnswer = ({ answers, ...restProps }: RecheckAnswerProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToQuestion = (questionNo: number) => {
    const bookPath = location.pathname.replace('review', 'examroom');
    navigate(`${bookPath}#${questionNo}`, { replace: true });
  };

  return (
    <Paper sx={{ pt: 2, px: { xs: 2, sm: 3 }, pb: 3 }} {...restProps}>
      <Grid container gap={{ xs: 3, md: 2 }} direction="column">
        <Text variant={'h3'}>ตรวจทานข้อสอบ</Text>
        <Grid container gap={2} direction="row">
          <Flex>
            <StatusBox bgcolor="common.white" borderColor="grey.400" />
            <Text variant={'body1'}>ยังไม่ได้ตอบ</Text>
          </Flex>
          <Flex>
            <StatusBox bgcolor="success.main" borderColor="transparent" />
            <Text variant={'body1'}>ตอบแล้ว</Text>
          </Flex>
        </Grid>
        <Divider />
        <Box
          display="grid"
          gridTemplateColumns={{ xs: 'repeat(5, 1fr)', sm: 'repeat(10, 1fr)' }}
          columnGap={{ xs: 1, sm: 1.5, md: 2 }}
          rowGap={{ xs: 3, sm: 2 }}
          width={1}
          maxWidth={'765px'}
          alignSelf={'center'}
        >
          {answers.map((answer, index) => {
            const questionNo = index + 1;
            const isAnswered = checkAnswerdQuestions(answer);

            return (
              <QuestionNoItem
                key={`answered-${questionNo}`}
                questionNo={questionNo}
                isMark={answer?.isBookmark ?? false}
                isAnswered={isAnswered}
                onClick={() => handleNavigateToQuestion(questionNo)}
              />
            );
          })}
        </Box>
      </Grid>
    </Paper>
  );
};

export default RecheckAnswer;
