import { FormControl, FormGroup } from '@mui/material';

import { Box, Flex, Text, TextInput } from '../../../../Components/Base';

const InputView = () => {
  return (
    <form>
      <FormGroup>
        <FormControl>
          <Flex flex={'1 0 auto'} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box width={1}>
              <Text variant="h3">Text Input</Text>
              <TextInput label="Text Input" placeholder="enter your name here" required />
              <Box mt={1} />
              <TextInput
                label="Text Input Error"
                placeholder="enter your name here"
                error
                helperText="required"
              />
              <TextInput label="Text Input Disabled" placeholder="enter your name here" disabled />
            </Box>
          </Flex>
        </FormControl>
      </FormGroup>
    </form>
  );
};

export default InputView;
