import { Box, Flex, Paper, Text, Tooltip } from '../../../../Components/Base';
import FormSwitch from '../../../../Components/Form/FormSwitch';
import InfoOutlinedIcon from '../../../../Components/Icons/InfoOutlinedIcon';
import TimerOutlinedIcon from '../../../../Components/Icons/TimerOutlinedIcon';

export type ExaminationTimerProps = {
  timeLimit: number;
  isTimerDisabled: boolean;
};

const ExaminationTimer = ({ timeLimit, isTimerDisabled }: ExaminationTimerProps) => {
  return (
    <Paper
      sx={{
        mb: { xs: 3, sm: 4 },
        p: { xs: 2, md: 3 },
        borderRadius: { xs: 0, sm: 1 },
      }}
    >
      <Flex>
        <Box>
          <FormSwitch name="isShowTimer" disabled={isTimerDisabled} sx={{ mr: 0 }} />
        </Box>
        <Box>
          <Flex alignItems={'center'}>
            <Text variant="subtitle1" mr={1}>
              โหมดจับเวลาเสมือนจริง
            </Text>
            <Tooltip
              arrow
              PopperProps={{
                sx: {
                  textAlign: 'center',
                },
              }}
              placement="left"
              title={
                <Text variant="caption" whiteSpace={'pre-line'}>
                  {`หาก “เปิด” เวลาจะนับถอยหลัง
                เมื่อเวลาหมดระบบจะบังคับส่งอัตโนมัติ
                (เหมาะกับฝึกเสมือนจริง)
                หาก “ปิด” จะไม่มีการจับเวลา
                และส่งเมื่อไหร่ก็ได้`}
                </Text>
              }
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </Flex>
          <Flex alignItems={'center'} color={'text.secondary'}>
            <TimerOutlinedIcon fontSize={'small'} sx={{ mr: 0.5 }} />
            <Text variant="body2">ระยะเวลา {timeLimit} นาที</Text>
          </Flex>
        </Box>
      </Flex>
    </Paper>
  );
};

export default ExaminationTimer;
