import { Divider, styled } from '@mui/material';

import { Flex, FlexProps, Text } from '../Base';

export type ExaminationQuestionTitleProps = {
  questionNo: string | number;
  title: string;
} & FlexProps;

const TitleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExaminationQuestionTitle = ({
  questionNo,
  title,
  ...restProps
}: ExaminationQuestionTitleProps) => {
  return (
    <Flex py={2} width={'100%'} {...restProps}>
      <Text variant="subtitle1">ข้อที่ {questionNo}</Text>
      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
      <Flex justifyContent={'space-between'} flexGrow="1">
        <TitleText variant="body1">{title}</TitleText>
      </Flex>
    </Flex>
  );
};

export default ExaminationQuestionTitle;
