import { Button, Flex, Image, Text, Label, FlexProps } from '../../../Components/Base';

type MyBookPreviewType = {
  isSubmitting: boolean;
  onSubmit: () => void;
  title: string;
  imageUrl: string;
  author: string;
  refNo: string;
} & FlexProps;

const MyBookPreview = (props: MyBookPreviewType) => {
  const { title, imageUrl, author, refNo, isSubmitting, onSubmit, ...restProps } = props;

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      maxWidth={350}
      height={{ xs: '100%', sm: 'auto' }}
      mt={{ sm: 6 }}
      mx={'auto'}
      {...restProps}
    >
      <Text sx={{ typography: { xs: 'h3', md: 'h2' } }} mb={1}>
        ยืนยันข้อมูลหนังสือ
      </Text>
      <Text mb={3}>โปรดตรวจสอบข้อมูลหนังสือว่าตรงกันหรือไม่</Text>
      <Image src={imageUrl} alt="myBookPreview" width={140} mb={3} />
      <Text variant={'subtitle1'} textAlign={'center'} mb={1}>
        {title}
      </Text>
      <Text variant={'caption'} mb={1}>
        เขียนโดย {author}
      </Text>
      <Label color={'info'}>{`Ref: ${refNo}`}</Label>
      <Button
        fullWidth
        type="button"
        size={'large'}
        disabled={isSubmitting}
        onClick={onSubmit}
        sx={{ mt: 4 }}
      >
        ยืนยัน
      </Button>
    </Flex>
  );
};

export default MyBookPreview;
