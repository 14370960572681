import { Box, Skeleton, Typography, styled } from '@mui/material';
import _ from 'lodash';
import { Link as ReactLink, useLocation, useParams } from 'react-router-dom';

import LoLButton from '../../../../Components/Base/LoLButton';
import useGoogleTagManager from '../../../../Utils/useGoogleTagManager';
import { useGetQmsExamDetailsByBookIdQuery as useGetExamDetails } from '../../../../generated/graphql';

const Link = styled(ReactLink)(() => ({
  color: 'inherit',
  textDecoration: 'none',
}));

const LoLExamList = () => {
  const location = useLocation();
  const { bookId = '' } = useParams();

  const {
    data: examData,
    loading: examLoading,
    error: examError,
  } = useGetExamDetails({
    variables: {
      bookId,
    },
  });

  const sendDataToGTM = useGoogleTagManager();

  if (examLoading) {
    return (
      <Skeleton
        width={'100%'}
        height={56}
        variant="rectangular"
        sx={{ bgcolor: 'lolButton.secondary' }}
      />
    );
  }

  if (examError) {
    return (
      <LoLButton buttonVariant="secondary" disabled fullWidth size="large">
        เกิดข้อผิดพลาด
      </LoLButton>
    );
  }

  const examinations = examData?.qmsGetBookDetailsByBookId.exams ?? [];
  const examOrder = examData?.qmsGetBookDetailsByBookId.bookExamItems ?? [];
  const sortedExaminationsByOrder = _.sortBy(examinations, (exam) => {
    const order = examOrder.find((examItem) => examItem.examId === exam.id)?.order ?? 0;
    return order;
  });

  const handleGoToExaminationInstruction = (examTitle: string) => {
    //TODO: Handle GA
    // sendDataToGTM({
    //   event: 'lol_item_click',
    //   examTitle,
    // });
  };

  return (
    <Box width={1}>
      {sortedExaminationsByOrder.map((examItem, index) => {
        const title = examItem.title ?? '';

        return (
          <Box key={examItem.id}>
            <Link to={`${location.pathname}/${examItem.id}`}>
              <Box
                py={2}
                px={3}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'8px'}
                bgcolor={'lolButton.secondary'}
                color={'lolTextButton.contrast'}
                mt={index > 0 ? 2 : 0}
                onClick={() => handleGoToExaminationInstruction(title)}
              >
                <Typography>{title}</Typography>
              </Box>
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};

export default LoLExamList;
