import { styled, Toolbar } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import { AppBar, APP_BAR_HEIGHT } from '../../Components/Layout';
import { Box, Flex, Image } from '../../Components/Base';
import { useConfig } from '../../Contexts/ConfigProvider';
import HamburgerMenu from '../HamburgerMenu';
import UserButton from '../UserButton';

const StyledContentLayout = styled(Flex)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: `760px`,
  },
}));

const BaseLayout = ({ children }: { children?: React.ReactNode }) => {
  const {
    logo: { logoLargeUrl = '' },
  } = useConfig();

  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate('/', { replace: true });
  };

  return (
    <>
      <AppBar>
        <Flex width={1} flex={1} alignItems={'center'} justifyContent={'space-between'}>
          <HamburgerMenu />
          <Image
            src={logoLargeUrl}
            alt="TCASterLogo"
            sx={{ cursor: 'pointer' }}
            onClick={handleGoToHome}
          />
          <UserButton />
        </Flex>
      </AppBar>
      <Toolbar />
      <Box bgcolor={'grey.50'}>
        <StyledContentLayout
          flexDirection={'column'}
          component="main"
          px={{ xs: 0, sm: 3 }}
          pt={3}
          minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`}
          sx={{ flexGrow: 1 }}
        >
          {children ?? <Outlet />}
        </StyledContentLayout>
      </Box>
    </>
  );
};

export default BaseLayout;
