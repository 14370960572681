import { useTranslation } from 'react-i18next';

import LoLButton, { LoLButtonProps } from '../../../../Components/Base/LoLButton';

type LearnIDRegisterButtonProps = LoLButtonProps;

const LearnIDRegisterButton = (props: LearnIDRegisterButtonProps) => {
  const { t } = useTranslation('lolSignIn');
  return (
    <LoLButton size="large" {...props}>
      {t('LOGIN_PROVIDER.LEARN_ID.REGISTER')}
    </LoLButton>
  );
};

export default LearnIDRegisterButton;
