import { ThemeProvider } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Outlet, useParams } from 'react-router-dom';

import { Flex } from '../../Components/Base';
import theme, { extendTheme } from '../../Components/Base/LoLTheme';
import Loading from '../../Components/Loading';
import { useConfig } from '../../Contexts/ConfigProvider';
import LoLLanguageProvider from '../../Contexts/LoLLanguageProvider';
import ExaminationPortal from '../../Routes/ExaminationPortal';
import { useGetQmsMyBookMetadataQuery as useGetMyBookMetadata } from '../../generated/graphql';
import FixedMobileLayout from '../FixedMobileLayout';

const BaseLoLLayout = ({ children }: { children?: React.ReactNode }) => {
  const { bookId = '' } = useParams();
  const { lol = {} } = useConfig();
  const { data, loading } = useGetMyBookMetadata({ skip: !bookId, variables: { bookId } });

  if (loading) {
    return <Loading />;
  }

  const { provider = '' } = data?.qmsGetBookDetailsByBookId?.metadata;

  const { themeByProvider = {} } = lol;

  const overrideTheme = themeByProvider[provider as keyof typeof Object];

  const extendedDefaultTheme: Theme = {
    ...theme,
    palette: { ...theme.palette, ...themeByProvider['DEFAULT'].palette },
  };

  const mergedTheme = extendTheme(overrideTheme, extendedDefaultTheme);

  return (
    <ExaminationPortal>
      <ThemeProvider theme={mergedTheme}>
        <LoLLanguageProvider>
          <Flex
            width={'100vw'}
            height={'100vh'}
            overflow={'hidden'}
            justifyContent={'center'}
            alignItems={'center'}
            bgcolor={'black'}
            sx={{
              '& > main ': {
                bgcolor: 'lolBackground.primary',
              },
            }}
          >
            <FixedMobileLayout>{children ?? <Outlet />}</FixedMobileLayout>
          </Flex>
        </LoLLanguageProvider>
      </ThemeProvider>
    </ExaminationPortal>
  );
};

export default BaseLoLLayout;
