import { useMemo } from 'react';
import { Box } from '@mui/material';

import { useExamination } from '../../../../Contexts/ExaminationProvider';
import HTMLView from '../../../../Components/HTMLView';
import { Flex, Text } from '../../../../Components/Base';
import { renderAnswerTypeMessage } from '../../../../Containers/ExaminationRoom/utils';
import { QbQuestionType } from '../../../../generated/graphql';

const renderChoices = (type?: QbQuestionType, metadata?: any) => {
  switch (type) {
    case QbQuestionType.MultipleSubQuestionsYesNoAnswer: {
      const subQuestions = metadata?.subQuestions ?? [];
      return (
        <Box>
          {subQuestions.map((question: Record<string, any>, index: number) => {
            const label = question?.label;
            return (
              <Flex key={index} mt={index === 0 ? 0 : 1} px={2} py={1.25}>
                <Text minWidth={24} mr={1}>{`${index + 1}.`}</Text>
                <HTMLView rawContent={label} />
              </Flex>
            );
          })}
        </Box>
      );
    }
    case QbQuestionType.MultipleChoiceDifferentScore:
    case QbQuestionType.MultipleSelectWithCorrectAnswers:
    case QbQuestionType.MultipleChoice: {
      const choices = metadata?.choices ?? [];
      return (
        <Box>
          {choices.map((choice: Record<string, any>, index: number) => {
            const label = choice?.label;
            return (
              <Flex key={index} mt={index === 0 ? 0 : 1} px={2} py={1.25}>
                <Text minWidth={24} mr={1}>{`${index + 1}.`}</Text>
                <HTMLView rawContent={label} />
              </Flex>
            );
          })}
        </Box>
      );
    }
    default:
      return null;
  }
};

const QuestionContent = () => {
  const { examQuestions } = useExamination();

  const anchorId = location.hash.replace('#', '');
  const questionNo = parseInt(anchorId.trim() === '' ? '1' : anchorId) ?? 1;

  const currentQuestion = useMemo(() => {
    const currentExam = examQuestions.find((question) => question.order === questionNo);
    return currentExam;
  }, [examQuestions, questionNo]);

  const { type, subType, question, metadata } = currentQuestion ?? {};

  return (
    <Box>
      <Text fontWeight={600}>{`ข้อ ${questionNo} ${renderAnswerTypeMessage(type, subType)}`}</Text>
      <Box mt={2} width={1}>
        <HTMLView rawContent={question} />
        <Box pt={2} />
        {renderChoices(type, metadata)}
      </Box>
    </Box>
  );
};

export default QuestionContent;
