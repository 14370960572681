import { Divider, styled } from '@mui/material';

import { Flex, FlexProps, Text, Label, TextProps } from '../Base';
import CancelRoundedIcon from '../Icons/CancelRoundedIcon';
import CheckCircleRoundedIcon from '../Icons/CheckCircleRoundedIcon';

export type ResultByQuestionProps = {
  prefixText: string | number;
  prefixTextProps?: TextProps;
  answer?: string | number;
  isCorrect?: boolean;
  score?: number | string;
} & FlexProps;

const getColorByVariant = (variant: boolean) => (variant ? 'success' : 'error');

const getLabelContentByVariant = (variant: boolean) => {
  if (variant) {
    return (
      <>
        <CheckCircleRoundedIcon sx={{ mr: 0.5, fontSize: 16, color: 'common.white' }} />
        <Text variant="caption" color="common.white" fontWeight={600}>
          ถูก
        </Text>
      </>
    );
  } else {
    return (
      <>
        <CancelRoundedIcon sx={{ mr: 0.5, fontSize: 16, color: 'common.white' }} />{' '}
        <Text variant="caption" color="common.white" fontWeight={600}>
          ผิด
        </Text>
      </>
    );
  }
};

const TitleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ResultByQuestion = ({
  prefixText,
  prefixTextProps,
  answer,
  isCorrect,
  score,
  ...restProps
}: ResultByQuestionProps) => {
  return (
    <Flex py={2} width={'100%'} alignItems={'flex-start'} {...restProps}>
      <Text variant={'subtitle1'} width={'60px'} textAlign={'left'} {...prefixTextProps}>
        {prefixText}
      </Text>
      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
      <Flex justifyContent={'space-between'} flexGrow="1">
        <TitleText variant="body1">{answer}</TitleText>
        <Flex>
          {typeof isCorrect === 'boolean' ? (
            <Label
              alignItems="center"
              variant={'contained'}
              size="small"
              color={getColorByVariant(isCorrect)}
            >
              {getLabelContentByVariant(isCorrect)}
            </Label>
          ) : null}

          {typeof score === 'number' || typeof score === 'string' ? (
            <Text textAlign={'right'} variant={'subtitle1'} marginLeft={3}>
              {score}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ResultByQuestion;
