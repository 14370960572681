import { Tooltip, TooltipProps } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const toolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      defaultProps: {
        // enterTouchDelay is set as 0 for calling a tooltip without delay.
        enterTouchDelay: 0,
      },
    },
  },
});

export default Tooltip;
export { toolTipTheme };
export type { TooltipProps };
