import { Divider as MUIDivider, styled } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Flex, Image, Text } from '../../Components/Base';
import LoLSponsors from '../../Containers/LoLSponsors/LoLSponsors';
import { useAnonymous } from '../../Contexts/AnonymousProvider';
import { LoginState, useAuthentication } from '../../Contexts/AuthenticationProvider';
import { useConfig } from '../../Contexts/ConfigProvider';

import FacebookLoginButton from './Containers/FacebookLoginButton';
import GoogleLoginButton from './Containers/GoogleLoginButton';
import LearnIDLoginButton from './Containers/LearnIDLoginButton';
import LearnIDRegisterButton from './Containers/LearnIDRegisterButton';
import LoLSeeResultInstruction from './Containers/LoLSeeResultInstruction';
import PhoneNumberLoginButton from './Containers/PhoneNumberLoginButton';

const Divider = styled(MUIDivider)(({ theme }) => ({
  color: theme.palette.lolText?.secondary,
  margin: `${theme.spacing(3)} 0px`,
  width: '100%',
  '&::before, &::after': {
    borderTopStyle: 'dashed',
    borderColor: theme.palette.lolLine?.line1,
  },
}));

const LoLSignIn = () => {
  const {
    lol: { personalityLogoUrl = '', imgPrefixPath },
  } = useConfig();
  const { anonymousUser } = useAnonymous();
  const { loginState, loginWithLearnId, registerLearnId } = useAuthentication();
  const { t } = useTranslation('lolSignIn');

  const location = useLocation();
  const navigate = useNavigate();

  const locationState = location.state as { redirectPath: string; shouldOpenResultModal: boolean };

  const defaultRedirectPath = location.pathname.replace('/sign-in', '');

  const redirectPath = locationState?.redirectPath ?? defaultRedirectPath;
  const shouldOpenResultModal = locationState?.shouldOpenResultModal ?? false;

  const isSeeResultInstruction =
    (location?.state as { isShowSeeResultInstruction: boolean })?.isShowSeeResultInstruction ??
    false;

  const handleRegisterLearnId = () => {
    if (anonymousUser?.id) {
      registerLearnId({ anonymousUserId: anonymousUser?.id, redirectPath, shouldOpenResultModal });
    }
  };

  const handleLoginWithLearnId = () => {
    if (anonymousUser?.id) {
      loginWithLearnId({ anonymousUserId: anonymousUser?.id, redirectPath, shouldOpenResultModal });
    }
  };
  //To clear local storage when user fail to login
  useEffect(() => {
    localStorage.removeItem('anonymousUserId');
    localStorage.removeItem('redirectPath');
    localStorage.removeItem('shouldOpenResultModal');
  }, []);

  useEffect(() => {
    if (loginState === LoginState.LOGGED_IN) {
      if (redirectPath) {
        navigate(redirectPath, { replace: true });
      } else {
        navigate('/mybooks', { replace: true });
      }
    }
  }, [loginState, navigate, redirectPath, shouldOpenResultModal]);

  return (
    <Box textAlign={'center'} p={3} pt={4} columnGap={2}>
      {isSeeResultInstruction ? (
        <LoLSeeResultInstruction />
      ) : (
        <Image
          src={`${imgPrefixPath}/${personalityLogoUrl}`}
          alt={'personality-logo'}
          height={'auto'}
          style={{ objectFit: 'contain', maxWidth: '100%' }}
        />
      )}
      <Box mt={2} width={1}>
        <Flex flexDirection={'column'} gap={2}>
          <FacebookLoginButton
            redirectPath={redirectPath}
            shouldOpenResultModal={shouldOpenResultModal}
          />
          <GoogleLoginButton
            redirectPath={redirectPath}
            shouldOpenResultModal={shouldOpenResultModal}
          />
          <PhoneNumberLoginButton
            redirectPath={redirectPath}
            shouldOpenResultModal={shouldOpenResultModal}
          />
        </Flex>
        <Divider aria-orientation="horizontal" aria-hidden="true" role="presentation">
          <Text variant="subtitle2" fontWeight={600}>
            {t('DIVIDER_TEXT')}
          </Text>
        </Divider>
        <LearnIDLoginButton fullWidth onClick={handleLoginWithLearnId} />
        <LearnIDRegisterButton
          variant="outlined"
          fullWidth
          onClick={handleRegisterLearnId}
          sx={{ mt: 2, color: 'lolElement.primary', borderColor: 'lolLine.line1' }}
        />
      </Box>
      <LoLSponsors mt={2} />
    </Box>
  );
};

export default LoLSignIn;
