import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LoLButton from '../../../../Components/Base/LoLButton';
import { useLoLExamination } from '../../../../Contexts/LoLExaminationProvider';
import { MilestoneCardAction } from '../../types';

const SeeLoLExamResultButton = () => {
  const { t } = useTranslation('lolCommon');
  const { milestones, examPaperId } = useLoLExamination();
  const navigate = useNavigate();

  const disabledGoToFullReport = useMemo(() => {
    if (!milestones || milestones.length === 0) return true;

    const firstMilestone = milestones[0];
    if (firstMilestone.status !== MilestoneCardAction.COMPLETED) return true;

    return false;
  }, [milestones]);

  const handleGoToFullReport = () => {
    const newPath = `../../results/${examPaperId}`;
    navigate(newPath);
  };

  return (
    <LoLButton
      fullWidth
      size="large"
      variant={'outlined'}
      disabled={disabledGoToFullReport}
      onClick={handleGoToFullReport}
      sx={{ color: 'lolTextButton.secondary', borderColor: 'lolLine.line1' }}
    >
      {t('BUTTON.MY_RESULTS')}
    </LoLButton>
  );
};

export default SeeLoLExamResultButton;
