import { createContext, useContext } from 'react';

import useGetExaminationSession from '../../Domains/useGetExaminationSession';
import useTimer from '../../Utils/useTimer';

import { ExaminationTimerProviderType, IExaminationTiemrContext } from './types';

const ExaminationTimerContext = createContext<IExaminationTiemrContext | undefined>(undefined);

const ExaminationTimerProvider = (props: ExaminationTimerProviderType) => {
  const { children } = props;

  const { data: examSession } = useGetExaminationSession({ fetchPolicy: 'network-only' });
  const hasTimer = examSession?.qmsExamRoomGetExamSessionAnswer?.isSetTimer ?? false;
  const endExamAt = examSession?.qmsExamRoomGetExamSessionAnswer.endedAt;

  const { remainingTime, setRemainingTime, timerStatus } = useTimer(endExamAt);

  return (
    <ExaminationTimerContext.Provider
      value={{ hasTimer, setRemainingTime, timerStatus, remainingTime }}
    >
      {children}
    </ExaminationTimerContext.Provider>
  );
};

export const useExaminationTimer = () => {
  const context = useContext(ExaminationTimerContext);
  if (context === undefined) {
    throw new Error('useExamination must by used within ExaminationTiemrContext');
  }
  return context;
};

export default ExaminationTimerProvider;
