import { Alert, AlertTitle, Snackbar, Text, SnackbarProps } from '../../../Components/Base';
import ErrorOutlineIcon from '../../../Components/Icons/ErrorOutlineIcon';

const SubmitExamErrorSnackbar = (props: SnackbarProps) => (
  <Snackbar {...props}>
    <Alert color={'error'} icon={<ErrorOutlineIcon />}>
      <AlertTitle>ส่งข้อมูลไม่สำเร็จ</AlertTitle>
      <Text variant={'body2'} color={'text.secondary'}>
        กรุณาลองใหม่อีกครั้ง
      </Text>
    </Alert>
  </Snackbar>
);

export default SubmitExamErrorSnackbar;
