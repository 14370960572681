import { Button, Flex, Modal, ModalContent, ModalProps, Text } from '../Base';
import PageErrorIcon from '../Icons/PageErrorIcon';

export type DataLoadingErrorProps = {
  onBackToHome: () => void;
} & ModalProps;

export const NotFoundError = ({ onBackToHome, ...restprops }: DataLoadingErrorProps) => (
  <Modal fullScreen {...restprops}>
    <ModalContent>
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        maxWidth={350}
        height={{ xs: '100%', sm: 'auto' }}
        mt={{ sm: 13 }}
        mx={'auto'}
      >
        <PageErrorIcon
          color={'primary'}
          sx={{
            width: 100,
            height: 100,
          }}
        />
        <Text sx={{ typography: { xs: 'h3', md: 'h2' } }} mt={{ xs: 3, sm: 4 }} mb={1}>
          ไม่พบข้อมูล
        </Text>
        <Text mb={3} textAlign={'center'}>
          กรุณาตรวจสอบลิงก์อีกครั้ง
        </Text>
        <Button fullWidth size={'large'} onClick={onBackToHome}>
          <Text>กลับสู่หน้าหลัก</Text>
        </Button>
      </Flex>
    </ModalContent>
  </Modal>
);
export default NotFoundError;
