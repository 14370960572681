import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { LoginState, useAuthentication } from '../../Contexts/AuthenticationProvider';
import { useLoLExamination } from '../../Contexts/LoLExaminationProvider';
import { LoLExaminationSection, Milestone } from '../../Contexts/LoLExaminationProvider/types';

type LoLFlowControllerType = {
  children?: JSX.Element;
};

const flowMapper = new Map([
  [JSON.stringify([LoLExaminationSection.RIASEC_PART_1]), '*/milestones'],
  [JSON.stringify([LoLExaminationSection.RIASEC_PART_2]), '*/milestones'],
  [
    JSON.stringify([LoLExaminationSection.ACTIVITY_PART_1, LoLExaminationSection.VALUE]),
    '*/milestones',
  ],
]);

const LoLFlowController = ({ children }: LoLFlowControllerType) => {
  const { currentQuestionNo, milestones, handleUpdateExamPaper, isExamCompleted, isTrialFinished } =
    useLoLExamination();
  const { loginState } = useAuthentication();
  const navigate = useNavigate();
  const [currentMilestone, setCurrentMilestone] = useState<Milestone | undefined>();

  const getCurrentMilestone = () => {
    if (currentQuestionNo === undefined) {
      return undefined;
    }
    return milestones?.find(
      ({ startQuestionNo, endQuestionNo }) =>
        startQuestionNo <= currentQuestionNo && currentQuestionNo <= endQuestionNo
    );
  };

  const handleGoToSignIn = () => {
    const parts = location.pathname.split('/').filter(Boolean);
    parts[parts.length - 1] = 'milestones';
    const milestones = '/' + parts.join('/');
    navigate('../sign-in', {
      replace: true,
      state: {
        redirectPath: milestones,
        shouldOpenResultModal: true,
        isShowSeeResultInstruction: true,
      },
    });
  };

  const handleGoToRedirectPath = ({ path }: { path: string }) => {
    if (path.includes('*')) {
      const pathSegments = location.pathname.split('/');
      pathSegments.pop();
      const milestonePath = `${pathSegments.join('/')}/${path.replace('*/', '')}`;
      navigate(milestonePath);
    } else {
      if (path.includes('/sign-in')) {
        handleGoToSignIn();
      }
    }
  };

  useEffect(() => {
    if (isExamCompleted) {
      handleGoToRedirectPath({ path: '*/milestones' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExamCompleted]);

  useEffect(() => {
    const isSignInPage = location.pathname.includes('sign-in');
    if (isTrialFinished && loginState === LoginState.NOT_LOGGED_IN && !isSignInPage) {
      handleGoToSignIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (currentMilestone === undefined || currentQuestionNo === undefined) return;
    else if (currentMilestone.endQuestionNo < currentQuestionNo) {
      const currentExamPart = currentMilestone?.tags ?? [];
      const key = JSON.stringify(currentMilestone?.tags);
      const redirectPath = flowMapper.get(key) ?? '*/milestones';
      handleGoToRedirectPath({ path: redirectPath });
      handleUpdateExamPaper(currentExamPart)
        .then(() => {
          setCurrentMilestone(undefined);
        })
        .catch((error) => {
          console.log('handleUpdateExamPaper: ', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMilestone, currentQuestionNo]);

  useEffect(() => {
    if (currentMilestone === undefined) {
      const milestone = getCurrentMilestone();
      setCurrentMilestone(milestone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionNo, loginState, isTrialFinished, currentMilestone]);
  return children ?? <Outlet />;
};

export default LoLFlowController;
