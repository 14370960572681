import useGetExaminationResults, {
  QMSExamPapersResultType,
  QmsFoMyExamPapersQuery,
  QmsExamPaperStatus,
  QmsOrderType,
} from './useGetExaminationResults';

export default useGetExaminationResults;
export { QmsExamPaperStatus, QmsOrderType };
export type { QMSExamPapersResultType, QmsFoMyExamPapersQuery };
