import { alpha, styled } from '@mui/material';

import { Flex } from '../../../Components/Base';
import SolutionItem from '../Components/SolutionItem';
import ChevronRightRoundedIcon from '../../../Components/Icons/ChevronRightRoundedIcon';
import { QbQuestionType } from '../../../Domains/useGetSolutionById';

const SolutionItemWrapper = styled(Flex)(({ theme }) => ({
  '&:hover': {
    background: alpha(theme.palette.primary.main, 0.04),
    cursor: 'pointer',
  },
}));

const renderSolution = (type: string, solution: Array<string> | string | number | boolean) => {
  switch (type) {
    case QbQuestionType.MultipleChoiceDifferentScore: {
      return 'ดูเฉลย';
    }
    case QbQuestionType.MultipleSelectWithCorrectAnswers: {
      const newSolution = Array.isArray(solution)
        ? solution
            .map((answer) => parseInt(answer))
            .sort()
            .join(', ')
        : solution;
      return `เฉลย ${newSolution}`;
    }
    case QbQuestionType.MultipleSubQuestionsYesNoAnswer: {
      return `เฉลย ${solution ? 'ใช่' : 'ไม่ใช่'}`;
    }
    case QbQuestionType.MultipleSubQuestionsConnectionAnswers: {
      const newSolution = Array.isArray(solution) ? solution.join(', ') : solution;
      return `เฉลย ${newSolution}`;
    }
    case QbQuestionType.MultipleChoice:
    case QbQuestionType.FillNumber:
    default: {
      return `เฉลย ${solution}`;
    }
  }
};

const renderAnswerNumber = (type: string, questionNo: number, index: number) => {
  switch (type) {
    case QbQuestionType.MultipleSubQuestionsYesNoAnswer:
      return `${questionNo}.${index + 1}`;
    default:
      return '';
  }
};

interface SolutionListItemProps {
  examItemId: string;
  order: number;
  type: QbQuestionType;
  correctAnswer?: any | null;
  handleGoToSolutionDetails: (examItemId: string) => void;
}

const SolutionListItem = (props: SolutionListItemProps) => {
  const { examItemId, order, type, correctAnswer, handleGoToSolutionDetails } = props;

  const hasSubQuestion = type === QbQuestionType.MultipleSubQuestionsYesNoAnswer;

  return (
    <SolutionItemWrapper
      px={{ xs: 2, md: 3 }}
      alignItems={'center'}
      flexDirection={hasSubQuestion ? 'column' : 'row'}
      onClick={() => handleGoToSolutionDetails(examItemId)}
    >
      <Flex width={'100%'} py={2}>
        <SolutionItem
          questionNo={`ข้อที่ ${order ?? 0}`}
          solution={hasSubQuestion ? '' : renderSolution(type, correctAnswer?.value)}
        />
        <ChevronRightRoundedIcon color={'primary'} sx={{ ml: 1 }} />
      </Flex>
      {hasSubQuestion &&
        correctAnswer?.value?.map(
          ({ correctAnswerValue }: { correctAnswerValue: boolean }, index: number) => (
            <SolutionItem
              key={index}
              pr={4}
              py={2}
              isSubQuestion
              questionNo={renderAnswerNumber(type, order, index)}
              solution={renderSolution(type, correctAnswerValue)}
              pl={hasSubQuestion ? 4.5 : 0} // 4.5 is a magic number for setting alignment of the order number
            />
          )
        )}
    </SolutionItemWrapper>
  );
};

export default SolutionListItem;
