import { InfoRounded } from '@mui/icons-material';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Flex, Grid } from '../../Base';

import HelperText from './HelperText';
import { ChangeEvent, KeyboardEvent, OTPInputProps } from './OTPInput.d';
import SingleOTPInput from './SingleOTPInput';

const KEY_CODE = {
  BACKSPACE: 8,
  ENTER: 13,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  DELETE: 46,
  SPACEBAR: 32,
};

//This component modified from react-otp-input more info: https://github.com/devfolioco/react-otp-input/blob/master/src/lib/index.jsx
const OTPInput = ({
  defaultValue,
  value: valueProps,
  pattern = /^\d?$/,
  error,
  helperText,
  onChange: onChangeProps = () => undefined,
  onBlur,
  disabled = false,
  ...props
}: OTPInputProps): JSX.Element => {
  const inputRef = useRef<Array<HTMLInputElement | null>>([null, null, null, null, null, null]);
  const isControlled = useMemo(() => defaultValue?.length === 6, [defaultValue]);

  const [uncontrolledValue, setUncontrolledValue] = useState(['', '', '', '', '', '']);

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current[0]) inputRef.current[0].focus();
  }, []);
  useEffect(() => {
    //workaround to handle onblur of input touch state
    if (inputRef && inputRef.current && inputRef.current[5] && onBlur)
      inputRef.current[5].onblur = onBlur;
  }, [onBlur]);

  const value = valueProps ? valueProps : uncontrolledValue;

  const onChange = useCallback(
    (changedValue) => {
      if (!isControlled) setUncontrolledValue(changedValue);
      onChangeProps(changedValue);
    },
    [isControlled, onChangeProps]
  );

  const focusPrevInput = (index: number) => {
    const previousIndex = index - 1 <= 0 ? 0 : index - 1;
    (inputRef.current[previousIndex] as HTMLInputElement).focus();
  };

  const focusNextInput = (index: number) => {
    const nextIndex = index + 1 >= value.length - 1 ? value.length - 1 : index + 1;
    (inputRef.current[nextIndex] as HTMLInputElement).focus();
  };

  const handleInputChange = (index: number) => (e?: ChangeEvent) => {
    const updateValue = e?.target?.value ?? '';
    const updateOTPCode = [...value];
    if (pattern.test(updateValue)) {
      updateOTPCode[index] = updateValue;
      if (updateValue) {
        focusNextInput(index);
      }
      onChange(updateOTPCode);
    }
  };

  const handleOnKeyDown = (index: number) => (e?: KeyboardEvent) => {
    if (!e) return;
    if (e.keyCode === KEY_CODE.BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      const indexToDel = value?.[index] ? index : index - 1;
      handleInputChange(indexToDel)();
      if (!value?.[index]) {
        focusPrevInput(index);
      }
    } else if (e.keyCode === KEY_CODE.DELETE || e.key === 'Delete') {
      e.preventDefault();
      handleInputChange(index)();
    } else if (e.keyCode === KEY_CODE.LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput(index);
    } else if (e.keyCode === KEY_CODE.RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault();
      focusNextInput(index);
    } else if (
      e.keyCode === KEY_CODE.SPACEBAR ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.key === 'Space'
    ) {
      e.preventDefault();
    } else if (e.keyCode === KEY_CODE.ENTER || e.key === 'Enter') {
      if (value?.some((val) => !val)) {
        const emptyIndex = value.findIndex((val) => !val);
        (inputRef.current[emptyIndex] as HTMLInputElement).focus();
      } else {
        (inputRef.current[0] as HTMLInputElement).focus();
      }
    } else if (value[index]) {
      handleInputChange(index)();
    }
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const clipboard = e.clipboardData.getData('text/plain');
    const otp = clipboard.split('');
    const updateOTPCode = Array(6).fill('');

    for (let i = 0; i < 6; i++) {
      if (pattern.test(otp[i])) {
        updateOTPCode[i] = otp[i];
      } else {
        (inputRef.current[i] as HTMLInputElement).focus();
        break;
      }
    }

    onChange(updateOTPCode);
  };

  return (
    <Box textAlign={'center'}>
      <Grid
        gridTemplateColumns="repeat(6,max-content)"
        gap={1}
        display={'flex'}
        justifyContent={'center'}
      >
        {inputRef.current.map((e, index) => (
          <Fragment key={index}>
            <SingleOTPInput
              value={value?.[index] ?? ''}
              error={error}
              onChange={handleInputChange(index)}
              onKeyDown={handleOnKeyDown(index)}
              onFocus={(e) => {
                e.preventDefault();
                (inputRef.current[index] as HTMLInputElement).focus();
              }}
              onPaste={handlePaste}
              ref={(el) => (inputRef.current[index] = el as HTMLInputElement)}
              data-testid={`otp-input-${index}`}
              disabled={disabled}
              {...props}
            />
          </Fragment>
        ))}
      </Grid>
      {helperText && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          {error && <InfoRounded fontSize="small" color="error" />}
          <HelperText error={error} data-testid="helper-text-otp-form">
            {helperText}
          </HelperText>
        </Flex>
      )}
    </Box>
  );
};

export type { OTPInputProps };
export default OTPInput;
