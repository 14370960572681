import { styled, SvgIcon, SvgIconProps } from '@mui/material';

const MyBookIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 115 100" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M22.338 93.388a2.147 2.147 0 0 1-2.146-2.146V8.597a8.5 8.5 0 0 1 8.49-8.49h2.48a2.147 2.147 0 0 1 2.146 2.147V84.62a2.147 2.147 0 0 1-2.147 2.146h-2.21a4.476 4.476 0 0 0-4.466 4.476 2.147 2.147 0 0 1-2.146 2.146ZM28.683 4.39a4.207 4.207 0 0 0-4.197 4.197v75.131a8.747 8.747 0 0 1 4.465-1.224h.065V4.39h-.333Z"
        fill={'currentColor'}
      />
      <path
        d="M62.738 86.766H31.16a2.147 2.147 0 0 1-2.147-2.146V2.243A2.147 2.147 0 0 1 31.161.097h55.147a8.5 8.5 0 0 1 8.5 8.49v38.896a2.147 2.147 0 1 1-4.293 0V8.587a4.207 4.207 0 0 0-4.207-4.197h-53v78.083h29.43a2.147 2.147 0 0 1 0 4.293Z"
        fill={'currentColor'}
      />
      <path
        d="M66.301.097v20.64a2.147 2.147 0 0 0 3.531 1.642l5.238-4.423 5.238 4.422c.387.327.878.506 1.384.505.311.002.619-.064.902-.193a2.146 2.146 0 0 0 1.245-1.954V.096H66.301Z"
        fill="#5F6060"
      />
      <path
        d="M69.306 100H29.23a9.209 9.209 0 0 1-6.569-2.79 8.587 8.587 0 0 1-2.457-6.247 8.748 8.748 0 0 1 8.747-8.49h33.788a2.147 2.147 0 0 1 0 4.293H28.95a4.454 4.454 0 0 0-4.454 4.293 4.294 4.294 0 0 0 1.245 3.124 4.903 4.903 0 0 0 3.488 1.48h40.077a2.147 2.147 0 0 1 0 4.294V100Z"
        fill={'currentColor'}
      />
      <path
        d="M92.662 100a21.916 21.916 0 1 1 21.917-21.917A21.94 21.94 0 0 1 92.662 100Zm0-39.54a17.624 17.624 0 1 0 17.623 17.623A17.646 17.646 0 0 0 92.662 60.45v.01Z"
        fill={'currentColor'}
      />
      <path
        d="M92.662 87.163a2.147 2.147 0 0 1-2.147-2.146V71.182a2.147 2.147 0 0 1 4.293 0v13.835a2.147 2.147 0 0 1-2.147 2.146Z"
        fill={'currentColor'}
      />
      <path
        d="M99.584 80.23H85.728a2.147 2.147 0 0 1 0-4.294h13.856a2.144 2.144 0 0 1 2.147 2.147 2.145 2.145 0 0 1-2.147 2.147ZM83.077 49.716H40.864a2.147 2.147 0 0 1-2.147-2.147v-12.88a2.146 2.146 0 0 1 2.147-2.146h42.213a2.146 2.146 0 0 1 2.147 2.146v12.88a2.147 2.147 0 0 1-2.147 2.147ZM43.01 45.422h37.92v-8.586H43.01v8.586Z"
        fill={'currentColor'}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.422)" d="M0 0h114.157v100H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MyBookIcon;
